import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../store";
import { StudliLogo } from "../../../shared/components/StudliLogo";
import { Loader } from "../../../shared/components/Loader";
import { ReactComponent as CloseIcon } from "../../../assets/icons/icon_close.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/icon_info.svg";
import {
  StyledSplashWrapper,
  StyledLogoContainer,
  StyledInfoContainer,
  StyledAboutContainer,
  StyledAboutText,
  StyledLoaderContainer,
  StyledCloseButton,
  StyledInfoCard,
  StyledTopBar,
  StyledModalProductName,
  StyledModalContent,
  StyledTextContainer,
  StyledPrimaryCloseButton,
  StyledProductLogoContainer,
  StyledAccessDeniedCard,
  StyledAccessText,
} from "./StyledSplash";
import { showModal, hideModal } from "../../../shared/store/splash/actions";
import { Modal } from "../Modal";
import { PrimaryButton } from "../Button";
import { MRCredits } from "./MRCredits/MRCredits";
import { ReactComponent as TomoyoLogo } from "../../../assets/logos/tomoyo_logo_white_tagline.svg";

interface Props {
  applicationReady: boolean;
  splashVisible: boolean;
  modalVisible: boolean;
  hasAccess?: boolean;
  openModal(): void;
  closeModal(): void;
}

const BOOK_SHELF_URL_TEST = "https://minbokhylla.test.studentlitteratur.se/";
const BOOK_SHELF_URL_PROD = "https://minbokhylla.studentlitteratur.se/";

const SplashScreenComponent: React.FC<Props> = (props) => {
  const renderInfo = () => (
    <StyledInfoContainer>
      <StyledProductLogoContainer>
        <TomoyoLogo />
      </StyledProductLogoContainer>
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
      <StyledAboutContainer onClick={props.openModal}>
        <InfoIcon />
        <StyledAboutText>Mer information om Tomoyo</StyledAboutText>
      </StyledAboutContainer>
    </StyledInfoContainer>
  );

  const renderModalContent = () => {
    return (
      <React.Fragment>
        <StyledInfoCard>
          <StyledTopBar>
            <StyledModalProductName>Tomoyo</StyledModalProductName>
            <StyledCloseButton onClick={props.closeModal}>
              <CloseIcon />
            </StyledCloseButton>
          </StyledTopBar>
          <StyledModalContent>
            <StyledTextContainer>
              <MRCredits />
            </StyledTextContainer>
            <StyledPrimaryCloseButton>
              <PrimaryButton
                onClick={props.closeModal}
                label={"Stäng"}
                overrideStyles={{ padding: "12px 50px" }}
              />
            </StyledPrimaryCloseButton>
          </StyledModalContent>
        </StyledInfoCard>
      </React.Fragment>
    );
  };

  const renderAccessDenied = () => (
    <div>
      <StyledAccessDeniedCard>
        <StyledAccessText>Du saknar produkten i din bokhylla</StyledAccessText>
        <StyledPrimaryCloseButton>
          <a
            href={
              process.env.NODE_ENV === "production"
                ? BOOK_SHELF_URL_PROD
                : BOOK_SHELF_URL_TEST
            }
          >
            <PrimaryButton
              label={"Aktivera produkt"}
              overrideStyles={{ padding: "12px 50px" }}
            />
          </a>
        </StyledPrimaryCloseButton>
      </StyledAccessDeniedCard>
    </div>
  );

  const renderSplashContent = () => (
    <React.Fragment>
      <Modal show={props.modalVisible}>{renderModalContent()}</Modal>
      {renderInfo()}
    </React.Fragment>
  );

  const renderSplash = () =>
    props.applicationReady && !props.hasAccess && !props.modalVisible
      ? renderAccessDenied()
      : renderSplashContent();

  return props.splashVisible ? (
    <StyledSplashWrapper
      backgroundImage={
        "https://media.studentlitteratur.se/image/mr/story/images/MR_tile_medium_dark.jpg"
      }
    >
      {renderSplash()}
      <StyledLogoContainer>
        <StudliLogo color={"#FFFFFF"} />
      </StyledLogoContainer>
    </StyledSplashWrapper>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  splashVisible: state.splash.visible,
  modalVisible: state.splash.modalOpen,
  hasAccess: state.access.access.has_access,
  applicationReady: state.application.ready,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: () => dispatch(showModal()),
  closeModal: () => dispatch(hideModal()),
});

export const SplashScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(SplashScreenComponent as any);
