export const TOGGLE_AUDIO_OVERLAY: string = 'TOGGLE_AUDIO_OVERLAY';
export const REMOVE_AUDIO_OVERLAY: string = 'REMOVE_AUDIO_OVERLAY';
export const AUDIO_PLAYING: string = 'AUDIO_PLAYING';
export const SET_AS_PLAYING: string = 'SET_AS_PLAYING';

export const toggleAudioOverlay: () => any = () => ({
  type: TOGGLE_AUDIO_OVERLAY
});

export const toggleAudioPlaying = (isPlaying: boolean) => ({
  type: AUDIO_PLAYING,
  isPlaying
});

export const setAsPlaying = (id: string) => ({
  type: SET_AS_PLAYING,
  id
});