import styled from "styled-components";

export const StyledGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  flex: 1;

  > :nth-child(odd) {
    background-color: #ffffff;
  }
`;

export const StyledGridRow = styled.div<{ dynamicHeight?: boolean }>`
  width: 100%;
  height: ${(props) => (props.dynamicHeight ? "auto" : "128px")};
  background-color: #f9f6f6;
  display: flex;
  flex-grow: 1;
`;

export const StyledCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 0.21px;
  line-height: 28px;
  color: ${(props) => props.theme.themePrimary};
`;

export const StyledTextCell = styled(StyledCell)`
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 48px 0 48px 50px;
`;

export const StyledMarkersCell = styled.div`
  align-self: center;
  flex: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, 54px);
  grid-template-rows: repeat(auto-fit, 54px);
  grid-gap: 12px;
  align-content: flex-start;
  justify-items: center;
  padding: 38px 16px;
`;

export const StyledItemCell = styled(StyledCell)`
  justify-content: flex-end;
  padding: 10px 20px 10px 10px;
  height: 100%;
  width: 100%;

  div {
    max-width: 140px;
    max-height: 108px;
  }
`;

export const StyledMarkerItem = styled.div`
  width: 54px;
  height: 54px;
  max-height: 54px;
  max-width: 54px;
`;
