import styled from "styled-components";
import {
  StyledDashboardWrapper,
  DashboardProps,
} from "../../../../views/dashboard/StyledDashboard";

interface TextProps {
  fontWeight?: string;
}

interface LinkContainerProps {
  justifyContent?: string;
  resizeWidth?: boolean;
}

export const StyledCampaignWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #000000;
  min-width: 320px;
`;

export const StyledTiledBG = styled(StyledDashboardWrapper)<DashboardProps>`
  max-width: 1024px;
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  align-items: center;
  padding-bottom: 60px;
  position: relative;
  background-image: url(${(props: DashboardProps) => props.backgroundImage});
  background-repeat: initial;
`;

export const StyledStudliLogoContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: #c63337;
  display: flex;
  align-items: center;
  padding: 16px;

  svg {
    width: 100%;
    max-width: 150px;
  }

  @media (min-width: 1021px) {
    display: none;
  }
`;

export const StyledBowContainer = styled.div`
  @media (max-width: 1020px) {
    svg {
      display: none;
    }
  }
`;

export const StyledCampaignContainer = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.themePrimary};
  text-align: center;
  padding: 10px 0;

  @media (max-width: 590px) {
    padding: 10px;
  }
`;

export const StyledHeading = styled.h1`
  color: inherit;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: normal;
  text-align: inherit;
  margin: 66px 0 45px 0;
`;

export const StyledLogoContainer = styled.div`
  margin-top: 12px;

  svg {
    max-width: 346px;
    width: 100%;
    height: auto;
  }
`;

export const StyledCampaignLinkContainer = styled.div<LinkContainerProps>`
  display: flex;
  gap: 1rem;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  width: ${(props) => (props.resizeWidth ? "60%" : "100%")};
  margin: 45px 0;

  @media (max-width: 530px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledInfoBoxContainer = styled.div`
  border: 1px solid ${(props) => props.theme.themePrimary};
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const StyledInfoText = styled.div<TextProps>`
  margin: 10px 0;
  color: inherit;
  font-size: 20px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  letter-spacing: 0;
  line-height: normal;
  text-align: inherit;

  svg {
    margin-right: 8px;
    vertical-align: bottom;
  }

  a,
  a:visited {
    color: inherit;
  }
`;

export const StyledSubHeading = styled.h4`
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  text-align: inherit;
  margin-top: 35px;
`;

export const StyledSmallText = styled.p`
  margin-bottom: 14px;
  color: inherit;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
  text-align: inherit;
`;

export const StyledExtraSmallText = styled.p`
  color: inherit;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: inherit;
`;

export const StyledSmallSubHeading = styled.h5`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
`;

export const StyledLinksContainer = styled.div`
  margin: 40px 0 60px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled.a`
  color: inherit;
  font-size: 16px;
  margin-bottom: 14px;

  &:visited {
    color: inherit;
  }

  &:hover {
    color: #000;
  }
`;

export const StyledCampaignLink = styled.div`
  padding: 8px 18px;
  background-color: #006cc8;
  color: #ffffff;
  height: auto;
  min-height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5px 0.7px rgba(0, 0, 0, 0.022),
    0 1.2px 1.8px rgba(0, 0, 0, 0.032), 0 2.3px 3.4px rgba(0, 0, 0, 0.04),
    0 4px 6px rgba(0, 0, 0, 0.048), 0 7.5px 11.3px rgba(0, 0, 0, 0.058),
    0 18px 27px rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: #2784d6;
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    font-weight: 700;
    font-family: inherit;
    font-size: 14px;
    letter-spacing: 1.25px;
    line-height: normal;
  }

  @media (max-width: 530px) {
    margin: 10px 6px;
  }
`;

export const StyledCampaignLinkWide = styled(StyledCampaignLink)`
  width: 356px;
  margin: 0 0 30px 0;
`;

export const StyledLinkText = styled.span`
  margin: auto;
`;

export const StyledCampaignImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 540px;
  margin: 35px 0;
`;
