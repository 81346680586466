import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as createStoreRedux,
  Store,
} from "redux";
import axios from "axios";
import createSagaMiddleware from "redux-saga";
import createAxiosMiddleware from "../shared/api/middleware";
import { ReducerService } from "../services/reducer";
import { SagaService } from "../services/saga";
import { mountApplication } from "./Application/actions";
import { MarkersState } from "../views/markers/api/types";
import { OnboardingState } from "../views/onboarding/api/types";
import { AuthState } from "../shared/store/auth/types";
import { ProfileState } from "../views/profile/api/types";
import { ProgressState } from "./progress/types";
import { ApplicationState } from "./Application/types";
import { SplashState } from "../shared/store/splash/types";
import { FlasherState } from "../shared/store/flasher/types";
import { TypedMap } from "./utils/typed-map";
import { TestState } from "../views/test/api/types";
import { AccessState } from "./access/types";
import { NetworkState } from "../shared/store/network/types";
import { AdventureGroupState } from "../views/adventurePicker/api/types";
import { SnackbarState } from "../shared/components/Snackbar/api/types";
import { AssignmentState } from "../views/assignment/api/types";
import { State as RouterState } from "../shared/store/router/reducer";
declare global {
  /* tslint:disable */
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
    store: Store;
  }
  /* tslint:enable */
}

export interface RootState {
  exercise: any;
  markers: MarkersState;
  onboarding: OnboardingState;
  location: TypedMap<RouterState>;
  auth: AuthState;
  snackbar: SnackbarState;
  profile: ProfileState;
  progress: ProgressState;
  application: ApplicationState;
  splash: SplashState;
  flasher: TypedMap<FlasherState>;
  test: TestState;
  access: AccessState;
  network: NetworkState;
  adventureGroup: AdventureGroupState;
  assignments: AssignmentState;
  exercise_handler: any; // TODO RÄTTA TILL MED ORDENTLIG TYPE
  deck_reducer: any; // TODO RÄTTA TILL MED ORDENTLIG TYPE
  exerciseViewReducer: any; // TODO RÄTTA TILL MED ORDENTLIG TYPE
}

export interface State {
  [key: string]: any;
}

const sagaMiddleware = createSagaMiddleware();
const axiosMiddleware = createAxiosMiddleware(axios);

function getComposeEnhancers(): <R>(a: R) => R {
  if (
    process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // eslint-disable-line
  }
  return compose;
}

export const createStore = (initialState: State = {}) => {
  const composeEnhancers = getComposeEnhancers();

  const rootReducer = combineReducers({
    ...ReducerService.fold(),
  });

  const store = createStoreRedux(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, axiosMiddleware))
  );

  sagaMiddleware.run(SagaService.root());
  store.dispatch(mountApplication());

  if (process.env.NODE_ENV === "development") {
    window.store = store;
  }

  return store;
};
