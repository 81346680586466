import { List, Map } from "immutable";

type AllowedValue =
  | string
  | number
  | boolean
  | AllowedMap
  | AllowedList
  | TypedMap<any>
  | undefined;

interface AllowedList extends List<AllowedValue> {}

interface AllowedMap extends Map<string, AllowedValue> {}

export type MapTypeAllowedData<DataType> = {
  [K in keyof DataType]: AllowedValue
};

/**
 * TypedMap is a immutablejs Map with specific allowed values.
 * It also adds better integration with provided type
 *
 * @export
 * @interface TypedMap
 * @extends {Map<string, AllowedValue>}
 * @template DataType
 */
export interface TypedMap<DataType extends MapTypeAllowedData<DataType>>
  extends Map<string, AllowedValue> {
  toJS(): DataType;
  get<K extends keyof DataType>(key: string, notSetValue?: DataType[K]): DataType[K];
  set<K extends keyof DataType>(key: string, value: DataType[K]): this;
  update(updater: (value: this) => this): this;
  update<K extends keyof DataType>(
    key: string,
    updater: (value: DataType[K]) => DataType[K]
  ): this;
  update<K extends keyof DataType>(
    key: string,
    notSetValue: DataType[K],
    updater: (value: DataType[K]) => DataType[K]
  ): this;
  remove(key: string): this;
}

export const createTypedMap = <DataType extends MapTypeAllowedData<DataType>>(
  data: DataType
): TypedMap<DataType> => Map(data) as any;
