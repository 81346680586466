import * as React from "react";
import {
  StyledColumnContent,
  StyledHelpHeading,
  StyledAudioContainer,
  StyledHelpText,
} from "../StyledHelp";
import { HelpData } from "../../../../views/exercise/api/types";
import { connect } from "react-redux";
import { AudioPlayerComponent } from "../../Audio";
import HtmlRenderer from "../../HtmlRenderer";

interface Props {
  data: HelpData;
  isHelpShowing: boolean;
}

export const HelpAudio: React.FC<Props> = ({ data, isHelpShowing }) => (
  <StyledColumnContent>
    <StyledHelpHeading>{data.title}</StyledHelpHeading>
    <StyledAudioContainer>
      {data.audio ? (
        <AudioPlayerComponent url={data.audio.src} condition={!isHelpShowing} />
      ) : null}
    </StyledAudioContainer>
    {data.text && <HtmlRenderer component={StyledHelpText} html={data.text} />}
  </StyledColumnContent>
);

const mapStateToProps = (state: any) => ({
  isHelpShowing: state.exercise.isHelpShowing,
});

export default connect(mapStateToProps)(HelpAudio);
