import styled from "styled-components";
import { deviceSize } from "../../../../styles/theme";
import { MIDDLE_SCHOOL, PRIMARY_SCHOOL } from "../../../constants";
import { LayoutOptionsProps } from "../StyledIntroductionView";

export const StyledSliderBox = styled.div<LayoutOptionsProps>`
  position: relative;
  display: flex;
  flex: 1;
  ${(props) => props.theme === PRIMARY_SCHOOL && "flex-direction: column"}
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  ${(props) => props.theme === MIDDLE_SCHOOL && "place-self: flex-end;"}
  ${(props) => props.theme === MIDDLE_SCHOOL && "margin: 20px 10px 50px 10px;"}
  ${(props) => props.theme === PRIMARY_SCHOOL && "min-height: 40%;"}
  ${(props) => props.theme === PRIMARY_SCHOOL && "max-height: 75%;"}
  ${(props) =>
    props.theme === PRIMARY_SCHOOL && "box-shadow: 0 0 24px 0 rgba(0,0,0,0.5);"}
    ${(props) => props.theme === PRIMARY_SCHOOL && "background-color: #F9F6F6;"}

  @media (min-width: ${deviceSize.desktopL}px) {
    max-height: ${(props) => (props.showMarkers ? "400px" : "700px")};
    max-width: 1000px;
  }
`;

export const ContentWrapper = styled.div<LayoutOptionsProps>`
  display: flex;
  ${(props) => props.theme === MIDDLE_SCHOOL && "flex-direction: column;"}
  flex-grow: 1;
  overflow: auto;
  ${(props) => props.theme === MIDDLE_SCHOOL && "background-color: #F9F6F6;"}
  ${(props) =>
    props.theme === MIDDLE_SCHOOL && "box-shadow: 0 0 24px 0 rgba(0,0,0,0.5);"}
`;

export const StyledAudioContainer = styled.div<LayoutOptionsProps>`
  ${(props) => props.theme === MIDDLE_SCHOOL && "align-self: flex-end;"}
  ${(props) => props.theme === MIDDLE_SCHOOL && "margin: -20px 10px 0px 0px;"}
  ${(props) => props.theme === MIDDLE_SCHOOL && "position: absolute;"}
  ${(props) => props.theme === PRIMARY_SCHOOL && "margin-bottom: 20px;"}
`;

export const StyledSliderBoxArrowWrapper = styled.div<LayoutOptionsProps>`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 60px;
  flex-grow: 0;
  ${(props) => props.theme === MIDDLE_SCHOOL && "margin 0px 10px;"}
`;

export const StyledNextButton = styled.button`
  cursor: pointer;
  display: flex;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.themePrimary};

  &:hover {
    color: ${(props) => props.theme.themeSecondary};
  }

  svg {
    transition: color 0.2ms ease;
  }
`;

export const StyledSliderBoxContent = styled.div<LayoutOptionsProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: ${(props) => (props.theme === PRIMARY_SCHOOL ? "30px 0" : "20px")};

  @media (min-width: ${deviceSize.desktopL}px) {
    ${(props) => props.theme === PRIMARY_SCHOOL && "padding: 50px 20px;"}
  }
`;

export const StyledSliderBoxContentText = styled.p`
  font-size: 22px;
  letter-spacing: 0.58px;
  line-height: 32px;
  overflow: auto;

  @media (min-width: 1400px) {
    font-size: 28px;
    line-height: 42px;
  }

  ul {
    margin: 10px 0 10px 30px;
  }
`;

export const StyledSliderBoxContentTitle = styled(StyledSliderBoxContentText)`
  margin-bottom: 20px;
  overflow: visible;
  font-weight: 600;
  color: ${(props) => props.theme.themePrimary};
`;

export const StyledSliderBoxContentOptional = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 20px;
`;
