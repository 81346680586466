import * as React from "react";

import AutoLaTeX from 'react-autolatex';
import {SliderItem} from "react-compound-slider";
import {
  StyledTickmark,
  StyledTicklabel,
  StyledTickImage,
  StyledTickImageContainer
} from "./StyledXcNumberLine";
import {LINES} from "../../../../../../../shared/constants";

interface TickProps {
  tick: SliderItem;
  count?: number;
  label: string
  showLabel: boolean;
  key?: any;
  marking: string;
  image: string;
}

export const Tick: React.FC<TickProps> = ({tick: { percent }, count, label, showLabel, marking, image}) => {
  /**
   * Conditionally renders label
   * @returns {JSX.Element}
   */
  const renderLabel = ():JSX.Element | null => image
    ? renderTickImage()
    : renderLabelText();
  
  /**
   * Conditionally renders label text
   * @returns {JSX.Element}
   */
  const renderLabelText = ():JSX.Element | null => {
    label = label.includes('.') ? label.replace('.', ',') : label;
    return showLabel ? <AutoLaTeX>{label}</AutoLaTeX> : null;
  }
  
  /**
   * Conditionally renders tick image
   * @returns {JSX.Element}
   */
  const renderTickImage = ():JSX.Element => ( 
    <StyledTickImageContainer>
      <StyledTickImage src={image} alt='Tick image' />
    </StyledTickImageContainer>
  );

  /**
   * Conditionally renders tick mark
   * @returns {JSX.Element}
   */
  const renderTickMark = ():JSX.Element | null => marking === LINES
    ? <StyledTickmark
      percent={percent}
    />
    : null;
  
  return (
    <div>
      <StyledTicklabel
        percent={percent}
        count={count}
      >
        { renderLabel()}
      </StyledTicklabel>
      { renderTickMark() }
    </div>
  );
};
