import styled from "styled-components";
import { deviceSize } from "../../styles/theme";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check_mark2.svg";
import { Button } from "../../shared/components/Button";

interface OnboardingProps {
  backgroundImage?: string;
}

export const StyledOnboardingWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%),
    url(${(props: OnboardingProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;

  @media (-ms-high-contrast: none), (-ms-high-contrast: active) /* FIX IE 10 & 11 */
  {
    *::-ms-backdrop {
      background: url(${(props: OnboardingProps) => props.backgroundImage})
      repeat-x repeat-y;
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%));
  }

  @media (max-width: ${deviceSize.phone}px ) {
    justify-content: flex-start;
  }
`;

export const StyledOnboardingContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto 0;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-top: 7rem;
  }
`;

export const StyledOnboardingRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`;

export const OnboardingSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
`;

export const OnboardingConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAudioContainer = styled.div`
  width: 100%;
`;

export const OnboardingHeadingContainer = styled.div`
  max-width: 100%;
  word-wrap: break-word;
  hyphens: auto;
  display: inline-flex;
  flex-direction: column;
  padding: 12px;
  margin-bottom: 24px;
  @media (max-width: ${deviceSize.phone}px) {
    padding: 12px 16px;
    margin-top: 24px;
  }
`;

export const OnboardingHeading = styled.div`
  color: ${(props) => props.theme.themePrimary};
  font-size: 36px;
  font-weight: bold;
  line-height: 50px;
  text-transform: capitalize;

  @media (max-width: ${deviceSize.tabletS}px) {
    line-height: 38px;
    font-size: 36px;
    margin-bottom: 8px;
  }
`;

export const OnboardingParagraph = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${(props) => props.theme.themePrimary};
  line-height: 33px;
  margin-top: 1.5rem;

  @media (max-width: ${deviceSize.tabletS}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const OnboardingSelection = styled.div`
  font-size: 24px;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.themePrimary};
  line-height: 33px;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const StyledFomTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.32px;
  line-height: 34px;
  align-self: flex-start;
  margin-bottom: 5px;
  color: ${(props) => props.theme.themePrimary};
`;

export const StyledOnboardingFooter = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px;
`;

export const StyledCheckMark = styled(CheckMarkIcon)`
  color: #12726b;
`;

export const StyledIconButton = styled(Button)`
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: 2px solid #12726b;
  margin-bottom: 0.5rem;
`;
