import styled from 'styled-components';
import { getCellSize } from '../../../Chip/chipsFormats';

interface Props {
  chipsFormat: string;
}

export const StyledOrderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledOrderBoxContainer = styled.div`
  width: 100%;
  flex: 1;
  margin: 20px 0;
`;

export const StyledOrderBoxGraphic = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => getCellSize(props.chipsFormat)});
  grid-gap: 8px;
  justify-content: center;
  width: 100%;
  margin: auto;
`;