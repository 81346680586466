import * as React from 'react';
import XcAudioSL from "./XcAudioStateless";

export interface AudioProps {
  path: string,
  icon?: string,
}

export interface AudioState {
  isPlaying: boolean;
}

export class XcAudio extends React.PureComponent<AudioProps, AudioState> {
  private audio: HTMLAudioElement;

  constructor(props: AudioProps) {
    super(props);
    this.audio = new Audio(this.props.path);
    this.state = {
      isPlaying: false,
    };
  }

  public componentDidMount() {
    this.audio.addEventListener('ended', this.endAudio);
  }

  public componentWillUnmount() {
    this.stopAudio();
    this.audio.removeEventListener('ended', this.endAudio);
  }

  public render() {
    return <XcAudioSL
      active={this.state.isPlaying}
      callback={this.toggleAudio}
      aria-label="Lyssna"
    />
  };

  private endAudio = () => this.setState({ isPlaying: false });

  private stopAudio = () => {
    this.audio.pause();
    this.setState({
      isPlaying: false,
    });
  };

  private playAudio = () => {
    this.audio.currentTime = 0;
    this.audio.play();
    this.setState({
      isPlaying: true,
    });
  };

  private toggleAudio = (e: React.MouseEvent) => {
    this.audio.currentTime = 0;
    if (this.state.isPlaying) {
      this.stopAudio();
    } else {
      this.playAudio();
    }
    e.stopPropagation();
  }
}

export default XcAudio;
