import * as React from "react";
import { StyledLoader, LoadingDot } from "./StyledLoader";

export const Loader = () => (
  <StyledLoader>
    <LoadingDot />
  	<LoadingDot />
  	<LoadingDot />
  	<LoadingDot />
  	<LoadingDot />
  	<LoadingDot />
  	<LoadingDot />
  </StyledLoader>
);