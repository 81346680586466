import axios from "axios";

export function resetGameProgressRequest(token: string, productId: number) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `/api/product/${productId}/testdashboard/adventure/resetgameprogress`,
    null,
    config
  );
}
