import * as React from 'react';
import { StyledInputContainer } from '../../StyledFraction';
import {
  StyledFractionInput,
  StyledCaret
} from './StyledFractionInput';
import {
  renderCross
} from '../../helpers';
import { isIOSDevice } from '../../../../../../../../../shared/platform/Platform';
import { VirtualKeyboard } from '../../../../../VirtualKeyboard/VirtualKeyboard';
import { getKeyboardLayout, getKeyboardPosition } from '../../../../../VirtualKeyboard/helpers';
import { KeyboardData } from '../../../../../VirtualKeyboard/types';

interface Props {
  borderBottom: boolean;
  cellIndex?: number;
  correctedStateValue?: string;
  renderCross: boolean;
  disabled: boolean;
  id: string;
  isTouchDevice: boolean;
  isVertical?: boolean;
  isHorizontal?: number;
  isInteger?: boolean;
  isDecimal?: boolean;
  isPercent?: boolean;
  answerLength?: number;
  keyboardData?: KeyboardData;
  refObj: any;
  refIndex?: number;
  value: string;
  width: number;
  callback: (param: string, param2: number, param3: string) => void;
}


export const FractionInput: React.FC<Props> = (props) => {

  React.useEffect(() => {
    const onResize = () => {
      props.isTouchDevice && props.refObj.current.blur();
    }

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [props.refObj, props.isTouchDevice]);

  const [showNumpad, setShowNumpad] = React.useState<boolean>(false);

  const selectText = (event: React.FocusEvent) => {
    const inputEl = event.target as HTMLInputElement;
    /*Delay here is so text wont be instantly deselected */
    setTimeout(() => {
      isIOSDevice() ? inputEl.setSelectionRange(0, inputEl.value.length) : inputEl.select();
    }, 0);
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateValue(e.currentTarget.value)
  }

  const handleOnFocus = (e: React.FocusEvent) => {
    selectText(e);
    setShowNumpad(true);
  }

  const handleOnBlur = (e: React.FocusEvent) => {
    setShowNumpad(false);
  }

  const updateValue = (value: string) => {
    value = value.replace(',', '.');
    props.callback(value, props.refIndex ? props.refIndex : 0, props.id)
  }

  const numpadCallback = (value: string) => {
    // '%&' means backspace
    const allowedLength = props.isDecimal || props.isPercent ? 6 : props.isInteger ? 2 : 4;
    if (value === '%&'){
      value = props.value.slice(0,-1);
    }else if (props.value.length === allowedLength) {
      value = props.value;
    } else if (value === ','){
      value = props.value + '.';
    }else {
      value = props.value + value;
    }

    updateValue(value)
  }

  return (
    <>
    <StyledInputContainer
      key={'container_' + props.cellIndex}
      correctedState={props.correctedStateValue}
      isVertical={props.isVertical}
      isInteger={props.isInteger}
      isHorizontal={props.isHorizontal}
      borderBottom={props.borderBottom}
      width={props.width}
    >
      { 
        // cursor in input field
        props.isTouchDevice && showNumpad && props.value.length < 1 && <StyledCaret margin={null} isHorizontal={props.isHorizontal} />
      } 
      <StyledFractionInput
        ref={props.refObj} 
        inputMode={'numeric'}
        onChange={handleOnChange}
        maxLength={props.isDecimal || props.isPercent ? 6 : props.isInteger ? 3 : 4}
        value={props.value.replace('.',',')}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        correctedState={props.correctedStateValue}
        disabled={props.disabled}
        readOnly={props.isTouchDevice}
        isVertical={props.isVertical}
        isInteger={props.isInteger}
        isHorizontal={props.isHorizontal}
      />
        {renderCross(props.correctedStateValue, props.renderCross, props.isInteger, props.width, props.isHorizontal)}
    </StyledInputContainer>
      {props.isTouchDevice && showNumpad
        ? <VirtualKeyboard 
            buttonLayout={props.keyboardData ? getKeyboardLayout(props.keyboardData) : undefined} 
            callback={numpadCallback}
            position={getKeyboardPosition()}
          /> 
        : null}
    </>
  )
}
