import * as React from "react";
import { ButtonGroup } from "./StyledXcMultipleChoice";
import { MpcButton } from "./MultipleChoiceBtn";
import { CorrectionBtnStates } from "../../../CorrectionButton";
import {
  MultipleChoiceProps,
  MultipleChoiceState,
  MultipleChoiceOption,
  OptionId,
} from "./MultipleChoiceTypes";
import { shuffleArray } from "../../../../../Helpers";

export class XcMultipleChoice extends React.PureComponent<
  MultipleChoiceProps,
  MultipleChoiceState
> {
  public static defaultProps = {
    // TODO: Remove props that aren't used
    settings: {
      multipleAnswers: false,
      randomizeAlternatives: false,
    },
    buttonLineUp: "horizontal",
    buttonSize: "medium",
  };

  public state: MultipleChoiceState = {
    selectedAnswerId: [],
    previousAnswers: [[]],
    isCorrect: false,
    retry: false,
  };

  private options: MultipleChoiceOption[] = [];

  constructor(props: MultipleChoiceProps) {
    super(props);
    this.options = this.prepOptions();
  }

  public render() {
    return (
      <ButtonGroup layout={this.props.size}>{this.renderButtons()}</ButtonGroup>
    );
  }

  /**
   * Prep options, setting up correct settings for answers
   * @returns {any[]}
   */
  private prepOptions = () => {
    const opt = this.props.options || [];

    return shuffleArray(opt);

    // * Keeping this in case we want to use static or random alternatives *
    // return this.props.settings.randomizeAlternatives
    //   ? shuffleArray(opt)
    //   : opt;
  };

  /**
   * Renders MpcButtons
   *
   * @returns {JSX.Element[]}
   */
  private renderButtons = (): JSX.Element[] =>
    this.options.map((option: MultipleChoiceOption, index: number) => {
      return (
        <MpcButton
          key={index.toString() + "_" + option.id}
          id={option.id}
          isGivenAnswer={this.isPreviousAnswer(option.id)}
          isSelected={this.isSelected(option.id)}
          answerState={this.props.state}
          retry={this.state.retry}
          assets={option.assets}
          image={option.image}
          callback={this.setSelectionStateAndDoCallback}
          displayCorrectAnswer={this.props.displayCorrectAnswer}
          isCorrectAnswer={this.getIsCorrectAnswer(option.id)}
          size={this.props.size}
          aria-label="Fler vals knapp"
          fontSize={this.props.settings.fontSize}
        >
          {option.text}
        </MpcButton>
      );
    });

  /**
   * Check if its the right answer
   * @param {id}
   */
  private getIsCorrectAnswer = (id: string): boolean =>
    this.props.correctAnswers &&
    this.props.correctAnswers.correctAnswers.includes(id);

  /**
   * Used to check in a button whether it's selected or not
   *
   * @param {OptionId} id
   * @returns {boolean}
   */
  private isSelected = (id: OptionId): boolean =>
    this.existsInArray(id, this.state.selectedAnswerId);

  /**
   * Creates new array and removes ID from it
   *
   * @param {OptionId} id
   * @param {OptionId[]} arr
   * @returns {OptionId[]}
   */
  private removeId = (id: OptionId, arr: OptionId[]): OptionId[] =>
    arr.filter((arrId: number | string) => arrId !== id);

  /**
   * Creates a new array and append ID to it
   *
   * @param {OptionId} id
   * @param {OptionId[]} arr
   * @returns {OptionId[]}
   */
  private addId = (id: OptionId, arr: OptionId[]): OptionId[] => [...arr, id];

  /**
   * Returns a boolean based on if an ID exists in an array
   *
   * @param {OptionId} id
   * @param {OptionId[]} arr
   * @returns {boolean}
   */
  private existsInArray = (id: OptionId, arr: OptionId[]): boolean =>
    arr.findIndex((arrId: OptionId) => arrId === id) > -1;

  /**
   * Used to check in a button whether it's part of answer already given in last try
   *
   * @param {OptionId} id
   * @returns {boolean}
   */
  private isPreviousAnswer = (id: OptionId): boolean =>
    this.props.currentAttempt > 0
      ? this.state.previousAnswers &&
        this.state.previousAnswers.length > 0 &&
        this.state.previousAnswers[this.props.currentAttempt - 1] &&
        this.state.previousAnswers[this.props.currentAttempt - 1].length > 0 &&
        this.existsInArray(
          id,
          this.state.previousAnswers[this.props.currentAttempt - 1]
        )
      : false;

  /**
   * Creates a new array and add's or removes id based on if it does exist in old array or not
   *
   * @param {OptionId} id
   * @param {OptionId[]} arr
   * @returns {OptionId[]}
   */
  private addOrRemoveId = (id: OptionId, arr: OptionId[]): OptionId[] =>
    this.existsInArray(id, arr) ? this.removeId(id, arr) : this.addId(id, arr);

  /**
   * Updates selected states and publishes answer
   *
   * @param {OptionId} id
   */
  private setSelectionStateAndDoCallback = (id: OptionId): void =>
    this.props.settings.multipleAnswers
      ? this.setState((prevState: MultipleChoiceState) => {
          return {
            selectedAnswerId: this.addOrRemoveId(
              id,
              prevState.selectedAnswerId
            ),
          };
        }, this.publishSelectionState)
      : this.setState({ selectedAnswerId: [id] }, this.publishSelectionState);

  /**
   * Saves previous attempts to state
   */
  private savePreviousAttempts = () => {
    const previousAnswers: OptionId[][] = [...this.state.previousAnswers];
    previousAnswers[this.props.currentAttempt] = [
      ...this.state.selectedAnswerId,
    ];

    this.setState({
      previousAnswers,
      retry: this.props.state === CorrectionBtnStates.TRY_AGAIN,
    });
  };

  /**
   * First updates state for button CSS behaviour then runs callback to publish answer to rootParent
   */
  private publishSelectionState = (): void => {
    this.savePreviousAttempts();

    const answers = { answers: this.state.selectedAnswerId };

    this.props.callback(JSON.stringify(answers));
  };
}

export default XcMultipleChoice;
