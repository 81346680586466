import * as React from "react";

import {
  GetHandleProps,
  SliderItem
} from "react-compound-slider";
import Handle from "./Handle/Handle";
import {StyledHandle} from "./Handle/StyledHandle";

export interface HandleProps {
  handle: SliderItem;
  hasLatex: boolean;
  startingPosition: number;
  getHandleProps: GetHandleProps;
  exeState?: string;
  correctPosition?: number;
  displayCorrectAnswer?: boolean;
}

export const Handler: React.FC<HandleProps> = ({ handle: { id, percent, value }, startingPosition, getHandleProps, exeState, correctPosition, displayCorrectAnswer }):JSX.Element => {
  /**
   * Returns true if tha handle has been moved from start position
   * @returns {boolean}
   */
  const handleHasMoved = ():boolean => startingPosition !== value;
  
  const getPosition = (): number => (displayCorrectAnswer && (correctPosition !== undefined)
    ? correctPosition
    : percent
  );
  
  return (
    <div>
      <StyledHandle
        percent={getPosition()}
        handleHasMoved={handleHasMoved()}
        {...getHandleProps(id)}
        >
        <Handle 
          exeState={exeState}
          displayCorrectAnswer={displayCorrectAnswer}
        />
      </StyledHandle>
    </div>
  )
};
