import { takeLatest, call, put } from "typed-redux-saga";
import axios from "axios";
import {
  AUTH_TOKEN_API_ACTIONS,
  AUTH_READY,
  AUTH_TOKEN_UPDATE,
  AUTH_TOKEN_UPDATE_FAILURE,
  AUTH_TOKEN_UPDATE_SUCCESS,
  CAMPAIGN_REQUEST,
  CAMPAIGN_REQUEST_SUCCESS,
  CAMPAIGN_REQUEST_FAILURE,
} from "./actions";
import { showSplash } from "../splash/actions";

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watchGetTokenSaga() {
  yield* takeLatest(AUTH_TOKEN_API_ACTIONS.READ.REQUEST, getTokenSaga);
}

export function* watchUpdateTokenSaga() {
  yield* takeLatest(AUTH_TOKEN_UPDATE, updateTokenFromResponseHeader);
}

export function* watchRequestCampaignSaga() {
  yield* takeLatest(CAMPAIGN_REQUEST, getCampaignSaga);
}

function fetchCampaign(payload: any) {
  return axios.get(`/open-api/campaign/${payload.campaignName}`);
}

// fetches token from oauth2 server and returns a Promise
function fetchToken(payload: any) {
  const body = {
    code: payload.code,
    state: payload.state,
    redirect_uri: payload.redirectUri,
  };

  return axios.post(payload.tokenUrl, body);
}

function* getCampaignSaga(action: any) {
  try {
    const response = yield* call(fetchCampaign, action.payload);
    yield* put({ type: CAMPAIGN_REQUEST_SUCCESS, campaign: response.data });
  } catch (error) {
    yield* put({ type: CAMPAIGN_REQUEST_FAILURE, error });
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* getTokenSaga(action: any) {
  try {
    yield* put(showSplash());
    const response = yield* call(fetchToken, action.payload);
    const jwt = response.data.access_token;

    // dispatch a success action to the store with the exercises
    yield* put({ type: AUTH_TOKEN_API_ACTIONS.READ.SUCCESS, jwt });
    yield* put({ type: AUTH_READY });
  } catch (error) {
    yield* put({ type: AUTH_TOKEN_API_ACTIONS.READ.FAILURE, error });
    // TODO: Display some error page if authentication fails
  }
}

function* updateTokenFromResponseHeader(action: any) {
  try {
    const jwt = getAuthTokenFromHeaders(action.headers);
    if (jwt) {
      yield* put({ type: AUTH_TOKEN_UPDATE_SUCCESS, jwt });
    }
  } catch (error) {
    yield* put({ type: AUTH_TOKEN_UPDATE_FAILURE, error });
  }
}

function getAuthTokenFromHeaders(headers: any) {
  const tokenPrefix = "Bearer ";
  if (
    headers.authorization &&
    headers.authorization.substr(0, tokenPrefix.length) === tokenPrefix
  ) {
    return headers.authorization.replace(tokenPrefix, "");
  }
  return "";
}
