import {
  AUDIO_PLAYING,
  SET_AS_PLAYING,
  TOGGLE_AUDIO_OVERLAY,
  REMOVE_AUDIO_OVERLAY
} from "./actions";

const initialState = {
  soundComponents: {},
  audioOverlayActive: false,
  isPlaying: false,
  currentlyPlayingAudioId: ''
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case TOGGLE_AUDIO_OVERLAY:
      return { ...state, audioOverlayActive: !state.audioOverlayActive};
    case REMOVE_AUDIO_OVERLAY:
      return { ...state, audioOverlayActive: false};
    case AUDIO_PLAYING:
      return { ...state, isPlaying: action.isPlaying};
    case SET_AS_PLAYING:
      return { ...state, currentlyPlayingAudioId: action.id };

    default:
      return state;
  }
}