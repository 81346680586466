import * as React from "react";
import { StyledLineupGrid, StyledCellValue } from "./StyledLineup";
import { LineupInput } from "./components/LineupInput";
import { LineUpCell } from "./types";
import { LineupCell } from "./components/LineupCell";
import {
  focusNextInput,
  isInteger,
  getCorrectedState,
  renderEmptyRow,
  renderMemoryCell,
  prepareGridData,
  renderStaticComma,
  updateCommaInGrid,
} from "./helpers";
import { hasAttemptsLeft } from "../../../../../Helpers";
import {
  isTouchDevice,
  isMobileDevice,
} from "../../../../../../../shared/platform/Platform";

interface Props {
  grid: LineUpCell[][];
  settings: any;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  focusInput: boolean;
  correctAnswers: any;
  keyboardData?: string;
  state: string;
  callback(answers: string): void;
}

interface CellPosition {
  row: number;
  cell: number;
}

const CELL_PADDING = 1;

export const XcLineup: React.FC<Props> = (props) => {
  const inputRefs: Array<React.RefObject<HTMLInputElement>> = [];
  const inputRefsMap: Record<string, React.RefObject<HTMLInputElement>> = {};
  const isTouch = isTouchDevice();
  const isMobile = isMobileDevice();
  const [commaSelection, setCommaSelection] = React.useState("");

  /**
   * Prepare grid data
   */
  const [gridData, setGridData] = React.useState(function getInitialState() {
    return prepareGridData(props.grid);
  });

  /**
   * Collect answer and run callback
   */
  const publishSelectionState = (answer: LineUpCell[][]): void => {
    props.callback(JSON.stringify({ answer }));
  };

  const updateGridDataForComma = (id: string) => {
    const newList = updateCommaInGrid(id, gridData, commaSelection);
    setCommaSelection(id === commaSelection ? "" : id);
    setGridData(newList);
    publishSelectionState(newList);
  };

  /**
   * Updates the grid with user input.
   */
  const updateGridData = (id: string, value: string) => {
    let memoryPos: CellPosition | null = null;

    const newList = gridData.map((row, rowIndex) =>
      row.map((cell: LineUpCell, cellIndex) => {
        if (cell.id === id) {
          memoryPos = { row: rowIndex - 3, cell: cellIndex - 1 };
          return { ...cell, value };
        }

        return cell;
      })
    );

    // Toggle visibility of memory digit
    if (memoryPos !== null && props.settings.calculateType === "plus") {
      const rowString = "row";
      const cellString = "cell";
      const memoryCell = newList[memoryPos[rowString]][memoryPos[cellString]];
      memoryCell.hidden = value === "";
    }

    setGridData(newList);
    publishSelectionState(newList);
  };

  const inputCallback = (value: string, refIndex: number, id: string) => {
    updateGridData(id, value);
    isInteger(value) && focusNextInput(refIndex, inputRefs);
  };

  /**
   * Create input elements for interactive cells.
   * A ref is saved to help with auto focus.
   */
  const createInputEl = (
    cell: LineUpCell,
    rowIndex: number,
    cellIndex: number
  ) => {
    const correctedState = getCorrectedState(
      cell.value,
      cell.comma,
      rowIndex,
      cellIndex,
      props.correctAnswers,
      props.currentAttempt
    );
    const ref = React.createRef<HTMLInputElement>();
    inputRefs.push(ref);
    inputRefsMap[cell.id] = ref;

    return (
      <LineupInput
        id={cell.id || ""}
        refObj={ref}
        callback={inputCallback}
        refIndex={inputRefs.length - 1}
        rowIndex={rowIndex}
        value={cell.value}
        key={cellIndex}
        disabled={
          !hasAttemptsLeft(props.currentAttempt, 2) || props.state === "CORRECT"
        }
        correctedStateComma={correctedState.comma}
        correctedStateValue={correctedState.value}
        keyboardType={props.settings.keyboardCharacterSet}
        keyboardData={
          props.keyboardData ? JSON.parse(props.keyboardData) : undefined
        }
        inContainer
        updateCommaCallback={updateGridDataForComma}
        comma={cell.comma}
        isCommaSelected={commaSelection !== ""}
        cross={correctedState.value === "INCORRECT"}
        cellIndex={cellIndex}
        isTouchDevice={isTouch && isMobile}
      />
    );
  };

  /**
   * Toggle visibility of memory digit in subtraction.
   */
  const toggleSubtractionMemory = (id: string, memoryCellId: string) => {
    let nextCell: LineUpCell | undefined;
    let memoryCell: LineUpCell | undefined;

    let newList = gridData.map((row, rowIndex) =>
      row.map((cell: LineUpCell, cellIndex: number) => {
        if (cell.id === id) {
          return { ...cell, crossed: !cell.crossed };
        }

        if (cell.id === memoryCellId) {
          // Necessary to save reference to this cell for later
          memoryCell = cell;
          // If the value of the cell under this is 0, then it is possible to borrow from this one.
          if (gridData[rowIndex + 1][cellIndex].value === "0") {
            nextCell = gridData[rowIndex][cellIndex + 1];
          }
          return { ...cell, hidden: !cell.hidden };
        }

        return cell;
      })
    );

    // Abort operation if another cell has borrowed from this memorycell.
    if (memoryCell && memoryCell.crossed) {
      return;
    }

    // If the next memorycell needs to borrow from this memorycell.
    if (nextCell !== undefined && nextCell.inMemory && !nextCell.hidden) {
      newList = newList.map((row) =>
        row.map((cell) => {
          if (nextCell !== undefined && cell.id === nextCell.id) {
            return { ...cell, hidden: !cell.hidden };
          }
          return cell;
        })
      );
    }

    setGridData(newList);
  };

  const borrowCallback = (id: string, memoryCellId: string) => {
    toggleSubtractionMemory(id, memoryCellId);
  };

  /**
   * You can borrow from cell if it is in the
   * second row, has a value and is not the last cell.
   */
  const isBorrowable = (row: number, cell: number, cellObj: LineUpCell) => {
    // Not possible to borrow from 0
    if (cellObj.value === "0") {
      return;
    }

    if (row === 0 && cellObj.inMemory === true) {
      // Check if digit under this one is 0
      const cellBelowThisOne = gridData[row + 1][cell];
      if (cellBelowThisOne.value === "0") {
        const memoryCell = gridData[row][cell + 1];

        if (memoryCell && memoryCell.inMemory) {
          return memoryCell.id;
        }
      }
    }

    if (row > 0) {
      const memoryCell = gridData[row - 1][cell + 1];
      if (memoryCell && memoryCell.inMemory) {
        return memoryCell.id;
      }
    }
  };

  const renderRow = (row: LineUpCell[], rowIndex: number) => {
    return row.map((cell: LineUpCell, cellIndex: number) => {
      return cell.interactive ? (
        createInputEl(cell, rowIndex, cellIndex)
      ) : (
        <LineupCell
          id={cell.id}
          memoryCellId={
            props.settings.calculateType === "minus"
              ? isBorrowable(rowIndex, cellIndex, cell)
              : undefined
          }
          callback={props.currentAttempt < 2 ? borrowCallback : undefined}
          borderBottom={rowIndex === gridData.length - 2}
          key={"key_" + cellIndex}
          hidden={cell.hidden}
          crossed={cell.crossed}
          displayCorrectAnswer={props.displayCorrectAnswer}
          isMemoryDigit={cell.inMemory}
        >
          {cell.inMemory ? (
            renderMemoryCell(
              cell.hidden,
              cell.value,
              props.displayCorrectAnswer
            )
          ) : (
            <StyledCellValue>{cell.value}</StyledCellValue>
          )}
          {renderStaticComma(cell.comma)}
        </LineupCell>
      );
    });
  };

  /**
   * Renders the grid. An empty cell is added to
   * the beginning and end of each row as padding.
   */
  const renderData = (grid: LineUpCell[][]) => {
    return grid.map((row, index) => {
      return (
        <React.Fragment key={"key_" + index}>
          <LineupCell borderBottom={index === grid.length - 2} />
          {renderRow(row, index)}
          <LineupCell borderBottom={index === grid.length - 2} />
        </React.Fragment>
      );
    });
  };

  React.useEffect(() => {
    const answerRow = gridData.length - 1;
    const firstInputCell = gridData[answerRow][gridData[answerRow].length - 1];
    const ref = inputRefsMap[firstInputCell.id];

    if (props.focusInput && ref && ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line
  }, [props.focusInput]);

  return (
    <StyledLineupGrid rowLength={gridData[0].length + CELL_PADDING * 2}>
      {renderEmptyRow(gridData[0].length, CELL_PADDING)}
      {props.displayCorrectAnswer
        ? renderData(props.correctAnswers.correctAnswers)
        : renderData(gridData)}
      {renderEmptyRow(gridData[0].length, CELL_PADDING)}
    </StyledLineupGrid>
  );
};
