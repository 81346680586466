import styled from "styled-components";

interface WrapperProps {
  backgroundImage?: string
}

export const StyledProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%), 
    url(${(props:WrapperProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;
  
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) /* FIX IE 10 & 11 */
  {
    *::-ms-backdrop { 
      background: url(${(props:WrapperProps) => props.backgroundImage}) 
      repeat-x repeat-y;  
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%);  
  }
`;

export const StyledProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 20px;
  background-color: #fff;
`;