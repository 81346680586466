import {
  put,
  select,
  call,
  take,
  takeEvery,
  race,
  all,
  delay,
} from "typed-redux-saga";

import {
  socketOpened,
  socketError,
  socketClosed,
  recievedSocketMessage,
} from "./actions";
import {
  networkConnected,
  delayedNetworkDisonnected,
} from "../network/actions";
import { getToken } from "../auth";
import { getProductId } from "../../../store/Application";
import {
  WS_SOCKET_CLOSED,
  WS_SOCKET_OPENED,
  WS_SOCKET_ERROR,
  ERROR_TYPES,
} from "../../constants";
import { eventChannel, Channel } from "redux-saga";
import { addFlash } from "../flasher/actions";

let socketChannel: Channel<{}>;
let webSocket: WebSocket;
let reconnectAttempts = 0;
let keepAliveId: NodeJS.Timeout;
const MAX_RECONNECT_INTERVAL_MS = 15000;
const RECONNECT_INCREASE_STEP_MS = 250;

const generateReconnectInterval = (attempt: number) => {
  const newReconnectInterval =
    (Math.pow(2, attempt) - 1) * RECONNECT_INCREASE_STEP_MS;
  return newReconnectInterval <= MAX_RECONNECT_INTERVAL_MS
    ? newReconnectInterval
    : MAX_RECONNECT_INTERVAL_MS;
};

/**
 * Creates the websocket eventChannel and listens for socket closed events
 * @param socket the WebSocket object
 */
const createSocketChannel = (socket: WebSocket) => {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      window.console.log("WebSocket closed");
      emit(socketClosed());
    };

    return () => {
      socket.close();
    };
  }) as Channel<{}>;
};

/**
 * Handles opening of websocket
 * @param socket the WebSocket object
 */
const open = (token: string, productId: number, wsUrl: string) => {
  return new Promise((resolve, reject) => {
    webSocket = new WebSocket(
      `${wsUrl}?productId=${productId}&accessToken=${token}`
    );
    webSocket.onopen = () => {
      window.console.log("WebSocket successfully opened!");
      reconnectAttempts = 0;
      resolve(webSocket);
    };
    webSocket.onerror = (err) => {
      window.console.log("Failed to open socket");
      reject(err);
    };
  });
};

/**
 * Sends a ping to the websocket server if connection is open.
 */
const keepAlive = () => {
  if (webSocket.readyState === webSocket.OPEN) {
    webSocket.send("ping");
  }
};

/**
 * Saga to init websocket. Should be set as a contraint of the application.
 */
export function* rootSaga() {
  yield* call(connectSocketSaga);
}

/**
 * Worker saga to connect websocket
 */
export function* connectSocketSaga() {
  const token = yield* select(getToken);
  const productId = yield* select(getProductId);
  const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  let wsUrl = process.env.REACT_APP_WS_URL || `${wsProtocol}localhost:8082/ws`;
  if (window.location.host.substring(0, "localhost".length) !== "localhost") {
    wsUrl = `${wsProtocol}${window.location.host}/ws`;
  }

  try {
    const socket = yield* call(open, token, productId, wsUrl);
    socketChannel = yield* call(createSocketChannel, socket as WebSocket);
    yield* put(socketOpened());
    yield* put(networkConnected());
    yield* call(keepAliveSocketSaga);
  } catch (e) {
    yield* put(socketError());
    yield* put(delayedNetworkDisonnected(e as Error));
    yield* call(cancelKeepAliveSocketSaga);
  }
}

/**
 * Worker saga to reconnect websocket
 */
export function* reconnectSocketSaga() {
  yield* call(cancelKeepAliveSocketSaga);

  const interval = generateReconnectInterval(reconnectAttempts);
  window.console.log(
    `Will try to reconnect to socket in ${interval}ms (attempt ${reconnectAttempts})`
  );
  yield* delay(interval);
  reconnectAttempts++;
  yield* call(connectSocketSaga);
}

/**
 * Worker saga to start a keep alive loop that pings the websocket server every 20 s.
 */
export function* keepAliveSocketSaga() {
  if (!keepAliveId) {
    const interval =
      (process.env.REACT_APP_SOCKET_KEEPALIVE_INTERVAL as number | undefined) ||
      30000;
    keepAliveId = yield* call(setInterval, keepAlive, interval);
  }
}

/**
 * Worker saga to cancel the keep alive loop on socket errors.
 */
export function* cancelKeepAliveSocketSaga() {
  if (keepAliveId) {
    yield* call(clearInterval, keepAliveId);
    clearTimeout(keepAliveId);
  }
}

/**
 * Watcher saga to handle socket closed actions
 */
export function* watchSocketClosedSaga() {
  yield* takeEvery(WS_SOCKET_CLOSED, reconnectSocketSaga);
}

/**
 * Watcher saga to handle socket error actions
 */
export function* watchSocketErrorSaga() {
  yield* takeEvery(WS_SOCKET_ERROR, reconnectSocketSaga);
}

/**
 * Watcher saga for the socket eventChannel
 */
export function* watchSocketChannelSaga() {
  while (true) {
    yield* take(WS_SOCKET_OPENED);
    try {
      const { cancel } = yield* race({
        task: all([call(serverListener)]),
        cancel: take(WS_SOCKET_CLOSED),
      });
      if (cancel) {
        socketChannel.close();
        webSocket.close();
      }
    } catch (e) {
      yield* put(socketError());
      break;
    }
  }
}

export function* serverListener() {
  while (true) {
    const action = yield* take(socketChannel) as unknown as string;
    if (action === "pong") {
      continue;
    }

    if (action.type === WS_SOCKET_CLOSED) {
      yield* put(socketClosed());
      continue;
    }

    try {
      const message = JSON.parse(action);
      yield* put(recievedSocketMessage(message));
      if (message.type === "sso-token-invalidated") {
        yield* put(addFlash("Du har blivit utloggad", ERROR_TYPES.INFO, 5000));
        yield* call(setTimeout, reloadWindow, 5000);
      }
    } catch (e) {
      window.console.error("Failed to parse socket message", action);
      yield* put(socketClosed());
    }
  }
}

const reloadWindow = () => {
  window.location.reload();
};
