import * as React from "react";
import * as Sentry from "@sentry/browser";
import ErrorPage from "../../../views/errorpage/ErrorPage";

interface ErrorBoundaryProps {
  children?: React.ReactChild;
}

interface ErrorBoundaryState {
  error: Error | null;
}
interface ErrorInfo {
  [key: string]: any;
}

/**
 * Reloads the current page
 */
export const reloadPage = () => window.location.reload();

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  /**
   * Constructor
   * @param props
   */
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  /**
   * Catches all uncaugt errors in application, logs to Sentry and displays Error page
   * @param error
   * @param errorInfo
   */
  public componentDidCatch(error: Error | null, errorInfo: ErrorInfo) {
    this.setState({ error });
    if (process.env.REACT_APP_ENV !== "production") {
      // Only log errors to Sentry in production
      return;
    } else {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  /**
   * Render function for ErrorBoundary component
   */
  public render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return (
      <ErrorPage
        backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg"
        sound="https://media.studentlitteratur.se/image/mr/story/audio/fbg_stx_20.mp3"
      />
    );
  }
}
