import styled from "styled-components";
import { PrimaryButton } from "../../shared/components/Button";

interface WrapperProps {
  backgroundImage?: string;
}

export const StyledPrimarybutton = styled(PrimaryButton)`
  height: 40px;
  font-size: 1em;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  margin: 0.5em 0;
`;

export const StyledTestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    ),
    url(${(props: WrapperProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;

  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) /* FIX IE 10 & 11 */ {
    *::-ms-backdrop {
      background: url(${(props: WrapperProps) => props.backgroundImage})
        repeat-x repeat-y;
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    );
  }
`;

export const StyledTestContainer = styled.div`
  font-family: "Nunito";
  width: 100%;
  height: 100%;
  position: relative;
  padding: 25px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTestCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 1000px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
  padding: 12px;
`;

export const StyledTestColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin: 8px;
  overflow: auto;
  min-width: 250px;
`;

export const StyledTestTableColumn = styled(StyledTestColumn)`
  flex-direction: column;
  flex-basis: auto;
  overflow: hidden;
  padding: 16px;
`;

export const StyledTestContentSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

export const StyledTestContentRow = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

export const StyledTestAnswersPagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: auto;

  button:first-child {
    margin-right: 16px;
  }
`;

export const StyledSectionHeading = styled.h2`
  font-size: 21px;
  font-weight: 300;
  color: #333333;
  margin-bottom: 10px;
`;

export const StyledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 18px;
    width: auto;
  }
`;

export const StyledSettingsLabel = styled.h3`
  font-size: 12px;
  font-weight: 300;
`;

export const StyledInputLabel = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-right: 8px;
`;

export const StyledToggleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
`;

export const StyledToggleLabel = styled(StyledInputLabel)`
  margin-right: auto;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 18px;
  }
`;

export const StyledTextInput = styled.input`
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #979797;
  padding: 8px 0;

  &:focus {
    border-bottom: 1px solid ${props => props.theme.themePrimary};
    outline: none;
  }
`;

export const StyledTestLi = styled.li`
  list-style: none;
`;

export const StyledErrorMessage = styled.p`
font-size: 16px;
color: red;
`