import styled from "styled-components";
import { deviceSize } from "../../../styles/theme";
interface SplashScreenProps {
  backgroundImage?: string;
}

export const StyledSplashWrapper = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(142deg, rgba(25,91,90,0.6) 0%, rgba(25,91,90,0) 37%, rgba(25,91,90,0) 50%, rgba(25,91,90,0.25) 75%, rgba(25,91,90,0.5) 100%), 
    url(${(props: SplashScreenProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;
  
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) /* FIX IE 10 & 11 */
  {
    *::-ms-backdrop { 
      background: url(${(props: SplashScreenProps) => props.backgroundImage});
      repeat-x repeat-y;  
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%);
  }
`;

export const StyledInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const StyledProductNameContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledProductName = styled.h1`
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 38px;
  text-align: center;
`;

export const StyledLoaderContainer = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
`;

export const StyledAboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 10px;
  }
  cursor: pointer;
`;

export const StyledAboutText = styled.p`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledProductLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  margin-bottom: 40px;

  svg {
    max-width: 600px;
    width: 100%;
    height: 100%;
  }
`;

export const StyledLogoContainer = styled.div`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
`;

export const StyledInfoCard = styled.div`
  max-height: 512px;
  max-width: 900px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f6f6;
  box-shadow: 0 0 96px 0 rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.themePrimary};
  overflow: hidden;

  @media (max-width: ${deviceSize.phone}px) {
    max-height: 100%;
  }
`;

export const StyledAccessDeniedCard = styled(StyledInfoCard)`
  align-items: center;
  height: 100%;
  padding: 30px 40px;
  min-height: 230px;
`;

export const StyledTopBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
  background-color: #f9f6f6;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  padding: 20px 24px;
`;

export const StyledModalProductName = styled.h4`
  margin-left: auto;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 20px;
  color: inherit;
`;

export const StyledCloseButton = styled.button`
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin-left: auto;
  cursor: pointer;
  color: ${(props) => props.theme.themePrimary};

  &:hover {
    color: ${(props) => props.theme.themeSecondary};
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 26px 0 26px;
  overflow: auto;

  //Hack because padding-bottom does not work in Firefox with overflow: auto
  &:after {
    content: "";
    height: 26px;
    min-height: 26px;
    width: 100%;
    display: block;
  }
`;

export const StyledTextContainer = styled.div`
  max-width: 80%;

  @media (max-width: ${deviceSize.phone}px) {
    max-width: 100%;
  }
`;

export const StyledPrimaryCloseButton = styled.div`
  margin-top: auto;
`;

export const StyledAccessText = styled.h1`
  color: inherit;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 52px;
  margin: 14px 0;
  margin-top: 0;
`;
