import { SagaService } from "../../services/saga";
import {
  watchGetAdventureGroupsSaga,
  watchGetAdventuresSaga,
  watchGetAdventureCardSaga,
  watchStartAdventureCardSaga,
  watchGetMarkersToCompleteSaga,
  watchGetAllAdventuresSaga,
  watchSetActiveAdventureSaga,
  watchCompleteProblemSaga,
  watchResetProblemSaga
} from "./api/sagas";
import { ReducerService } from "../../services/reducer";
import { reducer } from "./api/reducer";

export * from "./AdventurePicker";

export const init = () => {
  SagaService.set(watchGetAdventureGroupsSaga);
  SagaService.set(watchGetAdventuresSaga);
  SagaService.set(watchGetAdventureCardSaga);
  SagaService.set(watchStartAdventureCardSaga);
  SagaService.set(watchGetMarkersToCompleteSaga);
  SagaService.set(watchGetAllAdventuresSaga);
  SagaService.set(watchSetActiveAdventureSaga);
  SagaService.set(watchCompleteProblemSaga);
  SagaService.set(watchResetProblemSaga);
  ReducerService.set("adventureGroup", reducer);
};
