import { CorrectionBtnStates } from "../../../shared/components/CorrectionButton";
import {
  ActCreateNewDeck,
  ActWatchCardUpdate,
  CardStates,
  Deck,
  RawExercise,
  NewCard,
  ProcessedRecRespData,
} from "./types";
import {
  EXERCISE,
  INTERMEDIATE,
  ITEM,
  RESULT,
  MARKERS,
} from "../../../shared/constants";
import { viewSlideStates } from "../Exercise";
import { v4 as uuid } from "uuid";
import { ExerciseState } from "../../../external/EddaXcomp/src/components/ExerciseRenderer/ExerciseRendererTypes";
import { CorrBtnState } from "../../../external/EddaXcomp/src/components/CorrectionButton/CorrectionButtonTypes";

const prepareCardStates = (exercises: RawExercise[]): CardStates => {
  const cardsStates: CardStates = {};

  exercises.forEach((item: RawExercise, index: number): void => {
    const id = index + 1;
    cardsStates[id] = {
      id,
      exerciseId: item.id,
      state: CorrectionBtnStates.DEFAULT,
      totalAttempts: item.attempts,
      currentAttempt: 0,
      exerciseFinished: false,
      isRepetition: false,
      hasAudio: item.hasAudio,
      hasHelpAudio: item.hasHelpAudio,
    };
  });

  return cardsStates;
};

export const prepareNewDeck = (data: ActCreateNewDeck): Deck => ({
  id: data.id,
  deckFinished: false,
  activeCard: data.exercises.length,
  amountOfCards: data.exercises.length,
  cards: getFirstSetOfCards(data.exercises.length),
  cardsStates: prepareCardStates(data.exercises),
});

export const getNextExerciseState = (
  currentState: CorrBtnState,
  currentAttempt: number,
  totalAttempts: number
): CorrBtnState => {
  const { DEFAULT, INCORRECT, CORRECT, TRY_AGAIN } = CorrectionBtnStates;

  switch (currentState) {
    case CORRECT:
      return CORRECT;

    case INCORRECT:
      return currentAttempt < totalAttempts ? TRY_AGAIN : INCORRECT;

    default:
      return DEFAULT;
  }
};

export const prepareCard = (
  data: ActWatchCardUpdate,
  secondaryUpdate: boolean
): ExerciseState => {
  const totalAttempts = data.cardData.totalAttempts;
  const currentAttempt: number = getNewAttempt(data.newState, data.cardData);
  const state: CorrBtnState = getNextExerciseState(
    data.newState,
    currentAttempt,
    totalAttempts
  ); // CHANGE DECK CARD INDEX
  const correctAnswer = data.correctAnswer;
  return {
    ...data.cardData,
    state,
    currentAttempt,
    exerciseFinished: secondaryUpdate
      ? true
      : isFinalState(state, currentAttempt, totalAttempts),
    isRepetition: false, // TODO get actual value from recommendation response
    correctAnswer,
  };
};

export const isFinalState = (
  currentState: CorrBtnState,
  currentAttempt: number,
  totalAttempts: number
): boolean => {
  const state: CorrBtnState = getNextExerciseState(
    currentState,
    currentAttempt,
    totalAttempts
  );
  return (
    state === CorrectionBtnStates.CORRECT ||
    state === CorrectionBtnStates.INCORRECT
  );
};

export const deckIsDone = (deck: Deck): boolean => {
  const { CORRECT, INCORRECT } = CorrectionBtnStates;
  const { cardsStates, amountOfCards } = deck;
  let numberOfCorrectedExercises: number = 0;

  Object.keys(deck.cardsStates).forEach((cardIndex) => {
    const exerciseSate: CorrBtnState =
      cardsStates[parseInt(cardIndex, 10)].state;
    numberOfCorrectedExercises =
      numberOfCorrectedExercises +
      (exerciseSate === CORRECT || exerciseSate === INCORRECT ? 1 : 0);
  });

  return deck.activeCard === 1 && numberOfCorrectedExercises === amountOfCards;
};

export const getNewAttempt = (
  currentState: string,
  exerciseData: ExerciseState
): number => {
  return exerciseData.currentAttempt < exerciseData.totalAttempts
    ? exerciseData.currentAttempt + 1
    : exerciseData.currentAttempt;
};

/** DECK RELATED STUFF */
export const getPositionForCard = (i: number, pos: number, left: boolean) => {
  if (left) {
    return i === pos + 1 ? 2 : i === pos + 2 ? 1 : 0;
  }
  return i === pos - 2 ? 1 : 2;
};

export const getNextSetOfCards = (count: any, cards: any, pos: any) => {
  const newCards: NewCard[] = [];
  let length = count - cards.length - 1;
  length = length < 0 ? 0 : length;
  let i = count;

  let position = pos;
  position--;

  for (i; i > length; --i) {
    const isTop = i === position;
    newCards.push({
      right: i < position,
      top: isTop,
      left: i > position,
      pos: getPositionForCard(i, position, i > position),
    });
  }

  return newCards;
};

export const getFirstSetOfCards = (count: any) => {
  const newCards: NewCard[] = [];

  let i = count;
  let len = count - 3;
  len = len < 0 ? 0 : len;

  for (i; i > len; --i) {
    newCards.push({
      right: i < count,
      top: i === count,
      left: false,
      pos: getPositionForCard(i, count, false),
    });
  }

  return newCards;
};

export const prepExerciseSlideData = (data: ProcessedRecRespData): any => {
  return data.map((item: any) => {
    if (item.type.toUpperCase() === EXERCISE) {
      return {
        type: EXERCISE,
        deckID: item.deckID,
        slideState: viewSlideStates.LOADED,
      };
    } else if (item.type.toUpperCase() === INTERMEDIATE) {
      return { type: INTERMEDIATE, slideState: viewSlideStates.LOADED };
    } else if (item.type.toUpperCase() === RESULT) {
      return { type: RESULT, slideState: viewSlideStates.LOADED };
    } else if (item.type.toUpperCase() === ITEM) {
      return { type: ITEM, slideState: viewSlideStates.LOADED };
    } else if (item.type.toUpperCase() === MARKERS) {
      return { type: MARKERS, slideState: viewSlideStates.LOADED };
    } else {
      throw new Error(`Unknown exerciseView slide type: ${item.type}`);
    }
  });
};

const addIdToData = (obj: any) => {
  const newData = { ...obj, guiId: uuid() };

  if (obj.hasOwnProperty("data")) {
    let newObjData;

    if (Array.isArray(obj.data)) {
      newObjData = obj.data.map((item: any) => addIdToData(item));
    } else if (typeof obj.data === "object" && obj.data !== null) {
      newObjData = addIdToData(obj.data);
    }

    newData.data = newObjData;
  }

  return newData;
};

/**
 * Checks if exercise has audio connected to text
 * @param exerciseData
 * @returns {boolean}
 */
export const getHasAudio = (exerciseData: any): boolean => {
  let hasAudio = false;

  if (Array.isArray(exerciseData.data)) {
    exerciseData.data.forEach((item: any) => {
      if (item.type === "audio" || item.type === "helpaudio") {
        return;
      } else if (
        (item.data.audio && item.data.audio.src) ||
        (item.data.words && item.data.words[0].soundsrc)
      ) {
        hasAudio = true;
      }
    });
  }
  return hasAudio;
};

export const prepExerciseObject = (exerciseObject: any) => {
  return exerciseObject.map((item: any) => {
    const itemBody = JSON.parse(item.body);
    const helpData = "help" in item ? JSON.parse(item.help) : null;
    return {
      ...item,
      body: addIdToData(itemBody),
      hasAudio: getHasAudio(itemBody),
      hasHelpAudio: helpData ? getHasAudio(helpData) : null,
      help: helpData,
    };
  });
};

/**
 * Extracts item data
 * @param {any} parts
 */
export const extractItemData = (parts: any) => {
  if (!parts) {
    return null;
  }
  for (const part of parts) {
    if (part.type.toUpperCase() === ITEM) {
      return part.item;
    }
  }
  return null;
};

/**
 * Checks if parts contain information that a badge has been completed
 * @param {any} parts
 */
export const badgeCompleted = (parts: any) => {
  if (!parts) {
    return false;
  }
  for (const part of parts) {
    if (part.badge_completed) {
      return true;
    }
  }
  return false;
};
