import * as React from "react";

import {
  StyledJump,
  StyledJumpWrapper,
  StyledLeftArrow,
  StyledRightArrow,
  StyledRailEnd
} from './StyledXcNumberLine';
import Jump from "./Jump";
import {
  FixedJumpData
} from "./types";
import {
  GetTrackProps,
  SliderItem
} from "react-compound-slider";

interface TrackProps {
  startingPosition: number;
  currentPosition: number;
  fixedJumpData: any;
  source: SliderItem;
  target: SliderItem;
  currentStepNumbers: number;
  totalSteps: number;
  image: string;
  turnImage: boolean;
  showRightArrow: boolean;
  showLeftArrow: boolean;
  showAutoJumps: boolean;
  filledLine: boolean;
  jumpInterval: number;
  startingPercent: number;
  getTrackProps: GetTrackProps;
  fixedJump?: FixedJumpData;
}

export const Track: React.FC<TrackProps> = ({ fixedJumpData, target, currentStepNumbers, totalSteps, image, turnImage, showRightArrow, showLeftArrow, jumpInterval, showAutoJumps, filledLine, startingPercent, getTrackProps, fixedJump, startingPosition, currentPosition}): JSX.Element | null => {
  const steps: number = currentStepNumbers;
  const { startIndex, endIndex } = fixedJumpData

  /**
   * Renders right arrow
   * @returns {JSX.Element | null}
   */
  const renderRightArrow = ():JSX.Element | null => showRightArrow
    ? <StyledRightArrow />
    : <StyledRailEnd direction={'right'} />;

  /**
   * Renders left arrow
   * @returns {JSX.Element | null}
   */
  const renderLeftArrow = ():JSX.Element | null => showLeftArrow
    ? <StyledLeftArrow />
    : <StyledRailEnd direction={'left'} />;

  /**
   * Returns true if bird should turn direction and slider is on negative side
   * @returns {boolean}
   */
  const turn = ():boolean => turnImage && (steps < 0);

  /**
   * Renders fixed Jumps
   * @returns {JSX.Element}
   */
  const renderFixedJumps = ():JSX.Element | null => fixedJump
    ? <StyledJumpWrapper
      totalSteps={totalSteps}
      startingPosition={startIndex}
      currentPosition={endIndex}
    >
      <Jump
        steps={fixedJump.fixedJumpSteps}
        totalSteps={totalSteps}
        jumpInterval={jumpInterval}
        image={image}
        turnImage={turnImage}
        showJumps={ showAutoJumps ? false : true }
      />
    </StyledJumpWrapper>
    : null;

  /**
   * Renders the jumps with its wrapper
   * @returns {any}
   */
  const renderAutoJumps = () => ( showAutoJumps
    ? <StyledJumpWrapper
      totalSteps={totalSteps}
      startingPosition={startingPosition}
      currentPosition={currentPosition}
    >
      <Jump
        steps={steps}
        totalSteps={totalSteps}
        jumpInterval={jumpInterval}
        image={image}
        turnImage={turnImage}
        showJumps={true}
      />
    </StyledJumpWrapper>
    : null
  );

  /**
   * Renders Track
   * @returns {JSX.Element}
   */
  const renderTrack = ():JSX.Element | null => filledLine
    ? <StyledJump
      sourcePercent={0}
      targetPercent={target.percent}
      turn={turn()}
      filledLine={true}
      {...getTrackProps()}
    />
  : null;

  return (
    <div>
      { renderTrack() }
      { renderRightArrow() }
      { renderLeftArrow() }

      {renderAutoJumps()}
      {renderFixedJumps()}
    </div>
  )
};
