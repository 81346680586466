import styled from 'styled-components';

interface KeyboardProps {
  position: string;
}

interface ButtonProps {
  color: string;
  background: string;
}

export const StyledKeyboardWrapper = styled.div<KeyboardProps>`
  position: absolute;
  z-index: 20;
  max-width: 100%;
  min-width: 200px;
  display: flex;
  flex: 1;

  ${props => props.position === 'HORIZONTAL' ?
    `
      width: 100%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
    `
    :
    `
      bottom: 50%;
      right: -28px;
      transform: translate(0, 50%);
    `
  }
`;

export const StyledKeyboard = styled.div<KeyboardProps>`
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: #FFFFFF;
  border: 2px solid ${props => props.theme.themePrimary};
  border-radius: 22px;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.16);
  margin: auto;
  padding: ${props => props.position === 'HORIZONTAL' ? '10px' : '17px 14px'};
`;

export const StyledKeyboardRow = styled.div<KeyboardProps>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${props => props.position === 'HORIZONTAL' ?
    `
      display: flex; 
      button { margin: 4px; }
    `
    :
    `
      &:not(:first-child) { margin-top: 14px; }
      @media (max-width: 1000px) {
        &:not(:first-child) { margin-top: 7px; }
      }
      &:last-child { justify-content: flex-start; }
      
      button {
        &:not(:first-child) {
          margin-left: 14px;
          @media (max-width: 1000px) {
            margin-left: 7px;
          }
        }
      }
    `
  }
`;

export const StyledKeyboardButton = styled.button<ButtonProps>`
  background-color: ${props => props.theme[props.background]};
  height: 44px;
  width: 44px;
  border-radius: 50%;
  color: ${props => props.theme[props.color]};
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 24px;
  font-family: 'Muli';
  border: none;
  cursor: pointer;
  padding:0px 0px 2px 0px;
  border: 1px solid #979797;
  -webkit-tap-highlight-color: ${props => props.theme.themeSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:active {
    background-color: ${props => props.theme.themeSecondary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px #8AB4B3;
    background-color: black;
  }

  svg {
    pointer-events: none;
  }
`;

export const StyledUtilityButton = styled(StyledKeyboardButton)`
  background-color: ${props => props.background};
  color: ${props => props.color};
  padding: 6px;

  &:active {
    background-color: ${props => props.theme.themeLighter};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #979797;
  }
`;