import * as React from "react";
import {
  StyledKeyboardWrapper,
  StyledKeyboard,
  StyledKeyboardButton,
  StyledKeyboardRow,
  StyledUtilityButton,
} from "./StyledVirtualKeyboard";

import { defaultLayoutSquare, defaultLayoutHorizontal } from "./defaultLayout";
import { ButtonKey } from "./types";
import { keyboardIcons } from "./iconsList";

interface Props {
  buttonLayout?: ButtonKey[][];
  position?: string;
  callback: (param: string) => void;
}

export const VirtualKeyboard: React.FC<Props> = (props) => {
  const defaultLayout =
    props.position === "SQUARE" ? defaultLayoutSquare : defaultLayoutHorizontal;

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const buttonElement = e.target as HTMLButtonElement;
    props.callback(buttonElement.value);
  };

  const handleOnMouseDown = (e: React.MouseEvent) => e.preventDefault();

  const renderButtonElement = (keyObj: ButtonKey) => (
    <StyledKeyboardButton
      onClick={handleOnClick}
      value={keyObj.value}
      key={`key_${keyObj.value}`}
      color={keyObj.color}
      background={keyObj.background}
    >
      {keyObj.icon ? keyboardIcons[keyObj.icon] : keyObj.value}
    </StyledKeyboardButton>
  );

  const renderKeys = (buttonLayout: ButtonKey[][] = defaultLayout) =>
    buttonLayout.map((row: ButtonKey[], index: number) => (
      <StyledKeyboardRow position={props.position} key={index}>
        {row.map((key: ButtonKey) => {
          if (key.value === "%&") {
            return renderBackspaceButton();
          } else if (key.value === "%(" || key.value === "%)") {
            // case of layout change button, will be implemented later
            return undefined;
          } else {
            return renderButtonElement(key);
          }
        })}
      </StyledKeyboardRow>
    ));

  const renderBackspaceButton = () => {
    const iconName = "IconUndo";
    return (
      <StyledUtilityButton
        onClick={handleOnClick}
        value={"%&"}
        key={"backspace_button"}
        color={"#277575"}
        background={"white"}
      >
        {keyboardIcons[iconName]}
      </StyledUtilityButton>
    );
  };

  return (
    <StyledKeyboardWrapper position={props.position}>
      <StyledKeyboard position={props.position} onMouseDown={handleOnMouseDown}>
        {renderKeys(props.buttonLayout)}
      </StyledKeyboard>
    </StyledKeyboardWrapper>
  );
};
