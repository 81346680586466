import * as React from "react";

import PrimaryButton from "./PrimaryButton";
import { ReactComponent as NextArrow } from "../../../assets/icons/next-arrow.svg";
import { ReactComponent as NextArrowSlim } from "../../../assets/icons/icon_arrow_slim.svg";
import { useDispatch } from "react-redux";
import { RouterActions } from "../..";

interface ArrowButtonProps {
  direction?: string;
  width?: string;
  square?: boolean;
  onClick?: () => void;
  inverted?: boolean;
  disabled?: boolean;
  slim?: boolean;
  route?: string;
  ariaLabel?: string;
}

interface Rotations {
  right: number;
  down: number;
  left: number;
  up: number;
  [key: string]: number;
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  direction = "right",
  width = "80px",
  route,
  square = false,
  onClick,
  inverted = false,
  disabled,
  slim = false,
  ariaLabel = "Fortsätt",
  ...rest
}) => {
  const rotations: Rotations = { right: 0, down: 90, left: 180, up: 270 };

  const dispatch = useDispatch();

  const innerOnClick = () => {
    onClick?.();
    route && dispatch(RouterActions.push(route));
  };

  const buttonStyles = {
    width,
    height: width,
    borderRadius: square ? "4px" : "",
    padding: square ? "6px" : "",
    backgroundColor: inverted ? "#fff" : "#227574",
    opacity: disabled ? "0.3" : "1",
    cursor: disabled ? "not-allowed" : "pointer",
  };
  const iconStyles = {
    transform: `rotate(${rotations[direction]}deg)`,
    maxHeight: "100%",
    color: inverted ? "#227574" : "#fff",
  };

  const icon = slim ? (
    <NextArrowSlim
      id={`arrow-${direction}`}
      style={{ ...iconStyles, width: "100%" }}
    />
  ) : (
    <NextArrow style={iconStyles} />
  );

  return (
    <PrimaryButton
      icon={icon}
      overrideStyles={buttonStyles}
      circular
      onClick={innerOnClick}
      disabled={disabled}
      {...rest}
      aria-label={ariaLabel}
    />
  );
};
