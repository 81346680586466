import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import AdventureInfoModal from "../../shared/components/AdventureInfoModal/AdventureInfoModal";
import { NavBar } from "../../shared/components/NavBar";
import { SplashDialog } from "../../shared/components/Splash/SplashDialog";
import {
  selectCurrentRoute,
  selectNextRoute,
} from "../../shared/store/router/selectors";
import { RootState } from "../../store";
import { Progress } from "../../store/progress/types";
import { StyledProps } from "../../styles/theme";
import { selectHasActiveAdventure } from "../adventurePicker/api/selectors";
import { ASSIGNMENT_API_ACTIONS } from "../assignment/api/actions";
import { selectAssignments } from "../assignment/api/selectors";
import { renderFooter, renderMainMenu } from "../dashboard/markup";
import {
  StyledBottomRow,
  StyledDashboardWrapper,
  StyledMenuRow,
} from "../dashboard/StyledDashboard";
import { selectTheme } from "../profile/api/selectors";
import { ADVENTURE_STORY_ROUTE, EXERCISE_ROUTE } from "../routes";
import { menuItems } from "./DashboardItems";

export interface DashboardViewProps extends StyledProps {
  progress: Progress;
  assignmentProgress: number;
  taskDesc: string;
}

const defaultProps = {
  assignmentProgress: 80,
  taskDesc: "Vi ska samla ihop 100 stjärnor och då äter vi popcorn!",
};

export const DashboardViewComponent = (props: DashboardViewProps) => {
  const nextRoute = useSelector(selectNextRoute);
  const currentRoute = useSelector(selectCurrentRoute);
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const assignments = useSelector(selectAssignments);
  const hasActiveAdventure = useSelector(selectHasActiveAdventure);
  const [showAdventureModal, setShowAdventureModal] = React.useState(false);

  React.useEffect(() => {
    dispatch({ type: ASSIGNMENT_API_ACTIONS.READ.REQUEST });
  }, [dispatch]);

  const showAssignmentsButton = Boolean(
    assignments.current || assignments.previous
  );

  const renderAdventureInfoModalOpen = () => setShowAdventureModal(true);

  const getExeRoute = (currentStoryRead: boolean) =>
    !currentStoryRead ? ADVENTURE_STORY_ROUTE : EXERCISE_ROUTE;

  const exerciseRoute = getExeRoute(
    props.progress.current_challenge_story_read || false
  );

  return (
    <StyledDashboardWrapper
      backgroundImage={
        "https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg"
      }
    >
      <NavBar position="absolute" hideHomeBtn={true} />
      <SplashDialog />
      <StyledMenuRow>
        {renderMainMenu(
          menuItems(
            theme,
            nextRoute ? nextRoute.route : exerciseRoute,
            showAssignmentsButton,
            hasActiveAdventure,
            renderAdventureInfoModalOpen
          )
        )}
      </StyledMenuRow>
      <AdventureInfoModal
        show={showAdventureModal}
        theme={theme}
        currentRoute={currentRoute as string}
        nextRoute={(nextRoute || {}).route as string}
        onAdventureInfoModalClose={setShowAdventureModal}
      />
      <StyledBottomRow>{renderFooter()}</StyledBottomRow>
    </StyledDashboardWrapper>
  );
};

DashboardViewComponent.defaultProps = defaultProps;

const mapStateToProps = (state: RootState) => ({
  progress: state.progress.progress,
});

export const DashboardView = connect(
  mapStateToProps,
  null
)(DashboardViewComponent);
