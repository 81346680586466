import { PRIMARY_SCHOOL } from "../../shared/constants";
import { Themes } from "../profile/api/types";
import { ASSIGNMENT_ROUTE, ATTIC_ROUTE } from "../routes";
import DashboardMenuItem from "./DashBoardMenuItem/DashboardMenuItem";

export const menuItems = (
  type: Themes | undefined,
  route: string,
  showAssignmentButton: boolean,
  hasActiveAdventure: boolean,
  onAdventureInfoModalCallback: () => void
) => {
  const items =
    type === PRIMARY_SCHOOL
      ? PrimarySchoolMenuItems(
          route,
          hasActiveAdventure,
          onAdventureInfoModalCallback
        )
      : MiddleSchoolMenuItems(route, hasActiveAdventure);
  if (showAssignmentButton) {
    items.push(
      <DashboardMenuItem
        key="UPPDRAG"
        route={ASSIGNMENT_ROUTE}
        imageData={{
          url: "https://media.studentlitteratur.se/image/mr/story/images/badges_collection.png",
          width: 90,
          left: 0,
          bottom: 0,
        }}
        isAssignment
        bgPosition="center left"
        label="UPPDRAG"
        height="100px"
      />
    );
  }
  return items;
};

const PrimarySchoolMenuItems = (
  exerciseRoute: string,
  hasActiveAdventure: boolean,
  onAdventureInfoModalCallback: () => void
) => [
  <DashboardMenuItem
    key="RÄKNA"
    route={hasActiveAdventure ? exerciseRoute : ""}
    main
    imageData={{
      url: "https://media.studentlitteratur.se/image/mr/story/images/_characters/mentor_neutral_2.svg",
    }}
    bgPosition="center right"
    label="RÄKNA"
    width="100%"
    height="230px"
    radius="50px"
    onClick={onAdventureInfoModalCallback}
  />,
  <DashboardMenuItem
    key="VINDEN"
    route={ATTIC_ROUTE}
    imageData={{
      url: "https://media.studentlitteratur.se/image/mr/story/images/chest_transporter_v2.png",
      width: 168,
      left: 0,
      bottom: -30,
    }}
    bgPosition="center"
    label="VINDEN"
    height="100px"
    withAnimations={!hasActiveAdventure}
  />,
];

const MiddleSchoolMenuItems = (
  exerciseRoute: string,
  hasActiveAdventure: boolean
) => [
  <DashboardMenuItem
    key="first"
    route={exerciseRoute}
    main
    bgPosition="center right"
    label="RÄKNA"
    width="100%"
    height="230px"
    radius="50px"
    imageData={{
      url: "https://media.studentlitteratur.se/image/mr/story/images/_characters/mentor_neutral_2.svg",
    }}
  />,
  <DashboardMenuItem
    key="second"
    route={ATTIC_ROUTE}
    bgPosition="center"
    label="VINDEN"
    height="100px"
    imageData={{
      url: "https://media.studentlitteratur.se/image/mr/story/images/timemachine_4-6.png",
      width: 197,
      left: -24,
      bottom: -67,
    }}
    withAnimations={!hasActiveAdventure}
  />,
];
