import { Action } from "redux";

export const INIT_ROUTER = "INIT_ROUTER";

export interface InitRouterAction extends Action {
  route: string;
}

export const initRouter = (route: string | undefined) => ({
  route,
  type: INIT_ROUTER,
});

export const push = (route: string) => ({
  type: route,
});
