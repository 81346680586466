import * as React from "react";
import { HelpButtonRack } from "./components/ButtonRack";
import { HelpRenderer } from "./HelpRenderer";
import { StyledBackContent, StyledHelpText } from "./StyledHelp";
import {
  HelpItem,
  MathWord,
  HelpData,
} from "../../../views/exercise/api/types";
import SoundWrapper from "../../containers/SoundWrapper";
import HtmlRenderer from "../HtmlRenderer";

interface Props {
  help: HelpItem[];
}

export const renderSoundWrappedElement = (helpData: HelpData) => {
  if (helpData.audio && helpData.audio.src) {
    return (
      <SoundWrapper
        Component={
          helpData.text ? (
            <HtmlRenderer component={StyledHelpText} html={helpData.text} />
          ) : (
            <></>
          )
        }
        url={helpData.audio.src}
      />
    );
  }
  return (
    helpData.text && (
      <HtmlRenderer component={StyledHelpText} html={helpData.text} />
    )
  );
};

export const Help: React.FC<Props> = ({ help }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  /**
   * Splits array into chunks
   * @param words
   * @param chunkSize
   */
  const groupMathWords = (words: MathWord[], chunkSize: number) => {
    const newArray = [];

    for (let index = 0; index < words.length; index += chunkSize) {
      const chunk = {
        type: "mathword",
        data: { words: words.slice(index, index + chunkSize) },
      };
      newArray.push(chunk);
    }
    return newArray;
  };

  /**
   * Puts mathwords in groups of 3 for display on card
   */
  const prepMathWords = () => {
    const mathWords: MathWord[] = [];

    help.forEach((helpItem) => {
      if (helpItem.type === "mathword" && helpItem.data.words) {
        mathWords.push(...helpItem.data.words);
      }
    });
    return [
      ...help.filter((item) => item.type !== "mathword"),
      ...groupMathWords(mathWords, 2),
    ];
  };

  const buttonCallback = (id: number) => {
    setActiveIndex(id);
  };

  const renderButtonRack = () =>
    modifiedData.length > 1 ? (
      <HelpButtonRack
        help={modifiedData}
        activeIndex={activeIndex}
        callback={buttonCallback}
      />
    ) : null;

  const renderHelp = () =>
    modifiedData ? (
      <React.Fragment>
        <HelpRenderer help={modifiedData[activeIndex]} />
        {renderButtonRack()}
      </React.Fragment>
    ) : null;

  const modifiedData: HelpItem[] = prepMathWords();

  return <StyledBackContent>{renderHelp()}</StyledBackContent>;
};
