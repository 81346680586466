import {
  LINES,
  NORMAL
} from "../../../../../../../shared/constants";
import {
  CustomStep,
  CustomSteps,
  DefaultFixed,
  DefaultJump,
  DefaultNumberLineSettings,
  NumberLineStep
} from "./types";

/**
 *
 * @param fixedData
 * @returns {{isActive: boolean, start: string, end: string, length: number}}
 */
const defaultFixedObject = (fixedData: DefaultFixed = {}): DefaultFixed => ({
  isActive: false,
  start: '',
  end: '',
  length: 0,

  ...fixedData
});

/**
 *
 * @returns {{image: string, auto: boolean, fixed: {isActive: boolean, start: string, end: string length: number}}}
 */
export const defaultJumpObject = (jumpData: DefaultJump = {}): DefaultJump => ({
  image: '',
  auto: false,
  jumpLength: 1,
  turnAsset: false,

  ...jumpData,

  fixed: defaultFixedObject(jumpData.fixed),
});

/**
 * returns a default arrow object
 * @returns {{left: boolean; right: boolean}}
 */
export const defaultArrowObject = () => ({
  left: false,
  right: false
});

/**
 * A default settings object
 * @type {{lineType: string, disabled: boolean, interval: number, errorMargin: number, marking: string, showArrow: {left: boolean, right: boolean}, jump: {image: string, auto: boolean, fixed: {isActive: boolean, start: string, end: string, length: number}}}}
 */
const defaultDataObject = ( jumpData: DefaultJump, overrideData: DefaultNumberLineSettings ): DefaultNumberLineSettings => ({
  lineType: NORMAL,
  interval: 1,
  disabled: false,
  smooth: false,
  errorMargin: 0,
  marking: LINES,
  startValue: 0,
  showArrow: defaultArrowObject(),
  ...overrideData,
  jump: jumpData
});

/**
 * Generate a step object with the possibility to override
 * @param index
 * @param overrideData
 * @returns {{id: string, text: *, isMarked: boolean, isCorrect: boolean, isStartingPoint: boolean, isJumpStart: boolean, isJumpEnd: boolean, showLabel: boolean, hasCustomValue: boolean}}
 */
const defaultStepObject = (index: number, overrideData: CustomStep = {}): NumberLineStep => ({
  id: `i${ index + 1 }`,
  text: (index + 1).toString(),
  isMarked: false,
  isCorrect: false,
  isStartingPoint: false,
  isJumpStart: false,
  isJumpEnd: false,
  showLabel: false,
  hasCustomValue: false,
  stepImage: '',
  ...overrideData
});

/**
 * Generate steps data object
 * @param numberOfSteps
 * @param customSteps
 * @returns {Array}
 */
const generateSteps = (numberOfSteps: number, customSteps: CustomSteps = {}): NumberLineStep[] => {
  const arr: NumberLineStep[] = [];

  for (let i: number = 0; i < numberOfSteps; i++) {
    const customData = customSteps.hasOwnProperty(i)
      ? customSteps[i]
      : {};

    arr.push(defaultStepObject(i, customData));
  }

  return arr;
};

/**
 * Generates root data object
 * @param numberOfSteps
 * @param customSteps
 * @param overrideData
 * @returns {{settings: {lineType: string, interval: number, errorMargin: number, marking: string, showArrow: {left: boolean, right: boolean}, jump: {image: string, auto: boolean, fixed: {isActive: boolean, start: string, end: string, length: number}}, disabled: boolean}, steps}}
 */
export const generateDataObject = (numberOfSteps: number, customSteps: CustomSteps, overrideData: DefaultNumberLineSettings = {}) => ({
  settings: defaultDataObject(defaultJumpObject(overrideData.jump), overrideData),
  steps: generateSteps(numberOfSteps, customSteps)
});
