import { RootState } from "../../../store";
import { createSelector } from "reselect";
import { Assignment, AssignmentStatuses, ParsedAssignment } from "./types";
import { toTextAndAudio } from "./helpers";
export const selectAssignments = (state: RootState) =>
  state.assignments.assignments;

export const selectActiveAssignment = (state: RootState): Assignment | null =>
  state.assignments.assignments.current;

export const selectAssignmentArray = createSelector(
  selectAssignments,
  assignments => {
    return Object.keys(assignments).reduce((acc: ParsedAssignment[], curr) => {
      if (assignments[curr]) {
        const assignmentObj = assignments[curr] as Assignment;
        return [
          ...acc,
          {
            ...assignmentObj,
            instruction: toTextAndAudio(assignmentObj.instruction as string),
            status: curr as AssignmentStatuses
          }
        ];
      }
      return acc;
    }, []);
  }
);
