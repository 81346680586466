import {
  NETWORK_CONNECTED,
  NETWORK_DISCONNECTED,
  NETWORK_DISCONNECTED_DELAYED,
  NETWORK_CHECK_CONNECTION,
} from "../../constants";

export const networkConnected = () => ({
  type: NETWORK_CONNECTED,
});

export const networkDisonnected = (error: Error) => ({
  type: NETWORK_DISCONNECTED,
  error
});

export const delayedNetworkDisonnected = (error: Error) => ({
  type: NETWORK_DISCONNECTED_DELAYED,
  error
});

export const checkNetworkConnection = () => ({
  type: NETWORK_CHECK_CONNECTION,
})
