import { Action } from "redux";
import { ApiActions, createApiActions } from "../../shared/store/actions";
import { 
  APPLICATION_PRODUCT,
  APPLICATION_MOUNT,
  APPLICATION_READY 
} from "../../shared/constants";

export const APPLICATION_PRODUCT_API_ACTIONS: ApiActions = createApiActions(APPLICATION_PRODUCT);

export const mountApplication = (): Action => ({
  type: APPLICATION_MOUNT
});

export const applicationReady = (): Action => ({
  type: APPLICATION_READY
});
