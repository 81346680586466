import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Themes } from "../../../views/profile/api/types";
import { RouterActions } from "../../index";
import { Button } from "../Button";

const dispatchToProps = (dispatch: Dispatch) => ({
  push: (route: string) => dispatch(RouterActions.push(route)),
});

export interface NavigatorProps {
  route?: string;
  icon?: any;
  skin?: string;
  label?: string;
  circular?: boolean;
  animation?: string;
  overrideStyles?: object;
  children?: React.ReactNode;
  disabled?: boolean;
  withTheme?: Themes;
  onClick?: () => void;
  push: (route: string) => void;
}

const NavigatorComponent: React.FC<NavigatorProps> = ({
  push,
  onClick,
  route,
  ...rest
}) => {
  const innerOnClick = () => {
    onClick && onClick();
    route && push(route);
  };

  return <Button skin="primary" onClick={innerOnClick} {...rest} />;
};

export const Navigator = connect(null, dispatchToProps)(NavigatorComponent);
