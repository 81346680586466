import * as React from "react";
import {
  StyledTooltip,
  StyledText,
  StyledTooltipContent,
} from "./StyledTooltip";

interface Props {
  text: string;
  show: boolean;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  arrowPosition: string;
  removeTime?: number;
  callback?: any;
}

export const Tooltip: React.FC<Props> = ({
  show,
  removeTime,
  callback,
  arrowPosition,
  top,
  left,
  bottom,
  right,
  text,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(show);
    if (show && removeTime) {
      const timer = setTimeout(() => {
        callback();
        setIsVisible(false);
      }, removeTime);

      return () => clearTimeout(timer);
    }
  }, [show, removeTime, callback]);

  return (
    <StyledTooltip
      visible={isVisible}
      arrowPosition={arrowPosition}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
    >
      <StyledTooltipContent>
        <StyledText>{text}</StyledText>
      </StyledTooltipContent>
    </StyledTooltip>
  );
};
