import * as React from "react";
import { ErrorBoundary } from "../shared";
import { Provider } from "react-redux";
import { Store } from "redux";
import { ThemeProvider } from "styled-components";
import { Router, Auth } from "../shared";
import theme from "../styles/theme";
import SplashScreen from "../shared/components/Splash";
import GlobalStyle from "../styles/base";
import { Flasher } from "../shared/containers/Flasher";
import { SmallScreenBlocker } from "../shared/components/SmallScreenBlocker";
import { NetworkErrorBlocker } from "../shared/containers/NetworkErrorBlocker";
import Snackbar from "../shared/components/Snackbar/Snackbar";

export interface IApp {
  store: Store;
}

export const App = ({ store }: IApp) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <Snackbar />
        <ErrorBoundary>
          <Auth>
            <React.Fragment>
              <Flasher />
              <SmallScreenBlocker />
              <NetworkErrorBlocker />
              <SplashScreen />
              <Router />
            </React.Fragment>
          </Auth>
        </ErrorBoundary>
      </React.Fragment>
    </ThemeProvider>
  </Provider>
);
