import packageJson from "../../../package.json"

/**
 * Gets the application version and commit-id
 *
 * @returns {String} the version as specified in package.json and commit-id (if it can be gotten,
 *                   the commit-id is gotten from env-variables and has to be set at built time).
 */
export const getApplicationVersion = (showShort?: boolean) => {
  const version = process.env.REACT_APP_VERSION || packageJson.version
  const commitId = process.env.REACT_APP_COMMIT_ID
    ? `_${process.env.REACT_APP_COMMIT_ID}`
    : ""

  return showShort ? `${version}` : `${version}${commitId}`
}
