import { call, put, select, takeLatest } from "typed-redux-saga";
import axios from "axios";
import { PROFILE_API_ACTIONS, UPDATE_ONBOARDING_COMPLETED } from "./actions";
import { getToken } from "../../../shared/store/auth";
import { Profile } from "./types";
import { RootState } from "../../../store";
import { AnyAction } from "redux";
import { getProductId } from "../../../store/Application";
import { getOnboardingSaga } from "../../onboarding/api/sagas";
import { getUserJourney } from "../../adventurePicker/api/sagas";

// fetches /profile from api and returns a Promise
function fetchProfile(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/profile`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

// worker saga: makes the api call when called by constraint service
export function* getProfileSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchProfile, token, productId);
    const profile = response.data;

    // dispatch a success action to the store with the profile
    yield* put({ type: PROFILE_API_ACTIONS.READ.SUCCESS, profile });
    yield* call(getUserJourney);
  } catch (error) {
    yield* put({ type: PROFILE_API_ACTIONS.READ.FAILURE, error });
  }
}

export function* watchUpdateProfileSaga() {
  yield* takeLatest(PROFILE_API_ACTIONS.UPDATE.REQUEST, updateProfileSaga);
}

function updateProfile(token: string, profile: Profile, productId: number) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(`/api/product/${productId}/profile`, profile, config);
}

function* updateProfileSaga(action: AnyAction) {
  try {
    // Post updated profile to api
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const profile = yield* select((state: RootState) => state.profile.profile);
    const response = yield* call(updateProfile, token, profile, productId);
    const updatedProfile = response.data;

    // dispatch a success action to the store with the updated profile
    yield* put({
      type: PROFILE_API_ACTIONS.UPDATE.SUCCESS,
      profile: updatedProfile,
    });
    //call the getOnboarding API and update the onboarding state
    yield* call(getOnboardingSaga);
  } catch (error) {
    yield* put({ type: PROFILE_API_ACTIONS.UPDATE.FAILURE, error });
  }
}

export function* watchSetOnboardingCompletedSaga() {
  yield* takeLatest(
    UPDATE_ONBOARDING_COMPLETED.UPDATE.REQUEST,
    updateOnboardingCompletedSaga
  );
}

function* updateOnboardingCompletedSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const profile = yield* select((state: RootState) => state.profile.profile);
    const updatedUser = { ...profile, ...action.payload };
    const response = yield* call(updateProfile, token, updatedUser, productId);
    const updatedProfile = response.data;

    yield* put({
      type: PROFILE_API_ACTIONS.UPDATE.SUCCESS,
      profile: updatedProfile,
    });
    yield* call(getUserJourney);
  } catch (error) {
    yield* put({ type: PROFILE_API_ACTIONS.UPDATE.FAILURE, error });
  }
}
