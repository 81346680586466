import { MarkerHeadGroup } from "../../../views/markers/api/types";
import { PROBLEM_SPECIFIC } from "./../../constants";

export const getAllProblemSpecificMarkerItems = (
  marker_head_groups: MarkerHeadGroup[]
) =>
  marker_head_groups
    .filter(
      (headGroup) => headGroup.marker_head_group_type === PROBLEM_SPECIFIC
    )
    .map((headGroup) =>
      headGroup.marker_sub_groups.map((subGroup) => subGroup.rewards)
    )
    .flat(3);

export const getAllProblemSpecificMarkers = (
  marker_head_groups: MarkerHeadGroup[]
) =>
  getAllProblemSpecificMarkerItems(marker_head_groups)
    .map((item) => item.sub_rewards)
    .flat(2);

export const getAllMarkerHeadgroupLabels = (
  marker_head_groups: MarkerHeadGroup[]
) => marker_head_groups.map((headGroup) => headGroup.marker_head_group_label);

export const getFirstNonNotifiedProblemSpecificMarkerItem = (
  marker_head_groups: MarkerHeadGroup[]
) =>
  getAllProblemSpecificMarkerItems(marker_head_groups).find(
    (item) => !item.notified
  );
