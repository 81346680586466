import * as React from "react";

interface Props {
  url: string;
  condition: boolean;
}

export const AudioPlayerComponent: React.FC<Props> = ({ url, condition }) => {
  const ref = React.useRef<HTMLAudioElement | null>(null);
  const pauseAudio = () => ref.current!.pause();
  const contextMenus = (e: any) => e.preventDefault();

  if (ref.current && condition) {
    pauseAudio();
  }

  return (
    <audio
      onContextMenu={contextMenus}
      controlsList="nodownload"
      ref={ref}
      src={url}
      controls={true}
    />
  );
};
