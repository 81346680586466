import * as React from "react";
import { Marker } from "../../../views/markers/api/types";
import { ViewSlideState } from "../../../views/exercise/api/types";
import {
  StyledEmptyMarkerWrapper,
  StyledMarkerContainerMiddleSchool,
  StyledMarkerItemContainer,
  StyledMarkerItemText,
  StyledMarkers,
} from "./StyledMarkers";
import { PRIMARY_SCHOOL } from "../../constants";
import { Themes } from "../../../views/profile/api/types";
import MarkerImagePrimarySchool from "./MarkerImagePrimarySchool";
import MarkerImageAnimator from "./MarkerImageAnimator";
import MarkerImageMiddleSchool from "./MarkerImageMiddleSchool";

interface MarkerProps {
  data: Marker[];
  headGroupLabels?: string[];
  theme: Themes;
  image?: string;
  border?: boolean;
  shadow?: boolean;
  withMargin?: boolean;
  viewSlideState?: ViewSlideState;
  intro?: boolean;
}

const Markers: React.FC<MarkerProps> = ({
  viewSlideState,
  headGroupLabels,
  border,
  shadow,
  theme,
  withMargin,
  data,
  intro,
}) => {
  const renderPrimarySchoolImage = (marker: Marker) => (
    <MarkerImagePrimarySchool
      image={marker.thumbnail_url}
      border={border}
      shadow={shadow}
      color={marker.color}
    />
  );

  const renderPrimarySchoolMarker = (marker: Marker, index: number) => (
    <StyledEmptyMarkerWrapper key={marker.id}>
      {marker.obtained && !marker.seen && viewSlideState && (
        <MarkerImageAnimator
          viewSlideState={viewSlideState}
          id={marker.id}
          content={renderPrimarySchoolImage(marker)}
          index={index}
        />
      )}
      {marker.obtained && marker.seen && renderPrimarySchoolImage(marker)}
    </StyledEmptyMarkerWrapper>
  );

  const renderMiddleSchoolMarker = (marker: Marker, index: number) => (
    <StyledMarkerContainerMiddleSchool
      obtained={marker.obtained}
      seen={marker.seen}
      key={marker.id}
    >
      <StyledMarkerItemContainer>
        <MarkerImageMiddleSchool
          image={marker.thumbnail_url}
          adventure={false}
          viewSlideState={viewSlideState}
          index={index}
          obtained={marker.obtained}
          seen={marker.seen}
          color={marker.color}
          intro={intro}
        />
        <StyledMarkerItemText>
          {headGroupLabels && headGroupLabels[index]}
        </StyledMarkerItemText>
      </StyledMarkerItemContainer>
    </StyledMarkerContainerMiddleSchool>
  );

  return (
    <StyledMarkers withMargin={withMargin}>
      {data.map((marker: Marker, index: number) =>
        theme === PRIMARY_SCHOOL
          ? renderPrimarySchoolMarker(marker, index)
          : renderMiddleSchoolMarker(marker, index)
      )}
    </StyledMarkers>
  );
};

export default Markers;
