import styled from 'styled-components';
import {Slider } from 'react-compound-slider';
import {StyledJumpWrapperProps} from "./types";

interface XcNumberLineProps {
  id?: number;
  sourcePercent?: number;
  targetPercent?: number;
  percent?: number;
  filledLine?: boolean;
  turn?: boolean;
  handleHasMoved?: boolean;
  hasLatex?: boolean;
  count?: number;
  isCorrect?: boolean;
  displayCorrectAnswer?: boolean;
  direction?: string;
}

/**
 * Sets position of element to a percentage from left of parent
 * @param percent
 */
const tickmarkProps = (percent: number | undefined): string => `left: ${percent || 0}%;`;

/**
 * Adjusts position of element to be centered
 * @param count
 */
const ticklabelCenter = (count: number | undefined): string => {
  count = count || 0;
  const width = 100 / (count - 1);
  const left = -(width / 2);
  return `
    margin-left: ${left}%;
    width: ${width}%;
  `;
};

/**
 * Displays track with correct length and position from left of parent (the main track)
 * @param sourcePercent
 * @param targetPercent
 */
const trackProps = (sourcePercent: number | undefined = 0, targetPercent: number | undefined = 0): string => `
  left: ${Math.min(sourcePercent, targetPercent)}%;
  width: ${Math.abs(targetPercent - sourcePercent)}%;
`;

/**
 * Sets color of line to either colored or transparent
 * @param {boolean} filledLine
 * @returns {string}
 */
const line = (filledLine: boolean | undefined): string => filledLine
  ? '#FFEC00' 
  : 'transparent';

export const StyledRail = styled.div`
    position: absolute;
    width: 100%;
    height: 16px;
    margin-top: 35px;
    border-radius: 0;
    background-color: #277575;
    cursor: pointer;
`;

export const StyledSlider = styled(Slider)`
    height: 80px;
    width: 100%;
    max-width: 1400px;
    position: relative;
    margin: 0 auto 18px auto;
`;

export const StyledJump = styled.div`
   position: absolute;
   height: 6px;
   z-index: 1;
   margin-top: 40px;
   background-color: ${(props: XcNumberLineProps) => line(props.filledLine)};
   border-radius: 5px;
   cursor: pointer;
   ${(props: XcNumberLineProps) => trackProps(props.sourcePercent, props.targetPercent)};
   & svg {
     width: 100%;
     position: absolute;
     bottom: 4px;
   };
   & #bird {
    ${({turn}: XcNumberLineProps): string => turn 
      ? '{transform: scale(-1, 1)}'
      : '{transform: scale(1, 1)}'}
   }
`;

export const StyledRightArrow  = styled.div`
    position: absolute;
    right: -24px;
    margin-top: 35px;
    height: 16px;
    width: 50px;
    background-color: #277575;
    clip-path: polygon(75% 0, 0 0, 0 100%, 75% 100%, 100% 50%);
    pointer-events: none;
`;

export const StyledLeftArrow  = styled.div`
    position: absolute;
    left: -24px;
    margin-top: 35px;
    height: 16px;
    width: 50px;
    background-color: #277575;
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 25% 100%, 0% 50%);
    pointer-events: none;
`;

export const StyledRailEnd  = styled.div`
    position: absolute;
    ${({direction}: XcNumberLineProps): string => direction === 'left' ?  'left: -24px' : 'right: -24px'};
    margin-top: 35px;
    height: 16px;
    width: 37px;
    background-color: #277575;
    pointer-events: none;
`;

export const StyledTickmark = styled.div`
    position: absolute;
    margin-left: -2.5px;
    width: 5px;
    height: 40px;
    bottom: 20px;
    background-color: #277575;
    ${({ percent }: XcNumberLineProps) => tickmarkProps(percent)}
    pointer-events: none;
`;

export const StyledTicklabel = styled.div`
    position: absolute;
    bottom: -16px;
    font-family: 'Muli', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 27px;
    letter-spacing: 0.19px;
    color: #277575;
    
    ${({ count }: XcNumberLineProps) => ticklabelCenter(count)};
    ${({ percent }: XcNumberLineProps) => tickmarkProps(percent)}

    & .katex {
      position: relative;
      top: 10px;    
    }
`;

export const StyledTickImageContainer = styled.div`
  position: relative;
  margin: auto;
  bottom: -25px;
  display: flex;
  justify-content: center;
`;

export const StyledTickImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 56px;
  max-height: 56px;
  margin-right: 1.5px;
`;

export const StyledJumpWrapper = styled.div`
  height: 50px;
  position: absolute;
  
  width: ${({ totalSteps: total, startingPosition: a, currentPosition: b}: StyledJumpWrapperProps): number => getPercentage(total, a, b)}%;
  left: ${(props: StyledJumpWrapperProps): number => getJumpLeftOffset(props)}%;
`;

/**
 * Orders given numbers in an ascending order
 * @param {number} firstNumber
 * @param {number} secondNumber
 * @returns {number}
 */
const ascendNumbers = (firstNumber: number, secondNumber: number): number => firstNumber - secondNumber;

/**
 * calculates a percentage from numbers
 * @param {number} total
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
const getPercentage = (total: number, a: number, b: number): number => {
  const [smallerNumber, largerNumber] = [a, b].sort(ascendNumbers);

  return ((largerNumber - smallerNumber) / total) * 100;
};

/**
 * Gets the left offset for the jumps
 * @param props StyledJumpWrapperProps
 * @returns {number}
 */
const getJumpLeftOffset = (props: StyledJumpWrapperProps): number => {
  const { startingPosition, currentPosition, totalSteps } = props;
  const startOffset = 100 - getPercentage(totalSteps, startingPosition, totalSteps);
  const currentOffset = 100 - getPercentage(totalSteps, currentPosition, totalSteps);

  return currentOffset <= startOffset
    ? currentOffset
    : startOffset;
};