import {
  ADD_SNACKBAR_MESSAGE,
  CLEAR_SNACKBAR_MESSAGES,
  REMOVE_SNACKBAR_MESSAGE,
  REMOVE_SNACKBAR_MESSAGES,
  REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE,
} from "../../../constants";
import { SnackbarState } from "./types";

const initialState: SnackbarState = {
  messages: [],
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_SNACKBAR_MESSAGE:
      const addDate = new Date().getTime();
      action.message.addedTime = addDate;
      return { ...state, messages: [...state.messages, action.message] };
    case REMOVE_SNACKBAR_MESSAGE:
      const messages = [...state.messages];
      messages[action.message].addedTime = 0;
      return { ...state, messages };
    case REMOVE_SNACKBAR_MESSAGES:
      return {
        ...state,
        messages: state.messages.filter((_, i) => !action.message.includes(i)),
      };
    case REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE:
      return {
        ...state,
        messages: state.messages.map((message) => {
          if (message.routes.some((r) => r === action.message)) {
            return { ...message, addedTime: 0 };
          }
          return message;
        }),
      };
    case CLEAR_SNACKBAR_MESSAGES:
      return { ...state, messages: [] };
    default:
      return state;
  }
}
