import styled from "styled-components";
import { DropDownStyles } from "./DropDown";

interface ContentProps {
  active?: boolean;
  width?: number;
  overrideStyles?: DropDownStyles, 
}

export const StyledDropDownContainer = styled.div<ContentProps>`
  font-family: 'Muli';
  position: relative;
  z-index: ${ ({ overrideStyles }) => overrideStyles ? overrideStyles.zIndex : '1'};
  margin-bottom: 20px;
  width: 100%;

  &:focus-within {
    filter: drop-shadow(0 0 4px #a2a2a2);
  }
`;

export const StyledDropDownContent = styled.ul<ContentProps>`
  position: absolute;
  top: ${ ({ overrideStyles }) => overrideStyles ? overrideStyles.height  : '56px'};
  left: 0;
  width: 100%;
  visibility: hidden;
  background-color: #fff;
  ${props => (props.width ? `max-width: ${props.width + 33}px` : 'max-width: 368px')};
  max-width: ${ ({ overrideStyles }) => overrideStyles ? overrideStyles.maxWidth  : '368px'};
  max-height: 280px;
  margin: 0;
  padding: 0;
  border: 0;
  transform-origin: top left;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4;
  transition: visibility 0ms linear 150ms, opacity 150ms linear; 

  ${props =>
  props.active
    ? `
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  `
    : null};
`;