import * as React from "react";
import { useDrop } from "react-dnd";
import { StyledUnOrderedDropZone } from "./StyledUnOrderedDropZone";

interface Props {
  id: string;
  chipsFormat: string;
  itemIsSelected: boolean;
  callback: () => void;
  onDropCallback: (param1: number, param2: string, param3: string) => void;
}

export const UnOrderedDropZone: React.FC<Props> = (props) => {
  const [{ isOver, item }, drop] = useDrop({
    accept: "sort-item",
    drop: () => onDrop(),
    collect: (monitor) => ({
      item: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: true,
    }),
  });
  const tempItem: any = item;

  const handleClick = () => props.callback();

  const onDrop = () => {
    props.onDropCallback(-1, tempItem.id, props.id);
  };

  return (
    <StyledUnOrderedDropZone
      ref={drop}
      onClick={handleClick}
      chipsFormat={props.chipsFormat}
      isOver={isOver}
      itemIsSelected={props.itemIsSelected}
    >
      {props.children}
    </StyledUnOrderedDropZone>
  );
};
