import * as actions from "./actions";
import { 
  rootSaga, 
  watchSocketClosedSaga, 
  watchSocketErrorSaga,
  watchSocketChannelSaga 
} from "./sagas";

const SocketStore = {
  actions,
  saga: rootSaga,
  watchSocketErrorSaga,
  watchSocketClosedSaga,
  watchSocketChannelSaga
};

export default SocketStore;
