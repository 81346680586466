import styled from "styled-components";
import { Navigator } from "../Navigator";

export const StyledNavigatorTest = styled(Navigator)`
  display: flex;
  margin-right: 25px;
  height: 40px;
  transform: scale(1);
  box-shadow: none;
  background: #277575; 
  padding: 0px;
  &:hover {
   background: #277575;
  }
  &:focus {
    background: #277575;
  }
  &:active {
    background: #277575; 
  }
`;
