import { isIOSDevice } from "../../../../../shared/platform/Platform";
import { KeyboardData } from "./types";

export const updateNumpadValue = (
  ref: React.RefObject<HTMLInputElement>,
  value: string,
  oldValue: string
) => {
  if (ref && ref.current) {
    const start = ref.current.selectionStart;
    const end = ref.current.selectionEnd;

    return start !== null && end !== null && start < end
      ? replaceText(start, end, value, oldValue)
      : appendToEnd(value, oldValue);
  }
};

/**
 * Replaces the user selected text
 * '%&' is backspace
 * @param {start} number
 * @param {end} number
 * @param {value} string
 * @param {text} string
 */
export const replaceText = (start: number = 0, end: number, value: string, text: string) => {
  const before = text.slice(0, start);
  const after = text.slice(end);

  return value === '%&' ? before + after : before + value + after;
}

/**
 * Appends recieved value to the end of string
 * @param {start} number
 * @param {end} number
 * @param {value} string
 * @param {text} string
 */
export const appendToEnd = (value: string, text: string) => value === '%&'
  ? text.substring(0, text.length - 1) 
  : text + value
;

export const getKeyboardPosition = () => (
  document.documentElement.clientWidth > 840 ? 'SQUARE' : 'HORIZONTAL'
);

export const getKeyboardLayout = (keyboardData: KeyboardData) => {
  if (getKeyboardPosition() === 'SQUARE' && keyboardData.square_keyboard) {
    return keyboardData.square_keyboard.characters;
  }
  else if(keyboardData.horizontal_keyboard) {
    return keyboardData.horizontal_keyboard.characters;
  }
}

/**
 * Select all text on focus
 * @param {MouseEvent} event
 */
export const selectText = (event: React.MouseEvent) => {
  const inputEl = event.target as HTMLInputElement;

  if (inputEl !== document.activeElement) {
    /*Delay here is so text wont be instantly deselected */
    setTimeout(() => {
      isIOSDevice()
        ? inputEl.setSelectionRange(0, inputEl.value.length)
        : inputEl.select();
    }, 0);
  }
};