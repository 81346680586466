import styled from 'styled-components';
import {getCellSize} from '../../../Chip/chipsFormats';

interface RowProps {
  active?: boolean;
  chipsFormat: string;
}

export const StyledSortWrapper = styled.section`
  color: ${props => props.theme.themePrimary};
  width: 100%;
  max-width: 1200px;
  height: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Muli';
  min-height: 0;
  align-self: stretch;
`;

export const StyledSortRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 0;
  flex: 1;
  &:not(:first-child) { margin-top: 10px; }
`;

export const StyledSortItemsRow = styled(StyledSortRow)<RowProps>`
  flex-wrap: wrap;
  align-items: center;
  min-height: auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => getCellSize(props.chipsFormat)});
  grid-gap: 4px;
  align-content: center;
  padding: 8px;
  border-radius: 12px;
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  
  &:hover {
    background-color: ${props => props.active ? 'rgba(243, 247, 246, .8)' : 'none'};
  }
  @media (min-width: 1000px) { grid-gap: 20px; }
`;

export const StyledSortBoxesRow = styled.div`
  width: 100%;
  min-height: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(189px, 1fr));
  grid-gap: 6px;
  justify-items: center;
  @media (max-width: 1366px) and (max-height: 1366px) { max-height: 250px; }
  &:not(:first-child) { margin-top: 10px; }
`;

/* 
 *This span is necessary to prevent layout from breaking when rotating IOS10 device.
*/
export const StyledItemsRowWrapper = styled.span`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;