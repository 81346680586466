import { MarkerSubGroup, Marker } from "./api/types";

export const getNumberOfItems = (list: MarkerSubGroup[]) => {
  const items = list
    .reduce((res, grp) => [...res, ...grp.rewards], [] as Marker[])
    .reduce(
      (res, grp) => [...res, grp, ...(grp.sub_rewards as Marker[])],
      [] as Marker[]
    )
    .filter((item) => item.obtained)
    .filter((item) => !item.reward_type.includes("item"));

  return items.length;
};
