import * as React from "react";
import {
  StyledDropDownButton,
  StyledDropDownButtonLabel,
  StyledDropDownButtonIcon,
} from "./StyledDropDownButton";
import { DropDownStyles } from "../DropDown";

export interface DropDownButtonProps {
  label?: string;
  icon?: any;
  onClick?: () => void;
  open?: boolean;
  overrideStyles?: DropDownStyles;
}

export const DropDownButton: React.FC<DropDownButtonProps> = ({
  label,
  icon,
  onClick,
  open,
  overrideStyles,
}) => {
  return (
    <StyledDropDownButton
      overrideStyles={overrideStyles}
      open={open}
      onClick={onClick}
    >
      <StyledDropDownButtonLabel overrideStyles={overrideStyles}>
        {label}
      </StyledDropDownButtonLabel>
      <StyledDropDownButtonIcon flipped={open} src={icon} alt="menypil" />
    </StyledDropDownButton>
  );
};
