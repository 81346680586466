import * as React from "react";
import { ViewSlideState } from "../../../views/exercise/api/types";
import MarkerImageAnimator from "./MarkerImageAnimator";
import {
  StyledBadgeContainer,
  StyledBadgeImgWrapper,
  StyledBadgeImg,
  StyledMarkerItemImageWrapper,
} from "./StyledMarkers";

interface MarkerImageProps {
  adventure: boolean;
  image: string;
  obtained?: boolean;
  color?: string;
  index?: number;
  seen?: boolean;
  viewSlideState?: ViewSlideState;
  intro?: boolean;
}

const MarkerImageMiddleSchool: React.FC<MarkerImageProps> = ({
  adventure,
  obtained,
  image,
  color,
  index,
  viewSlideState,
  seen,
  intro,
}) => {
  if (viewSlideState && obtained && !seen) {
    const content = (
      <StyledBadgeContainer adventure={adventure} obtained={obtained}>
        <StyledBadgeImgWrapper adventure={adventure} obtained={obtained}>
          <StyledBadgeImg
            adventure={adventure}
            src={image}
            obtained={obtained}
            color={color}
          />
        </StyledBadgeImgWrapper>
      </StyledBadgeContainer>
    );
    return (
      <StyledMarkerItemImageWrapper adventure={adventure}>
        <MarkerImageAnimator
          viewSlideState={viewSlideState}
          id={image}
          content={content}
          index={index}
        />
      </StyledMarkerItemImageWrapper>
    );
  }
  return (
    <StyledMarkerItemImageWrapper adventure={adventure}>
      <StyledBadgeContainer adventure={adventure} obtained={obtained}>
        <StyledBadgeImgWrapper adventure={adventure} obtained={obtained}>
          <StyledBadgeImg
            adventure={adventure}
            src={image}
            obtained={obtained || intro}
            color={color}
          />
        </StyledBadgeImgWrapper>
      </StyledBadgeContainer>
    </StyledMarkerItemImageWrapper>
  );
};

export default MarkerImageMiddleSchool;
