import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { Dispatch } from "redux";
import { hideDialog } from "../../store/splash/actions";
import {
  StyledAccessDeniedCard,
  StyledAccessText,
  StyledPrimaryCloseButton,
  StyledSplashWrapper,
} from "../Splash/StyledSplash";
import { PrimaryButton } from "../Button";

interface MapStateProps {
  expiration_msg: string | undefined;
  dialogOpen: boolean;
}

interface DispatchProps {
  hideDialog(): void;
}

export const SplashDialogComponent: React.FC<MapStateProps & DispatchProps> = (
  props
) =>
  props.expiration_msg && props.dialogOpen ? (
    <StyledSplashWrapper>
      <div>
        <StyledAccessDeniedCard>
          <StyledAccessText>{props.expiration_msg}</StyledAccessText>
          <StyledPrimaryCloseButton>
            <PrimaryButton
              label={"Jag förstår"}
              overrideStyles={{ padding: "12px 50px" }}
              onClick={props.hideDialog}
            />
          </StyledPrimaryCloseButton>
        </StyledAccessDeniedCard>
      </div>
    </StyledSplashWrapper>
  ) : null;

const mapStateToProps = (state: RootState): MapStateProps => ({
  expiration_msg: state.access.access.expiration_msg,
  dialogOpen: state.splash.dialogOpen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideDialog: () => dispatch(hideDialog()),
});

export const SplashDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(SplashDialogComponent as any);
