import { Map, List } from "immutable";
import {
  FLASHER_ADD_FLASH,
  FLASHER_CLEAR_FLASH,
} from "../../../shared/constants";
import { AnyAction } from "redux";
import { createTypedMap, TypedMap } from "../../../store/utils/typed-map";
import { FlasherState, TypedFlashMessage } from "./types";

const initialState: TypedMap<FlasherState> = createTypedMap({
  messages: List<TypedFlashMessage>(),
});

/**
 * Reducer for flasher component
 * @param state Current state
 * @param action Incoming action
 * @return TypedMap<FlasherState> Updated state
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FLASHER_ADD_FLASH:
      return state.update("messages", (messages: List<TypedFlashMessage>) =>
        messages.push(
          Map({
            msg: action.msg,
            type: action.flashType,
            id: action.id,
          }) as unknown as TypedFlashMessage
        )
      );
    case FLASHER_CLEAR_FLASH:
      const flashIndex = state
        .get("messages")
        .findIndex(
          (flash?: TypedFlashMessage) =>
            flash !== undefined && flash.get("id") === action.id
        );
      if (flashIndex === -1) {
        return state;
      }
      return state.set("messages", state.get("messages").delete(flashIndex));
    default:
      return state;
  }
}
