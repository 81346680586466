import { takeLatest, call, put, select } from "typed-redux-saga";
import {
  EXERCISE_API_ACTIONS,
  changeExerciseViewSlide,
  createDeck,
  deckFinished,
  exerciseFinished,
  fetchExercisesFailure,
  fetchExerciseSuccess,
  newExerciseData,
  nextDeckCard,
  nextDeckReady,
  updateCard,
  createNewDeck,
  fetchNextExerciseSet,
  addSlideIndexTypes,
  startExercise,
  setAnswerId,
  initiateExerciseAnswerFailure,
  submitExerciseAnswerFailure,
  submitExerciseAnswerSuccess,
  submitExerciseAnswerCall,
  setObtainedItemId,
} from "./actions";
import { networkDisonnected } from "../../../shared/store/network/actions";
import { getToken } from "../../../shared/store/auth";
import {
  CREATE_NEW_DECK,
  EXERCISE,
  WATCH_CARD_UPDATE,
  EXERCISE_FINISHED,
  READY,
  DECK_FINISHED,
  NEXT_DECK_STATUS,
  START_EXERCISE,
  CORRECT_ANSWER,
  HANDLE_CARD_STATE_CHANGE,
  GA_EVENT,
  ERROR_TYPES,
  ANSWERING,
  SET_PROGRESS,
  SET_ITEM_FEEDBACK_INDEX,
  LOG_MEDIA_PLAYED,
} from "../../../shared/constants";
import {
  deckIsDone,
  prepareCard,
  prepareNewDeck,
  prepExerciseObject,
  prepExerciseSlideData,
  extractItemData,
  badgeCompleted,
} from "./helperFunctions";
import { getProductId } from "../../../store/Application";
import { RootState } from "../../../store";
import {
  ActLogMediaPlayed,
  ActCardSecondaryUpdate,
  ActCreateNewDeck,
  ActDeckId,
  ActReadExerciseRequest,
  ActWatchCardUpdate,
  FetchExerciseSuccess,
  AnswerData,
  NextDeckStatus,
  ActCorrectAnswer,
  RecommendationsResponseData,
} from "./types";
import { CorrectionBtnStates } from "../../../shared/components/CorrectionButton";
import {
  getCurrentExerciseId,
  getExerciseData,
  getIsRepetition,
} from "./reduxSelectors";
import {
  correctExercise,
  fetchExercises,
  initiateExerciseAnswer,
  submitExerciseAnswer,
  logInteractiveMetric,
} from "./requests";
import { v4 as uuid } from "uuid";
import { addFlash } from "../../../shared/store/flasher/actions";
import {
  ExerciseState,
  Answer,
} from "../../../external/EddaXcomp/src/components/ExerciseRenderer/ExerciseRendererTypes";
import { CorrBtnState } from "../../../external/EddaXcomp/src/components/CorrectionButton/CorrectionButtonTypes";
import ReactGA from "react-ga";
import { selectTheme } from "../../profile/api/selectors";
import { getUserJourney } from "../../adventurePicker/api/sagas";
import { Markers, MarkerSubGroup } from "../../markers/api/types";
import { addSnackbarMessage } from "../../../shared/components/Snackbar/api/actions";

// ____________________________ //
//        Watcher sagas         //
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ //

/** watcher saga: watches for actions dispatched to the store, starts worker saga */
export function* watchGetExercisesSaga() {
  yield* takeLatest(EXERCISE_API_ACTIONS.READ.REQUEST, getExercisesSaga);
}

/** Sagas for generating new decks */
export function* watchNewDeck() {
  yield* takeLatest(CREATE_NEW_DECK, makeNewDeck);
}

export function* watchNextDeckStatus() {
  yield* takeLatest(NEXT_DECK_STATUS, handleNextDeckStatus);
}

export function* watchExerciseFinish() {
  yield* takeLatest(EXERCISE_FINISHED, exerciseFinishSaga);
}

/**
 * Sagas for updating cards
 */
export function* watchCardUpdates() {
  yield* takeLatest(WATCH_CARD_UPDATE, handleCardStateChange);
}

/**
 * Saga watching for successful exercise data fetched
 */
export function* watchNewExerciseData() {
  yield* takeLatest(EXERCISE_API_ACTIONS.READ.SUCCESS, handleNewExerciseData);
}

/**
 * Saga watching for the DECK_FINISHED action
 */
export function* watchDeckFinished() {
  yield* takeLatest(DECK_FINISHED, handleDeckFinished);
}

/**
 * Saga watching for exercises starting in exercise view
 */
export function* watchExerciseStarted() {
  yield* takeLatest(START_EXERCISE, makeExerciseStart);
}

export function* watchCorrectAnswer() {
  yield* takeLatest(CORRECT_ANSWER, makeCorrectExercise);
}

/**
 * Saga watching for media played
 */
export function* watchLogMediaPlayed() {
  yield* takeLatest(LOG_MEDIA_PLAYED, logMediaPlayed);
}

// ____________________________ //
//          Makes sagas         //
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ //

/**
 * Fetches currently viewed exercise ID and pass it along
 */
function* makeExerciseStart() {
  const exerciseId = yield* select(getCurrentExerciseId);
  yield* call(initiateExerciseAnswerSaga, exerciseId);
}
/**
 * Sends metric data about played media
 */
function* logMediaPlayed(action: ActLogMediaPlayed) {
  const token = yield* select(getToken);
  const productId = yield* select(getProductId);
  yield* call(logInteractiveMetric, token, productId, action.typeOfMediaPlayed);
}

/**
 * Handles exercise request result
 * @param action
 */
function* getExercisesSaga(action: ActReadExerciseRequest) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(
      fetchExercises,
      token,
      productId,
      action.callType,
      action.callParams
    );
    yield* call(logInteractiveMetric, token, productId, ANSWERING);

    yield* put(fetchExerciseSuccess(action, response.data));

    const showNotifications = response.data.parts.some(
      (p: any) => p.type === "markers" || "result"
    );
    if (showNotifications) {
      yield* call(notifyAboutMarkers, response.data.notificationData);
    }
  } catch (error) {
    yield* put(addFlash("Oj, det blev något fel", ERROR_TYPES.ERROR));
    yield* put(fetchExercisesFailure(error as Error));
  }
}

function* notifyAboutMarkers(
  notifications: RecommendationsResponseData["notificationData"] = []
) {
  for (let notification of notifications) {
    yield* put(
      addSnackbarMessage({
        severity: "success",
        message: `Du har fått ett märke! ${notification}`,
        routes: ["EXERCISE_ROUTE"],
      })
    );
  }
}

/**
 * Generates new Deck data from exercise data
 * @param action
 */
function* makeNewDeck(action: ActCreateNewDeck) {
  yield* put(createDeck(prepareNewDeck(action)));
  yield* put(nextDeckReady());
}

/**
 * Handles status for next deck and changes slide to it if it's ready and not the first deck
 * @param action
 */
function* handleNextDeckStatus(action: NextDeckStatus) {
  const decks = yield* select((state: RootState) => state.exercise.decks);
  if (
    decks &&
    Object.keys(decks).length > 1 &&
    action.status &&
    action.status === READY
  ) {
    yield* put(changeExerciseViewSlide());
  }
}

/**
 * Compiles answer data and calls submitExerciseAnswer
 * @param cardData
 */
function* initiateExerciseAnswerSaga(exerciseId: number) {
  try {
    const exercise = yield* select(getExerciseData, exerciseId);
    const isRepetition = yield* select(getIsRepetition);
    if (exercise) {
      const goalId = exercise.goal.id;
      if (goalId > 0) {
        const answerData: AnswerData = {
          body: "",
          answer: "",
          correct: false,
          attempts: 0,
          goal_id: goalId,
          is_repetition: isRepetition,
        };
        const token = yield* select(getToken);
        const productId = yield* select(getProductId);
        const response = yield* call(
          initiateExerciseAnswer,
          token,
          productId,
          exerciseId,
          answerData
        );
        yield* put(setAnswerId(parseInt(response.data.id, 10)));
      }
    }
  } catch (error) {
    yield* put(initiateExerciseAnswerFailure(error as Error));
  }
}

/**
 * Compliles answer data and calls submitExerciseAnswer
 * @param cardData
 */
function* submitExerciseAnswerSaga(cardData: ExerciseState) {
  try {
    const exerciseId = cardData.exerciseId;
    const exercise = yield* select(getExerciseData, exerciseId);
    const isRepetition = yield* select(getIsRepetition);
    if (exercise) {
      const goalId = exercise.goal.id;
      const answerData: AnswerData = {
        body: "",
        answer: "",
        correct: cardData.state === CorrectionBtnStates.CORRECT,
        attempts: cardData.currentAttempt,
        goal_id: goalId,
        is_repetition: isRepetition,
      };
      const token = yield* select(getToken);
      const productId = yield* select(getProductId);
      const answerId = yield* select(
        (state: RootState) => state.exercise.answerId
      );
      // skip submitting answer if there is no answer id
      if (answerId > 0) {
        yield* put(submitExerciseAnswerCall(exerciseId));
        yield* call(
          submitExerciseAnswer,
          token,
          productId,
          exerciseId,
          answerId,
          answerData
        );
        yield* put(submitExerciseAnswerSuccess(exerciseId));
      }
    }
  } catch (error) {
    yield* put(networkDisonnected(error as Error));
    yield* put(submitExerciseAnswerFailure(error as Error));
  }
}

/**
 * Calls submit answer saga and dispatches correct action depending on current card beeing last in deck or not
 * @param action
 */
function* exerciseFinishSaga(action: ActDeckId) {
  const deck = yield* select(
    (state: RootState) => state.exercise.decks[action.deckID]
  );
  const deckDone = yield* call(deckIsDone, deck);

  yield* call(submitExerciseAnswerSaga, action.cardData);

  if (deckDone) {
    yield* put(deckFinished(action.deckID, action.cardData));
  } else {
    yield* put(nextDeckCard(action.deckID, action.cardData));
    yield* put(startExercise());
  }
}

/**
 * Handles necessary updates when an exercise is finished and the deck is done.
 * @param action
 */
function* handleDeckFinished(action: ActWatchCardUpdate) {
  const goalId = yield* select(
    (state: RootState) =>
      state.exercise && state.exercise.goal && state.exercise.goal.id
  );

  yield* put(fetchNextExerciseSet(goalId));
}

/**
 * Updates a card and gives it a new state
 * @param action
 * @param {boolean} secondaryUpdate
 */
function* makeCardUpdate(action: ActWatchCardUpdate, secondaryUpdate: boolean) {
  yield* put(updateCard(action.deckID, prepareCard(action, secondaryUpdate)));
}

/**
 * Takes state changes on a card and furthers it to appropriate saga.
 * @param action
 */
function* handleCardStateChange(action: ActCardSecondaryUpdate) {
  if (action.secondaryUpdate !== undefined) {
    // IF UPDATE COMES FROM handleExerciseFinished
    yield* call(makeCardUpdate, action, true);
  } else if (action.cardData.exerciseFinished) {
    // IF EXERCISE FINISHED
    yield* put(exerciseFinished(action.deckID, action.cardData));
  } else {
    // IF EXERCISE ISN'T OVER
    yield* call(makeCardUpdate, action, false);
  }
}

/**
 * Process new exercise data
 * @param action
 */
function* handleNewExerciseData({
  data: { goal, parts: acParts, is_repetition, adventureProgress },
}: FetchExerciseSuccess) {
  let exercise: any;
  const parts = acParts.map((part: any) => {
    if (part.type.toUpperCase() === EXERCISE) {
      exercise = {
        ...part,
        data: prepExerciseObject(part.data),
        deckID: uuid(),
      };
      return exercise;
    } else {
      return part;
    }
  });

  const item = extractItemData(acParts);
  if (item && item.obtained) {
    ReactGA.event({
      category: GA_EVENT.EVENT_CATEGORY,
      action: GA_EVENT.EVENT_ACTIONS.ITEM_COMPLETED,
      label: item.title,
      nonInteraction: true,
    });
    yield* put(setObtainedItemId(item.id, item.challenge_id));
  }
  if (badgeCompleted(acParts)) {
    ReactGA.event({
      category: GA_EVENT.EVENT_CATEGORY,
      action: GA_EVENT.EVENT_ACTIONS.BADGE_COMPLETED,
      nonInteraction: true,
    });
  }

  yield* put(addSlideIndexTypes(prepExerciseSlideData(parts))); // Adds necessary data for navbar in exercise view

  // Update goal
  yield* put(newExerciseData(parts, goal, is_repetition));

  if (!adventureProgress.adventureId) {
    yield* call(getUserJourney);
  } else {
    yield* put({ type: SET_PROGRESS, progress: adventureProgress });
  }

  // Create new deck using the exercise data from backend
  if (exercise !== undefined) {
    yield* put(createNewDeck(exercise.deckID, exercise.data));
  }

  const theme = yield* select(selectTheme);
  if (theme !== "primary-school") {
    return;
  }

  const [marker] = acParts.filter(
    (item) => item.type === "markers"
  ) as unknown as Markers[];
  if (!marker) {
    return;
  }

  const obtainedItemIndex = (marker.marker_head_groups || [])
    .filter((grp) => grp.marker_head_group_type === "problem_specific")
    .reduce(
      (res, grp) => [...res, ...grp.marker_sub_groups],
      [] as MarkerSubGroup[]
    )
    .reduce((res, sub) => {
      const index = sub.rewards.findIndex((i) => i.obtained && !i.notified);

      return res > -1 ? res : index;
    }, -1);

  if (obtainedItemIndex === -1) {
    return;
  }

  yield* put({ type: SET_ITEM_FEEDBACK_INDEX, index: obtainedItemIndex });
}

/**
 * Corrects the exercise or updates the current card if a correction has already been done
 * @param action
 */
function* makeCorrectExercise(action: ActCorrectAnswer) {
  yield* put(submitExerciseAnswerCall(action.meta.cardData.exerciseId));

  try {
    const {
      answer,
      cardData,
      meta: {
        deckID,
        cardData: { exerciseId },
      },
    } = action;

    const token: string = yield* select(getToken);
    const productId = yield* select(getProductId);
    const answerId = yield* select(
      (state: RootState) => state.exercise.answerId
    );

    let newState: CorrBtnState;
    let correctAnswer: Answer = { answer: "" };

    if (!cardData.exerciseFinished) {
      const { CORRECT, INCORRECT } = CorrectionBtnStates;
      const isCorrectReq = yield* call(
        correctExercise,
        token,
        productId,
        exerciseId,
        answerId,
        answer
      );
      const {
        data: { is_correct, correct_answer },
      } = isCorrectReq;
      correctAnswer = correct_answer;
      newState = is_correct ? CORRECT : INCORRECT;
      ReactGA.event({
        category: GA_EVENT.EVENT_CATEGORY,
        action: is_correct
          ? GA_EVENT.EVENT_ACTIONS.CORRECT_ANSWER
          : GA_EVENT.EVENT_ACTIONS.INCORRECT_ANSWER,
        nonInteraction: true,
      });
    } else {
      newState = cardData.state;
    }

    yield* call(handleCardStateChange, {
      type: HANDLE_CARD_STATE_CHANGE,
      newState,
      deckID,
      cardData,
      correctAnswer,
    });
    yield* put(submitExerciseAnswerSuccess(exerciseId));
  } catch (error) {
    yield* put(networkDisonnected(error as Error));
    yield* put(submitExerciseAnswerFailure(error as Error));
  }
}
