import { keyframes } from 'styled-components';

export const handleShake = keyframes`
  0.00%,
  18.92% {
    transform: translateX(0);
  }

  15.14% {
    transform: translateX(8px);
  }

  17.03% {
    transform: translateX(-8px);
  }

  1.89%,
  5.68%,
  9.46%,
  13.24% {
    transform: translateX(-10px);
  }

  3.78%,
  7.57%,
  11.35% {
    transform: translateX(10px);
  }
`;