import * as React from "react";
import { ReactComponent as StudliBow } from "../../../../assets/logos/studli_bow.svg";
import { ReactComponent as StudliLogo } from "../../../../assets/logos/studli_logo.svg";
import { ReactComponent as TomoyoLogo } from "../../../../assets/logos/tomoyo_logo_color.svg";
import { ReactComponent as BookMark } from "../../../../assets/icons/icon_bookmark.svg";
import {
  StyledCampaignWrapper,
  StyledHeading,
  StyledTiledBG,
  StyledLogoContainer,
  StyledCampaignLinkContainer,
  StyledCampaignContainer,
  StyledInfoText,
  StyledSmallText,
  StyledSubHeading,
  StyledSmallSubHeading,
  StyledLinksContainer,
  StyledExtraSmallText,
  StyledLink,
  StyledCampaignLink,
  StyledLinkText,
  StyledCampaignImage,
  StyledBowContainer,
  StyledStudliLogoContainer,
} from "./StyledFreeTomoyo";
import { Campaign } from "../../../store/auth/types";
import { CampaignError } from "../CampaignError/CampaignError";
import { Modal } from "../../Modal";
import MetaTags from "react-meta-tags";
import { FreeTomoyoEnd } from "./FreeTomoyoEnd";

const renderOGTags = () => (
  <div className="wrapper">
    <MetaTags>
      <title>Tomoyo</title>
      <meta
        name="description"
        content="Rådande omständigheter gör att många elever befinner sig hemma och att lärare behöver bedriva undervisningen helt eller delvis på distans. Därför erbjuder vi fri tillgång till Tomoyo, digital färdighetsträning i matematik, terminen ut. Fri tillgång till Tomoyo terminen ut."
      />
      <meta property="og:title" content="Tomoyo" />
      <meta
        property="og:image"
        content="https://media.studentlitteratur.se/product_images/pcatimg/9789144135854.jpg"
      />
    </MetaTags>
  </div>
);

interface Props {
  url: string;
  currentHostUrl: string;
  campaignData: Campaign;
  error: boolean;
}

export const FreeTomoyo: React.FC<Props> = (props) => {
  if (
    window.location.hostname === "minprodukt.studentlitteratur.se" &&
    window.location.protocol !== "https:"
  ) {
    window.location.replace(window.location.href.replace("http:", "https:"));
  }

  const [showErrorMessage, setShowErrorMessage] = React.useState(props.error);

  const errorHeading = "Ett fel inträffade.";
  const firstInfo = (
    <StyledInfoText>
      Kunde inte starta Tomoyo - var god försök igen senare!
    </StyledInfoText>
  );
  const secondInfo = (
    <StyledInfoText>
      Vid återkommande fel ber vi dig kontakta vår{" "}
      <a href="https://www.studentlitteratur.se/#sida/support/32566">
        kundservice
      </a>
      .
    </StyledInfoText>
  );

  const removeHashFromUrl = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
  };

  const closeErrorMessage = () => {
    removeHashFromUrl();
    setShowErrorMessage(false);
  };

  const getDestLink = (destination: string) => {
    const dest = btoa(destination);
    return `${props.url}?dest=${dest}`;
  };

  const renderModal = () => (
    <Modal show={showErrorMessage}>
      <CampaignError
        heading={errorHeading}
        firstInfo={firstInfo}
        secondInfo={secondInfo}
        renderCloseButton={true}
        callback={closeErrorMessage}
      />
    </Modal>
  );

  const renderContent = () => (
    <>
      <StyledHeading>FRI TILLGÅNG TILL TOMOYO TERMINEN UT</StyledHeading>
      <StyledInfoText fontWeight={"bold"}>
        <BookMark />
        Tips! Bokmärk den här sidan för att lätt hitta tillbaka.
      </StyledInfoText>
      <StyledCampaignLinkContainer>
        <StyledCampaignLink>
          <a
            href={getDestLink(
              props.currentHostUrl + "40676-WB01_tomoyo_matematik"
            )}
          >
            <StyledLinkText>TOMOYO FÖR ELEVER</StyledLinkText>
          </a>
        </StyledCampaignLink>
        <StyledCampaignLink>
          <a href={getDestLink(props.campaignData.bookshelf_host)}>
            <StyledLinkText>TOMOYO FÖR LÄRARE</StyledLinkText>
          </a>
        </StyledCampaignLink>
      </StyledCampaignLinkContainer>
      <StyledInfoText>
        Rådande omständigheter gör att många elever befinner sig hemma och att
        lärare behöver bedriva undervisningen helt eller delvis på distans.
      </StyledInfoText>
      <StyledInfoText fontWeight={"bold"}>
        Därför erbjuder vi fri tillgång till Tomoyo, digital färdighetsträning i
        matematik, t.o.m. den 20 juni. Därefter ingår det som vanligt för dig
        som har Favorit matematik 1-3.
      </StyledInfoText>
      <StyledCampaignImage
        src="https://media.studentlitteratur.se/image/mr/story/images/promo/free-tomoyo-tablet.png"
        alt="kampanj-bild"
      />
      <StyledSmallText>
        Tomoyo är ett spelifierat digitalt läromedel i matematik och finns just
        nu för åk 1–3. Med motivationshöjande inslag från spelens värld ökar
        eleverna sina kunskaper inom grundläggande matematiska moment.
      </StyledSmallText>
      <StyledSubHeading>KOM IGÅNG:</StyledSubHeading>
      <StyledSmallText>
        Börja med att bokmärka den här sidan för enkel åtkomst.
      </StyledSmallText>
      <StyledSmallText>
        När du startar Tomoyo måste du logga in så vi kan spara ditt resultat
        mellan gångerna. Om du inte redan har ett konto hos Studentlitteratur
        får du möjlighet att skapa ett nytt.
      </StyledSmallText>
      <StyledSmallText>Sedan är det bara att sätta igång!</StyledSmallText>
      <StyledSubHeading>ÄR DU LÄRARE?</StyledSubHeading>
      <StyledSmallText>
        Du som lärare har möjlighet att via Min bokhylla skapa ett digitalt
        klassrum för att följa elevernas arbete i Tomoyo.
      </StyledSmallText>
      <StyledCampaignLinkContainer>
        <StyledCampaignLink>
          <a
            href={getDestLink(
              props.currentHostUrl + "/40676-WB01_tomoyo_matematik"
            )}
          >
            <StyledLinkText>TOMOYO FÖR ELEVER</StyledLinkText>
          </a>
        </StyledCampaignLink>
        <StyledCampaignLink>
          <a href={getDestLink(props.campaignData.bookshelf_host)}>
            <StyledLinkText>TOMOYO FÖR LÄRARE</StyledLinkText>
          </a>
        </StyledCampaignLink>
      </StyledCampaignLinkContainer>
      <StyledLinksContainer>
        <StyledSmallSubHeading>MER INFORMATION</StyledSmallSubHeading>
        <StyledLink href="https://www.studentlitteratur.se/tomoyo">
          Läs mer om Tomoyo
        </StyledLink>
        <StyledLink href="https://media.studentlitteratur.se/file/mr/story/images/promo/logga-in_skapa_konto_240320.pdf">
          Skapa konto och logga in
        </StyledLink>
        <StyledLink href="https://media.studentlitteratur.se/file/mr/story/images/promo/skapa_klassrum_tomoyo_240320.pdf">
          Så skapar du klassrum
        </StyledLink>
        <StyledLink href="https://www.studentlitteratur.se/#katalog/sida/1/512672">
          FAQ
        </StyledLink>
        <StyledLink href="https://www.studentlitteratur.se/#sida/support/32566">
          Kundservice
        </StyledLink>
      </StyledLinksContainer>
      <StyledSmallSubHeading>VILLKOR</StyledSmallSubHeading>
      <StyledExtraSmallText>
        Detta är ett tidsbegränsat kostnadsfritt erbjudande under vårterminen
        2020, som inte övergår i ett abonnemang om inte parterna senare
        överenskommer om det. Observera att när man startar Tomoyo första gången
        knyts läromedlet till ett enskilt användarkonto. Driftsstörningar och
        avbrott för underhåll kan förekomma.
      </StyledExtraSmallText>
    </>
  );

  return (
    <StyledCampaignWrapper>
      {renderOGTags()}
      <StyledTiledBG
        backgroundImage={
          "https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg"
        }
      >
        {props.error && renderModal()}
        <StyledStudliLogoContainer>
          <StudliLogo fill={"#FFFFFF"} />
        </StyledStudliLogoContainer>
        <StyledBowContainer>
          <StudliBow />
        </StyledBowContainer>
        <StyledCampaignContainer>
          <StyledLogoContainer>
            <TomoyoLogo />
          </StyledLogoContainer>
          {props.campaignData.enabled ? renderContent() : <FreeTomoyoEnd />}
        </StyledCampaignContainer>
      </StyledTiledBG>
    </StyledCampaignWrapper>
  );
};
