const key1 = {
  alt: "Värde 1",
  color: "white",
  value: "1",
  background: "themePrimary"
}

const key2 = {
  alt: "Värde 2",
  color: "white",
  value: "2",
  background: "themePrimary"
}
const key3 = {
  alt: "Värde 3",
  color: "white",
  value: "3",
  background: "themePrimary"
}
const key4 = {
  alt: "Värde 4",
  color: "white",
  value: "4",
  background: "themePrimary"
}
const key5 = {
  alt: "Värde 5",
  color: "white",
  value: "5",
  background: "themePrimary"
}
const key6 = {
  alt: "Värde 6",
  color: "white",
  value: "6",
  background: "themePrimary"
}
const key7 = {
  alt: "Värde 7",
  color: "white",
  value: "7",
  background: "themePrimary"
}
const key8 = {
  alt: "Värde 8",
  color: "white",
  value: "8",
  background: "themePrimary"
}
const key9 = {
  alt: "Värde 9",
  color: "white",
  value: "9",
  background: "themePrimary"
}
const key0 = {
  alt: "Värde 0",
  color: "white",
  value: "0",
  background: "themePrimary"
}

const keyUndo = {
  alt: "Radera",
  color: "themePrimary",
  value: "%&",
  background: "white"
}

const keyHorizontalLayout = {
  alt: "Horizontal layout",
  icon: "HorizontalLayout",
  color: "themePrimary",
  value: "%(",
  background: "white"
}

const keyVerticalLayout = {
  alt: "Vertikal layout",
  icon: "VerticalLayout",
  color: "themePrimary",
  value: "%)",
  background: "white"
}

export const defaultLayoutSquare =
  [
    [
      key7,
      key8,
      key9
    ],
    [
      key4,
      key5,
      key6
    ],
    [
      key1,
      key2,
      key3
    ],
    [
      keyUndo,
      key0,
      keyHorizontalLayout
    ]
  ]

export const defaultLayoutHorizontal =
  [
    [
      key1,
      key2,
      key3,
      key4,
      key5,
      key6,
      key7,
      key8,
      key9,
      key0,
      keyUndo,
      keyVerticalLayout
    ]
  ]