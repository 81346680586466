import styled from 'styled-components';

/**
 * The use of position absolute below is to fix bad flexbox handling in iOS10 and in Edge.
 * We need a div surrounding the image with height & width 100%.
 * Then sticking the image to the div with "position:absolute;left:0;top:0;right:0;bottom:0;"
 * The problem is that height height of some flexbox elements is undefined.
 */

interface StyledImageProps {
  position?: string
  finishedLoading?: boolean;
}

export const ImgWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  display: ${({ finishedLoading }: StyledImageProps) => finishedLoading ? 'block' : 'none'};
`;

export const StyledSvgWrapper = styled.div`
  height: 100%;
  flex: 1;
`;

export const StyledMultiSvg = styled.svg`
  object-fit: contain;
  max-height: 100%;
  height: 100%;
  width: auto;
  max-width: 100%;
  position: ${({ position }: StyledImageProps) => position ? position : 'absolute'};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: ${({finishedLoading}: StyledImageProps) => finishedLoading ? 'block' : 'none'};
`;
