import { takeLatest, call, put, select } from "typed-redux-saga";
import axios from "axios";
import {
  ONBOARDING_API_ACTIONS,
  ONBOARDING_GRADES_API_ACTIONS,
} from "./actions";
import { getToken } from "../../../shared/store/auth";
import { getProductId } from "../../../store/Application";
import { Grade } from "./types";
import * as qs from "query-string";
import { PROFILE_SET_THEME } from "../../profile/api/actions";
import { selectProfileFetching } from "../../profile/api/selectors";

export function* watchGetOnboardingSaga() {
  yield* takeLatest(ONBOARDING_API_ACTIONS.READ.REQUEST, getOnboardingSaga);
}

export function* watchGetOnboardingGradesSaga() {
  yield* takeLatest(
    ONBOARDING_GRADES_API_ACTIONS.READ.REQUEST,
    getOnboardingGradesSaga
  );
}

function fetchOnboarding(
  token: string,
  productId: number,
  theme: string | string[] | null
) {
  const url = theme
    ? `/api/product/${productId}/onboarding/${theme}`
    : `/api/product/${productId}/onboarding`;
  return axios({
    method: "get",
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getOnboardingSaga() {
  try {
    while (true) {
      const isProfileFetching = yield* select(selectProfileFetching);
      if (!isProfileFetching) break;
    }

    const queryParams = qs.parse(window.location.search);
    const theme = queryParams && queryParams.theme;
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchOnboarding, token, productId, theme);
    const onboarding = response.data;

    if (theme) {
      yield* put({
        type: PROFILE_SET_THEME,
        theme: theme,
      });
    }

    yield* put({
      type: ONBOARDING_API_ACTIONS.READ.SUCCESS,
      posts: onboarding.posts,
    });
  } catch (error) {
    yield* put({ type: ONBOARDING_API_ACTIONS.READ.FAILURE, error });
  }
}

const fetchGrades = (token: string) => {
  return axios({
    method: "get",
    url: `/api/grades`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

function* getOnboardingGradesSaga() {
  try {
    const token = yield* select(getToken);
    const response = yield* call(fetchGrades, token);
    const gradesData = response.data;

    const grades: Grade[] = gradesData.map(
      (data: { id: number; label: string }) => {
        return {
          value: data.id,
          name: data.label,
        };
      }
    );

    yield* put({ type: ONBOARDING_GRADES_API_ACTIONS.READ.SUCCESS, grades });
  } catch (error) {
    yield* put({ type: ONBOARDING_GRADES_API_ACTIONS.READ.FAILURE, error });
  }
}
