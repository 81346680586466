import { AxiosStatic, AxiosResponse } from "axios";
import { Middleware, MiddlewareAPI, Dispatch, Action } from "redux";
import { AUTH_TOKEN_UPDATE } from "../store/auth/actions";

let responseInterceptor: number = -1;

const createAxiosMiddleware = (axios: AxiosStatic) : Middleware => (store: MiddlewareAPI) => (next: Dispatch) => <A extends Action>(action: A) => {
  if (responseInterceptor < 0) {
    responseInterceptor = axios.interceptors.response.use((response: AxiosResponse) : AxiosResponse => {
      if (response && response.headers && response.headers.authorization) {
        // update api json web token from response header
        store.dispatch({ type: AUTH_TOKEN_UPDATE, headers: response.headers });
      }
      return response;
    });
  }
  return next(action);
}

export default createAxiosMiddleware;
