import styled from "styled-components";
import { Themes } from "../../../views/profile/api/types";
import { MIDDLE_SCHOOL, PRIMARY_SCHOOL } from "../../constants";
import { Navigator } from "../Navigator";
export interface ResSlideTextWrapProps {
  marginTop?: string;
  marginBottom?: string;
  imgUrl?: string;
}

export interface ResultSlideLayoutProps {
  theme: Themes;
}

export const StyledResSlideContainer = styled.div<ResultSlideLayoutProps>`
  display: flex;
  height: 100%;
  max-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme === PRIMARY_SCHOOL && "max-width: 410px;"}
  ${({ theme }) => theme === PRIMARY_SCHOOL && "justify-content: space-evenly;"}
  ${({ theme }) => theme === MIDDLE_SCHOOL && "text-align: center;"}
  ${({ theme }) => theme === MIDDLE_SCHOOL && "justify-content: space-between;"}
`;

export const StyledImgContainer = styled.div`
  ${(props: ResSlideTextWrapProps) => `background-image: url(${props.imgUrl});`}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  flex-grow: 1;
  width: 100%;
  max-height: 350px;
`;

export const StyledAudioContainer = styled.div`
  width: 100%;
`;

export const StyledExerciseAudioPlayer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const StyledResSlideTextWrapper = styled.div`
  ${(props: ResSlideTextWrapProps) => `margin-top: ${props.marginTop};`}
  ${(props: ResSlideTextWrapProps) => `margin-bottom: ${props.marginBottom};`}
  color: #227574;
  width: 100%;
`;

export const StyledProgressBarContainer = styled.div`
  margin-bottom: 6px;
  width: 100%;
`;

export const StyledResSlideTitle = styled.h2`
  font-size: 26px;
  letter-spacing: 0.31px;
  line-height: 33px;
`;

export const StyledResSlideText = styled.span`
  font-size: 26px;
`;

export const StyledResSlidePoints = styled.span`
  font-size: 18px;
  font-weight: 900;
`;

export const StyledTitleWrapper = styled.div`
  color: #227574;
  margin-left: 20px;
  align-self: center;
`;

export const StyledTrophyWrapper = styled.div`
  display: flex;
  margin-right: 50px;
  flex: 2;

  svg {
    align-self: center;
  }
`;

export const StyledFeedback = styled.div`
  margin: 10px;
  flex: 1;
`;

export const StyledAtticRoute = styled.div`
  width: 100%;
  margin: 10px;
  flex: 1;
  text-align: -webkit-center;
`;

export const StyledNavigatorCount = styled(Navigator)`
  background-color: rgb(34, 117, 116);
  font-weight: 600;
  color: white;
  padding: 7px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
`;

export const StyledNavigatorTimeMachine = styled(Navigator)`
  display: flex;
  background-color: rgb(34, 117, 116);
  font-weight: 600;
  color: white;
  padding: 7px;
  min-width: 75px;
  width: 75px;
  height: 75px;
  box-shadow: 0px 0px 20px 7px white;
`;

export const StyledNavigatorWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  align-items: center;
`;

export const StyledNavigatorsContainer = styled.div`
  display: flex;
`;
