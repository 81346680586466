import styled, {css} from 'styled-components';
import {getCellSize} from '../../../../../Chip/chipsFormats';

interface BoxProps {
  canAcceptItem: boolean;
  isOver: boolean;
}

interface BoxLabelProps {
  textSize: string;
}

interface ItemContainerProps {
  chipsFormat: string;
}

const getTextSize = (size: string) => {
  switch (size) {
    case 'S':
      return '21px';
    case 'L':
      return '35px';
    default:
      return '30px';
  }
}

export const StyledSortBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-height: 0;
  max-height: 100%; 
  min-width: 189px;
  width: 100%;
`;
  
const getBoxState = (canAcceptItem: boolean, isOver: boolean) => {
  if (isOver && canAcceptItem) {
    return css`
      border: 2px dashed #277575;
      background-color: #F3F7F6;
    `;
  }
  else if (canAcceptItem) {
    return css`
      border: 2px dashed #AFABAB;
      background-color: #F9F6F6;

      &:hover {
        border: 2px dashed #277575;
        background-color: #F3F7F6;
      }
    `;
  }
  else {
    return css`
      border: 2px solid #AFABAB;
      background-color: #F9F6F6;
    `;
  }
}

export const StyledSortBoxGraphic = styled.div<BoxProps>`
  border-radius: 12px;
  flex: 1;
  min-height: 172px;
  max-height: 100%; 
  overflow: auto;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  transition: background-color .2s ease, border .2s ease;
  cursor: ${props => props.canAcceptItem ? 'pointer' : 'auto'};
  ${props => getBoxState(props.canAcceptItem, props.isOver)}
`;
  
export const StyledBoxItemContainer = styled.div<ItemContainerProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => getCellSize(props.chipsFormat)});
  grid-gap: 6px;
  justify-content: center;
  min-width: 190px;
  width: 100%;
  margin: auto;
`;

export const StyledSortBoxLabel = styled.span<BoxLabelProps>`
  display: flex;
  justify-content: center;  
  margin-top: 4px;
  color: inherit;
  font-family: inherit;	
  font-size: ${props => getTextSize(props.textSize)};
  font-weight: 400;	
  letter-spacing: 0.67px;	
  line-height: 1;	
  text-align: center;
  user-select: none;
`;

export const StyledSortBoxImageContainer = styled.div`
  display: flex;
  justify-content: center;  
  margin-top: 4px;
  user-select: none;
  position: relative;
  min-height: 42px;
  max-height: 20%;
  height: 10%;

  svg {
    padding: 3px;
    max-height: 90px;
  }
`;

export const StyledSortBoxImage = styled.img`
  height: auto;
  width: 100%;
  max-height: 90px;
`;