import styled from "styled-components";
import { Navigator } from "../../../shared/components/Navigator";
import { PRIMARY_SCHOOL } from "../../../shared/constants";
import { Themes } from "../../profile/api/types";

export const StyledContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const StyledBackgroundImageWrapper = styled.div`
  display: flex;
  margin: 0 22px;
  align-self: center;
  max-height: 100%;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 22px;
  align-self: center;
`;

export const StyledBackgroundImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const StyledText = styled.span<{ theme: Themes }>`
  font-family: Muli;
  letter-spacing: 0;
  height: auto;
  color: ${({ theme }) => (theme === PRIMARY_SCHOOL ? "#277575" : "white")};
  width: ${({ theme }) => (theme === PRIMARY_SCHOOL ? "33vw" : "100%")};
  font-size: ${({ theme }) => (theme === PRIMARY_SCHOOL ? "2.5vw" : "28px")};
  line-height: ${({ theme }) => theme === PRIMARY_SCHOOL && "3vw;"};
`;

export const StyledNavigator = styled(Navigator)<{ withTheme?: Themes }>`
  background-color: rgb(34, 117, 116);
  color: white;
  display: flex;
  justify-content: center;
  align-self: end;
  margin: 20px 0;
  font-family: Muli;
  font-weight: 600;
  height: ${(props) => (props.withTheme === PRIMARY_SCHOOL ? "4vw" : "55px")};
  font-size: ${(props) =>
    props.withTheme === PRIMARY_SCHOOL ? "2vw" : "28px"};
  ${(props) => props.withTheme === PRIMARY_SCHOOL && "width: 12vw;"}
  ${(props) => props.withTheme === PRIMARY_SCHOOL && "line-height: 2vw;"}
  ${(props) => props.withTheme === PRIMARY_SCHOOL && "border-radius: 4vw;"}
`;
