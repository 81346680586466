import * as React from "react";
import {
  StyledBlanksWrapper,
  StyledText,
  StyledBlanksLine,
  StyledInputContainer,
  StyledCard,
} from "./StyledBlanks";
import { Blank } from "./Blank";
import { BLANKS, TEXT, M } from "../../../../constants";
import {
  BlankComponent,
  TextComponent,
  BlankAnswers,
  BlankAudio,
} from "./BlanksTypes";
import AutoLaTeX from "react-autolatex";
import SoundWrapper from "../../../../../../../shared/containers/SoundWrapper";
import { KeyboardData } from "../../../VirtualKeyboard/types";
import HtmlRenderer from "../../../../../../../shared/components/HtmlRenderer";

export interface BlanksProps {
  children?: JSX.Element;
  state: string;
  text: string;
  displayCorrectAnswer: boolean;
  correctAnswers: BlankAnswers;
  settings: any;
  audio: BlankAudio;
  currentAttempt: number;
  keyboardData?: string;
  focusInput: boolean;

  callback(answer: string): void;
}

export interface BlanksState {
  answer: string | number;
  components: Array<TextComponent | BlankComponent>;
  lines: Array<Array<TextComponent | BlankComponent>>;
}
interface CompProps {
  className: string;
  textSize: string;
}
export class XcBlanks extends React.PureComponent<BlanksProps, BlanksState> {
  public textSize: string = this.props.settings.fontSize || M;
  public FRONT_DELIMITER = "<<<<<";
  public BACK_DELIMITER = ">>>>>";
  public parsedKeyboard: KeyboardData | undefined;

  public state: Readonly<BlanksState> = {
    answer: "",
    lines: [],
    components: [],
  };

  public componentDidMount() {
    this.parsedKeyboard = this.props.keyboardData
      ? JSON.parse(this.props.keyboardData)
      : undefined;
    this.setState({
      components: this.getComponents(),
    });
  }
  public render() {
    const lines = this.prepLines();
    return (
      <StyledBlanksWrapper>
        {lines ? this.renderLines(lines) : null}
      </StyledBlanksWrapper>
    );
  }

  /**
   * Updates state and call upon parent to declare correct state.
   *
   * @param {boolean} isCorrect
   * @param {string} answer
   * @param {string} id
   */
  public correct = (id: string, answer: string): void => {
    const components = [...this.state.components];
    components[this.getInputIndex(id)] = {
      ...this.state.components[this.getInputIndex(id)],
      answer,
    };

    this.setState({ components }, this.updateParent);
  };

  /**
   * Prepares the components for render by splitting given string and rendering components based on strings types
   *
   * @returns {Array<TextComponent|BlankComponent>}
   */
  private prepLines = (): Array<Array<TextComponent | BlankComponent>> => {
    // Strip &nbsp; and replace with regular space. Also strip alternative br-tags to normal <br> tag.
    const strippedText = this.props.text
      .split("&nbsp;")
      .join(" ")
      .split("<br />")
      .join("<br>")
      .split("<br/>")
      .join("<br>")
      .trim();
    // Replace all <p> with space and </p> with <br> to normalize input rows
    let replacedText = strippedText
      .split("<p>")
      .join(" ")
      .split("</p>")
      .join("<br>");
    // Remove trailing br-tag
    if (replacedText.endsWith("<br>")) {
      replacedText = replacedText.substring(0, replacedText.length - 4);
    }
    // Split text in rows
    const textFragments = replacedText.split("<br>");

    return textFragments.map((text) => {
      text = text.trim();
      text = text.replace(/[[]/g, "#[").replace(/[\]]/g, "]#"); // Add # before [ and after ] to split later
      text = this.replaceDelimiters(text);

      let strings: any = text.split(this.FRONT_DELIMITER);
      strings = strings.map((stringValue: string) => {
        if (stringValue.includes("#")) {
          return stringValue.split("#");
        } else {
          return stringValue;
        }
      });
      // Remove empty and flatten back to one dimensional array
      strings = strings
        .reduce((acc: string[], val: string) => acc.concat(val), [])
        .filter(
          (v: string) => v !== ""
        ); /* Array.flat() does not work in Edge*/

      let components: Array<TextComponent | BlankComponent> = [];
      strings.forEach((cutString: string, index: number) => {
        const id = this.extractIdFromString(cutString);
        const rowComponents = [
          {
            type: TEXT,
            component: this.renderTextComponent(cutString.trim(), index),
          },
        ];
        components = id
          ? [
              ...components,
              {
                type: BLANKS,
                id,
                component: this.renderFillInBlank(id),
              } as BlankComponent,
              ...rowComponents,
            ]
          : [...components, ...rowComponents];
      });
      return components;
    });
  };

  /**
   * Renders a text or a single value on a card
   * @param {string} cutString
   * @param {number} index
   * @returns {JSX.Element | undefined}
   */
  private renderTextComponent = (
    cutString: string,
    index: number
  ): JSX.Element | undefined => {
    if (cutString.indexOf("]") !== -1) {
      return this.renderValueCard(cutString, index);
    } else if (cutString.indexOf("$") !== -1) {
      return this.renderLatex(this.removeDelimiter(cutString), index);
    } else {
      return this.renderText(cutString, index);
    }
  };

  private removeDelimiter = (cutString: string) =>
    cutString.indexOf(this.BACK_DELIMITER) >= 0
      ? cutString.replace(
          cutString.substring(
            0,
            cutString.indexOf(this.BACK_DELIMITER) + this.BACK_DELIMITER.length
          ),
          ""
        )
      : cutString;

  /**
   * Renders LaTeX
   * @param {string} value
   * @returns {JSX.Element}
   */
  private renderLatex = (value: string, index: number): JSX.Element => (
    <StyledText textSize={this.textSize} key={`latex_key_${index}`}>
      <AutoLaTeX>{value}</AutoLaTeX>
    </StyledText>
  );

  /**
   * Renders a card with a single value
   * @param {string} value
   * @param {number} index
   * @returns {JSX.Element}
   */
  private renderValueCard = (value: string, index: number): JSX.Element => {
    const valueText = value.substring(1, value.indexOf("]"));

    return (
      <StyledInputContainer
        textSize={this.textSize}
        key={`blanks_input_container_${index}`}
      >
        <HtmlRenderer
          key={`blanks_text_${index}`}
          component={StyledCard}
          html={valueText}
        />
      </StyledInputContainer>
    );
  };

  /**
   * Replaces delimiters to avoid crashing with LaTeX characters
   * @param {string} text
   * @returns {string}
   */
  private replaceDelimiters = (text: string): string => {
    const modifiedString: string[] = text.split("$").map((s, i) => {
      return i % 2
        ? s
        : s
            .split("{")
            .join(this.FRONT_DELIMITER)
            .split("}")
            .join(this.BACK_DELIMITER);
    });
    return modifiedString.join("$");
  };

  private getComponents = (): BlankComponent[] => {
    const text = this.replaceDelimiters(this.props.text);
    const strings: string[] = text.split(this.FRONT_DELIMITER);
    const blanksFragments = strings.filter((s) => {
      const id = this.extractIdFromString(s);
      return !!id;
    });
    return blanksFragments.map((f) => {
      const id = this.extractIdFromString(f);
      return {
        type: BLANKS,
        answer: undefined,
        id,
        component: this.renderFillInBlank(id),
      };
    });
  };

  /**
   * Runs parents callback to update the parents exercise state
   */
  private updateParent = (): void => {
    const data: Array<string | undefined> = this.getInputs().map(
      ({ answer }: BlankComponent) => {
        return answer;
      }
    );
    if (!data) {
      return;
    }
    const answers = { inputs: data.filter((val) => val) };
    this.props.callback(JSON.stringify(answers));
  };

  /**
   * Renders a text-components from a string
   *
   * @param {JSON.stringify(answers)string} cutString
   * @param {number} index
   * @returns {JSX.Element}
   */
  private renderText = (
    cutString: string,
    index: number
  ): JSX.Element | undefined => {
    const textWithNoDelimiter = this.removeDelimiter(cutString);
    const styledText = textWithNoDelimiter;
    const textElem = { __html: styledText };
    const styledElem = (
      <HtmlRenderer<CompProps>
        key={`blanks_text_${index}`}
        component={StyledText}
        html={styledText}
        className="soundContainer"
        textSize={this.textSize}
      />
    );

    return textElem.__html ? this.hasAudio(styledElem, index) : undefined;
  };

  /**
   * Wrap element with sound wrapper if audio exists
   *
   * @param {JSX.Element} element
   * @param {number} index
   * @returns {JSX.Element}
   */
  private hasAudio = (element: JSX.Element, index: number): JSX.Element => {
    if (this.props.audio && this.props.audio.src !== "") {
      return (
        <SoundWrapper
          Component={element}
          url={this.props.audio.src}
          key={`blanks_sound_text_${index}`}
        />
      );
    }
    return element;
  };

  /**
   * Returns a renderable FillInBlank
   *
   * @param {number} id
   * @returns {JSX.Element}
   */
  private renderFillInBlank = (id: string): JSX.Element => (
    <Blank
      id={id}
      callback={this.correct}
      key={`blanks_${id}`}
      correctAnswer={this.getCorrectAnswer(id)}
      displayCorrectAnswer={this.props.displayCorrectAnswer}
      inputType={this.props.settings.inputType}
      state={this.props.state}
      textSize={this.textSize}
      currentAttempt={this.props.currentAttempt}
      keyboardData={this.parsedKeyboard}
      focusInput={this.props.focusInput && id === "0" ? true : false}
      aria-label="Skriv-i-lucka"
      punctuationMarksSensitive={this.props.settings.punctuationMarksSensitive}
    />
  );

  /**
   * Gets the correct answer
   *
   * If the 'settings.inputType === number' we remove all spaces
   *
   * @param {string} id
   */
  private getCorrectAnswer = (id: string): string => {
    if (
      this.props.correctAnswers &&
      this.props.correctAnswers.inputs &&
      this.props.correctAnswers.inputs[id] &&
      this.props.correctAnswers.inputs[id].correctAnswers
    ) {
      return this.props.settings.inputType === "number"
        ? this.props.correctAnswers.inputs[id].correctAnswers[0].replace(
            /\s/g,
            ""
          )
        : this.props.correctAnswers.inputs[id].correctAnswers[0];
    }
    return "";
  };

  /**
   * Extract an id from a string
   *
   * @param {string} cutString
   * @returns {string}
   */
  private extractIdFromString = (cutString: string): string =>
    (cutString = cutString.substring(
      0,
      cutString.indexOf(this.BACK_DELIMITER)
    ));

  /**
   * Fetches index of component in state.components based on its id
   *
   * @param {string} id
   */
  private getInputIndex = (id: string): number =>
    this.state.components.map((input: BlankComponent) => input.id).indexOf(id);

  /**
   * Renders input lines
   * @param {Array<Array<TextComponent | BlankComponent>>} lines
   * @returns {JSX.Element[]}
   */
  private renderLines = (
    lines: Array<Array<TextComponent | BlankComponent>>
  ): JSX.Element[] => {
    return lines.map(
      (line: Array<TextComponent | BlankComponent>, index: number) => {
        return this.renderComponents(line, index);
      }
    );
  };

  /**
   * Returns a renderable array
   *
   * @param {Array<TextComponent|BlankComponent>} arr
   */
  private renderComponents = (
    arr: Array<TextComponent | BlankComponent>,
    key: number
  ): JSX.Element => (
    <StyledBlanksLine key={`line_${key}`}>
      {arr.map((item: TextComponent | BlankComponent) => item.component)}
    </StyledBlanksLine>
  );

  /**
   * Filters out all but input-components from state.components
   */
  private getInputs = () =>
    this.state.components.filter(
      (component: TextComponent | BlankComponent) => component.type === BLANKS
    );
}
