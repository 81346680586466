import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNextRoute } from "../../shared/store/router/selectors";
import { selectCurrentItemFeedback } from "../adventurePicker/api/selectors";
import { push } from "../../shared/store/router/actions";
import { SET_ITEM_FEEDBACK_INDEX } from "../../shared/constants";
import { StoryState, Post } from "../adventurePicker/api/types";

function useFeedback(): [
  (Post & StoryState) | null,
  boolean,
  boolean,
  string | undefined,
  (i: number) => void | null,
  () => void
] {
  const nextRoute = useSelector(selectNextRoute);
  const dispatch = useDispatch();
  const feedback = useSelector(selectCurrentItemFeedback);
  const [{ isLastSlide, lastSlideVisited }, setState] = useState({
    isLastSlide: false,
    lastSlideVisited: false,
  });

  const postLength = (feedback || { posts: [] }).posts.length;

  const onSlideChange = useCallback(
    (slideIndex: number) =>
      setState({
        isLastSlide: slideIndex === postLength - 1,
        lastSlideVisited:
          slideIndex === postLength - 1 && !lastSlideVisited
            ? true
            : lastSlideVisited,
      }),
    [postLength, lastSlideVisited]
  );

  const route = nextRoute?.route;

  const goto = useCallback(() => {
    if (route) {
      dispatch(push(route));
    }

    dispatch({ type: SET_ITEM_FEEDBACK_INDEX, index: null });
  }, [route, dispatch]);

  return [
    feedback,
    isLastSlide,
    lastSlideVisited,
    nextRoute?.text,
    onSlideChange,
    goto,
  ];
}

export default useFeedback;
