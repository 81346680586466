import * as React from "react";
import { useDrop } from "react-dnd";
import { StyledUnsortedBox } from "./StyledUnstortedBox";

interface Props {
  active: boolean;
  id: string;
  chipsFormat: string;
  canAcceptItem: boolean;
  callback: (param: string) => void;
  onDropCallback: (param: string, param2: string) => void;
}

export const UnsortedBox: React.FC<Props> = (props) => {
  const handleClick = () => props.canAcceptItem && props.callback(props.id);

  const onDrop = () => {
    props.canAcceptItem && props.onDropCallback(props.id, tempItem.id);
  };

  const [{ item, isOver }, drop] = useDrop({
    accept: "sort-item",
    drop: () => onDrop(),
    collect: (monitor) => ({
      item: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: props.canAcceptItem,
    }),
  });
  const tempItem: any = item;

  return (
    <StyledUnsortedBox
      ref={drop}
      active={props.active}
      onClick={handleClick}
      chipsFormat={props.chipsFormat}
      isOver={isOver}
    >
      {props.children}
    </StyledUnsortedBox>
  );
};
