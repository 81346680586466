import axios from "axios";

/**
 * API request to get all markers
 * @param token jwt token
 * @param productId product id
 */
export function fetchMarkers(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/rewards/earned`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
