import * as React from "react";
import { connect } from "react-redux";
import { RouteService } from "../../../services/route";
import { TypedMap } from "../../../store/utils/typed-map";
import { State } from "../../store/router/reducer";
import ReactGA from "react-ga";
import { ApplicationState } from "../../../store/Application/types";
import Helmet from "react-helmet";
import { translateMap } from "./TranslatePageTitles";

export interface RouterState {
  location: TypedMap<State>;
  application: ApplicationState;
}

const mapStateToProps = ({ location, application }: RouterState) => {
  const currentLocation = location.get("current", "") as string;

  if (!currentLocation) {
    return {};
  }
  const currentRoute = RouteService.get(currentLocation);
  if (!currentRoute) {
    return {};
  }
  return {
    view: currentRoute.view,
    trackerUrl: currentRoute.trackerUrl,
    sesamName: application.product.sesam_name,
  };
};

export interface RouterProps {
  view: React.ComponentClass<any> | React.FC<any> | undefined;
  trackerUrl?: string;
  sesamName?: string;
}

const RouterComponent: React.FC<RouterProps> = ({
  view: View,
  trackerUrl,
  sesamName,
}) => {
  if (trackerUrl && sesamName) {
    ReactGA.pageview(`/${sesamName}/${trackerUrl}`);
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{trackerUrl && translateMap.get(trackerUrl)}</title>
      </Helmet>
      {View ? <View trackerUrl={trackerUrl} /> : null}
    </React.Fragment>
  );
};

export const Router = connect(mapStateToProps)(RouterComponent as any);
