import * as React from "react";
import {
  StyledInfoCard,
  StyledTopBar,
  StyledCloseButton,
  StyledTextContainer,
  StyledModalContent,
  StyledPrimaryCloseButton,
} from "../../Splash/StyledSplash";
import { PrimaryButton } from "../../Button";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/icon_close.svg";
import {
  StyledSubHeading,
  StyledCampaignLinkContainer,
  StyledCampaignLink,
  StyledLinkText,
} from "../FreeTomoyo/StyledFreeTomoyo";

interface Props {
  heading: string;
  firstInfo?: object;
  secondInfo?: object;
  thirdInfo?: object;
  renderLinks?: boolean;
  renderCloseButton?: boolean;
  bookShelf?: string;
  callback: () => void;
}

const renderCloseButton = (render: boolean | undefined, callback: () => void) =>
  render ? (
    <StyledPrimaryCloseButton>
      <PrimaryButton
        onClick={callback}
        label={"Stäng"}
        overrideStyles={{ padding: "12px 50px" }}
      />
    </StyledPrimaryCloseButton>
  ) : null;

const renderLinks = (
  renderNavLinks: boolean | undefined,
  bookShelf: string | undefined
) =>
  renderNavLinks ? (
    <StyledCampaignLinkContainer
      justifyContent={"space-evenly"}
      resizeWidth={true}
    >
      <StyledCampaignLink>
        <a
          href={
            "https://www.studentlitteratur.se/om-oss/kontakta-oss/kundservice/"
          }
        >
          <StyledLinkText>KUNDSERVICE</StyledLinkText>
        </a>
      </StyledCampaignLink>
      <StyledCampaignLink>
        <a href={bookShelf}>
          <StyledLinkText>MIN BOKHYLLA</StyledLinkText>
        </a>
      </StyledCampaignLink>
    </StyledCampaignLinkContainer>
  ) : null;

export const CampaignError: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <StyledInfoCard>
        <StyledTopBar>
          <StyledCloseButton onClick={props.callback}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledTopBar>
        <StyledModalContent>
          <StyledTextContainer>
            <StyledSubHeading>{props.heading}</StyledSubHeading>
            {props.firstInfo}
            {props.secondInfo}
            {props.thirdInfo}
          </StyledTextContainer>
          {renderLinks(props.renderLinks, props.bookShelf)}
          {renderCloseButton(props.renderCloseButton, props.callback)}
        </StyledModalContent>
      </StyledInfoCard>
    </React.Fragment>
  );
};
