import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentRoute } from "../../store/router/selectors";
import { removeSnackbarMessage, removeSnackbarMessages } from "./api/actions";
import { selectSnackbarMessages } from "./api/selectors";
import { SnackBarMessage } from "./api/types";

const MESSAGE_TIMINGS: { [key: string]: number } = {
  success: 30000,
  warning: 30000,
  error: 30000,
};

interface RenderMessage extends SnackBarMessage {
  renderIndex: number;
}

type ReturnProps = {
  messagesToShow: RenderMessage[];
  removeMessage: (index: number) => void;
};

function useSnackbar(): ReturnProps {
  const messages = useSelector(selectSnackbarMessages);
  const currentRoute = useSelector(selectCurrentRoute);
  const [messagesToShow, setMessagesToShow] = useState<RenderMessage[]>([]);
  const dispatch = useDispatch();

  const removeMessage = (index: number) => {
    dispatch(removeSnackbarMessage(index));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const filterExpiredMessages = messages
        .map((m, index) => ({
          ...m,
          renderIndex: index,
        }))
        .filter((m) => {
          const messageExpired = m.addedTime + MESSAGE_TIMINGS[m.severity];
          return m.addedTime < now && messageExpired > now;
        });

      setMessagesToShow(filterExpiredMessages.reverse());
    }, 250);
    return () => clearInterval(interval);
  }, [messages]);

  useEffect(() => {
    const removeMessages = messages
      .map((m, index) => ({
        ...m,
        renderIndex: index,
      }))
      .filter((m) => {
        if (m.routes.length) {
          return !m.routes.some((route) => route === currentRoute);
        }
        return false;
      })
      .map((m) => m.renderIndex);

    if (removeMessages.length > 0 && messages.length > 0) {
      dispatch(removeSnackbarMessages(removeMessages));
    }
  }, [currentRoute, dispatch, messages]);
  return { messagesToShow, removeMessage };
}

export default useSnackbar;
