import React from "react";
import { ReactComponent as Close } from "../../../assets/icons/icon_close.svg";
import { Adventure } from "../../../views/adventurePicker/api/types";
import {
  ADVENTURE_STORY_ROUTE,
  ASSIGNMENT_ROUTE,
  ATTIC_ROUTE,
  DASHBOARD_ROUTE,
  EXERCISE_ROUTE,
  RECAP_ROUTE,
} from "../../../views/routes";
import {
  StyledAdventureInfoModalWrapper,
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledNavigator,
  StyledNavigatorsContainer,
  StyledAdventureImage,
  StyledImageAndTextWrapper,
} from "./StyledAdventureModal";
import { PRIMARY_SCHOOL } from "../../../shared/constants";
import { AdventureInfoText } from "./AdventureInfoText";
import { Modal } from "../../../shared/components/Modal";

type AdventureInfoModalProps = {
  adventureGroup?: Adventure;
  activeAdventure?: Adventure;
  theme?: string;
  show: boolean;
  currentRoute: string;
  nextRoute: string;
  onAdventureInfoModalClose: (value: boolean) => void;
};

const AdventureInfoModal = ({
  adventureGroup,
  activeAdventure,
  theme,
  show,
  currentRoute,
  nextRoute,
  onAdventureInfoModalClose,
}: AdventureInfoModalProps) => {
  const onHandleClick = () => {
    onAdventureInfoModalClose(false);
  };
  const handleClickOutside = () => onAdventureInfoModalClose(false);
  const active = activeAdventure && adventureGroup;
  return (
    <Modal show={show} backdropClick={handleClickOutside}>
      <StyledAdventureInfoModalWrapper
        expandModal={currentRoute === ASSIGNMENT_ROUTE}
        showBackground={
          currentRoute !== DASHBOARD_ROUTE && currentRoute !== ASSIGNMENT_ROUTE
        }
      >
        <StyledCloseButtonContainer>
          <StyledCloseButton aria-label="Stäng" onClick={onHandleClick}>
            <Close />
          </StyledCloseButton>
        </StyledCloseButtonContainer>
        <StyledImageAndTextWrapper>
          {activeAdventure && activeAdventure.image_url && (
            <StyledAdventureImage
              src={activeAdventure.image_url}
              alt={activeAdventure.title}
            />
          )}
          {active ? (
            <AdventureInfoText
              nextRoute={nextRoute}
              active
              isImage={!active.image_url}
              adventureGroupTitle={active.title}
              adventureTitle={activeAdventure && activeAdventure.title}
            />
          ) : (
            <AdventureInfoText isImage nextRoute={nextRoute} />
          )}
        </StyledImageAndTextWrapper>
        <StyledNavigatorsContainer>
          {theme === PRIMARY_SCHOOL &&
            active &&
            nextRoute !== ADVENTURE_STORY_ROUTE && (
              <StyledNavigator
                overrideStyles={{ minWidth: "185px" }}
                route={RECAP_ROUTE}
              >
                Om berättelsen
              </StyledNavigator>
            )}
          {active && nextRoute !== ADVENTURE_STORY_ROUTE && (
            <StyledNavigator route={EXERCISE_ROUTE}>Räkna</StyledNavigator>
          )}
          {!active && currentRoute !== ATTIC_ROUTE && (
            <StyledNavigator route={ATTIC_ROUTE}>Vinden</StyledNavigator>
          )}
        </StyledNavigatorsContainer>
      </StyledAdventureInfoModalWrapper>
    </Modal>
  );
};
export default AdventureInfoModal;
