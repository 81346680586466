import { Map } from "immutable";

export interface Route {
  view: React.ComponentClass<any> | React.StatelessComponent<any>;
  defaultRoute?: boolean;
  trackerUrl?: string;
}

let routeMap = Map<string, Route>();

/**
 * Helper for seamlessly add a route to the application
 *
 * @export
 * @class RouteService
 */
export class RouteService {
  public static set(key: string, route: Route): void {
    if (routeMap.has(key)) {
      throw new Error(`Route '${key}' already exists`);
    }

    const defaultRoute = RouteService.getDefaultRouteKey();
    if (route.defaultRoute && defaultRoute) {
      throw new Error(
        `Cannot add a new default route. Route '${defaultRoute}' is already marked as default route`
      );
    }

    routeMap = routeMap.set(key, route);
  }

  public static get(key: string): Route | undefined {
    if (!routeMap.has(key)) {
      throw new Error(`Route '${key}' does not exists`);
    }

    return routeMap.get(key);
  }

  public static fold(): { [key: string]: Route } {
    return routeMap.reduce((routes, route?: Route, key?: string) => {
      return Object.assign({}, routes, {
        [key || "unknown-route-key"]: route,
      });
    }, {});
  }

  public static keys(): string[] {
    return routeMap.reduce((keys: string[] = [], _, key?: string) => {
      keys.push(key || "unknown-route-key");
      return keys;
    }, [] as string[]);
  }

  public static getDefaultRouteKey(): string | undefined {
    return routeMap.findKey((route?: Route) => {
      if (!route) {
        return false;
      }
      return route.defaultRoute as boolean;
    });
  }
}
