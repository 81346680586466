import {
  WS_SOCKET_OPENED,
  WS_SOCKET_ERROR,
  WS_SOCKET_CLOSED
} from "../../constants";

/**
 * Action for websocket opened
 */
export const socketOpened = () => ({
  type: WS_SOCKET_OPENED
});

/**
 * Action for websocket error
 */
export const socketError = () => ({
  type: WS_SOCKET_ERROR
});

/**
 * Action for websocket closed
 */
export const socketClosed = () => ({
  type: WS_SOCKET_CLOSED
});

export const recievedSocketMessage = (message: {
  type: string;
  payload: any;
}) => ({
  type: message.type,
  payload: message.payload
});
