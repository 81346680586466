import styled from "styled-components";

export const ExerciseViewContainer = styled.div`
  background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    ),
    url(https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg);
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) /* FIX IE 10 & 11 */ {
    *::-ms-backdrop {
      background: url(https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg)
        repeat-x repeat-y;
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    );
  }
`;

export const ExerciseSliderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
`;

export const StyledProgressContainer = styled.div`
  flex: 1;
  max-width: 200px;
`;

export const StyledFooterContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 12px;
  position: relative;
  pointer-events: none;
`;

export const StyledExeContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 75px 25px 75px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
