import { Navigator } from "../../shared/components/Navigator";
import { ATTIC_ROUTE } from "../routes";
import { RenderLabelText } from "../../shared/components/Button/ButtonLabelText";
import IntroductionView from "../../shared/components/IntroductionView";
import { NavBar } from "../../shared/components/NavBar";
import {
  StyledLoaderCenterContainer,
  StyledLoader,
} from "../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../external/EddaXcomp/src/constants";
import {
  StyledLoadingContainer,
  StyledParagraph,
} from "../dashboard/StyledDashboard";
import { useSelector } from "react-redux";
import { selectAdventureCharacters } from "./selectors";

const buttonStyles = {
  width: "237px",
  height: "50px",
  boxShadow: "none",
  animationDelay: "4s",
};

export const AdventureCharactersView = () => {
  const characters = useSelector(selectAdventureCharacters);

  return (
    <>
      <NavBar position="fixed" />
      {characters && (
        <IntroductionView slides={characters}>
          <Navigator
            route={ATTIC_ROUTE}
            skin="primary"
            animation="pulse"
            {...buttonStyles}
          >
            <RenderLabelText label="Vinden" />
          </Navigator>
        </IntroductionView>
      )}
      {!characters && (
        <StyledLoadingContainer>
          <StyledParagraph>Laddar innehåll!</StyledParagraph>
          <StyledLoaderCenterContainer>
            <StyledLoader size={LARGE} />
          </StyledLoaderCenterContainer>
        </StyledLoadingContainer>
      )}
    </>
  );
};
