import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetProgress } from "../../store/progress/actions";
import {
  ADVENTURE_GROUPS_API_ACTIONS,
  getAllAdventures,
  setActiveAdventure,
  completeProblem,
  resetProblem,
} from "../adventurePicker/api/actions";
import {
  selectActiveAdventure,
  selectAllAdventures,
  selectCurrentProblem,
} from "../adventurePicker/api/selectors";
import { Adventure } from "../adventurePicker/api/types";
import {
  selectMarkerHeadGroups,
  selectSelectedMarkerHeadGroup,
} from "../test/api/selectors";
import {
  fetchProblemIndependentHeadMarkerGroups,
  setMarkerHeadGroupSelected,
  generateMarkersForSelectedGroup,
  deleteMarkers,
} from "./api/actions";
import { ProblemIndependentMarkerHeadGroup } from "./api/types";

type ReturnProps = {
  activeAdventure: Adventure | undefined;
  allAdventures: Adventure[];
  activeProblem: Adventure | undefined;
  selectedGroup: ProblemIndependentMarkerHeadGroup | undefined;
  onToggleActiveAdventure: (adventure: Adventure) => void;
  completeActiveProblem: (
    adventure: Adventure,
    activeProblemId: number
  ) => void;
  resetActiveProblem: (adventure: Adventure, activeProblemId: number) => void;
  markers: ProblemIndependentMarkerHeadGroup[];
  resetGameProgress: () => void;
  onSelectMarkerGroup: (id: string) => void;
  onAmountOfMarkersChange: (value: string) => void;
  generateMarkers: () => void;
  resetErrorMessage: () => void;
  deleteAllMarkers: () => void;
  amountOfMarkers: number;
  errorMessage: string;
};

function useNewTestDashboard(): ReturnProps {
  const activeAdventure = useSelector(selectActiveAdventure);
  const allAdventures = useSelector(selectAllAdventures);
  const activeProblem = useSelector(selectCurrentProblem);
  const markers = useSelector(selectMarkerHeadGroups);
  const selectedGroup = useSelector(selectSelectedMarkerHeadGroup);
  const [amountOfMarkers, setAmountOfMarkers] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAdventures());
    dispatch(fetchProblemIndependentHeadMarkerGroups());
    dispatch({ type: ADVENTURE_GROUPS_API_ACTIONS.READ.REQUEST });
  }, [dispatch]);

  function resetGameProgress() {
    dispatch(resetProgress());
  }

  function completeActiveProblem(
    adventure: Adventure,
    activeProblemId: number
  ) {
    dispatch(
      completeProblem(adventure.parent_id, adventure.id, activeProblemId)
    );
  }

  function resetActiveProblem(adventure: Adventure, activeProblemId: number) {
    dispatch(resetProblem(adventure.parent_id, adventure.id, activeProblemId));
  }

  function onToggleActiveAdventure(adventure: Adventure) {
    dispatch(setActiveAdventure(adventure.id, adventure.parent_id));
  }

  function onSelectMarkerGroup(id: string) {
    dispatch(setMarkerHeadGroupSelected(parseInt(id)));
  }

  function onAmountOfMarkersChange(value: string) {
    let numberOfMarkers = parseInt(value);
    value && !isNaN(numberOfMarkers) && setAmountOfMarkers(numberOfMarkers);
  }

  function generateMarkers() {
    if (selectedGroup && amountOfMarkers <= selectedGroup.available_markers) {
      dispatch(
        generateMarkersForSelectedGroup(selectedGroup.id, amountOfMarkers)
      );
    }
    selectedGroup &&
      amountOfMarkers > selectedGroup.available_markers &&
      setErrorMessage(
        `Endast ${selectedGroup.available_markers} märken är tillgängliga för denna gruppen!`
      );
    setAmountOfMarkers(0);
  }

  function resetErrorMessage() {
    setErrorMessage("");
  }
  function deleteAllMarkers() {
    dispatch(deleteMarkers());
  }

  return {
    activeAdventure,
    allAdventures,
    activeProblem,
    onToggleActiveAdventure,
    completeActiveProblem,
    resetActiveProblem,
    markers,
    selectedGroup,
    resetGameProgress,
    onSelectMarkerGroup,
    onAmountOfMarkersChange,
    generateMarkers,
    resetErrorMessage,
    deleteAllMarkers,
    amountOfMarkers,
    errorMessage,
  };
}

export default useNewTestDashboard;
