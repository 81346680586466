import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  changeExerciseViewSlide,
  fetchInitialData,
  fetchExercisePreview,
  updateViewSlideState,
  startExercise,
  correctAnswer,
  setIsHelpShowing,
} from "./api/actions";
import { toggleAudioOverlay as toggleAudioOverlayAction } from "../../shared/containers/SoundWrapper/store/actions";
import * as React from "react";
import { NavBar } from "../../shared/components/NavBar";
import * as qs from "query-string";
import { StudliLogo } from "../../shared/components/StudliLogo";
import {
  Exercise,
  Intermediate,
  SlideIndexData,
  SlideIndexData1,
  Result,
  ViewSlideStates,
  ViewSlideState,
  RawExercise,
  Decks,
} from "../exercise/api/types";
import { StyledProps } from "../../styles/theme";
import { ExerciseSlider } from "../exercise/components/exersiceSlider/ExerciseSlider";
import { IntermediateSlider } from "../../shared/components/IntermediateSlider/IntermediateSlider";
import Deck from "../../shared/components/Deck";
import ExerciseRenderer from "../../external/EddaXcomp/src";
import {
  ExerciseSliderWrapper,
  ExerciseViewContainer,
  StyledFooterContainer,
  StyledProgressContainer,
} from "./StyledExercise";
import {
  ENTERING,
  EXERCISE,
  IN_VIEW,
  INTERMEDIATE,
  EMPTY,
  ITEM,
  LEAVING,
  LOADED,
  OUT_OF_VIEW,
  RESULT,
  MARKERS,
  PRIMARY_SCHOOL,
} from "../../shared/constants";
import StatusDots from "../../shared/components/StatusDots/StatusDots";
import { ProgressBar } from "../../shared/components/ProgressBar/ProgressBar";
import ResultSlide from "../../shared/components/ResultSlide/ResultSlide";
import {
  Meta,
  Answer,
  ExerciseState,
} from "../../external/EddaXcomp/src/components/ExerciseRenderer/ExerciseRendererTypes";
import { Help } from "../../shared/components/Help";
import {
  StyledLoader,
  StyledLoaderCenterContainer,
} from "../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../external/EddaXcomp/src/constants";
import { Button } from "../../shared/components/Button";
import {
  StyledCardOptionsWrapper,
  StyledTipContainer,
} from "../../shared/components/Card/StyledCard";
import { Tooltip } from "../../shared/components/Tooltip";
import XcAudioSL from "../../external/EddaXcomp/src/components/BlockLoader/ExerciseComponents/XcAudio/XcAudioStateless";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon_close.svg";
import { ReactComponent as CrystalIcon } from "../../assets/icons/icon_help.svg";
import { DndProvider } from "react-dnd";
import { HTML5toTouch } from "../../external/EddaXcomp/src/components/Chip/dragAndDrop";
import MarkerItemSlide from "../../shared/components/MarkerItemSlide";
import { selectNextRoute } from "../../shared/store/router/selectors";
import { selectTheme } from "../profile/api/selectors";
import { Themes } from "../profile/api/types";
import { Markers } from "../markers/api/types";
import { removeSnackbarMessagesWithRoute } from "../../shared/components/Snackbar/api/actions";
import { MultiBackend } from "react-dnd-multi-backend";

interface Props extends StyledProps {
  exercises: Array<Exercise | Intermediate | Result | Markers>;
  state: string;
  activeViewSlide: number;
  decks: Decks;
  slideIndexTypes: Array<SlideIndexData1 | SlideIndexData>;
  progressBarValue: number;
  animationStates: any;
  disabled: boolean;
  submitFailure: boolean;
  currentChallengeStoryRead?: boolean;
  isHelpShowing: boolean;
  fetching: boolean;
  audioOverlayActive: boolean;
  theme?: Themes;
  nextRoute?: { route: string; text: string };

  correctAnswer(answer: Answer, exerciseRes: ExerciseState, meta: Meta): void;
  logInteractiveMetric(interactiveType: string): void;
  requestExercise(preview?: string): void;
  nextViewSlide(): void;
  updateViewSlideState(index: number, state: ViewSlideState): void;
  exerciseStarted(): void;
  toggleAudioOverlay(): void;
  setHelpIsShowing(isHelpShowing: boolean): void;
  goto: (route: string) => () => void;
  removeMessages: (route: string) => void;
}

export const viewSlideStates: ViewSlideStates = {
  LOADED,
  ENTERING,
  IN_VIEW,
  LEAVING,
  OUT_OF_VIEW,
};
class ExerciseViewComponent extends React.PureComponent<Props> {
  private sliderSettings: object = {
    afterChange: (index: number): void => {
      this.afterSlideChange(index);
    },
    accessibility: false,
  };

  public state = {
    audioTooltipShown: false,
  };

  public componentDidMount() {
    const queryParams = qs.parse(window.location.search);
    const preview = queryParams && queryParams.preview;
    this.props.requestExercise(preview as string);
  }

  public componentDidUpdate() {
    if (!this.props.audioOverlayActive) {
      this.setState({
        audioTooltipShown: false,
      });
    }
  }

  /** Function that runs after the view slides it's content */
  public afterSlideChange = (index: number): void => {
    const { slideIndexTypes, activeViewSlide } = this.props;

    this.props.updateViewSlideState(index, viewSlideStates.IN_VIEW);

    if (slideIndexTypes[activeViewSlide].type === EXERCISE) {
      this.props.exerciseStarted();
    }
  };

  public render() {
    const { theme } = this.props;

    return (
      <ExerciseViewContainer theme={theme}>
        <NavBar
          position="absolute"
          centerPiece={
            this.props.slideIndexTypes.length ? this.renderCenterPiece() : null
          }
        />

        {this.getShowLoader()
          ? this.renderLoader()
          : this.renderExerciseSliderWrapper()}

        <StyledFooterContainer>
          <StudliLogo />
        </StyledFooterContainer>
      </ExerciseViewContainer>
    );
  }

  /**
   * Renders appropriate center piece for the navbar based on slide type.
   * @returns {JSX.Element | null}
   */
  public renderCenterPiece = (): JSX.Element | null => {
    const { slideIndexTypes, decks, activeViewSlide } = this.props;
    const {
      [this.props.activeViewSlide]: { type },
    } = slideIndexTypes;

    switch (type) {
      case EXERCISE:
        const slideIndexType: SlideIndexData1 = slideIndexTypes[
          activeViewSlide
        ] as SlideIndexData1;
        if (decks && decks[slideIndexType.deckID]) {
          const { cardsStates, activeCard } = decks[slideIndexType.deckID];
          return <StatusDots dotStates={cardsStates} activeDot={activeCard} />;
        }
        return null;
      case MARKERS:
      case INTERMEDIATE:
      case ITEM:
        return this.props.theme === PRIMARY_SCHOOL ? (
          <StyledProgressContainer>
            <ProgressBar progress={this.props.progressBarValue} />
          </StyledProgressContainer>
        ) : null;
    }
    return null;
  };

  /**
   * Determines if loader should be shown or not
   */
  private getShowLoader = () => {
    const { decks, fetching } = this.props;
    const keys = Object.keys(decks);
    const deckKey = keys[keys.length - 1] ? keys[keys.length - 1] : null;

    return fetching && !(deckKey ? decks[deckKey].deckFinished : false);
  };

  /**
   * Render the exercise slider component
   */
  private renderExerciseSlider = () =>
    this.props.decks && this.props.exercises && this.props.exercises.length ? (
      <ExerciseSlider
        slideIndex={this.props.activeViewSlide}
        slides={this.generateContent()}
        overrideSettings={this.sliderSettings}
      />
    ) : null;

  /**
   * Function for determining what to render, an Exercise or an Intermediate
   * @returns {any[]}
   */
  private generateContent = () => {
    return this.props.exercises.map((exerciseData, index) => {
      switch (exerciseData.type) {
        case EXERCISE.toLowerCase():
          return this.renderDeck(exerciseData as Exercise);

        case INTERMEDIATE.toLowerCase():
          return this.renderIntermediate(exerciseData as Intermediate);

        case RESULT.toLowerCase():
          return this.renderResult(exerciseData as Result);

        case MARKERS.toLowerCase():
          return this.renderMarkerItem(exerciseData as Markers, index);

        case EMPTY.toLowerCase():
          return null;

        default:
          return null;
      }
    });
  };

  /**
   * Activate sound overlay
   */
  private playSound = (): void => this.props.toggleAudioOverlay();

  /**
   * Toggles help
   */
  private toggleHelp = (): void =>
    this.props.setHelpIsShowing(!this.props.isHelpShowing);

  /**
   * Renders button
   * @param {void} callback
   * @param icon
   * @param active
   * @returns {any}
   */
  private renderButton = (
    callback: () => void,
    icon: JSX.Element,
    active?: boolean
  ): any => (
    <div>
      <Button
        aria-label="Vänd på kortet"
        overrideStyles={{ width: "46px", height: "46px", zIndex: "1" }}
        onClick={callback}
        icon={icon}
        active={active}
        circular
      />
      <p
        style={{
          textAlign: "center",
          paddingTop: ".4rem",
          paddingBottom: "1rem",
          color: "#277575",
        }}
      >
        Hjälp
      </p>
    </div>
  );

  /**
   * Renders audio button
   * @param showAudioButton
   * @returns {JSX.Element | null}
   */
  private renderAudioButton = (showAudioButton: boolean) =>
    showAudioButton ? (
      <>
        <XcAudioSL
          callback={this.playSound}
          active={this.props.audioOverlayActive}
        />
        <StyledTipContainer>
          <Tooltip
            show={
              this.props.audioOverlayActive && !this.state.audioTooltipShown
            }
            text="Tryck på markerad text för att lyssna på ljud"
            right="-24px"
            top="8px"
            arrowPosition="top"
            removeTime={4000}
            callback={this.setAudioTooltipShown}
          />
        </StyledTipContainer>
      </>
    ) : null;

  private setAudioTooltipShown = () =>
    this.setState({
      audioTooltipShown: true,
    });

  /**
   * Renders buttons
   * @returns {JSX.Element}
   */
  private renderButtons = (showAudioButton: boolean = false): JSX.Element => (
    <StyledCardOptionsWrapper>
      {this.renderButton(
        this.toggleHelp,
        this.props.isHelpShowing ? (
          <CloseIcon color="#277575" />
        ) : (
          <CrystalIcon color="#277575" />
        )
      )}
      {this.renderAudioButton(showAudioButton)}
    </StyledCardOptionsWrapper>
  );

  /**
   * Render exercise slider wrapper
   */
  private renderExerciseSliderWrapper = () => (
    <ExerciseSliderWrapper>{this.renderExerciseSlider()}</ExerciseSliderWrapper>
  );

  /**
   * Renders loader
   */
  private renderLoader = () => (
    <StyledLoaderCenterContainer>
      <StyledLoader size={LARGE} />
    </StyledLoaderCenterContainer>
  );

  /**
   * Renders intermediate screen
   * @param {ExerciseData} data
   * @returns {any}
   */
  private renderIntermediate = (data: Intermediate): JSX.Element => {
    return (
      <IntermediateSlider
        nextCallback={this.props.nextViewSlide}
        img_url={data.img_url}
        title={data.title}
        text={data.text}
        type={data.type}
        goals={data.goals}
      />
    );
  };

  /**
   * Updates exercise state when exercise finished correcting
   * @param {object} answer
   * @param exerciseResult
   * @param meta
   */
  private exerciseResultCallback = (answer: Answer, meta: Meta): void => {
    const cardData =
      this.props.decks[meta.deckID].cardsStates[
        this.props.decks[meta.deckID].activeCard
      ];
    this.props.correctAnswer(answer, cardData, meta);
  };

  /**
   * Determines wether a card and its components can be focused or not.
   * @param currentAttempt
   * @param decks
   * @param deckID
   */
  private focusAllowed = (
    currentAttempt: number,
    decks: Decks,
    deckID: string
  ) => {
    const deck = decks[deckID];
    const slideIndexType: SlideIndexData1 = this.props.slideIndexTypes[
      this.props.activeViewSlide
    ] as SlideIndexData1;

    if (deck.deckFinished) {
      return false;
    }

    if (deck.id && slideIndexType.deckID && deck.id !== slideIndexType.deckID) {
      return false;
    }

    return slideIndexType &&
      slideIndexType.type === EXERCISE &&
      slideIndexType.slideState === IN_VIEW &&
      currentAttempt === 0
      ? true
      : false;
  };

  /**
   * Function for rendering the Card
   * @param exercise
   * @param deckID
   * @returns {null}
   */
  private renderCard = (
    exercise: RawExercise,
    deckID: string
  ): JSX.Element | null => {
    if (this.props.decks[deckID]) {
      const { decks, disabled, submitFailure } = this.props;
      const cardData = decks[deckID].cardsStates[decks[deckID].activeCard];
      const {
        state,
        currentAttempt,
        correctAnswer: correctAnswerData,
      } = cardData;
      return (
        <>
          {this.renderButtons(cardData.hasAudio)}
          <ExerciseRenderer
            data={exercise.body}
            submitFailure={!!submitFailure}
            meta={{
              deckID,
              cardData,
              cardID: cardData.id,
              disabled,
              correctAnswerData,
            }}
            callback={this.exerciseResultCallback}
            state={state}
            currentAttempt={currentAttempt}
            isHelpShowing={this.props.isHelpShowing}
            focusInput={this.focusAllowed(currentAttempt, decks, deckID)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  /**
   * Renders the help side of the card if help is provided in the data
   * @param data
   * @returns {JSX.Element}
   */
  private renderHelp = (data: any): JSX.Element | null => {
    const defaultHelp = [
      {
        type: "default",
        data: {
          text: "Jag är ute på ett hemligt uppdrag! Be en kompis eller vuxen i närheten om hjälp så länge.",
        },
        settings: {},
      },
    ];
    return (
      <>
        {this.renderButtons(data.hasHelpAudio)}
        {data.help && data.help.data && data.help.data.length ? (
          <Help help={data.help.data} />
        ) : (
          <Help help={defaultHelp} />
        )}
      </>
    );
  };

  /**
   * Renders result slide component
   * @param data
   * @returns {any}
   */

  private onResultAndMarkerCallback = () => {
    this.props.removeMessages("EXERCISE_ROUTE");
    this.props.nextRoute
      ? this.props.goto(this.props.nextRoute.route)()
      : this.props.nextViewSlide();
  };

  private renderResult = (data: any): JSX.Element => (
    <ResultSlide data={data} callback={this.onResultAndMarkerCallback} />
  );

  /**
   * Renders an item
   * @param data
   * @param {number} index
   * @returns {JSX.Element}
   */
  private renderMarkerItem = (data: any, index: number): JSX.Element => (
    <MarkerItemSlide
      theme={this.props.theme!}
      viewSlideState={this.props.slideIndexTypes[index].slideState}
      data={data}
      callback={this.onResultAndMarkerCallback}
    />
  );

  /**
   * Function for rendering the Deck
   * @param {Exercise} data
   * @returns {null}
   */
  private renderDeck = (data: Exercise): JSX.Element | null => {
    if (this.props.decks[data.deckID]) {
      const activeCard = this.props.decks[data.deckID].activeCard - 1;

      return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <Deck responsibilityId={data.deckID}>
            {this.renderCard(data.data[activeCard], data.deckID)}
            {this.props.isHelpShowing && this.renderHelp(data.data[activeCard])}
          </Deck>
        </DndProvider>
      );
    } else {
      return null;
    }
  };
}

const getProgressBarValue = (
  points: number,
  pointsPerBadge: number
): number => {
  return Math.min(Math.floor((points * 100) / pointsPerBadge), 100);
};

const mapStateToProps = (state: any) => {
  return {
    decks: state.exercise.decks,
    exercises: state.exercise.exercises,
    fetching: state.exercise.fetching,
    activeViewSlide: state.exercise.slideIndex,
    slideIndexTypes: state.exercise.slideIndexTypes,
    currentChallengeStoryRead:
      state.progress.progress.current_challenge_story_read,
    progressBarValue: getProgressBarValue(
      state.progress.progress.points || 0,
      state.application.product.points_per_badge
    ),
    submitFailure: state.exercise.submitFailure,
    disabled:
      state.exercise.submittingAnswer ||
      state.exercise.exerciseFinished ||
      state.exercise.fetching ||
      !state.exercise.slideIndexTypes.length ||
      state.exercise.slideIndexTypes[state.exercise.slideIndex].slideState !==
        IN_VIEW,
    isHelpShowing: state.exercise.isHelpShowing,
    audioOverlayActive: state.audio.audioOverlayActive,
    theme: selectTheme(state),
    nextRoute: selectNextRoute(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  correctAnswer: (answer: Answer, cardData: ExerciseState, meta: Meta) =>
    dispatch(correctAnswer(answer, cardData, meta)),
  requestExercise: (preview?: string) =>
    dispatch(preview ? fetchExercisePreview(preview) : fetchInitialData()),
  nextViewSlide: () => dispatch(changeExerciseViewSlide()),
  goto: (route: string) => () => dispatch({ type: route }),
  updateViewSlideState: (index: number, state: ViewSlideState) =>
    dispatch(updateViewSlideState(index, state)),
  removeMessages: (route: string) =>
    dispatch(removeSnackbarMessagesWithRoute(route)),
  exerciseStarted: () => dispatch(startExercise()),
  toggleAudioOverlay: () => dispatch(toggleAudioOverlayAction()),
  setHelpIsShowing: (condition: boolean) =>
    dispatch(setIsHelpShowing(condition)),
});

export const ExerciseView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseViewComponent);

export default ExerciseViewComponent;
