import { parseJSON, fromOption, chain, Either, fold } from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";

export type TextAndAudio = {
  text?: string;
  audio?: string;
  useraudio?: string[];
};

export const nuller = () => null;

export const parse = <Left, Right>(onLeft: (e: unknown) => Left) => (
  val: string
) => parseJSON<Left>(val, onLeft) as Either<Left, Right>;

export const getAudio = <Return>(
  onNone: () => Return,
  onSome: (a: string | string[]) => Return
) => (audio?: string | string[]) =>
  pipe(
    O.fromNullable(
      Array.isArray(audio)
        ? audio.length > 0
          ? audio
          : undefined
        : audio || undefined
    ),
    O.fold(onNone, onSome)
  );

export const getText = <Return>(
  onNone: () => Return,
  onSome: (d: string) => Return
) => (text?: string) =>
  pipe(O.fromNullable(text || undefined), O.fold(onNone, onSome));

export const parseTextAndAudio = <Return>(
  onLeft: (e?: unknown) => Return,
  onRight: (d: TextAndAudio) => Return
) => (val?: string) =>
  pipe(
    O.fromNullable(val),
    fromOption(onLeft),
    chain(parse<Return, TextAndAudio>(onLeft)),
    fold(onLeft, onRight)
  );

export const toTextAndAudio = parseTextAndAudio(
  () => ({ text: "", audio: "" }),
  d => ({ text: d.text || "", audio: d.audio || "" })
);

export const calcProgressPercentage = (progress: number, total: number) => {
  const percentage = Math.round((progress / total) * 100);
  return percentage > 100 ? 100 : percentage;
};
