import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startAdventureCard } from "../adventurePicker/api/actions";
import {
  isNextLastProblem,
  selectAdventureCardPosts,
  selectAllIds,
  selectMarkersToComplete,
} from "../adventurePicker/api/selectors";
import { selectTheme } from "../profile/api/selectors";
import { EXERCISE_ROUTE } from "../routes";

function useAdventureStory() {
  const posts = useSelector(selectAdventureCardPosts);
  const ids = useSelector(selectAllIds);
  const markersToComplete = useSelector(selectMarkersToComplete);
  const theme = useSelector(selectTheme);
  const isLast = useSelector(isNextLastProblem);
  const dispatch = useDispatch();
  const [{ isLastSlide, lastSlideVisited }, setState] = useState({
    isLastSlide: false,
    lastSlideVisited: false,
  });

  const onSlideChange = useCallback(
    (slideIndex: number) => {
      setState({
        isLastSlide: slideIndex === posts.length - 1,
        lastSlideVisited:
          slideIndex === posts.length - 1 && !lastSlideVisited
            ? true
            : lastSlideVisited,
      });
    }, [lastSlideVisited, posts.length]
  )

  const onStartAdventureCard = useCallback(
    () => dispatch(startAdventureCard(ids, EXERCISE_ROUTE)),
    [dispatch, ids]
  );

  return {
    posts: posts || [],
    markersToComplete,
    theme,
    isLastSlide,
    isLastProblem: isLast,
    lastSlideVisited,
    buttonText: isLast ? "Gå till vinden" : "Räkna",
    onSlideChange,
    onStartAdventureCard,
  };
}

export default useAdventureStory;
