import * as React from "react";
import {
  StyledHelpWrapper,
  StyledHelpContent,
  StyledHelpColumn,
  StyledSideImg,
  FlexContainer,
  StyledColumnContent,
  StyledHelpHeading,
  StyledHelpText,
} from "./StyledHelp";
import HelpAudio from "./components/HelpAudio";
import { HelpImage } from "./components/HelpImage";
import { About } from "./components/About";
import { Tips } from "./components/Tips";
import { MathWords } from "./components/MathWords";
import { HelpItem } from "../../../views/exercise/api/types";
import { isSafari } from "../../platform/Platform";
import HelpVideo from "./components/HelpVideo";
import HtmlRenderer from "../HtmlRenderer";

interface Props {
  help: HelpItem;
}

export const mentorImgSrc =
  "https://media.studentlitteratur.se/image/mr/story/images/_characters/Mentor_glad_1_animated.svg";

export const HelpRenderer: React.FC<Props> = ({ help }) => {
  const renderMathWords = () =>
    help.data.words ? <MathWords mathwords={help.data.words} /> : null;

  const renderAbout = () => (
    <StyledHelpContent columns={"3fr 1fr"}>
      <StyledHelpColumn>
        <About data={help.data} />
      </StyledHelpColumn>
      {renderSideColumn()}
    </StyledHelpContent>
  );

  const renderVideo = (): JSX.Element => (
    <StyledHelpContent columns={"1fr"}>
      <StyledHelpColumn>
        <HelpVideo data={help.data} />
      </StyledHelpColumn>
    </StyledHelpContent>
  );

  const renderImage = (): JSX.Element => (
    <StyledHelpContent columns={"1fr"}>
      <StyledHelpColumn>
        <HelpImage data={help.data} />
      </StyledHelpColumn>
    </StyledHelpContent>
  );

  const renderAudio = () => (
    <StyledHelpContent columns={"3fr 1fr"}>
      <StyledHelpColumn>
        <HelpAudio data={help.data} />
      </StyledHelpColumn>
      {renderSideColumn()}
    </StyledHelpContent>
  );

  const renderTips = ({ image, assets } = help.data) => (
    <StyledHelpContent
      columns={
        (image && image.src) || (assets && assets.icons) ? "1fr 1fr" : "3fr 1fr"
      }
    >
      <Tips data={help.data} />
    </StyledHelpContent>
  );

  const renderDefaultHelp = () => (
    <StyledHelpContent columns={"3fr 1fr"}>
      <StyledHelpColumn>
        <StyledColumnContent>
          {help.data.text && (
            <HtmlRenderer component={StyledHelpText} html={help.data.text} />
          )}
          <StyledHelpText margin={"30px 0 0 0"}>
            {"Vi ses snart"}
          </StyledHelpText>
          <StyledHelpHeading>Mentorn</StyledHelpHeading>
        </StyledColumnContent>
      </StyledHelpColumn>
      {renderSideColumn()}
    </StyledHelpContent>
  );

  const renderSideColumn = () => (
    <StyledHelpColumn>
      <StyledColumnContent topAligned={isSafari()}>
        <StyledSideImg src={mentorImgSrc} />
      </StyledColumnContent>
    </StyledHelpColumn>
  );

  /**
   * Gets the right content
   */
  const getContent = (): JSX.Element | null => {
    switch (help.type) {
      case "about":
        return renderAbout();

      case "helpaudio":
        return renderAudio();

      case "helpimage":
        return renderImage();

      case "helpvideo":
        return renderVideo();

      case "tips":
        return renderTips();

      case "mathword":
        return renderMathWords();

      default:
        return renderDefaultHelp();
    }
  };

  return (
    <StyledHelpWrapper>
      <FlexContainer isVideo={help.type === "helpvideo" ? true : false}>
        {getContent()}
      </FlexContainer>
    </StyledHelpWrapper>
  );
};
