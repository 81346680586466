import styled from 'styled-components';

interface IconContainerProps {
  color: string;
}

export const StyledFeedbackDisplay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIconContainer = styled.div`
  margin-right: 8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${(props: IconContainerProps) => props.color};
  background-color: white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${(props: IconContainerProps) => props.color};
`;

export const StyledFeedbackText = styled.span`
  color: #191717;
  font-family: Muli;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;