import { 
  getProfileSaga, 
  watchUpdateProfileSaga,
  watchSetOnboardingCompletedSaga
} from "./api/sagas";
import { ConstraintService } from "../../services/constraint";
import { reducer } from "./api/reducer";
import * as actions from "./api/actions";
import { ReducerService } from "../../services/reducer";
import { SagaService } from "../../services/saga";

export * from "./Profile";

export const ProfileActions = actions;

export const init = () => {
  ConstraintService.set(getProfileSaga);
  SagaService.set(watchUpdateProfileSaga);
  SagaService.set(watchSetOnboardingCompletedSaga);
  ReducerService.set("profile", reducer);
};
