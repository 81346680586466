import shared from "./shared";

export interface StyledProps {
  theme?: any;
}

export const deviceSize = {
  desktopL: 1200,
  desktop: 992,
  tablet: 768,
  tabletS: 680,
  phone: 520,
  phoneS: 320,
}

const theme = {...shared};

export default theme;