import { createSelector } from "reselect";
import { RootState } from "../../../store";
import { selectProgress } from "../../../views/adventurePicker/api/selectors";
import { Progress } from "../../../views/adventurePicker/api/types";
import {
  ADVENTURE_PICKER_ROUTE,
  ADVENTURE_STORY_ROUTE,
  ATTIC_ROUTE,
  EXERCISE_ROUTE,
  ITEM_FEEDBACK_ROUTE,
} from "../../../views/routes";

export const selectCurrentRoute = (state: RootState) =>
  state.location.get("current");

export const selectNextRoute = createSelector(
  selectProgress,
  selectCurrentRoute,
  (progress, currentRoute) => {
    const fn = routeMap[currentRoute as string];

    if (!fn) {
      return;
    }

    return fn(progress);
  }
);

interface RouteMap {
  [key: string]: (p: Progress) => { route: string; text: string } | undefined;
}

const routeMap: RouteMap = {
  DASHBOARD_ROUTE: ({ adventureId, problemId }: Progress) => {
    if (!adventureId) {
      return {
        route: EXERCISE_ROUTE,
        text: "Räkna",
      };
    }

    if (!problemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Se berättelsen",
      };
    }

    return {
      route: EXERCISE_ROUTE,
      text: "Räkna",
    };
  },
  ATTIC_ROUTE: ({ adventureId, problemId }: Progress) => {
    if (!adventureId) {
      return {
        route: ADVENTURE_PICKER_ROUTE,
        text: "Äventyr",
      };
    }

    if (!problemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Se berättelsen",
      };
    }

    return undefined;
  },
  MARKERS_ROUTE: ({ adventureId, problemId }: Progress) => {
    if (!adventureId) {
      return {
        route: EXERCISE_ROUTE,
        text: "Räkna",
      };
    }

    if (!problemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Se berättelsen",
      };
    }

    return {
      route: EXERCISE_ROUTE,
      text: "Räkna",
    };
  },
  ASSIGNMENT_ROUTE: ({ adventureId, problemId }: Progress) => {
    if (!adventureId) {
      return {
        route: EXERCISE_ROUTE,
        text: "Räkna",
      };
    }

    if (!problemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Se berättelsen",
      };
    }

    return {
      route: EXERCISE_ROUTE,
      text: "Räkna",
    };
  },
  EXERCISE_ROUTE: ({
    adventureId,
    problemId,
    nextProblemId,
    prevProblemId,
    itemFeedBackIndex,
  }: Progress) => {
    // User received an item (1 - 3) - show feedback for the item
    if (itemFeedBackIndex !== null) {
      return {
        route: ITEM_FEEDBACK_ROUTE,
        text: "",
      };
    }

    // User has an active problem - continue on the same path
    if (problemId) {
      return undefined;
    }

    // User has no active problem, but an upcoming problem - send to the story about the problem
    if (!problemId && nextProblemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Se berättelsen",
      };
    }

    // User has no active adventure, but has a previous problem - send user to pick new adventure
    if (!adventureId && prevProblemId) {
      return {
        route: ATTIC_ROUTE,
        text: "Vinden",
      };
    }

    return undefined;
  },
  ITEM_FEEDBACK_ROUTE: ({ adventureId, problemId }: Progress) => {
    if (!adventureId) {
      return {
        route: ATTIC_ROUTE,
        text: "Gå till vinden",
      };
    }

    if (!problemId) {
      return {
        route: ADVENTURE_STORY_ROUTE,
        text: "Fortsätt äventyret",
      };
    }

    return {
      route: EXERCISE_ROUTE,
      text: "Räkna",
    };
  },
};
