import styled, { keyframes } from 'styled-components';

export const loadingFade = keyframes`
0% { opacity: 0; }
50% { opacity: 0.8; }
100% { opacity: 0; }
`;

export const StyledLoader = styled.div`
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
`;

export const LoadingDot = styled.div`
  float: left;
  width: 6px;
  height: 6px;
  margin: 0 12px;
  background: white;
  border-radius: 50%;

  opacity: 1;
  animation: ${loadingFade} 1s infinite;
  
  &:nth-child(1) {
    animation-delay: 0s;
  }
  
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  &:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  &:nth-child(6) {
    animation-delay: 0.5s;
  }
  
  &:nth-child(7) {
    animation-delay: 0.6s;
  }
  
  &:nth-child(8) {
    animation-delay: 0.7s;
  }
`;

