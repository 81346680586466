import * as React from "react";
import {
  StyledSubHeading,
  StyledContentText,
  StyledCopyright,
  StyledStudliLink,
  StyledTomoyoLogoContainer,
} from "./StyledMRCredits";
import { ReactComponent as TomoyoLogo } from "../../../../assets/logos/logo_tomoyo_color_2.svg";

export const MRCredits: React.FC = () => {
  return (
    <StyledContentText>
      <h1>Medverkande och rättigheter</h1>

      <h2>Medverkande</h2>

      <h3>Projektledning och redaktion</h3>
      <ul>
        <li>Sandra Bergdahl </li>
        <li>Marie Delshammar</li>
        <li>Johan Erlandsson </li>
        <li>Marie Holgersson</li>
        <li>Gabriella Larsson</li>
        <li>Jenny Linander</li>
        <li>Tommy Lundahl</li>
        <li>Åse Nordberg</li>
        <li>Tanja Zlomuzica</li>
      </ul>

      <h3>Innehåll</h3>
      <StyledSubHeading>
        Materialet baseras på Forlaget Alineas produkt CampMat. Dessutom ingår
        innehåll framtaget av:
      </StyledSubHeading>
      <ul>
        <li>Dörte Peters</li>
      </ul>
      <StyledSubHeading>Matteorden från Favorit matematik:</StyledSubHeading>
      <ul>
        <li>Författarna: Elisabeth Pettersson och Christina Svensson</li>
        <li>Illustrationer: Lena Serholt</li>
      </ul>
      <StyledSubHeading>Illustrationer:</StyledSubHeading>
      <ul>
        <li>Dricka, piltavla, börs: Magdalena Lavebratt</li>
        <li>Hus med romerska siffror: Radovan Vujovic/Shutterstock.com</li>
        <li>Övriga illustrationer: Shutterstock</li>
      </ul>

      <h3>Utveckling och produktion</h3>
      <ul>
        <li>Rebeca Albertsson </li>
        <li>Admir Bajraktarevic</li>
        <li>Mattias Berkström</li>
        <li>Jimmie Broberg</li>
        <li>Bjarne Bülow</li>
        <li>Moa Delshammar</li>
        <li>Osama Fayad</li>
        <li>Daniel Forsberg</li>
        <li>Cecilia Friis</li>
        <li>Peter Hansen</li>
        <li>Maria Heimer</li>
        <li>Fanny Hoberg</li>
        <li>Fredrik Hård</li>
        <li>Sophie Ivan</li>
        <li>Kim Jansheden</li>
        <li>Wojtek Jasinski</li>
        <li>Nicklas Johansson</li>
        <li>Petra Jonsson</li>
        <li>Mikael Karlsson</li>
        <li>Kristofer Kinberg</li>
        <li>Christina Knepper</li>
        <li>Hilda Linander</li>
        <li>Magnus Lorentz</li>
        <li>Eva Moström</li>
        <li>Avani Nath</li>
        <li>Iryna Sabo</li>
        <li>Johan Samuelsson</li>
        <li>Oskar Sjöberg</li>
        <li>Anton Sjöstrand</li>
        <li>Jacob Söderblom</li>
        <li>Caroline Topolovec</li>
        <li>Erik Tufvesson</li>
        <li>Marie Wingert</li>
        <li>Hilda Zlomuzica</li>
      </ul>

      <h3>Ett stort tack till våra samarbetspartners</h3>
      <ul>
        <li>ID Kommunikation</li>
        <li>Innovaform</li>
        <li>inUse</li>
        <li>Hello There</li>
        <li>Sayit Educational Audio & Video</li>
      </ul>

      <h3>Ett stort tack till våra testpiloter</h3>

      <h2>Rättigheter</h2>
      <h6> I Tomoyo för åk 1-3 är berättelserna i äventyren helt fiktiva. </h6>
      <h6>
        {" "}
        I Tomoyo för åk 4-6 är berättelserna i äventyren fiktiva men är
        faktainspirerade och bygger på historiska händelser med fokus på
        matematikhistoria. Till åk 4-6 finns även faktatexter som berör olika
        historiska händelser. Faktatexterna i Tomoyo åk 4-6 är skrivna av Maria
        Heimer och därefter bearbetade av redaktionen.{" "}
      </h6>
      <h3>Kopieringsförbud</h3>
      <p>
        Detta verk är skyddat av upphovsrättslagen. Kopiering, utöver lärares
        begränsade rätt att kopiera för undervisningsändamål enligt Bonus
        Copyright Access skolkopieringsavtal, är förbjuden. För information om
        avtalet hänvisas till utbildningsanordnarens huvudman eller Bonus
        Copyright Access. Den som bryter mot lagen om upphovsrätt kan åtalas av
        allmän åklagare och dömas till böter eller fängelse i upp till två år
        samt bli skyldig att erlägga ersättning till upphovsman eller
        rättsinnehavare.
      </p>

      <StyledCopyright>© 2019, 2021 Studentlitteratur AB.</StyledCopyright>
      <StyledTomoyoLogoContainer>
        <TomoyoLogo />
      </StyledTomoyoLogoContainer>
      <StyledStudliLink href="https://www.studentlitteratur.se">
        studentlitteratur.se
      </StyledStudliLink>
    </StyledContentText>
  );
};
