import styled from "styled-components";
import { Navigator } from "../Navigator";
 
export const StyledNavigatorHome = styled(Navigator)`
  display: flex;
  height: 60px;
  width: 60px;
  padding: 16px;
  background-color: ${props => props.theme.themePrimary};
  color: ${props => props.theme.white}
  &:hover {
    background-color: #1f5d5d;
  }
  &:focus {
    background-color: #1f5d5d;
  }
  &:active {
    background-color: #1f5d5d;
  }
`;