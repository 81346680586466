import * as React from "react";
import {
  PlaceableImage,
  PlaceableImageWrapper,
  StyledIntroductionViewLayout,
  StyledSliderBoxWrapper,
  StyledWrapper,
  StyledIntroductionViewRightColumn,
  StyledPlaceablesArea,
  StyledProgressWrapper,
  StyledProgressBarContainer,
  StyledStoryMarkers,
} from "./StyledIntroductionView";
import SliderBox from "./SliderBox";
import { AudioComponent } from "../Audio";
import { Motion, spring } from "@serprex/react-motion";
import ProgressBar from "../ProgressBar";
import { Themes } from "../../../views/profile/api/types";
import { MIDDLE_SCHOOL } from "../../constants";
import { MarkerItemsToComplete } from "../../../views/markers/api/types";
import Markers from "../Markers/Markers";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../views/profile/api/selectors";
import { BackgroundImage } from "../../../views/attic/StyledAttic";
import {
  Post,
  Image as StoryImage,
} from "../../../views/adventurePicker/api/types";
export interface Props {
  slides: Post[]; // Array of data for each slide
  activeIndex?: number; // Prop if slide is to start at anything other than 0
  children?: any;
  markersToComplete?: MarkerItemsToComplete[];
  isLastProblem?: boolean;
  onSlideChange?: (slideIndex: number) => void;
}

const progressBarStyles = {
  height: "10px",
  padding: "3px",
  backgroundColor: "#F9F6F6",
};

const IntroductionView = ({
  isLastProblem,
  slides,
  activeIndex: index,
  markersToComplete,
  children,
  onSlideChange,
}: Props) => {
  const loadedImages = React.useRef<HTMLImageElement[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(index ? index : 0);
  const theme = useSelector(selectTheme);

  const isFirstSlide = activeIndex === 0;
  const isLastSlide = activeIndex === slides.length - 1;

  const progress = Math.ceil((activeIndex / (slides.length - 1)) * 100);
  const markers =
    markersToComplete && markersToComplete.map((item) => item.markers).flat();
  const labels =
    markersToComplete &&
    markersToComplete.map((item) => item.marker_group_label);
  const showMarkers = theme === MIDDLE_SCHOOL && isLastSlide;

  React.useEffect(() => {
    !isLastSlide && preloadImages(slides[activeIndex + 1]);

    return () => {
      loadedImages.current = [];
    };
  });

  React.useEffect(() => {
    onSlideChange && onSlideChange(activeIndex);
  }, [activeIndex, onSlideChange]);

  const activeSlide = slides[activeIndex] || {
    images: [],
    body: "",
    audio: "",
    title: "",
    backgroundImage: "",
  };

  const slideBackward = () =>
    activeIndex !== 0 && setActiveIndex(activeIndex - 1);

  const slideForward = () =>
    activeIndex !== slides.length - 1
      ? setActiveIndex(activeIndex + 1)
      : onSlideChange && onSlideChange(activeIndex);

  /**
   * Preloads story images
   * @param  {Post} slide
   * @return  {void}
   */
  const preloadImages = (slide: Post): void => {
    if (
      slide &&
      slide.background_image &&
      !isImgLoaded(slide.background_image)
    ) {
      createImageElement(slide.background_image);
    }

    if (slide && slide.images) {
      slide.images.forEach((image: StoryImage) => {
        if (!isImgLoaded(image.url)) {
          createImageElement(image.url);
        }
      });
    }
  };

  /**
   * Checks if image is already loaded.
   * @param  {string} url
   * @return   {boolean}
   */
  const isImgLoaded = (url: string): boolean =>
    loadedImages.current.some((image) => image.src === url);

  /**
   * Creates image element and set image url as src
   * so it will be cached in the browser.
   * @param {string} url
   * @return {void}
   */
  const createImageElement = (url: string): void => {
    const imageEl = new Image();
    imageEl.src = url;
    loadedImages.current.push(imageEl);
  };

  return (
    <StyledWrapper>
      {showMarkers && markers && !isLastProblem && (
        <StyledStoryMarkers>
          <Markers
            theme={MIDDLE_SCHOOL as Themes}
            data={markers}
            headGroupLabels={labels}
            intro
          />
        </StyledStoryMarkers>
      )}
      <StyledIntroductionViewRightColumn theme={theme}>
        <StyledSliderBoxWrapper theme={theme} showMarkers={showMarkers}>
          <SliderBox
            audio={
              activeSlide.audio && (
                <AudioComponent
                  key={activeSlide.audio}
                  audioUrl={activeSlide.audio}
                />
              )
            }
            prevCallback={slideBackward}
            nextCallback={slideForward}
            title={activeSlide.title}
            body={activeSlide.body}
            isFirstSlide={isFirstSlide}
            isLastSlide={isLastSlide}
            showMarkers={showMarkers}
            theme={theme}
          >
            {children}
          </SliderBox>
        </StyledSliderBoxWrapper>
      </StyledIntroductionViewRightColumn>
      <StyledIntroductionViewLayout theme={theme}>
        {!showMarkers && (
          <StyledPlaceablesArea>
            {activeSlide.images &&
              activeSlide.images.map((image: StoryImage, index: number) => (
                <PlaceableImageWrapper
                  key={`${index}_${image.url}`}
                  scale={image.scale}
                  offsetX={image.offset_x}
                  offsetY={image.offset_y}
                  zIndex={image.z_index}
                >
                  <Motion
                    defaultStyle={{ opacity: 0 }}
                    style={{
                      opacity: spring(1, { stiffness: 110, damping: 17 }),
                    }}
                  >
                    {(interpolatingstyle) => (
                      <PlaceableImage
                        style={interpolatingstyle}
                        src={image.url}
                        key={image.id}
                        isMirrored={image.is_mirrored}
                        alt="placeable"
                      />
                    )}
                  </Motion>
                </PlaceableImageWrapper>
              ))}
          </StyledPlaceablesArea>
        )}
        <BackgroundImage
          src={activeSlide.background_image}
          alt="bakgrundsbild"
        />
      </StyledIntroductionViewLayout>

      {slides.length > 1 && (
        <StyledProgressWrapper>
          <StyledProgressBarContainer>
            <ProgressBar
              progress={progress}
              barColor="#5D5D5D"
              overrideStyles={progressBarStyles}
            />
          </StyledProgressBarContainer>
        </StyledProgressWrapper>
      )}
    </StyledWrapper>
  );
};

export default IntroductionView;
