import {
  StyledIntermediateSliderImage,
  StyledIntermediateSliderContent,
  StyledIntermediateSliderTitle,
  StyledIntermediateSliderText,
  StyledContinueButtonWrapper,
  StyledIntermediateSliderDescription,
  StyledIntermediateSliderListItem,
  StyledIntermediateSliderList,
} from "./StyledIntermediateSlider";
import { ArrowButton } from "../Button/ArrowButton";
import { Intermediate } from "../../../views/exercise/api/types";
import { StyledExeContentContainer } from "../../../views/exercise/StyledExercise";
import { SplashDialog } from "../Splash/SplashDialog";

interface Props extends Intermediate {
  children?: JSX.Element;
  nextCallback(): void;
}

export const IntermediateSlider = (props: Props) => {
  return (
    <StyledExeContentContainer>
      <div>
        <StyledIntermediateSliderImage src={props.img_url} />
      </div>

      <SplashDialog />

      <StyledIntermediateSliderContent>
        <StyledIntermediateSliderDescription>
          Arbeta med att:
        </StyledIntermediateSliderDescription>

        <StyledIntermediateSliderTitle>
          {props.title}
        </StyledIntermediateSliderTitle>

        <StyledIntermediateSliderText>
          {props.text}
        </StyledIntermediateSliderText>

        {props.goals && (
          <StyledIntermediateSliderList>
            {props.goals.map((goal: string) => (
              <StyledIntermediateSliderListItem key={goal}>
                {goal}
              </StyledIntermediateSliderListItem>
            ))}
          </StyledIntermediateSliderList>
        )}

        <div>{props.children}</div>

        <StyledContinueButtonWrapper>
          <ArrowButton onClick={props.nextCallback} direction={"right"} />
        </StyledContinueButtonWrapper>
      </StyledIntermediateSliderContent>
    </StyledExeContentContainer>
  );
};
