import {
  CREATE,
  READ,
  UPDATE,
  DELETE
} from '../constants';

export interface ApiActions {
  CREATE: ApiActionStates,
  READ: ApiActionStates,
  UPDATE: ApiActionStates,
  DELETE: ApiActionStates,
}

interface ApiActionStates {
  REQUEST: string,
  SUCCESS: string,
  FAILURE: string
}

export function createApiActions(prefix: string) {
  return {
    CREATE: createApiActionStates(prefix, CREATE),
    READ: createApiActionStates(prefix, READ),
    UPDATE: createApiActionStates(prefix, UPDATE),
    DELETE: createApiActionStates(prefix, DELETE),
  };
}

function createApiActionStates( prefix: string, suffix: string ) {
  const PREFIX = prefix.toUpperCase();
  const SUFFIX = suffix.toUpperCase();
  return {
      REQUEST: `${SUFFIX}_${PREFIX}_REQUEST`,
      SUCCESS: `${SUFFIX}_${PREFIX}_SUCCESS`,
      FAILURE: `${SUFFIX}_${PREFIX}_FAILURE`
    };
}