import * as React from "react";

interface Props {
  source: string;
  reference: React.RefObject<HTMLAudioElement>;
  showControls?: boolean;
}

export const AudioItem: React.FC<Props> = (props) => {
  const contextMenus = (e: any) => e.preventDefault();

  return (
    <audio
      onContextMenu={contextMenus}
      controls={props.showControls}
      controlsList="nodownload"
      ref={props.reference}
    >
      <source src={props.source} type="audio/mp3" />
    </audio>
  );
};
