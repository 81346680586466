export const px2unitless = (pixels: number, context = fontSizeDefaultNumber) =>
  `${pixels / context}em`;

export const fontSizeDefaultNumber = 14;
export const lineHeightNumber = 20;
export const lineHeight = `${lineHeightNumber}px`;
export const fontFamily =
  '"Muli", sans-serif;';
export const fontSizeDefault = `${fontSizeDefaultNumber}px`;
export const lineHeightDefault = px2unitless(lineHeightNumber);
