import { 
  ONBOARDING_API_ACTIONS, 
  ONBOARDING_GRADES_API_ACTIONS 
} from "./actions";

const initialState = {
  fetching: false,
  posts: null,
  grades: null,
  error: null,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ONBOARDING_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null }
    case ONBOARDING_API_ACTIONS.READ.SUCCESS:
      return { ...state, fetching: false, error: null, posts: action.posts };
    case ONBOARDING_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case ONBOARDING_GRADES_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null }
    case ONBOARDING_GRADES_API_ACTIONS.READ.SUCCESS:
      return { ...state, fetching: false, error: null, grades: action.grades };
    case ONBOARDING_GRADES_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };
    
    default: 
      return state;
  }
  
}

