import * as React from "react";
import { StyledItem } from "./StyledItem";

export interface ItemProps {
  value: string;
  callback: (arg: string) => void;
  overrideStyles?: {};
  children: React.ReactNode;
}

export const Item: React.FC<ItemProps> = ({
  value,
  callback,
  children,
  ...rest
}) => {
  const onclick = () => callback(value);

  return (
    <StyledItem {...rest} onClick={onclick}>
      {children}
    </StyledItem>
  );
};
