import { takeLatest, call, select, put } from "typed-redux-saga";
import axios from "axios";
import { flow, pipe } from "fp-ts/lib/function";
import { Json, parse } from "fp-ts/lib/Json";
import { fold, right, left } from "fp-ts/lib/Either";
import * as Sentry from "@sentry/browser";

import { ADVENTURE_GROUPS_API_ACTIONS, getAllAdventures } from "./actions";
import { getToken } from "../../../shared/store/auth";
import { getProductId } from "../../../store/Application";
import {
  START_ADVENTURE_PROBLEM_REQUEST,
  GET_ADVENTURES_REQUEST,
  GET_ADVENTURES_REQUEST_SUCCESS,
  START_ADVENTURE_REQUEST_SUCCESS,
  START_ADVENTURE_PROBLEM_REQUEST_SUCCESS,
  GET_ADVENTURE_CARD_REQUEST,
  START_ADVENTURE_PROBLEM_REQUEST_ERROR,
  SET_PROBLEMS,
  SET_PROGRESS,
  GET_MARKERS_TO_COMPLETE_SUCCESS,
  GET_MARKERS_TO_COMPLETE_ERROR,
  GET_ALL_ADVENTURES_REQUEST,
  GET_ALL_ADVENTURES_REQUEST_SUCCESS,
  SET_ACTIVE_ADVENTURE,
  COMPLETE_PROBLEM,
  RESET_PROBLEM,
} from "../../../shared/constants";
import { AnyAction } from "redux";
import { RouterActions } from "../../../shared";
import { ADVENTURE_STORY_ROUTE, ATTIC_ROUTE } from "../../routes";
import { Adventure, AdventureResponse, Slides } from "./types";
import { isLastProblem, selectProgress } from "./selectors";

export function* watchGetAdventureGroupsSaga() {
  yield* takeLatest(
    ADVENTURE_GROUPS_API_ACTIONS.READ.REQUEST,
    getAdventureGroupsSaga
  );
}

function fetchAdventureGroups(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/adventuregroups`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* getAdventureGroupsSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchAdventureGroups, token, productId);
    const adventureGroups = response.data;
    yield* put({
      type: ADVENTURE_GROUPS_API_ACTIONS.READ.SUCCESS,
      adventureGroups,
    });
  } catch (error) {
    yield* put({
      type: ADVENTURE_GROUPS_API_ACTIONS.READ.FAILURE,
    });
    throw error;
  }
}

export function* watchCompleteProblemSaga() {
  yield* takeLatest(COMPLETE_PROBLEM, completeProblemSaga);
}

export function completeProblem(
  token: string,
  productId: number,
  adventureGroupID: number,
  adventureID: number,
  problemID: number
) {
  return axios({
    method: "put",
    url: `/api/product/${productId}/testdashboard/adventuregroup/${adventureGroupID}/adventures/${adventureID}/problem/${problemID}/completeproblem`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* completeProblemSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(
      completeProblem,
      token,
      productId,
      action.payload.adventureGroupId,
      action.payload.adventureId,
      action.payload.activeProblemId
    );
    if (response.status === 200) {
      yield* call(getUserJourney);
    }
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
}

export function* watchResetProblemSaga() {
  yield* takeLatest(RESET_PROBLEM, resetProblemSaga);
}

function resetProblem(
  token: string,
  productId: number,
  adventureGroupID: number,
  adventureID: number,
  problemID: number
) {
  return axios({
    method: "post",
    url: `/api/product/${productId}/testdashboard/adventuregroup/${adventureGroupID}/adventures/${adventureID}/problem/${problemID}/resetproblem`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* resetProblemSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(
      resetProblem,
      token,
      productId,
      action.payload.adventureGroupId,
      action.payload.adventureId,
      action.payload.activeProblemId
    );
    if (response.status === 200) {
      yield* call(getUserJourney);
    }
  } catch (error) {
    throw error;
  }
}

export function* watchSetActiveAdventureSaga() {
  yield* takeLatest(SET_ACTIVE_ADVENTURE, setActiveAdventureSaga);
}

function setActiveAdventureRequest(
  token: string,
  productId: number,
  adventureId: number,
  adventureGroupId: number
) {
  return axios({
    method: "put",
    url: `/api/product/${productId}/adventuregroup/${adventureGroupId}/adventures/${adventureId}/togglelock`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* setActiveAdventureSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(
      setActiveAdventureRequest,
      token,
      productId,
      action.payload.adventureId,
      action.payload.adventureGroupId
    );
    if (response.status === 200) {
      yield* call(getAllAdventures);
      yield* call(getUserJourney);
    }
  } catch (error) {
    throw error;
  }
}

export function* watchGetAllAdventuresSaga() {
  yield* takeLatest(GET_ALL_ADVENTURES_REQUEST, getAllAdventuresSaga);
}

function* getAllAdventuresSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchAllAdventures, token, productId);
    const adventures = response.data;
    yield* put({
      type: GET_ALL_ADVENTURES_REQUEST_SUCCESS,
      adventures,
    });
  } catch (error) {
    throw error;
  }
}

function fetchAllAdventures(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/adventures`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* watchGetAdventuresSaga() {
  yield* takeLatest(GET_ADVENTURES_REQUEST, getAdventuresSaga);
}

function fetchAdventures(
  token: string,
  productId: number,
  adventureGroupId: number
) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/adventuregroup/${adventureGroupId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* getAdventuresSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(
      fetchAdventures,
      token,
      productId,
      action.payload.adventureGroupId
    );
    const adventures = response.data;
    yield* put({
      type: GET_ADVENTURES_REQUEST_SUCCESS,
      adventures,
    });
  } catch (error) {
    throw error;
  }
}

export function* watchGetAdventureCardSaga() {
  yield* takeLatest(GET_ADVENTURE_CARD_REQUEST, startAdventureSaga);
}

function* startAdventureSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);

    const response = yield* call(
      startAdventure,
      token,
      productId,
      action.payload.adventureGroupId,
      action.payload.adventureId
    );

    yield* put({
      type: START_ADVENTURE_REQUEST_SUCCESS,
      progress: response.data,
    });

    yield* put(RouterActions.push(ADVENTURE_STORY_ROUTE));
  } catch (error) {
    throw error;
  }
}

export function* watchGetMarkersToCompleteSaga() {
  yield* takeLatest(ADVENTURE_STORY_ROUTE, getMarkersToCompleteSaga);
}

function* getMarkersToCompleteSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const { nextProblemId } = yield* select(selectProgress);

    const response = yield* call(
      getMarkersToCompleteInProblem,
      token,
      productId,
      nextProblemId
    );

    yield* put({
      type: GET_MARKERS_TO_COMPLETE_SUCCESS,
      markersToComplete: response.data,
    });
  } catch (error) {
    yield* put({
      type: GET_MARKERS_TO_COMPLETE_ERROR,
    });
    throw error;
  }
}

export function* watchStartAdventureCardSaga() {
  yield* takeLatest(START_ADVENTURE_PROBLEM_REQUEST, startAdventureProblemSaga);
}

function* startAdventureProblemSaga(action: AnyAction) {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);

    const response = yield* call(
      startAdventureProblem,
      token,
      productId,
      action.payload.adventureGroupId,
      action.payload.adventureId,
      action.payload.adventureCardId
    );

    yield* put({
      type: START_ADVENTURE_PROBLEM_REQUEST_SUCCESS,
      progress: response.data,
    });

    const isLast = yield* select(isLastProblem);

    if (isLast) {
      yield* call(
        completeAdventureAndProblem,
        token,
        productId,
        action.payload.adventureGroupId,
        action.payload.adventureId,
        action.payload.adventureCardId
      );

      yield* call(getUserJourney);

      yield* put(RouterActions.push(ATTIC_ROUTE));

      return;
    }

    if (action.payload.nextRoute) {
      yield* put(RouterActions.push(action.payload.nextRoute));
    }
  } catch (error) {
    yield* put({
      type: START_ADVENTURE_PROBLEM_REQUEST_ERROR,
    });
    throw error;
  }
}

function startAdventure(
  token: string,
  productId: number,
  adventureGroupId: number,
  adventureId: number
) {
  return axios({
    method: "put",
    url: `/api/product/${productId}/adventuregroup/${adventureGroupId}/adventures/${adventureId}/init`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function startAdventureProblem(
  token: string,
  productId: number,
  adventureGroupId: number,
  adventureId: number,
  problemId: number
) {
  return axios({
    method: "put",
    url: `/api/product/${productId}/adventuregroup/${adventureGroupId}/adventures/${adventureId}/problem/${problemId}/init`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function completeAdventureAndProblem(
  token: string,
  productId: number,
  adventureGroupId: number,
  adventureId: number,
  problemId: number
) {
  return axios({
    method: "put",
    url: `/api/product/${productId}/adventuregroup/${adventureGroupId}/adventures/${adventureId}/problem/${problemId}/complete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function getMarkersToCompleteInProblem(
  token: string,
  productId: number,
  problemId: number | null
) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/problem/${problemId}/markers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getUserJourney() {
  const token = yield* select(getToken);
  const productId = yield* select(getProductId);

  const res = yield* call(getJourney, token, productId);

  yield* put({
    type: ADVENTURE_GROUPS_API_ACTIONS.READ.SUCCESS,
    adventureGroups: res.data.tree.filter(
      (a: Adventure) => a.node_type === "adventure-group"
    ),
  });

  yield* put({
    type: GET_ADVENTURES_REQUEST_SUCCESS,
    adventures: res.data.tree.filter(
      (a: Adventure) => a.node_type === "adventure"
    ),
  });

  yield* put({
    type: SET_PROBLEMS,
    problems: res.data.tree
      .filter((a: AdventureResponse) => a.node_type === "problem")
      .map((a: AdventureResponse) => ({
        ...a,
        data: parseAdventureData(a.data),
      })),
  });

  yield* put({
    type: SET_PROGRESS,
    progress: res.data.progress,
  });
}

const getJourney = (token: string, productId: number) =>
  axios({
    method: "get",
    url: `/api/product/${productId}/user/adventure/journey`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const parseAdventureData = (data: string = "") =>
  pipe(
    parse(data),
    fold<unknown, Json, Slides>(
      flow(
        (e) => e as Error,
        logError,
        () =>
          isSlides({
            characters: [],
            posts: [],
            recap: [],
            items: [],
          })
      ),
      isSlides
    )
  );

const logError = flow(
  (err: Error) =>
    process.env.REACT_APP_ENV === "production" ? right(err) : left(err),
  fold(console.error, Sentry.captureException)
);

const isSlides = (s: unknown) => s as Slides;
