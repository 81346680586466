import * as React from "react";
import {
  StyledSortWrapper,
  StyledSortBoxesRow,
  StyledItemsRowWrapper,
} from "./StyledSort";
import { SortBox } from "./components/SortBox/SortBox";
import { UnsortedBox } from "./components/UnsortedBox/UnsortedBox";
import {
  SortBoxType,
  SortItemType,
  SortItemListType,
  SortSettings,
  SortReturnedAnswer,
  SortAnswerType,
} from "./types";
import { SortItem } from "../../../Chip/SortItem";
import { StyledSortItemPlaceholder } from "../../../Chip/StyledSortItem";
import {
  prepareBoxesList,
  findFirstUnsortedItem,
  buildCorrectedList,
} from "./helpers";

import { CustomDragLayer } from "../../../Chip/CustomDragLayer";
import { hasAttemptsLeft } from "../../../../../Helpers";
import { PARKING } from "../../../../constants";
import { CorrectionBtnStates } from "../../../../../../../shared/components/CorrectionButton";

interface Props {
  boxes: SortBoxType[];
  items: SortItemType[];
  maxItemsInBox: number;
  settings: SortSettings;
  correctAnswers: SortReturnedAnswer;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  callback(answers: string): void;
}

interface State {
  selectedItemId: string | null;
  boxesList: SortBoxType[];
  dirty: boolean;
}

const UNSORTED_BOX_ID = "unsortedbox";

export const XcSort: React.FC<Props> = (props) => {
  /**
   * Prepares the new boxesList,
   * getInitialState() is executed just once, at the initial render, to get the initial state
   */
  const [initialList] = React.useState(function getInitialState() {
    return prepareBoxesList(
      props.boxes,
      props.items,
      props.settings.boxType,
      props.maxItemsInBox,
      props.settings.order
    );
  });

  /**
   * Prepares the first selected unsorted item,
   * getInitialState() is executed just once, at the initial render, to get the initial state
   */
  const [initialItemId] = React.useState(function getInitialState() {
    return findFirstUnsortedItem(props.items);
  });

  /**
   * Set state with initial values.
   */
  const [state, setState] = React.useState<State>({
    selectedItemId: "",
    boxesList: initialList,
    dirty: false,
  });

  /**
   * Prepares answer to be sent to backend for correction.
   * Returns an array of objects with id of item and the box it is in.
   */
  const extractAnswers = React.useCallback(() => {
    let allItems = state.boxesList.map((box) => {
      return box.items.map((item) => {
        if (item) {
          return { [item.id]: box.id };
        }
        return null;
      });
    });
    allItems = allItems.reduce((acc: SortAnswerType[][], val: any) => {
      return acc.concat(val);
    }, []);

    return allItems.filter((item: any) => item !== null);
  }, [state.boxesList]);

  /**
   * Collect answer and run callback
   */
  const publishSelectionState = React.useCallback((): void => {
    const answers = extractAnswers();

    props.callback(JSON.stringify({ answers }));
  }, [extractAnswers, props]);

  /**
   * Returns the corrected state.
   * If an item has shouldBeInBox property it means
   * that it has been corrected.
   * @param itemId
   * @param boxId
   */
  const getCorrectedState = (renderItem: SortItemType, boxId: string) => {
    const answer = props.correctAnswers.inputs.find(
      (item) => item.itemID === renderItem.id
    );

    // Display correct answers
    if (props.displayCorrectAnswer && answer) {
      return renderItem.initialBox === UNSORTED_BOX_ID
        ? CorrectionBtnStates.CORRECT
        : CorrectionBtnStates.DEFAULT;
    }

    // User answers corrected
    return answer && answer.boxID === boxId
      ? renderItem.initialBox === UNSORTED_BOX_ID
        ? CorrectionBtnStates.CORRECT
        : CorrectionBtnStates.DEFAULT
      : CorrectionBtnStates.INCORRECT;
  };

  /**
   * Returns true or false depending on if the item is in the provided box.
   */
  const findItemInBox = (id: string, box: SortBoxType): boolean => {
    if (box.items) {
      const foundItem = box.items.find((item: SortItemListType) => {
        if (item && item.id) {
          return item.id === id;
        }
        return false;
      });
      return !!foundItem;
    }
    return false;
  };

  /**
   * Returns the box with provided id.
   */
  const getBoxById = React.useCallback((boxId: string, list: SortBoxType[]) => {
    return list.find((box) => box.id === boxId);
  }, []);

  /**
   * Returns the id for the top-left item in the "unsorted" area
   */
  const getNextUnsortedItem = React.useCallback(
    (list: SortBoxType[]): string | null => {
      const sortBox = getBoxById(UNSORTED_BOX_ID, list);
      if (
        sortBox &&
        sortBox.items &&
        hasAttemptsLeft(props.currentAttempt, 2)
      ) {
        const sortItem = sortBox.items.find((item) => item !== null);
        return sortItem ? sortItem.id : null;
      }
      return null;
    },
    [getBoxById, props.currentAttempt]
  );

  /**
   * Returns the box that contains the selected item.
   */
  const getSelectedItemsBox = React.useCallback(
    (list: SortBoxType[], itemId: string): SortBoxType | undefined => {
      return list.find((box) => findItemInBox(itemId, box));
    },
    []
  );

  /**
   * Returns the id of the box that contains the selected item.
   */
  const getSelectedItemsBoxId = React.useCallback(
    (list: SortBoxType[], itemId: string): string | undefined => {
      const box = getSelectedItemsBox(list, itemId);
      return box && box.id;
    },
    [getSelectedItemsBox]
  );

  /**
   * Removes selected item from the box-array its currently in and puts it in the destination box.
   * Empty space is represented by null.
   * @param selectedId
   * @param boxId
   */
  const updateBoxes = (
    clickedBoxId: string,
    clickedItemId: string
  ): SortBoxType[] => {
    const newBoxesList: SortBoxType[] = JSON.parse(
      JSON.stringify(state.boxesList)
    );
    const boxWithSelectedItem = getSelectedItemsBox(
      newBoxesList,
      clickedItemId
    );

    if (boxWithSelectedItem) {
      const indexOfSelectedItem = boxWithSelectedItem.items.findIndex(
        (item: SortItemListType) => {
          return item ? item.id === clickedItemId : false;
        }
      );

      const itemToMove = boxWithSelectedItem.items[indexOfSelectedItem];

      // Set old position to null (represents empty space)
      boxWithSelectedItem.items[indexOfSelectedItem] = null;
      const destinationBox = newBoxesList.find(
        (box) => box.id === clickedBoxId
      );

      if (destinationBox && destinationBox.items.includes(null) && itemToMove) {
        destinationBox.items = [...destinationBox.items];
        // Item should be placed at its original position if its moved back to unsortedbox.
        let emptySpace = destinationBox.items.findIndex(
          (item) => item === null
        );
        if (
          destinationBox.id === UNSORTED_BOX_ID &&
          itemToMove.initialIndex !== undefined &&
          destinationBox.items[itemToMove.initialIndex] === null
        ) {
          emptySpace = itemToMove.initialIndex;
        }

        emptySpace !== -1
          ? (destinationBox.items[emptySpace] = itemToMove)
          : destinationBox.items.push(itemToMove);

        return newBoxesList;
      }
    }
    return state.boxesList;
  };

  /**
   * Callback for sortable item click
   * @param itemId
   */
  const sortItemCallback = (itemId: string) => {
    if (hasAttemptsLeft(props.currentAttempt, 2)) {
      state.selectedItemId === itemId
        ? setState((prevState) => ({ ...prevState, selectedItemId: null }))
        : setState((prevState) => ({ ...prevState, selectedItemId: itemId }));
    }
  };

  /**
   * Callback for box click
   * @param boxId
   */
  const sortBoxCallback = (boxId: string) => {
    return state.selectedItemId !== null &&
      hasAttemptsLeft(props.currentAttempt, 2)
      ? runUpdate(boxId, state.selectedItemId)
      : null;
  };

  /**
   * Callback for drop
   * @param boxId
   * @param itemId
   */
  const onDropCallback = (boxId: string, itemId: string) =>
    runUpdate(boxId, itemId);

  /**
   * Handle click on unsorted items box
   */
  const onUnsortedBoxClick = () => {
    getCanAcceptItem(UNSORTED_BOX_ID) && sortBoxCallback(UNSORTED_BOX_ID);
  };

  /**
   * Checks if the box can accept another item
   * @param boxId
   */
  const getCanAcceptItem = (boxId: string) => {
    const destinationBox = getBoxById(boxId, state.boxesList);
    return destinationBox && destinationBox.items.includes(null) ? true : false;
  };

  /**
   * Runs when user moves an item
   */
  const runUpdate = (clickedBoxId: string, itemId: string) => {
    if (clickedBoxId !== getSelectedItemsBoxId(state.boxesList, itemId)) {
      const newBoxesList = updateBoxes(clickedBoxId, itemId);
      const nextItem = getNextUnsortedItem(newBoxesList);
      setState((prevState) => ({
        ...prevState,
        selectedItemId: nextItem,
        boxesList: newBoxesList,
        dirty: true,
      }));
    }
  };

  /**
   * Runs when a change has occured in boxesList
   * State becomes dirty
   */
  React.useEffect(() => {
    if (state.dirty) {
      publishSelectionState();
      setState((prevState) => ({ ...prevState, dirty: false }));
    }
  }, [state.dirty, publishSelectionState]);

  /**
   * Renders sortable item
   * @param item
   * @param boxId
   * @param index
   */
  const renderSortableItem = (
    item: SortItemListType,
    boxId: string,
    showPlaceholderOutlines: boolean,
    index?: number
  ): JSX.Element =>
    item ? (
      <SortItem
        key={item.id}
        id={item.id}
        label={item.label}
        image={item.image}
        assets={item.assets}
        chipsFormat={props.settings.chips}
        prefilled={item.initialBox !== UNSORTED_BOX_ID}
        state={
          item.id === state.selectedItemId &&
          hasAttemptsLeft(props.currentAttempt, 2)
            ? "SELECTED"
            : "DEFAULT"
        }
        callback={sortItemCallback}
        correctedState={
          props.currentAttempt === 2 && props.correctAnswers
            ? getCorrectedState(item, boxId)
            : "DEFAULT"
        }
        isDisabled={hasAttemptsLeft(props.currentAttempt, 2)}
      />
    ) : (
      <StyledSortItemPlaceholder
        chipsFormat={props.settings.chips}
        visible={showPlaceholderOutlines}
        key={`placeholder_${index}`}
      />
    );

  /**
   * Render unsorted items
   */
  const renderUnsortedItems = (
    list: SortBoxType[]
  ): JSX.Element[] | undefined => {
    const unsortedBox = getBoxById(UNSORTED_BOX_ID, list);

    return (
      unsortedBox &&
      unsortedBox.items &&
      unsortedBox.items.map((item: SortItemListType, index: number) =>
        renderSortableItem(item, unsortedBox.id, true, index)
      )
    );
  };

  /**
   * Render boxes
   */
  const renderBoxes = (list: SortBoxType[]): JSX.Element[] | null => {
    const sortBoxes = list.filter((box) => box.id !== UNSORTED_BOX_ID);

    return sortBoxes
      ? sortBoxes.map((box: SortBoxType) => (
          <SortBox
            key={box.id}
            id={box.id}
            label={box.label}
            value={box.value}
            canAcceptItem={getCanAcceptItem(box.id)}
            image={box.image}
            assets={box.assets}
            callback={sortBoxCallback}
            onDropCallback={onDropCallback}
            chipsFormat={props.settings.chips}
            textSize={props.settings.textSize}
          >
            {box.items &&
              box.items.map((item: SortItemListType, index: number) =>
                renderSortableItem(
                  item,
                  box.id,
                  props.settings.boxType === PARKING,
                  index
                )
              )}
          </SortBox>
        ))
      : null;
  };

  const renderSortExercise = (list: SortBoxType[]) => (
    <>
      <CustomDragLayer chipsFormat={props.settings.chips} />
      <StyledItemsRowWrapper>
        <UnsortedBox
          active={
            state.selectedItemId !== null &&
            getSelectedItemsBoxId(state.boxesList, state.selectedItemId) !==
              UNSORTED_BOX_ID
          }
          id={UNSORTED_BOX_ID}
          callback={onUnsortedBoxClick}
          chipsFormat={props.settings.chips}
          canAcceptItem={getCanAcceptItem(UNSORTED_BOX_ID)}
          onDropCallback={onDropCallback}
        >
          {renderUnsortedItems(list)}
        </UnsortedBox>
      </StyledItemsRowWrapper>
      <StyledSortBoxesRow>{renderBoxes(list)}</StyledSortBoxesRow>
    </>
  );

  /**
   * Build a new boxesList with every item in the correct place
   */
  const renderCorrectedExercise = () => {
    const correctedBoxesList = buildCorrectedList(
      state.boxesList,
      props.correctAnswers.inputs
    );

    return renderSortExercise(correctedBoxesList);
  };

  /**
   * Render component
   */
  return (
    <StyledSortWrapper>
      {props.displayCorrectAnswer
        ? renderCorrectedExercise()
        : renderSortExercise(state.boxesList)}
    </StyledSortWrapper>
  );
};
