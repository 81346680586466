import React from "react";
import {
  StyledLoader,
  StyledLoaderCenterContainer,
} from "../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../external/EddaXcomp/src/constants";
import { PrimaryButton } from "../../shared/components/Button";
import IntroductionView from "../../shared/components/IntroductionView";
import { NavBar } from "../../shared/components/NavBar";
import {
  StyledLoadingContainer,
  StyledParagraph,
} from "../dashboard/StyledDashboard";
import useFeedback from "./useFeedback";

const buttonStyles = {
  width: "237px",
  height: "50px",
  boxShadow: "none",
  animationDelay: "4s",
};

const ItemFeedback = () => {
  const [feedback, isLastSlide, lastSlideVisited, text, onSlideChange, route] =
    useFeedback();

  return (
    <>
      <NavBar position="fixed" />
      {feedback && (
        <IntroductionView slides={feedback.posts} onSlideChange={onSlideChange}>
          {(isLastSlide || lastSlideVisited) && (
            <PrimaryButton
              animation="pulse"
              label={text}
              overrideStyles={buttonStyles}
              onClick={route}
            />
          )}
        </IntroductionView>
      )}
      {!feedback && (
        <StyledLoadingContainer>
          <StyledParagraph>Laddar innehåll!</StyledParagraph>
          <StyledLoaderCenterContainer>
            <StyledLoader size={LARGE} />
          </StyledLoaderCenterContainer>
        </StyledLoadingContainer>
      )}
    </>
  );
};

export default ItemFeedback;
