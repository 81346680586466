import * as React from "react";
import { IFractionCell } from "./types";
import { FractionCell } from "./components/FractionCell";
import { FractionInput } from "./components/FractionInput";
import {
  getCorrectedState,
  getCorrectedStateList,
  renderEmptyRow,
  prepareGridData,
  addGridColumn,
  removeGridColumn,
  extractCellIndex,
  extractRowIndex,
  findHorizontalOffset,
  prepareCorrectGridData,
  mapFractionsInGrid,
  findFirstInteractiveCellId,
} from "./helpers";
import { hasAttemptsLeft } from "../../../../../Helpers";
import { StyledFractionGrid, StyledCellValue } from "./StyledFraction";
import {
  isTouchDevice,
  isMobileDevice,
} from "../../../../../../../shared/platform/Platform";

interface Props {
  grid: IFractionCell[][];
  settings: any;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  focusInput: boolean;
  correctAnswers: any;
  keyboardData?: string;
  state: string;
  callback(answers: string): void;
}

const CELL_PADDING = 1;

export const XcFraction: React.FC<Props> = (props) => {
  const isTouch = isTouchDevice();
  const isMobile = isMobileDevice();

  const [gridData, setGridData] = React.useState(function getInitialState() {
    return prepareGridData(props.grid);
  });

  const [correctGridData, setCorrectGridData] = React.useState<
    IFractionCell[][]
  >([]);

  const [idToCell, setIdToCell] = React.useState(function getInitialState() {
    const itc: Record<string, IFractionCell> = {};

    gridData.forEach((row) =>
      row.forEach((cell) => {
        if (cell.interactive) {
          itc[cell.id] = cell;
        }
      })
    );

    return itc;
  });

  const [focusTarget] = React.useState<string>(
    findFirstInteractiveCellId(gridData)
  );

  const [inputRefsMap] = React.useState(function getInitialState() {
    const irm: Record<string, React.RefObject<HTMLInputElement>> = {};

    gridData.forEach((row) =>
      row.forEach((cell) => {
        if (cell.interactive) {
          const ref = React.createRef<HTMLInputElement>();
          irm[cell.id] = ref;
        }
      })
    );

    return irm;
  });

  const [correctCellsList, setCorrectCellsList] = React.useState<string[]>([]);

  /**
   * Collect answer and run callback
   */
  const publishSelectionState = (answer: IFractionCell[][]): void => {
    props.callback(JSON.stringify({ answer }));
  };

  const getAssociatedFractionCell = (rowIndex: number, cellIndex: number) => {
    switch (rowIndex) {
      case 1:
        return gridData[rowIndex + 1][cellIndex].interactive
          ? gridData[2][cellIndex]
          : undefined;
      case 2:
        return gridData[rowIndex - 1][cellIndex].interactive
          ? gridData[1][cellIndex]
          : undefined;
      default:
        return undefined;
    }
  };

  const addColumnToGrid = (
    cellIndex: number,
    rowIndex: number,
    value: string
  ) => {
    const startIndex = value.length - 1 + cellIndex;

    if (startIndex > gridData[0].length - 1) {
      addGridColumn(gridData, startIndex);
    } else if (
      gridData[rowIndex][startIndex].isFillerColumn ||
      (gridData[rowIndex][startIndex].value === "" &&
        !gridData[rowIndex][startIndex].interactive &&
        !isNaN(+gridData[rowIndex + 1][startIndex].value))
    ) {
      // Do nothing
    } else {
      addGridColumn(gridData, startIndex);
    }
  };

  const removeColumnFromGrid = (
    cellIndex: number,
    rowIndex: number,
    value: string,
    cellWidth: number
  ) => {
    const removeIndex = value.length + cellIndex;
    const diff = cellWidth - value.length;

    if (
      gridData[rowIndex][removeIndex] &&
      !gridData[rowIndex][removeIndex].isFillerColumn &&
      diff <= 1
    ) {
      // Do nothing
    } else if (diff > 1) {
      const indexes = getColumnIndexesToRemove(
        cellIndex,
        rowIndex,
        cellWidth,
        diff
      );
      removeGridColumn(gridData, indexes);
    } else {
      removeGridColumn(gridData, [removeIndex]);
    }
  };

  const getColumnIndexesToRemove = (
    cellIndex: number,
    rowIndex: number,
    cellWidth: number,
    diff: number
  ) => {
    const indexes: number[] = [];
    let index = cellIndex + cellWidth - 1;

    for (let i = 0; i < diff; i++) {
      if (
        gridData[rowIndex][index].value === "" &&
        gridData[rowIndex][index].isFillerColumn
      ) {
        indexes.push(index);
      }
      index--;
    }
    return indexes;
  };

  /**
   * Updates the grid with user input.
   */
  const updateGridData = (id: string, value: string) => {
    const c = idToCell[id];
    const rowIndex = +extractRowIndex(c.id);
    const cellIndex = +extractCellIndex(c.id);

    // Add new columns or skip depending on griddata
    if (c.width && value.length > c.width) {
      addColumnToGrid(cellIndex, rowIndex, value);
    }

    const partner = getAssociatedFractionCell(rowIndex, cellIndex);
    const greater =
      partner &&
      value.length < partner.value.length &&
      partner.value.length !== 1
        ? true
        : false;

    // Remove columns, either multiple or a single column or skip depending on the griddata
    if (c.width && value.length <= c.width && !greater) {
      removeColumnFromGrid(cellIndex, rowIndex, value, c.width);
    }

    // Remove multiple columns but considers the length of the interactive cell above/below
    if (greater && partner && c.width) {
      removeColumnFromGrid(cellIndex, rowIndex, partner.value, c.width);
    }

    // Update cell data
    const newList = gridData.map((row, i) =>
      row.map((cell: IFractionCell, j) => {
        if (cell.id === id) {
          return {
            ...cell,
            value,
            width: value.length,
            valueLength: value.length === 0 ? 1 : value.length,
          };
        }
        return cell;
      })
    );

    // Update cell map
    const updatedIdToCell: Record<string, IFractionCell> = {};
    newList.forEach((row) =>
      row.forEach((cell: IFractionCell) => {
        if (cell.interactive) {
          updatedIdToCell[cell.id] = cell;
        }
      })
    );

    setIdToCell(updatedIdToCell);
    setGridData(newList);
    publishSelectionState(newList);
  };

  const inputCallback = (value: string, refIndex: number, id: string) => {
    updateGridData(id, value);
  };

  /**
   * Create input elements for interactive cells.
   */
  const createInputEl = (
    cell: IFractionCell,
    rowIndex: number,
    cellIndex: number
  ) => {
    const correctedState = props.displayCorrectAnswer
      ? { value: "CORRECT" }
      : getCorrectedState(
          cell,
          correctCellsList,
          props.currentAttempt,
          props.state
        );

    return (
      <FractionInput
        id={cell.id || ""}
        borderBottom={cell.borderBottom}
        refObj={inputRefsMap[cell.id]}
        callback={inputCallback}
        value={cell.value}
        key={cellIndex}
        isVertical={cell.isVertical}
        isHorizontal={
          props.displayCorrectAnswer
            ? findHorizontalOffset(cell, correctGridData)
            : findHorizontalOffset(cell, gridData)
        }
        isInteger={cell.isInteger}
        isDecimal={cell.isDecimal}
        isPercent={cell.isPercent}
        disabled={
          !hasAttemptsLeft(props.currentAttempt, 2) || props.state === "CORRECT"
        }
        correctedStateValue={correctedState.value}
        keyboardData={
          props.keyboardData ? JSON.parse(props.keyboardData) : undefined
        }
        renderCross={correctedState.value === "INCORRECT"}
        cellIndex={cellIndex}
        isTouchDevice={isTouch && isMobile}
        width={cell.width ? cell.width : 1}
      />
    );
  };

  const renderRow = (row: IFractionCell[], rowIndex: number) => {
    return row.map((cell: IFractionCell, cellIndex: number) => {
      return cell.interactive ? (
        createInputEl(cell, rowIndex, cellIndex)
      ) : (
        <FractionCell
          id={cell.id}
          borderBottom={cell.borderBottom}
          key={"key_" + cellIndex}
        >
          {
            <StyledCellValue
              isInteger={cell.isInteger}
              isVertical={cell.isVertical}
              isHorizontal={
                props.displayCorrectAnswer
                  ? findHorizontalOffset(cell, correctGridData)
                  : findHorizontalOffset(cell, gridData)
              }
            >
              {cell.value.replace(".", ",")}
            </StyledCellValue>
          }
        </FractionCell>
      );
    });
  };

  /**
   * Renders the grid. An empty cell is added to
   * the beginning and end of each row as padding.
   */
  const renderData = (grid: IFractionCell[][]) => {
    return grid.map((row, index) => {
      return (
        <React.Fragment key={"key_" + index}>
          <FractionCell borderBottom={false} />
          {renderRow(row, index)}
          <FractionCell borderBottom={false} />
        </React.Fragment>
      );
    });
  };

  React.useEffect(() => {
    if (props.currentAttempt === 2 && props.correctAnswers) {
      const prepData = prepareCorrectGridData(
        props.correctAnswers.correctAnswers
      );
      setCorrectGridData(prepData);
      const correctFractionsMap = mapFractionsInGrid(prepData);
      const answerFractionsMap = mapFractionsInGrid(gridData);
      setCorrectCellsList(
        getCorrectedStateList(
          answerFractionsMap,
          correctFractionsMap,
          props.correctAnswers.correctQuota,
          props.settings.multipleAnswers,
          props.settings.calculateType,
          props.settings.decimalCount
        )
      );
    }

    const ref = inputRefsMap[focusTarget];
    if (props.focusInput && ref && ref.current) {
      ref.current.focus();
    }

    // eslint-disable-next-line
  }, [
    props.displayCorrectAnswer,
    props.currentAttempt,
    focusTarget,
    inputRefsMap,
    props.focusInput,
  ]);

  return (
    <StyledFractionGrid
      rowLength={
        props.displayCorrectAnswer
          ? correctGridData[0].length + CELL_PADDING * 2
          : gridData[0].length + CELL_PADDING * 2
      }
    >
      {renderEmptyRow(
        props.displayCorrectAnswer
          ? correctGridData[0].length
          : gridData[0].length,
        CELL_PADDING
      )}
      {props.displayCorrectAnswer
        ? renderData(correctGridData)
        : renderData(gridData)}
      {renderEmptyRow(
        props.displayCorrectAnswer
          ? correctGridData[0].length
          : gridData[0].length,
        CELL_PADDING
      )}
    </StyledFractionGrid>
  );
};
