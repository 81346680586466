import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { GET_MARKERS_REQUEST } from "../../shared/constants";
import {
  selectMarkers,
  selectFetching,
  selectIsStoryRead,
} from "./api/selectors";
import { ADVENTURE_STORY_ROUTE, EXERCISE_ROUTE } from "../routes";
import { Marker, MarkerSubGroup } from "./api/types";
import { selectNextRoute } from "../../shared/store/router/selectors";
import { selectHasActiveAdventure } from "../adventurePicker/api/selectors";
interface MarkersHookProps {
  allMarkers: MarkerSubGroup[];
  adventureMarkers: MarkerSubGroup[];
  assignmentMarkers: Marker[];
  fetching: boolean;
  exerciseRoute: string;
  activeIndex: number;
  slideForward: () => void;
  slideBackward: () => void;
  storyRead: boolean;
  hasActiveAdventure: boolean;
}

export const useRequestMarkers = (): [MarkersHookProps] => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const initialIndexCalculated = useRef<boolean>(false);

  useEffect(() => {
    dispatch({ type: GET_MARKERS_REQUEST });
  }, [dispatch]);

  const allMarkers = useSelector(selectMarkers);
  const fetching = useSelector(selectFetching);
  const isStoryRead = useSelector(selectIsStoryRead);
  const nextRoute = useSelector(selectNextRoute);
  const hasActiveAdventure = useSelector(selectHasActiveAdventure);

  const getExeRoute = (currentStoryRead: boolean) =>
    !currentStoryRead ? ADVENTURE_STORY_ROUTE : EXERCISE_ROUTE;

  const exerciseRoute = getExeRoute(isStoryRead || false);

  const adventureMarkers =
    allMarkers &&
    allMarkers.filter((group: { type: string }) => group.type !== "assignment");

  if (!initialIndexCalculated.current && adventureMarkers.length > 0) {
    setActiveIndex(adventureMarkers.length - 1);
    initialIndexCalculated.current = true;
  }

  const assignmentMarkers =
    allMarkers &&
    allMarkers
      .filter((group) => group.type === "assignment")
      .reduce((res, grp) => [...res, ...grp.rewards], [] as Marker[]);

  const slideBackward = () => {
    if (activeIndex > 0) {
      setActiveIndex((oldIndex) => oldIndex - 1);
    }
  };

  const slideForward = () => {
    if (activeIndex < adventureMarkers.length - 1) {
      setActiveIndex((oldIndex) => oldIndex + 1);
    }
  };

  return [
    {
      allMarkers,
      adventureMarkers,
      assignmentMarkers,
      fetching,
      exerciseRoute: nextRoute ? nextRoute.route : exerciseRoute,
      activeIndex,
      slideForward,
      slideBackward,
      storyRead: !!isStoryRead,
      hasActiveAdventure,
    },
  ];
};

export const useFilterMarkers = (): [
  string,
  (param: React.MouseEvent<HTMLButtonElement>) => void
] => {
  const [selectedFilter, setSelectedFilter] = useState("all");

  const filter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedFilter((e.target as HTMLButtonElement).value);
  };

  return [selectedFilter, filter];
};
