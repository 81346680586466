import styled from 'styled-components';

export const StyledDeck = styled.div`
  position: relative;
  width: 90%;
  height: 100%;
  
  @media (max-width: 768px) {
    position: relative;
    min-height: 350px;
  }
  
  @media (min-aspect-ratio: 8/5) and (min-width: 1500px) {
    width: 70%;
  }
`;

export const StyledCardWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;