import { SagaService } from "../../services/saga";
import { watchGetMarkersSaga } from "./api/sagas";
import { ReducerService } from "../../services/reducer";
import { reducer } from "./api/reducer";

export * from "./MarkersView";

/**
 * Init markers view module
 */
export const init = () => {
  SagaService.set(watchGetMarkersSaga);
  ReducerService.set("markers", reducer);
};
