import axios, { AxiosResponse } from "axios";
import { AnswerData } from "./types";

/**
 * Fetches exercises from api and returns a Promise
 * @param token
 * @param productId
 * @param urlSuffix
 * @param urlParams
 */
export function fetchExercises(
  token: string,
  productId: number,
  urlSuffix: string = "",
  urlParams: string = ""
): Promise<AxiosResponse> {
  const suffix = urlParams ? `${urlSuffix}/${urlParams}` : urlSuffix;

  let url = `/api/product/${productId}/recommendations/${suffix.toLowerCase()}`;

  if (urlSuffix.toLowerCase() === "preview") {
    url = `/api/product/${productId}/preview?${urlParams
      .split(",")
      .map((s, i) => `${i > 0 ? "&" : ""}exerciseIds=${s}`)
      .join("")}`;
  }

  return axios({
    method: "get",
    url: url,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

/**
 * Intitate exercise answer in backend and returns answer id
 * @param token
 * @param productId
 * @param exerciseId
 * @param answerData
 */
export function initiateExerciseAnswer(
  token: string,
  productId: number,
  exerciseId: number,
  answerData: AnswerData
): Promise<AxiosResponse> {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `/api/product/${productId}/exercise/${exerciseId}/submit`,
    answerData,
    config
  );
}

/**
 * Submits exercise answer to backend
 * @param token
 * @param productId
 * @param exerciseId
 * @param answerId
 * @param answerData
 */
export function submitExerciseAnswer(
  token: string,
  productId: number,
  exerciseId: number,
  answerId: number,
  answerData: AnswerData
): Promise<AxiosResponse> {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(
    `/api/product/${productId}/exercise/${exerciseId}/submit/${answerId}`,
    answerData,
    config
  );
}

/**
 * Sends answer to check it is correct or wrong
 * @param {string} token
 * @param {number} productId
 * @param {number} exerciseId
 * @param {number} answerId
 * @param answer
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function correctExercise(
  token: string,
  productId: number,
  exerciseId: number,
  answerId: number,
  answer: any
): Promise<AxiosResponse> {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .post(
      `/api/product/${productId}/exercise/${exerciseId}/correction/${answerId}`,
      answer,
      config
    )
    .then((reqAnswer) => reqAnswer);
}

export function logInteractiveMetric(
  token: string,
  productId: number,
  interactionType: string
): Promise<AxiosResponse> {
  const data = {
    type: interactionType,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(
    `/api/product/${productId}/metric-interaction`,
    data,
    config
  );
}
