import { ChartCellType } from "../types";
import XcSimpleImage from "../../XcImage/XcSimpleImage";
import AutoLaTeX from "react-autolatex";
import { StyledChartCell, StyledTextValue } from "../StyledChart";
import { cellColumnSizes, cellRowSizes } from "../cellSizes";
import { XcImage } from "../..";

interface CellProps {
  cell: ChartCellType;
  showBorder: boolean;
  chartType: string;
  index: number;
}

export const ChartCell = ({
  cell,
  showBorder,
  chartType,
  index,
}: CellProps) => {
  const ImgCell = cell.assets ? (
    <XcImage icons={cell.assets.icons} />
  ) : (
    <XcSimpleImage Path={(cell.image && cell.image.src) || ""} />
  );

  return (
    <StyledChartCell
      index={index}
      chartType={chartType}
      minHeight={cellRowSizes[cell.row]}
      minWidth={cellColumnSizes[cell.column]}
      correctedState="DEFAULT"
      color={cell.color}
      showBorder={showBorder}
      borderBottom={cell.borderBottom}
      borderRight={cell.borderRight}
    >
      {cell.value ? (
        <StyledTextValue isTitle={cell.title}>
          <AutoLaTeX>{cell.value}</AutoLaTeX>
        </StyledTextValue>
      ) : (
        ImgCell
      )}
    </StyledChartCell>
  );
};
