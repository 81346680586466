import * as React from "react";

type Props = {
  children: React.ReactNode;
};

const MenuList = (props: Props) => {
  return <ul>{props.children}</ul>;
};

export { MenuList };
