import * as React from "react";
import {
  StyledColumnContent,
  StyledHelpHeading,
  StyledHelpColumn,
  StyledMathWordText,
  StyledHelpContent,
  StyledImageContainer,
  StyledMathWordImageContainer,
} from "../StyledHelp";
import { MathWord } from "../../../../views/exercise/api/types";
import SoundWrapper from "../../../containers/SoundWrapper";
import {
  XcImage,
  ImageProps,
} from "../../../../external/EddaXcomp/src/components/BlockLoader/ExerciseComponents";
import HtmlRenderer from "../../HtmlRenderer";

interface Props {
  mathwords: MathWord[];
}

interface CompProps {
  className: string;
}

export const MathWords: React.FC<Props> = ({ mathwords }) => {
  const renderSoundWrappedElement = (word: MathWord) => {
    if (word.soundsrc) {
      return (
        <SoundWrapper
          key={word.soundsrc}
          Component={
            <HtmlRenderer<CompProps>
              component={StyledMathWordText}
              html={word.text}
              className="soundContainer"
            />
          }
          url={word.soundsrc}
        />
      );
    }
    return <HtmlRenderer component={StyledMathWordText} html={word.text} />;
  };

  const renderHelpImage = (mediasrc: string) => (
    <StyledMathWordImageContainer>
      <XcImage image={{ src: mediasrc }} />
    </StyledMathWordImageContainer>
  );

  const renderHelpSVGImage = ({ icons, format }: ImageProps) => (
    <StyledImageContainer>
      <XcImage icons={icons} format={format} position={"relative"} />
    </StyledImageContainer>
  );

  const renderWord = (word: MathWord, index: number) => {
    const { assets, mediasrc, title } = word;

    return (
      <StyledHelpColumn
        key={"keynumber" + index}
        seperator={true}
        marginTop={"40px"}
      >
        <StyledColumnContent topAligned={true}>
          <StyledHelpHeading>{title}</StyledHelpHeading>
          {mediasrc
            ? renderHelpImage(mediasrc)
            : assets
            ? renderHelpSVGImage(assets)
            : null}
          {renderSoundWrappedElement(word)}
        </StyledColumnContent>
      </StyledHelpColumn>
    );
  };

  return (
    <StyledHelpContent>
      {mathwords && mathwords.map(renderWord)}
    </StyledHelpContent>
  );
};
