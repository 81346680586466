import * as React from 'react';

import {
  StyledMultiSvg,
  StyledSvgWrapper,
} from '../StyledXcImage';

import {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE
} from '../../../../../constants';
import { StyledLoader, StyledLoaderCenterContainer } from '../../../../CorrectionButton/StyledCorrectionButton';

interface ImageObject {
  src: string,
  attributes?: any
}

export interface SVGData {
  x: number;
  y: number;
  size: string;
  image: ImageObject;
}

export interface XcMultiSvgProps {
  icons: SVGData[],
  format?: string,
  width: number | string,
  height: number | string,
  position?: string
}

interface ImageScales {
  [key: string]: number;
}

interface ViewBoxes {
  [key: string]: string;
}

interface IconSizes {
  [key: string]: number;
}

export const XcMultiSvg: React.FC<XcMultiSvgProps> = ({icons, format, width, height, position}: XcMultiSvgProps): JSX.Element => {
  const defaultFormat: string = '16:9';
  const scales: ImageScales = {
    [EXTRA_SMALL]: 0.125,
    [SMALL]: 0.25,
    [MEDIUM]: 0.5,
    [LARGE]: 1
  };
  const [svgIsLoaded, setSvgIsLoaded] = React.useState<boolean>(false);
  // Counter used to show spinner until all parts of the svg is downloaded
  const loadCounter = React.useRef<number>(0);

  /**
   * Returns string with relevant viewbox size
   *
   * @param format
   */
  const getViewBox = (fmt: string): string => {
    const viewBoxes: ViewBoxes = {
      '16:9': '0 0 700 390',
      '3:1' : '0 0 900 300',
      '4:1': '0 0 1024 256',
      '2:1': '0 0 312 136',
      '1:1': '0 0 256 256'
    };
    return viewBoxes[fmt] || viewBoxes[defaultFormat];
  };

  const viewboxSize: string = getViewBox(format || defaultFormat);

  /**
   * Returns icon size in pixels
   *
   * @param fmt
   */
  const getIconSize = (fmt: string): number => {
    const iconSizes: IconSizes = {
      '16:9': 280,
      '3:1': 280,
      '4:1': 256,
      '2:1': 136,
      '1:1': 256
    };
    return iconSizes[fmt] || iconSizes[defaultFormat];
  };

  /**
   *  Takes img size and a scale factor (format). Returns actual size in pixels
   *
   * @param size
   * @param fmt
   */
  const getSize = (size: string, fmt: string): number => scales[size.toUpperCase()] * getIconSize(fmt);

  /**
   *  Callback for when image has finished loading
   */
  const onImageLoaded = () => {
    loadCounter.current = loadCounter.current + 1;
    if (loadCounter.current === icons.length) {
      setSvgIsLoaded(true);
    }
  }

  /**
   * Renders an SVG wrapper containing small SVGs with individual size and position
   *
   * @param x
   * @param y
   * @param size
   * @param image
   * @param format
   * @param index
   */
  const renderImg = ({x, y, size = SMALL, image}: SVGData, imageFormat: string, index: number): JSX.Element => {
    // 'onLoad' event does not trigger on <image> elements on ios 12 and 11
    // so we create an image object with the same src to be able to run a callback when
    // the image is done loading
    const img = new Image();
    img.onload = onImageLoaded;
    img.src = image && image.src;

      return (
        <image
          x={x}
          y={y}
          width={`${getSize(size, imageFormat)}px`}
          height={`${getSize(size, imageFormat)}px`}
          xlinkHref={image && image.src}
          key={'k' + index}
          name={index.toString()}
        />
  )};

  const renderLoader = () => (
    <StyledLoaderCenterContainer>
      <StyledLoader size={LARGE} />
    </StyledLoaderCenterContainer>
  );

  return (
    <StyledSvgWrapper className="svg-wrapper">
      {!svgIsLoaded && renderLoader()}
      <StyledMultiSvg
        preserveAspectRatio={'xMidYMid meet'}
        viewBox={viewboxSize}
        height={'200px'}
        width={width}
        position={position}
        finishedLoading={svgIsLoaded}
      >
        {icons.map((img: SVGData, i: number) => renderImg(img, format || defaultFormat, i))}
      </StyledMultiSvg>
    </StyledSvgWrapper>
  );
};
