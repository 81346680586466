import * as React from "react";
import {
  StyledErrorBoundary,
  StyledErrorContent,
  StyledErrorColumn,
  StyledErrorHeading,
  StyledErrorText,
  StyledErrorTextContainer,
  StyledErrorButtonContainer,
  StyledCharacterImage,
  StyledAudioButtonContainer,
  StyledMessageContainer,
} from "./StyledErrorPage";
import { PrimaryButton } from "../../shared/components/Button";
import { ReactComponent as ReloadIcon } from "../../assets/icons/icon_again.svg";
import AudioButton from "../../shared/components/Audio/AudioButton/AudioButton";
import { useAudio, UseAudioReturn } from "../../shared/effects/Effects";
import { reloadPage } from "../../shared/containers/ErrorBoundary";

interface ErrorPageProps {
  backgroundImage: string;
  sound: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  const url = props.sound;

  const { playing, toggle }: UseAudioReturn = useAudio({
    url,
  });

  const buttonStyles = {
    padding: "25px 40px",
    borderRadius: "70px",
    fontSize: "2.5em",
    animationDelay: "4s",
  };

  return (
    <StyledErrorBoundary backgroundImage={props.backgroundImage}>
      <StyledErrorContent>
        <StyledErrorColumn>
          <StyledErrorTextContainer>
            <StyledErrorHeading>Hoppsan!</StyledErrorHeading>
            <StyledMessageContainer>
              <StyledAudioButtonContainer>
                <AudioButton
                  isPlaying={playing}
                  callback={toggle}
                  aria-label={playing ? "Pausa" : "Lyssna"}
                />
              </StyledAudioButtonContainer>
              <StyledErrorText>
                Något blev fel. Tryck på knappen för att försöka igen.
              </StyledErrorText>
            </StyledMessageContainer>
          </StyledErrorTextContainer>
          <StyledErrorButtonContainer>
            <PrimaryButton
              overrideStyles={buttonStyles}
              labelFirst
              label="Ladda om"
              icon={<ReloadIcon color="#FFFFFF" />}
              onClick={reloadPage}
              animation="pulse"
            />
          </StyledErrorButtonContainer>
        </StyledErrorColumn>
        <StyledErrorColumn>
          <StyledCharacterImage
            src={
              "https://media.studentlitteratur.se/image/mr/story/images/_characters/Pim_ledsen_2.svg"
            }
          />
        </StyledErrorColumn>
      </StyledErrorContent>
    </StyledErrorBoundary>
  );
};

export default ErrorPage;
