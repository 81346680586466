import * as React from "react";
import {
  PLAYING_COLOR,
  STOPPED_COLOR,
  StyledAudioButton,
} from "./StyledXcAudio";
import { ReactComponent as SoundIcon } from "../../../../assets/icons/icon_speaker.svg";

interface Props {
  callback: any;
  audio?: any;
  player?: any;
  active?: boolean;
  children?: any;
}

const noop = () => {
  /* intentionally left empty */
};

const XcAudioSL = ({
  audio,
  player,
  callback = noop,
  children = null,
  active = false,
}: Props) => {
  const getColor = () => (active ? PLAYING_COLOR : STOPPED_COLOR);

  return (
    <div>
      <StyledAudioButton
        active={active}
        name="audio"
        type="button"
        onClick={callback}
        aria-label={!active ? "Lyssna" : "Pausa"}
      >
        <SoundIcon color={getColor()} />
      </StyledAudioButton>
      <p
        style={{
          textAlign: "center",
          paddingTop: ".4rem",
          paddingBottom: "1rem",
          color: "#277575",
        }}
      >
        Lyssna
      </p>
    </div>
  );
};

export default XcAudioSL;
