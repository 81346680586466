import { List } from "immutable";
import { all, fork } from "typed-redux-saga";

let sagas = List();

/**
 * Helper for seamlessly add a sagas to the application
 *
 * @export
 * @class SagaService
 */
export class SagaService {
  public static set(saga: () => Generator<any, void, { cancel: any }>) {
    sagas = sagas.push(fork(saga));
  }

  public static root(): () => Iterator<any> {
    return function* () {
      return yield* all(sagas.toArray());
    };
  }
}
