import styled from 'styled-components';

export const StyledHelpButtonRack = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  font-family: 'Muli';
  color: #277575;
  `;
  
export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  &:last-child { margin: 0; }
`;

export const StyledButtonText = styled.span`
  font-size: 16px;
  line-height: 28px;
`;