// Primary
export const themeDarker = "#004578";
export const themeDark = "#005a9e";
export const themeDarkAlt = "#106ebe";
export const themePrimary = "#277575";
export const themeSecondary = "#8AB4B3";
export const themeTertiary = "#08B0B4";
export const themeLight = "#D6E2E1";
export const themeLighter = "#deecf9";
export const themeLighterAlt = "#eff6fc";
export const themeLightActive = "#eff680"

// Neutral
export const black = "#000000";
export const neutralDark = "#212121";
export const neutralPrimary = "#333333";
export const neutralPrimaryAlt = "#3c3c3c";
export const neutralSecondary = "#666666";
export const neutralSecondaryAlt = "#767676";
export const neutralTertiary = "#a6a6a6";
export const neutralTertiaryAlt = "#c8c8c8";
export const neutralQuaternary = "#d0d0d0";
export const neutralQuaternaryAlt = "#dadada";
export const neutralLight = "#eaeaea";
export const neutralLighter = "#f4f4f4";
export const neutralLighterAlt = "#f8f8f8";
export const white = "#ffffff";
