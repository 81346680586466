import styled, { css, keyframes } from "styled-components";
import { ReactComponent as Arrow } from "../../../assets/icons/icon_arrow_slim.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/icon_lock.svg";

export const StyledCarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
`;

export const StyledTimeline = styled.div`
  width: 94%;
  box-shadow: 0 0 3px 3px rgb(146 192 199 / 50%),
    inset 0 0 3px rgb(146 192 199 / 50%);
  height: 5px;
  background-color: white;
`;

export const StyledLockedStory = styled.div`
  width: 150px;
  height: 150px;
  box-shadow: 0 0 50px 12px rgb(146 192 199 / 50%),
    inset 0 0 12px rgb(146 192 199 / 50%);
  border-radius: 50%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  .cls-1 {
    fill-opacity: 0.9;
  }
`;

export const StyledTimelineBracket = styled.div`
  height: 15px;
  width: 5px;
  background-color: white;
  box-shadow: 0 0 3px 3px rgb(146 192 199 / 50%),
    inset 0 0 3px rgb(146 192 199 / 50%);
`;

export const StyledDetailsWrapper = styled.div<{
  viewsize: "tablet" | "desktop";
}>`
  color: white;
  height: 60%;
  margin: 5em;
  overflow: auto;
  display: flex;
  flex-direction: column;

  > div {
    background-color: rgba(20, 34, 40, 0.8);
    padding: 20px;
  }

  ${props =>
    props.viewsize === "desktop" &&
    `
    > div {
      -webkit-column-count: 2;
      -moz-column-count: 2;
           column-count: 2;

   -webkit-column-gap: 20px;
      -moz-column-gap: 20px;
           column-gap: 20px;
    }
    `}
`;

export const StyledCarouselWrapper = styled.div`
  width: 80%;
`;

export const StyledAdventureImage = styled.img`
  width: 150px;
  height: 150px;
  box-shadow: 0 0 50px 12px rgb(146 192 199 / 50%),
    inset 0 0 12px rgb(146 192 199 / 50%);
  border-radius: 50%;
`;

export const StyledButtonContainer = styled.div<{ selected: boolean }>`
  height: 205px;
  width: 205px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    border: 4px solid #ffffff;
    border-radius: 50%;
  }
  ${props =>
    props.selected &&
    `
    border: 5px solid #ffffff;

  border-radius: 50%;
  box-shadow: 0 0 27px 4px rgba(94, 139, 238, 0.6), inset 0 0 10px 10px rgba(94, 139, 238, 1)
  `}
`;

export const StyledButtonWrapper = styled.div`
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
  align-items: center;
`;

const pulse = keyframes`{
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(142, 240, 239, 0.38);
      box-shadow: 0 0 0 0 rgba(142, 240, 239, 0.38);
    }
    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204,169,44, 0);
        box-shadow: 0 0 0 20px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
    }`;

const colorAnimation = keyframes`{
  50% {
    color: rgb(22, 37, 44, 0.3);
  }
}`;

const hasActiveAdventuresAnimation = css`
  animation: ${colorAnimation} 2s infinite;
`;

export const StyledImageActiveBox = styled.button`
  border: 5px solid #a0d6d4;
  border-radius: 50%;
  background-color: transparent;
  height: 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 rgba(142, 240, 239, 0.38);
  animation: ${pulse} 2s infinite;
  cursor: pointer;
  :hover {
    border-color: #b9f5f2;
  }
  :focus {
    outline: none;
    box-shadow: none;
  }
  & > svg {
    width: 150px;
    height: 150px;
    box-shadow: 0 0 50px 12px rgb(146 192 199 / 50%),
      inset 0 0 12px rgb(146 192 199 / 50%);
    border-radius: 50%;
  }
`;

export const StyledButtonTitle = styled.span`
  font-size: 20px;
  font-weight: 900;
  padding: 0.5em;
  text-align: center;
  letter-spacing: -1px;
  color: white;
  text-transform: uppercase;
`;

export const StyledGoNextArrow = styled(Arrow)`
  height: 32px;
  transform: rotate(90deg);
  width: 32px;
`;

export const StyledGoNextContainer = styled.button<{
  direction: "left" | "right";
  disabled: boolean;
  hasActiveAdventures: boolean;
}>`
  background-color: ${props =>
    props.hasActiveAdventures ? "white" : "transparent"};
  ${props => props.disabled && "visibility: hidden"};
  width: 90px;
  height: 40px;
  cursor: pointer;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: ${props =>
    props.hasActiveAdventures
      ? "3px solid rgb(95, 127, 132)"
      : "3px solid rgb(146, 192, 199)"};
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
    box-shadow: none;
  }
  :hover {
    border-color: #b9f5f2;
    ${props => !props.hasActiveAdventures && "color: #b9f5f2;"}
  }
  transform: rotate(
    ${props => (props.direction === "left" ? "90deg" : "-90deg")}
  );
  color: ${props =>
    props.hasActiveAdventures ? "rgb(22, 37, 44)" : "rgb(146, 192, 199)"};

  ${props => props.hasActiveAdventures && hasActiveAdventuresAnimation}
`;

export const StyledImageNotActiveBox = styled.div`
  border: 5px solid #a0d6d4;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 150px;
    height: 150px;
    box-shadow: 0 0 50px 12px rgb(146 192 199 / 50%),
      inset 0 0 12px rgb(146 192 199 / 50%);
    border-radius: 50%;
  }
`;

export const StyledLock = styled(LockIcon)`
  color: #96ffff;
`;
