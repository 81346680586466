import * as colors from "../shared/colors";
import * as font from "../shared/font";
import 'katex/dist/katex.min.css';

export const global = `
  body,
  html {
    height: 100%;
    width: 100%;
  }
  html {
    position: relative;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    text-rendering: optimizeLegibility;
  }
  body {
    background-color: #fff;
    min-height: 100%;
    margin: 0;
    padding: 0;
    color: ${colors.neutralPrimary};
    font-family: ${font.fontFamily};
    font-size: ${font.fontSizeDefault};
    font-style: normal;
    font-weight: 400;
  }
}`;
