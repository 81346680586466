import {
  StyledMemoryValue,
  StyledIconContainer,
  StyledIconButton,
  StyledComma,
} from "./StyledLineup";
import { ReactComponent as Cross } from "../../../../assets/icons/icon_x.svg";
import { ReactComponent as Comma } from "../../../../assets/icons/icon_comma.svg";
import { LineupCell } from "./components/LineupCell";
import { Motion, spring } from "@serprex/react-motion";
import * as React from "react";
import { LineUpCell } from "./types";

/**
 * Set focus on the next input field in the order
 * @param index
 * @param inputRefs
 */
export const focusNextInput = (
  index: number,
  inputRefs: Array<React.RefObject<HTMLInputElement>>
) => {
  const currentInput = inputRefs[index].current;
  const nextInput = inputRefs[index - 1];

  if (nextInput && nextInput.current) {
    nextInput.current.focus();
  } else if (currentInput) {
    currentInput.blur();
  }
};

export const focusNextInputFromMap = (
  id: string,
  inputTargetMap: Record<string, string>,
  inputRefsMap: Record<string, React.RefObject<HTMLInputElement>>
) => {
  const targetCell = inputTargetMap[id];
  const targetRef = inputRefsMap[targetCell];
  const currentRef = inputRefsMap[id];

  if (targetRef && targetRef.current) {
    targetRef.current.focus();
  }

  if (currentRef && currentRef.current) {
    currentRef.current.blur();
  }
};

/**
 * Checks if the value is an integer
 * @param value
 */
export const isInteger = (value: string) =>
  /^[0-9]+$/.test(value) && !isNaN(parseInt(value, 10));

/**
 * Checks the state of the cell if its is either correct or incorrect
 * @param value
 * @param row
 * @param cell
 * @param correctAnswers
 */
export const getCellState = (
  value: string,
  comma: boolean | undefined,
  row: number,
  cell: number,
  correctAnswers: any
) => {
  const valueIsCorrect = value === correctAnswers[row][cell].value;
  const commaIsCorrect =
    correctAnswers[row][cell].comma || comma
      ? comma === correctAnswers[row][cell].comma
      : true;

  return {
    value: valueIsCorrect ? "CORRECT" : "INCORRECT",
    comma: commaIsCorrect ? "CORRECT" : "INCORRECT",
  };
};

export const getCorrectedState = (
  value: string,
  comma: boolean | undefined,
  row: number,
  cell: number,
  correctAnswers: any,
  currentAttempt: number
) =>
  correctAnswers !== undefined && currentAttempt === 2
    ? getCellState(value, comma, row, cell, correctAnswers.correctAnswers)
    : { value: "DEFAULT", comma: "DEFAULT" };

/**
 * Renders an empty row
 * @param gridLength
 * @param cellPadding
 */
export const renderEmptyRow = (gridLength: number, cellPadding: number) => {
  const cells = [];

  for (let i = 0; i < gridLength + cellPadding * 2; i++) {
    cells.push(<LineupCell borderBottom={false} key={"paddingRow" + i} />);
  }
  return <>{cells}</>;
};

export const updateCommaInGrid = (
  id: string,
  gridData: LineUpCell[][],
  commaSelection: string
) =>
  gridData.map((row) =>
    row.map((cell) => {
      if (cell.id === id) {
        return { ...cell, comma: !cell.comma };
      }
      if (cell.id === commaSelection) {
        return { ...cell, comma: false };
      }
      return cell;
    })
  );

export const renderComma = (
  disabled: boolean,
  comma: boolean | undefined,
  isAnswerInput: boolean,
  correctedState: string | undefined,
  isCommaSelected: boolean | undefined,
  callback: () => void
) => {
  const iconComma = (
    <StyledIconButton
      skin="primary"
      onClick={callback}
      correctedState={correctedState}
      comma={comma}
      disabled={disabled}
    >
      <Comma height="15px" width="15px" />
    </StyledIconButton>
  );

  if (isAnswerInput) {
    if (correctedState === "INCORRECT" && !isCommaSelected) {
      return iconComma;
    }

    if (disabled && comma) {
      return iconComma;
    }

    if (!disabled && correctedState === "DEFAULT") {
      return iconComma;
    }
  }
};

export const renderStaticComma = (comma: boolean | undefined) =>
  comma ? <StyledComma /> : null;

export const renderCross = (
  correctedState: string | undefined,
  inMemory: boolean | undefined
) =>
  correctedState === "INCORRECT" ? (
    <StyledIconContainer
      topPosition={true}
      inMemory={inMemory}
      correctedState={correctedState}
    >
      <Cross color="#EC4C3F" height="8px" width="8px" />
    </StyledIconContainer>
  ) : null;

export const extractRowIndex = (cellId: string) => {
  return cellId
    ? cellId.substring(cellId.lastIndexOf("[") + 1, cellId.lastIndexOf(","))
    : "";
};

export const extractCellIndex = (cellId: string) => {
  return cellId
    ? cellId.substring(cellId.lastIndexOf(",") + 1, cellId.lastIndexOf("]"))
    : "";
};

/**
 * Setup grid data && find the cells that does contain a comma & hide comma
 * @param grid
 */
export const prepareGridData = (grid: LineUpCell[][]) => {
  const data: LineUpCell[][] = grid;
  data.forEach((row) =>
    row.forEach((cell) => {
      if (cell.comma) {
        cell.comma = !cell.interactive;
      }
    })
  );
  return data;
};

/**
 * Render animated "minnessiffra"
 */
export const renderMemoryCell = (
  hidden: boolean = false,
  value: string,
  displayCorrectAnswer: boolean
) =>
  !hidden || displayCorrectAnswer ? (
    <Motion
      defaultStyle={{ scale: 0 }}
      style={{ scale: spring(1, { stiffness: 1000, damping: 14 }) }}
    >
      {(style: any) => (
        <StyledMemoryValue scale={style.scale}>{value}</StyledMemoryValue>
      )}
    </Motion>
  ) : null;

/**
 * Decides what color the border should have depending on current state
 * @param state
 */
export const getBorderColor = (state: string) => {
  switch (state) {
    case "CORRECT":
      return "2px solid #1FC84C";

    case "INCORRECT":
      return "2px solid #EC4C3F";

    case "DEFAULT":
      return "2px solid #b8cece";
  }
};
