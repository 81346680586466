import {NumberLineStep} from "./types";

/**
 * Finds start position
 * @param {NumberLineStep} step
 * @returns {boolean}
 */
export const findStartPosition = (step:NumberLineStep): boolean =>  step.isStartingPoint === true;

/**
 * Finds end position of jumps
 * @param {NumberLineStep} step
 * @returns {boolean}
 */
export const findJumpStart = (step:NumberLineStep): boolean => step.isJumpEnd === true;
