import styled from "styled-components";
import { SnackBarMessage } from "./api/types";
import { ReactComponent as CheckMarkIcon } from "../../../assets/icons/check_mark.svg";
import { RoundButton } from "../Button/RoundButton";

export const StyledSnackbarContainer = styled.div<{
  index: number;
  severity: SnackBarMessage["severity"];
}>`
  height: 40px;
  background-color: ${(props) => getSnackbarColor(props.severity, props.index)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: white;
`;

export const StyledRoundButton = styled(RoundButton)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const StyledCheckMark = styled(CheckMarkIcon)<{ render_index: number }>`
  color: ${(props) => getSnackbarColor("success", props.render_index)};
  margin-left: 1em;
  margin-right: 1.5em;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSnackbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 700;
  padding: 5px;
`;

export const StyledSnackbarWrapper = styled.div`
  width: 500px;
  left: 30%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1000;

  .fade-enter {
    max-height: 0;
    opacity: 0;
  }

  .fade-enter-active {
    max-height: 40px;
    opacity: 1;
    transition: all 500ms;
  }

  .fade-exit {
    max-height: 40px;
    opacity: 1;
  }

  .fade-exit-active {
    max-height: 0;
    opacity: 0;
    transition: all 500ms;
  }
`;

const getSnackbarColor = (
  severity: SnackBarMessage["severity"],
  index: number
) => {
  switch (severity) {
    case "error":
      return "red";
    case "warning":
      return "yellow";
    case "success":
      return index % 2 === 0 ? "#29726b" : "#46a199";
  }
};
