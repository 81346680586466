import * as React from 'react';
import {
  StyledSortItem,
  StyledSortItemLabel,
  StyledSortItemImageLabel,
  StyleldSortIconContainer
} from './StyledSortItem';
import { ReactComponent as WrongIcon } from "../../assets/icons/icon_x.svg";
import { ReactComponent as CorrectIcon } from "../../assets/icons/icon_correct.svg";
import { ReactComponent as TryAgainIcon } from "../../assets/icons/icon_pen.svg";
import XcImage from '../BlockLoader/ExerciseComponents/XcImage';
import AutoLaTeX from 'react-autolatex';

interface Props {
  chipsFormat: string;
  image?: {
    src: string
  },
  assets?: {
    icons: any,
    format: any
  }
  label?: string;
  state: string;
  correctedState?: string;
  isDragging: boolean;
  prefilled?: boolean;
}

export const Chip: React.FC<Props> = (props) => {

  const renderLabel = () => {
    if (props.image) {
      return <StyledSortItemImageLabel src={props.image.src} />
    }
    else if (props.assets) {
      return <XcImage icons={props.assets.icons} format={props.assets.format} />
    }
    else {
      return (
        <StyledSortItemLabel chipsFormat={props.chipsFormat}>
          <AutoLaTeX>{props.label}</AutoLaTeX>
        </StyledSortItemLabel>
      )
    }
  }

  const getIcon = () => {
    if (props.correctedState === 'CORRECT') {
      return renderCorrectIcon('#1FC84C', <CorrectIcon color={'#1FC84C'} />);
    }
    if (props.correctedState === 'INCORRECT') {
      return renderCorrectIcon('#EC4C3F', <WrongIcon color={'#EC4C3F'} />);
    }
    if (props.correctedState === 'TRY_AGAIN') {
      return renderCorrectIcon('#FDAF2D', <TryAgainIcon color={'#FDAF2D'} />);
    }
    return null;
  };

  const renderIcon = () => (props.correctedState
    ? getIcon()
    : null
  );

  const renderCorrectIcon = (color: string, icon: any) => (
    <StyleldSortIconContainer color={color}>
      {icon}
    </StyleldSortIconContainer>
  );

  return (
    <>
      <StyledSortItem
        isDragging={props.isDragging}
        state={props.state}
        chipsFormat={props.chipsFormat}
        correctedState={props.correctedState}
        prefilled={props.prefilled}
      >
        {renderLabel()}
      </StyledSortItem>
      {renderIcon()}
    </>
  )
}