import { takeLatest, put, select, delay } from "typed-redux-saga";
import { removeSplash } from "./actions";
import { RootState } from "../../../store";
import {
  SPLASH_MODAL_VISIBILITY,
  APPLICATION_READY,
} from "../../../shared/constants";

const getModalStatus = (state: RootState) => state.splash.modalOpen;
const getHasAccess = (state: RootState) => state.access.access.has_access;
const getApplicationReady = (state: RootState) => state.application.ready;

function* removeSplashSaga() {
  const modalStatus: boolean = yield* select(getModalStatus);
  const hasAccess: boolean | undefined = yield* select(getHasAccess);
  const applicationReady: boolean = yield* select(getApplicationReady);

  if (!modalStatus && hasAccess && applicationReady) {
    yield* put(removeSplash());
  }
}

export function* splashDelaySaga() {
  yield* delay(3000);
}

export function* watchModalClose() {
  yield* takeLatest(SPLASH_MODAL_VISIBILITY, removeSplashSaga);
}

export function* splashSaga() {
  yield* takeLatest(APPLICATION_READY, removeSplashSaga);
}
