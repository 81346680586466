import * as React from "react";
import { StyledDeck, StyledCardWrapper } from "./StyledDeck";
import { TransitionMotion, spring } from "@serprex/react-motion";
import Card from "../Card";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { Deck as DeckInterface } from "../../../views/exercise/api/types";
import { StyledExeContentContainer } from "../../../views/exercise/StyledExercise";

export interface CardData {
  right: boolean;
  left: boolean;
  top: boolean;
  pos: number;
}

export interface ReduxProps {
  animationSettings?: any;
  responsibilityId: string;
}

export interface Props extends ReduxProps {
  deck: DeckInterface;
  children: React.ReactNode;
}

export class DeckComponent extends React.PureComponent<Props> {
  private animationSettings: any;

  constructor(props: Props) {
    super(props);
    this.animationSettings = this.props.animationSettings || {
      stifness: 300,
      damping: 30,
    };
  }

  public render() {
    return this.props.deck.cards ? (
      <StyledExeContentContainer>
        <TransitionMotion
          willEnter={this.willEnter}
          willLeave={this.willLeave}
          styles={this.getStyles()}
        >
          {(interpolated) => {
            return (
              <StyledDeck>
                {interpolated.map(({ style, key, data }, index) => {
                  return (
                    <StyledCardWrapper
                      key={key}
                      style={{
                        zIndex: style.z,
                        opacity: style.opacity,
                        transform: `translateX(${style.x}%) scale(${style.scale})`,
                      }}
                    >
                      {data.component}
                    </StyledCardWrapper>
                  );
                })}
              </StyledDeck>
            );
          }}
        </TransitionMotion>
      </StyledExeContentContainer>
    ) : null;
  }

  private willEnter = () => ({
    opacity: 0,
    x: 16,
    scale: 0.8,
    z: 1,
  });

  private willLeave = () => ({
    opacity: 1,
    x: spring(-16, this.animationSettings),
    scale: 0.8,
    z: 1,
  });

  private getStyleForCard = (card: any) => {
    if (card.right) {
      if (card.pos === 1) {
        return {
          // Most right
          opacity: 1,
          x: spring(16, this.animationSettings),
          scale: 0.8,
          z: 1,
        };
      } else if (card.pos === 2) {
        return {
          // First right
          opacity: 1,
          x: spring(8, this.animationSettings),
          scale: spring(0.9, this.animationSettings),
          z: 2,
        };
      }
    } else if (card.left) {
      if (card.pos === 2) {
        return {
          // First left
          opacity: 1,
          x: spring(-8, this.animationSettings),
          scale: spring(0.9, this.animationSettings),
          z: 2,
        };
      } else if (card.pos === 1) {
        return {
          // Most left
          opacity: 1,
          x: spring(-16, this.animationSettings),
          scale: spring(0.8, this.animationSettings),
          z: 1,
        };
      } else if (card.pos === 0) {
        return {
          // Out
          opacity: spring(0),
          x: -16,
          scale: 0.8,
          z: 0,
        };
      }
    } else {
      return {
        // Front card
        opacity: 1,
        x: spring(0, this.animationSettings),
        scale: spring(1, this.animationSettings),
        z: 3,
      };
    }
    console.log("???");
    return {
      // ??
      opacity: 1,
      x: spring(0, this.animationSettings),
      scale: spring(1, this.animationSettings),
      z: 3,
    };
  };

  private getStyles = () => {
    const { children, deck } = this.props;

    return deck.cards.map((card: any, i: any) => ({
      key: i.toString(),
      data: {
        component: <Card>{card.top ? children : null}</Card>,
      },
      style: this.getStyleForCard(card),
    }));
  };
}

const mapStateToProps = (state: RootState, ownProps: ReduxProps) => ({
  deck: state.exercise.decks[ownProps.responsibilityId],
});

const mapDispatchToProps = () => ({});

export const Deck = connect(mapStateToProps, mapDispatchToProps)(DeckComponent);
