import styled, { css } from 'styled-components';
import {CorrectionBtnStates} from "./CorrectionButton";

interface StyleCorrBtnProps extends BtnState {
  backgroundColor: string;
  hover: boolean;
}

interface BtnState {
  state: string;
}

export const StyledCorrBtn: any = styled.div`
  min-width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
 

  -webkit-transition: background 200ms;
  transition: background 200ms;
  
  ${(props: StyleCorrBtnProps) => {
    if (props.state === CorrectionBtnStates.CORRECT || props.state === CorrectionBtnStates.INCORRECT || props.state === CorrectionBtnStates.TRY_AGAIN) {
      return css`padding: 0 20px;`
    } else {
      return;
    }
  }};
  background: ${(props:StyleCorrBtnProps) => props.backgroundColor };
`;

export const StyledBtnState = styled.div`
  display:flex;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  font-weight: 900;
  
  -webkit-transition: left 200ms;
  transition: left 200ms;
  
  ${(props: BtnState) => {
  if (props.state === CorrectionBtnStates.CORRECT || props.state === CorrectionBtnStates.INCORRECT || props.state === CorrectionBtnStates.TRY_AGAIN) {
    return css`padding-right: 10px;`
    } else {
      return;
    }
  }};
`;
export const StyledContinueState = styled.div`
  display:flex;
  display: flex;
  align-items: center;
  justify-content: center;


`;

export const StyledBtnLabel = styled.span`
  color: #fff;
`;