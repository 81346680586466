/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffleArray = (a: any[]) => {
  const arr = [...a];

  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

export const hasAttemptsLeft = (
  currentAttempt: number,
  allowedAttempts: number
) => currentAttempt < allowedAttempts;
