import * as React from "react";
import {
  StyledCampaignLinkContainer,
  StyledInfoText,
  StyledSmallText,
  StyledSmallSubHeading,
  StyledLinksContainer,
  StyledLink,
  StyledLinkText,
  StyledCampaignImage,
  StyledCampaignLinkWide,
  StyledHeading,
} from "./StyledFreeTomoyo";

export const FreeTomoyoEnd: React.FC = () => (
  <>
    <StyledHeading>TACK FÖR ATT DU TRÄNAT MATTE MED OSS!</StyledHeading>
    <StyledInfoText>
      Under vårterminen har vi gett fri tillgång till Tomoyo för att underlätta
      undervisningen i dessa tider. Vi har fått väldigt fint gensvar och vi vill
      tacka alla er som valt att använda vår nya färdighetsträning i matematik.
    </StyledInfoText>
    <StyledInfoText>
      Sista dagen att räkna fritt var den 20 juni.
    </StyledInfoText>
    <StyledInfoText fontWeight={"bold"}>
      Är du intresserad av att fortsätta med Tomoyo i höst?
    </StyledInfoText>
    <StyledInfoText>
      Tomoyo fortsätter att vara tillgängligt för dig som har Favorit matematik
      för åk 1–3. Du kommer åt produkterna som vanligt genom Min Bokhylla.
    </StyledInfoText>

    <StyledCampaignLinkContainer justifyContent={"center"}>
      <StyledCampaignLinkWide>
        <a href={"https://www.studentlitteratur.se/tomoyo"}>
          <StyledLinkText>LÄS MER OM TOMOYO</StyledLinkText>
        </a>
      </StyledCampaignLinkWide>
      <StyledCampaignLinkWide>
        <a
          href={
            "https://www.studentlitteratur.se/serier/favorit-matematik/favorit-matematik-1-3/"
          }
        >
          <StyledLinkText>LÄS MER OM FAVORIT MATEMATIK 1-3</StyledLinkText>
        </a>
      </StyledCampaignLinkWide>
      <StyledCampaignLinkWide>
        <a href={"https://minbokhylla.studentlitteratur.se/"}>
          <StyledLinkText>ÖPPNA MIN BOKHYLLA</StyledLinkText>
        </a>
      </StyledCampaignLinkWide>
    </StyledCampaignLinkContainer>

    <StyledCampaignImage
      src="https://media.studentlitteratur.se/image/mr/story/images/promo/free-tomoyo-tablet.png"
      alt="kampanj-bild"
    />
    <StyledSmallText>
      Tomoyo är ett spelifierat digitalt läromedel i matematik och finns just nu
      för åk 1–3. Med motivationshöjande inslag från spelens värld ökar eleverna
      sina kunskaper inom grundläggande matematiska moment.
    </StyledSmallText>
    <StyledLinksContainer>
      <StyledSmallSubHeading>MER INFORMATION</StyledSmallSubHeading>
      <StyledLink href="https://www.studentlitteratur.se/tomoyo-faq">
        FAQ
      </StyledLink>
      <StyledLink href="https://www.studentlitteratur.se/kundservice">
        Kundservice
      </StyledLink>
    </StyledLinksContainer>
  </>
);
