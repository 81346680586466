import { List } from "immutable";
import { AnyAction } from "redux";
import {
  createReducer,
  HandlerFn,
  IHandlers,
} from "../../../store/utils/reducer";
import { createTypedMap, TypedMap } from "../../../store/utils/typed-map";
import { INIT_ROUTER, InitRouterAction } from "./actions";

export interface State {
  current: string | undefined;
  history: List<string>;
}

const initialState: TypedMap<State> = createTypedMap({
  current: undefined,
  history: List<string>(),
});

const routeHandler: HandlerFn<State, AnyAction> = (state, action) =>
  state
    .update("history", (history?: string | List<string>) => {
      if (!history) {
        return;
      }
      const current = state.get("current");
      if (!current) {
        return history;
      }
      // If history is a string for some reason, convert it to List
      if (typeof history === "string") {
        const historyList = {} as List<string>;
        historyList.push(history);
        history = historyList;
      }
      return history.push(current as string);
    })
    .set("current", action.type);

export const createRouterReducer = (routes: string[]) =>
  createReducer<State, any>(
    initialState,
    routes.reduce(
      (handlers, route) => {
        return Object.assign({}, handlers, {
          [route]: routeHandler,
        });
      },
      {
        [INIT_ROUTER]: (state, action: InitRouterAction) => {
          return state.set("current", action.route);
        },
      } as IHandlers<State, any>
    )
  );
