import * as React from 'react';
import {
  StyledProgressBar,
  StyledProgressBarContainer
} from "./StyledProgressBar";

export interface Props {
  progress: number;
  overrideStyles?:{};
  barColor?: string;
};

export class ProgressBar extends React.PureComponent<Props> {

  public getProgress = () => {
    if (this.props.progress > 100) {
      return 100;
    }
    if (this.props.progress < 0 ) {
      return 0;
    }
    return this.props.progress;
  };

  public render() {
    return (
      <StyledProgressBarContainer style={ this.props.overrideStyles }>
        <StyledProgressBar barColor={ this.props.barColor || '#8AB4B3' } progress={ this.getProgress() }/>
      </StyledProgressBarContainer>
    )
  }
}

