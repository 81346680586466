import * as React from "react";
import { ReactComponent as Logo } from "../../../assets/logos/studli_logo.svg";
import { ReactComponent as LogoSmall } from "../../../assets/logos/studli_logo_small.svg";
import { StyledLogoContainer } from "./StyledStudliLogo";

interface Props {
  hideName?: boolean;
  color?: string;
  height?: string;
}

const StudliLogo: React.FC<Props> = (props) => {
  const styles = {
    height: props.height || "20px",
    color: props.color || "#000000",
  };
  return (
    <StyledLogoContainer style={styles}>
      {props.hideName ? (
        <LogoSmall fill={"currentColor"} />
      ) : (
        <Logo fill={"currentColor"} />
      )}
    </StyledLogoContainer>
  );
};

export { StudliLogo };
