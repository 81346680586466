import * as React from "react"
import sanitize from "sanitize-html"

export interface Props {
  html: string
  component?: React.FC | string
}
function HtmlRenderer<P>({ component, html, ...rest }: P & Props) {
  const props = React.useMemo(() => {
    const sanitized = sanitize(html, {
      allowedTags: sanitize.defaults.allowedTags.concat(["img", "span", "ins"]),
      allowedAttributes: {
        "*": ["style"],
        ...sanitize.defaults.allowedAttributes
      }
    })

    return {
      dangerouslySetInnerHTML: {
        __html: sanitized
      }
    }
  }, [html])

  return React.createElement<{ dangerouslySetInnerHTML: { __html: string } }>(
    component as React.FC | string,
    {...props, ...rest}
  )
}

HtmlRenderer.defaultProps = {
  component: "div"
}

export default HtmlRenderer
