import {
  FLASHER_CLEAR_FLASH,
  FLASHER_ADD_FLASH,
  ERROR_TYPES,
} from "../../../shared/constants";
import { v4 as uuid } from "uuid";

/**
 * Action to remove a flash message
 * @param id Flash message id
 */
export const clearFlash = (id: string) => ({
  type: FLASHER_CLEAR_FLASH,
  id,
});

/**
 * Action to add a flash message
 * @param msg Message
 * @param type Flash type (info/error)
 */
export const addFlash = (
  msg: string,
  type = ERROR_TYPES.INFO,
  closeTimeout?: number
) => ({
  type: FLASHER_ADD_FLASH,
  msg,
  flashType: type,
  id: uuid(),
  closeTimeout,
});
