import { 
  SortItemType,
  SortBoxType, 
  SortAnswerType
} from "./types";
import {
  PARKING,
  SHUFFLED
} from "./../../../../constants";
import { shuffleArray } from "../../../../../Helpers";

const UNSORTED_BOX_ID = 'unsortedbox';

/**
 * Prepares the boxes list by sorting all unsorted items to unsorted box &
 * sorted items to their respective boxes.
 * @param sortBoxes
 * @param sortItems
 */
export const prepareBoxesList = (sortBoxes: SortBoxType[] , sortItems: SortItemType[], boxType: string, maxItemsInBox: number, order: string) => {

  const arrangedSortItems = order === SHUFFLED ? shuffleArray(sortItems) : sortItems

  const itemsNotInBox = arrangedSortItems.filter( (item: SortItemType) => {
    return item.initialBox === UNSORTED_BOX_ID
  })

  const unsortedBox: SortBoxType = {
    id: UNSORTED_BOX_ID,
    items: [...itemsNotInBox],
    value: '',
    label: '',
    totalCorrectItems: 0
  }

  const boxes = sortBoxes.map((box: SortBoxType) => {
    const itemsInBox = arrangedSortItems.map( (item: SortItemType) => {
      if(item.initialBox === box.value) {
        return item
      }
      return null
    })

    const filteredItems = addPlaceHolders(itemsInBox, boxType === PARKING ? box.totalCorrectItems : maxItemsInBox);

    box.items = [...filteredItems];
    return box;
  });
  return [unsortedBox, ...boxes];
}

/**
 * Extracts incorrect items to a new list.
 * Sets their old position to null.
 * @param boxesList
 * @param correctAnswers 
 */
export const buildCorrectedList = (boxesList: SortBoxType[], correctAnswers: SortAnswerType[]) => {
  const incorrectAnsweredItems: SortItemType[] = [];
  const removedWrongAnswers = boxesList.map(box => {
    const boxItems = box.items.map(item => {
      if (item) {
        const correctAnswer = correctAnswers.find(answer => answer.itemID === item.id);
        if (correctAnswer && correctAnswer.boxID === box.id) {
          return item;
        }
        else {
          correctAnswer && incorrectAnsweredItems.push({ ...item, shouldBeInBox: correctAnswer.boxID });
          return null;
        }
      }

      return null
    })
    return { ...box, items: boxItems };
  })

  return insertInCorrectBox(incorrectAnsweredItems, removedWrongAnswers);
}

/**
 * Insert incorrectly placed items in the correct box.
 * Item is placed at the first free position (null).
 * @param incorrectItems
 * @param newBoxList
 */
const insertInCorrectBox = (incorrectItems: SortItemType[], newBoxList: SortBoxType[]) => {
  incorrectItems.forEach(incorrectItem => {
    const box:SortBoxType|undefined = newBoxList.find(sortBox => sortBox.id === incorrectItem.shouldBeInBox);
    if (box) {
    const emptySpaceInBox:number = box.items.findIndex(item => item === null);
      if (emptySpaceInBox > -1) {
        box.items[emptySpaceInBox] = incorrectItem;
      }
    }
  })

  return newBoxList;
}

/**
 * Fills up the list with null values to represent
 * empty spaces until max number of items in one box 
 * is reached
 * @param itemsInBox 
 * @param maxNumberOfItems 
 */
const addPlaceHolders = (itemsInBox: Array<SortItemType|null>, maxNumberOfItems: number = 12) => {
  const items = itemsInBox.filter(item => item !== null);
  if (items.length < maxNumberOfItems) {
    for (let i = items.length; i < maxNumberOfItems; i++) {
      items.push(null);
    }
  }
  return items;
}

/**
 * Finds and returns the first unsorted item
 * @param items
 */
export const findFirstUnsortedItem = (items: SortItemType[]): string | null => {
  const item: SortItemType | undefined = items.find(element => element.initialBox === UNSORTED_BOX_ID);
  return item ? item.id : null;
}