import styled, {css} from 'styled-components';
import {chipsFormats} from './chipsFormats';
import { StyleldIconContainer } from '../BlockLoader/ExerciseComponents/XcBlanks/StyledBlanks';
import { XYCoord } from 'react-dnd';

interface SortItemLabelProps {
  chipsFormat: string;
}

interface SortItemProps {
  chipsFormat: string;
  state?: string;
  correctedState?: string;
  isDragging?: boolean;
  prefilled?: boolean;
}

interface DragPreviewProps {
  currentOffset: XYCoord | null;
}

interface PlaceholderProps {
  chipsFormat: string;
  visible: boolean;
  isOver?: boolean;
  itemIsSelected?: boolean;
}

const getStateSpecificStyles = (state:string) => {
  if (state === 'CORRECT') {
    return css`
      border: 2px solid #1FC84C;
      color: #191717;
    `;
  }
  if (state === 'INCORRECT') {
    return css`
      border: 2px solid #EC4C3F;
      color: #191717;
    `;
  }
  else if (state === 'TRY_AGAIN') {
    return css`
      border: 2px solid #FDAF2D;
      color: #191717;
    `;
  }
  else {
    return css`
      border: 2px solid #277575;
    `;
  }
}

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      style: {display: 'none'}
    }
  }

  if (currentOffset) {
    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`
    return {
      style: {
        transform,
        WebkitTransform: transform,
        display: 'inline-block',
      }
    }
  }
}

export const StyledSortItemContainer = styled.div<SortItemProps>`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${props => chipsFormats[props.chipsFormat].width};
`;

export const StyledSortItem = styled.div<SortItemProps>`
  position: relative;
  box-sizing: border-box;
  height: ${props => chipsFormats[props.chipsFormat].height};
  min-width: ${props => chipsFormats[props.chipsFormat].width};
  border-radius: 20px;
  ${({prefilled}) => !prefilled && 'background-color: #F9F6F6'};
  color: inherit;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: grab;
  box-shadow: ${props => props.state === 'SELECTED' ? '0 0 0 4px #8AB4B3' : 'none'};
  opacity: ${props => props.isDragging ? .3 : 1};
  z-index: 2;
  transition: box-shadow .3s cubic-bezier(0.26, 0.15, 0, 1);
  ${({correctedState}) => correctedState && getStateSpecificStyles(correctedState)};
  border: ${({prefilled}) => prefilled ? 'none' : '2px solid #277575'};
  ${({prefilled}) => prefilled && 'pointer-events: none'};
  
  img {
    padding: 3px;
  }

  svg {
    padding: 3px;
  }
`;

export const StyledSortItemLabel = styled.span<SortItemLabelProps>`
  font-size: ${props => chipsFormats[props.chipsFormat].fontSize};
  line-height: 1;	
  text-align: center;
  font-family: inherit;
  font-weight: 400;
  user-select: none;
  margin: auto;
  max-width: 100%;
`;

export const StyledSortItemImageLabel = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
`;

export const StyledSortItemPlaceholder = styled.div<PlaceholderProps>`
  position: relative;
  box-sizing: border-box;
  height: ${props => chipsFormats[props.chipsFormat].height};
  min-width: ${props => chipsFormats[props.chipsFormat].width};
  border: ${props => props.visible ? '2px dashed #AFABAB' : 'none'};
  border-radius: 20px;
  background-color: ${props => props.isOver ? 'rgba(243, 247, 246, .9)' : 'transparent'};
  cursor: ${({ itemIsSelected }) => itemIsSelected ? 'pointer' : 'inherit'};
  &:hover {
    background-color: ${({ itemIsSelected }) => itemIsSelected ? 'rgba(243, 247, 246, .9)' : 'none'};
  }
`;

export const StyleldSortIconContainer = styled(StyleldIconContainer as any)`
  border-width: 2px;
  z-index: 2;
  top: 5px;
  right: 5px;
`;

export const StyledDragPreviewWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  top: -75px;
  width: 100vw;
  height: 100%;
`;

export const StyledDragPreviewContainer = styled.div.attrs(
  (props:DragPreviewProps) => getItemStyles(props.currentOffset)
)<DragPreviewProps>``;

export const StyledDragPreview = styled.div<SortItemProps>`
  height: ${props => chipsFormats[props.chipsFormat].height};
  width: ${props => chipsFormats[props.chipsFormat].width};
  border-radius: 20px;
  border: 2px solid #277575;
  background-color: #F9F6F6;
  color: inherit;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: grab;
  z-index: 2;
  transform: rotate(4deg) scale(1.1);
  transform-origin: center;
  box-shadow:
    0 0.2px 1.4px rgba(0, 0, 0, 0.053),
    0 0.5px 3.3px rgba(0, 0, 0, 0.067),
    0 1px 6px rgba(0, 0, 0, 0.074),
    0 1.8px 11px rgba(0, 0, 0, 0.08),
    0 3.3px 22.2px rgba(0, 0, 0, 0.092),
    0 8px 54px rgba(0, 0, 0, 0.14)
  ;


  img {
    padding: 3px;
  }

  svg {
    padding: 3px;
  }
`;