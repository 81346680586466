import * as React from "react";
import { StyledButton, StyledNumberSequence } from "./StyledXcMultipleChoice";
import XcImage from "../XcImage";
import AutoLaTeX from "react-autolatex";
import HtmlRenderer from "../../../../../../../shared/components/HtmlRenderer";
export interface MpcButtonProps {
  id: string;
  displayCorrectAnswer: boolean;
  isCorrectAnswer: boolean;
  isGivenAnswer: boolean;
  isSelected: boolean;
  answerState: string;
  retry: boolean;
  assets?: any;
  image?: string;
  children: string;
  size?: string;
  fontSize?: string;

  callback(id: string): void;
}

export const MpcButton = (props: MpcButtonProps) => {
  const {
    id,
    isGivenAnswer,
    isSelected,
    answerState,
    retry,
    assets,
    image,
    children,
    callback,
    isCorrectAnswer,
    displayCorrectAnswer,
    size,
    fontSize,
  } = props;
  const callBack = () => callback(id);

  const tryAgain: boolean =
    answerState === "DEFAULT" || answerState === "TRY_AGAIN";

  const icons = (assets || {}).icons;
  const format = (assets || {}).format;

  // handle old data structure
  const Icons = (assets || {}).Icons;
  const Format = (assets || {}).Format;

  /**
   * Renders text or modified text
   * @returns {string | JSX.Element}
   */
  const renderButtonText = (): string | JSX.Element =>
    children.includes("[") ? (
      modifyOptionText()
    ) : (
      <AutoLaTeX>{children}</AutoLaTeX>
    );

  /**
   * Inserts span tags in string
   * @returns {JSX.Element}
   */
  const modifyOptionText = (): JSX.Element => {
    const modifiedText = children
      .replace(/\[/g, "<span>") // Replace all '[' with <span>
      .replace(/\]/g, "</span>"); // Replace all ']' with </span>
    return (
      <HtmlRenderer component={StyledNumberSequence} html={modifiedText} />
    );
  };
  return (
    <StyledButton
      disabled={!tryAgain}
      onClick={callBack}
      isGivenAnswer={isGivenAnswer}
      isSelected={isSelected}
      answerState={answerState}
      retry={retry}
      isCorrectAnswer={isCorrectAnswer}
      displayCorrectAnswer={displayCorrectAnswer}
      size={size}
      aria-label="Flerval"
      fontSize={fontSize}
    >
      {!icons && !image && !Icons ? (
        renderButtonText()
      ) : (
        <XcImage
          icons={icons}
          format={format}
          image={image}
          Icons={Icons}
          Format={Format}
        />
      )}
    </StyledButton>
  );
};
