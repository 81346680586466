import styled from "styled-components";
import { ReactComponent as LockIcon } from "../../../assets/icons/icon_lock.svg";

export const StyledPickerListContainer = styled.div`
  width: 90%;
  height: 390px;
`;

export const StyledLockedAdventureIcon = styled(LockIcon)`
  width: 50px;
  height: 25px;
`;

export const StyledButton = styled.button<{
  selectable: boolean;
  selected: boolean;
}>`
  width: 45%;
  height: 55px;
  border-radius: 50px;
  margin-top: 1em;
  margin-left: 2em;
  display: flex;
  align-items: center;
  border: 1px solid #8fece6;
  color: white;
  box-shadow: 0 0 10px 2px rgba(146, 192, 199, 0.6);
  :focus {
    outline: none;
  }

  :hover {
    background-color: #93c5c4;
    color: #303030;
  }

  ${props =>
    props.selectable
      ? "background-color:rgba(255, 255, 255, 0.3); cursor:pointer;"
      : "background-color:rgba(0,0,0, 0.1)"};

  ${props => props.selected && "background-color:#bbfffe; color:#303030;"}
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledButtonImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 90%;
  max-width: 45px;
  border: 1px solid black;
  border-radius: 50%;
  overflow: hidden;
  > svg,
  img {
    height: 100%;
    width: 100%;
    object-fit: none;
  }
`;

export const StyledButtonText = styled.span<{
  selectable: boolean;
  selected: boolean;
}>`
  font-family: "Muli", sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin-left: 1em;
  width: 70%;
  word-break: break-word;
`;

export const StyledAdventureTitle = styled.span`
  margin-top: 1em;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -1px;
  color: #e9eaeb;
  text-transform: uppercase;
`;

export const StyledAdventureDescriptionWrapper = styled.div`
  display: flex;
  margin-left: 1em;
`;

export const StyledAdventureTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  justify-content: center;
`;

export const StyledDescriptionImageWrapper = styled.div`
  > svg,
  img {
    height: 150px;
    width: 150px;
  }
`;

export const StyledAdventureText = styled.span`
  color: #e9eaeb;
  margin-top: 1em;
`;

export const StyledAdventureDataWrapper = styled.div`
  display: flex-row;
  margin: 1em;
`;

export const StyledAdventureRewardsWrapper = styled.div`
  display: flex;
  margin: 1em;
`;

export const StyledAdventureRewards = styled.div`
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #43513f, 0 0 0 7px white;
  color: black;
  font-size: 50px;
  height: 75px;
  line-height: 75px;
  margin-left: 0.75em;
  text-align: center;
  width: 75px;
`;
