import { call, put, select, takeLatest } from "typed-redux-saga";
import { getToken } from "../../../shared/store/auth";
import { getProductId } from "../../../store/Application";
import {
  fetchAnswers,
  fetchGoalProgress,
  fetchGoals,
  updateGoalProgress,
  fetchProblemIndependenthMarkersHeadGroups,
  generateProblemIndependenthMarkersForSelectedGroup,
  deleteMarkers,
} from "./requests";
import {
  TEST_ANSWERS_API_ACTIONS,
  TEST_GOALS_API_ACTIONS,
  TEST_CONTENT_PROGRESS_API_ACTIONS,
  TEST_MARKER_GROUPS_API_ACTIONS,
} from "./actions";
import { AnyAction } from "redux";
import {
  ERROR_TYPES,
  TEST_UPDATE_GOAL_PROGRESS_REQUEST,
  TEST_UPDATE_GOAL_PROGRESS_FAILURE,
  TEST_UPDATE_GOAL_PROGRESS_SUCCESS,
} from "../../../shared/constants";
import { RootState } from "../../../store";
import { addFlash } from "../../../shared/store/flasher/actions";

/**
 * Watcher saga that watches for actions to get answers
 */
export function* watchGetAnswersSaga() {
  yield* takeLatest(TEST_ANSWERS_API_ACTIONS.READ.REQUEST, getAnswersSaga);
}

/**
 * Watcher saga that watches for actions to get goals
 */
export function* watchGetGoalsSaga() {
  yield* takeLatest(TEST_GOALS_API_ACTIONS.READ.REQUEST, getGoalsSaga);
}

/**
 * Watcher saga that watches for actions to get goals
 */
export function* watchGetGoalProgressSaga() {
  yield* takeLatest(
    TEST_CONTENT_PROGRESS_API_ACTIONS.READ.REQUEST,
    getGoalProgressSaga
  );
}

/**
 * Watcher saga that watches for actions to update content progress
 */
export function* watchUpdateGoalProgressSaga() {
  yield* takeLatest(TEST_UPDATE_GOAL_PROGRESS_REQUEST, updateGoalProgressSaga);
}

/**
 * Watcher saga that watches for actions to get problem independent marker head groups
 */
export function* watchGetProblemIndependentMarkerHeadGroupSaga() {
  yield* takeLatest(
    TEST_MARKER_GROUPS_API_ACTIONS.READ.REQUEST,
    getProblemIndependentMarkerHeadGroupSaga
  );
}

/**
 * Watcher saga that watches for actions to generate problem independent markers for selected marker group
 */
export function* watchGenerateMarkersSaga() {
  yield* takeLatest(
    TEST_MARKER_GROUPS_API_ACTIONS.UPDATE.REQUEST,
    generateMarkersSaga
  );
}

/**
 * Watcher saga that watches for actions to delete all markers
 */
export function* watchDeleteMarkersSaga() {
  yield* takeLatest(
    TEST_MARKER_GROUPS_API_ACTIONS.DELETE.REQUEST,
    deleteMarkersSaga
  );
}

/**
 * Worker saga to get answers
 */
export function* getAnswersSaga() {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(fetchAnswers, token, productId);
    const answers = response.data;

    yield* put({ type: TEST_ANSWERS_API_ACTIONS.READ.SUCCESS, answers });
  } catch (error) {
    yield* put({ type: TEST_ANSWERS_API_ACTIONS.READ.FAILURE, error });
  }
}

/**
 * Worker saga to get goals
 */
export function* getGoalsSaga(action: AnyAction) {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(fetchGoals, token, productId);
    const goals = response.data.goals;

    yield* put({ type: TEST_GOALS_API_ACTIONS.READ.SUCCESS, goals });
  } catch (error) {
    yield* put({ type: TEST_GOALS_API_ACTIONS.READ.FAILURE, error });
  }
}

/**
 * Worker saga to get goal progress
 */
export function* getGoalProgressSaga(action: AnyAction) {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(fetchGoalProgress, token, productId);
    const progress = response.data;

    yield* put({
      type: TEST_CONTENT_PROGRESS_API_ACTIONS.READ.SUCCESS,
      progress,
    });
  } catch (error) {
    yield* put({ type: TEST_CONTENT_PROGRESS_API_ACTIONS.READ.FAILURE, error });
  }
}

export function* updateGoalProgressSaga(action: AnyAction) {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const selectedGoal = yield* select(
      (state: RootState) => state.test.selectedGoal
    );
    const ability = yield* select((state: RootState) => state.test.ability);
    const goalScore = yield* select((state: RootState) => state.test.goalScore);
    yield* call(
      updateGoalProgress,
      token,
      productId,
      selectedGoal,
      ability,
      goalScore
    );
    // const updatedProgress = response.data;

    yield* put({ type: TEST_UPDATE_GOAL_PROGRESS_SUCCESS, error: null });
    yield* put(
      addFlash("Innehålls-progress uppdaterad.", ERROR_TYPES.INFO, 5000)
    );
  } catch (error) {
    yield* put({ type: TEST_UPDATE_GOAL_PROGRESS_FAILURE, error });
  }
}

export function* getProblemIndependentMarkerHeadGroupSaga() {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(
      fetchProblemIndependenthMarkersHeadGroups,
      token,
      productId
    );
    const markerGroups = response.data;

    yield* put({
      type: TEST_MARKER_GROUPS_API_ACTIONS.READ.SUCCESS,
      markerGroups,
    });
  } catch (error) {
    yield* put({ type: TEST_MARKER_GROUPS_API_ACTIONS.READ.FAILURE, error });
  }
}

export function* generateMarkersSaga(action: AnyAction) {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(
      generateProblemIndependenthMarkersForSelectedGroup,
      token,
      productId,
      action.payload.id,
      action.payload.amountOfMarkers
    );
    if (response.status === 200) {
      yield* call(getProblemIndependentMarkerHeadGroupSaga);
    }
  } catch (error) {
    yield* put({ type: TEST_MARKER_GROUPS_API_ACTIONS.UPDATE.FAILURE, error });
  }
}

export function* deleteMarkersSaga() {
  try {
    const productId = yield* select(getProductId);
    const token = yield* select(getToken);
    const response = yield* call(deleteMarkers, token, productId);
    if (response.status === 200) {
      yield* call(getProblemIndependentMarkerHeadGroupSaga);
    }
  } catch (error) {
    yield* put({ type: TEST_MARKER_GROUPS_API_ACTIONS.DELETE.FAILURE, error });
  }
}
