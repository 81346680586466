import * as React from "react";
import { StyledTopBar, TopBarColumn } from "./StyledTopBar";

interface Props {
  children: any;
  position?: string;
}

const TopBar: React.FC<Props> = (props) => {
  return (
    <StyledTopBar role="navigation" position={props.position}>
      {props.children}
    </StyledTopBar>
  );
};

export { TopBar, TopBarColumn };
