import styled from 'styled-components';
import { 
  getBorderColor
 } from './helpers'

interface GridProps {
  rowLength: number;
}

interface CellValueProps {
  isVertical?: boolean;
  isHorizontal?: number;
  isInteger?: boolean;
}

interface InputContainerProps {
  isVertical?: boolean;
  isHorizontal?: number;
  isInteger?: boolean;
  correctedState?: string;
  borderBottom?: boolean;
  width: number;
}

interface IconContainerProps {
  correctedState?: string;
  topPosition?: boolean;
  isInteger?: boolean;
  isHorizontal?: number;
}

export const StyledFractionGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.rowLength}, 33px);
  grid-auto-rows: 40px;

  input[type=number] {-moz-appearance: textfield;}
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledCellValue = styled.span<CellValueProps>`
  color: #277575;
  height: 100%;
  font-size: ${props => props.isInteger ? '48' : '32'}px;
  margin-top: ${props => props.isVertical ? '-48px' : props.isInteger ? '-40px' : '0px'};
  margin-left: ${props => props.isHorizontal ? props.isHorizontal * 33 : 0}px;
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  transform: scale(1);
  z-index: 2;
`;

export const StyledInputContainer = styled.div<InputContainerProps>`
  position: relative;  
  height: ${ props => props.isInteger ? '80' : '40'}px;
  width: ${props => props.width > 1 ? props.width*33 : 33 }px;
  border-bottom: ${props => props.borderBottom ? '2px solid #b8cece' : '0px'};
  margin-top: ${props => props.isInteger ? '-40px' : '0px'};
  ${props => props.borderBottom && 'border-bottom: 2px solid #1D7170'};
  z-index: 1;
  &:focus-within {
    border-color: #277575;
  }
`

export const StyledIconContainer = styled.div<IconContainerProps>`
  background-color: #f7fafa;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${props => props.topPosition 
    ? props.isHorizontal ? (props.isHorizontal*-16)-3 : '-3'
    : '-9'}px;
  bottom: ${props => props.isInteger ? '70px' : props.topPosition
    ? '27px'
    : '-9px'}
  border: ${ props => props.correctedState !== undefined ? getBorderColor(props.correctedState) : getBorderColor('DEFAULT')};
  z-index: 1;
`
