import { takeLatest, call, select, put } from "typed-redux-saga";
import axios from "axios";
import { ASSIGNMENT_API_ACTIONS } from "./actions";
import { getToken } from "../../../shared/store/auth";
import { getProductId } from "../../../store/Application";
import { ASSIGNMENT_UPDATED } from "../../../shared/constants";

export function* watchGetAssignmentsSaga() {
  yield* takeLatest(ASSIGNMENT_API_ACTIONS.READ.REQUEST, getAssignmentsSaga);
}

function fetchAssignments(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/assignments`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function* getAssignmentsSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchAssignments, token, productId);
    const assignments = response.data;
    yield* put({
      type: ASSIGNMENT_API_ACTIONS.READ.SUCCESS,
      assignments,
    });
  } catch (error) {
    throw error;
  }
}

export function* watchAssignmentUpdatedSaga() {
  yield* takeLatest(ASSIGNMENT_UPDATED, getAssignmentsSaga);
}
