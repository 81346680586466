import * as React from 'react';
import Slider from "react-slick";

interface Props {
  overrideSettings?: {},
  slideIndex: number,
  slides: any,
  callback?(): any,
}

class ExerciseSlider extends React.PureComponent <Props>{
  private someRef = React.createRef<any>();

  private get slickReady(){
    return this.someRef.current && this.someRef.current.slickGoTo;
  }

  public render(){
    if (this.slickReady) {
      this.someRef.current.slickGoTo(this.props.slideIndex);
    }
    
    const defaultSettings:object = {
      dots: false,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      className: "center",
      centerMode: true,
      slidesToScroll: 1,
      draggable: false,
      swipe: false,
      centerPadding: '0px',
      infinite: false,
      swipeToSlide: false,
      initialSlide: this.props.slideIndex,
    };

    return (
      <Slider { ...defaultSettings} {...this.props.overrideSettings } ref={this.someRef}>
        {
          this.props.slides.map((child: any, index: number) => (
              <div 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  width: '100%'
                }} 
                key={"ds" + index}
                // Hack to get native HTML attribute "inert" to work with React.
                // Stops elements in slides outside current view from being focusable.
                {...{inert: index !== this.props.slideIndex ? "" : undefined}}
              >
                {child}
              </div>
            )
          )
        }
      </Slider>
    )
  }
}

export { ExerciseSlider };