import styled, { css } from "styled-components";
import { getBorderColor } from "./helpers";
import { ReactComponent as Comma } from "../../../../assets/icons/icon_comma.svg";
import { Button } from "../../../../../../../shared/components/Button";

interface GridProps {
  rowLength: number;
}

interface CellValueProps {
  isRestCell?: boolean;
  isEqualCell?: boolean;
  isHorizontal?: number;
}

interface MemoryValueProps {
  scale: number;
}

interface InputContainerProps {
  isVertical?: boolean;
  correctedState?: string;
  borderBottom?: boolean;
  isHorizontal?: number;
}

interface IconContainerProps {
  correctedState?: string;
  topPosition?: boolean;
  inMemory?: boolean;
  comma?: boolean;
  disabled?: boolean;
}

export const StyledLineupGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.rowLength}, 33px);
  grid-auto-rows: 40px;
  grid-gap: 1px;
  background-color: #979797;

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledCellValue = styled.span<CellValueProps>`
  color: #277575;
  height: 100%;
  font-size: ${(props) => (props.isRestCell ? "18px" : "32px")};
  margin-top: ${(props) =>
    props.isRestCell || props.isEqualCell ? "-28px" : "0px"};
  margin-left: ${(props) =>
    props.isHorizontal ? props.isHorizontal * -32 : 0}px;
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  transform: scale(1);
`;

export const StyledMemoryValue = styled.span.attrs(
  (props: MemoryValueProps) => ({
    style: { transform: `scale(${props.scale})` },
  })
)<MemoryValueProps>`
  color: #277575;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  align-self: flex-end;
`;

export const StyledInputContainer = styled.div<InputContainerProps>`
  position: relative;
  background-color: #f7fafa;
  height: 40px;
  width: 100%;
  border: ${(props) =>
    props.correctedState !== undefined
      ? getBorderColor(props.correctedState)
      : getBorderColor("DEFAULT")};
  margin-top: ${(props) => (props.isVertical ? "-20px" : "0px")};
  ${(props) => props.borderBottom && "border-bottom: 2px solid #1D7170"};
  margin-left: ${(props) =>
    props.isHorizontal ? props.isHorizontal * -34 * 0.5 : 0}px;

  &:focus-within {
    outline: none;
    border-color: #277575;
  }
`;

export const StyledIconContainer = styled.div<IconContainerProps>`
  background-color: #f7fafa;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${(props) =>
    props.topPosition ? (props.inMemory ? "-2px" : "-3px") : "-9px"};
  bottom: ${(props) =>
    props.topPosition ? (props.inMemory ? "22px" : "27px") : "-9px"}
  border: ${(props) =>
    props.correctedState !== undefined
      ? getBorderColor(props.correctedState)
      : getBorderColor("DEFAULT")};
  z-index: 1;
`;

const iconComma = (comma: boolean | undefined) => css`
  background-color: ${comma ? "#3E8382" : "#f7fafa"};

  svg {
    use {
      fill: ${comma ? "#f7fafa" : "#3E8382"};
    }
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: #277575;
    background-color: ${comma ? "#3E8382" : "#f7fafa"};
  }
`;

export const StyledIconButton = styled(Button)<IconContainerProps>`
   position: absolute;
   height: 16px;
   width: 16px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   right: ${(props) =>
     props.topPosition ? (props.inMemory ? "-2px" : "-3px") : "-9px"};
   bottom: ${(props) =>
     props.topPosition ? (props.inMemory ? "22px" : "27px") : "-9px"}
   border: ${(props) =>
     props.correctedState !== undefined
       ? getBorderColor(props.correctedState)
       : getBorderColor("DEFAULT")};
   z-index: 1;
   padding: 0;
   ${(props) => iconComma(props.comma)}
   ${(props) => props.disabled && "pointer-events: none;"}
 `;

export const StyledComma = styled(Comma)`
  position: absolute;
  height: 20px;
  width: 20px;
  right: -9px;
  bottom: -9px;
  z-index: 1;
`;
