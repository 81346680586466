import * as React from "react";
import {
  StyledChart,
  StyledChartCell,
  StyledChartWrapper,
} from "./StyledChart";
import { ChartCellType } from "./types";
import { ChartInput } from "./components/ChartInput";
import { ChartCell } from "./components/ChartCell";
import {
  isMobileDevice,
  isTouchDevice,
} from "../../../../../../../shared/platform/Platform";
import { cellColumnSizes, cellRowSizes } from "./cellSizes";

interface Props {
  displayCorrectAnswer: boolean;
  correctAnswers: {
    correctAnswers: ChartCellType[][] | undefined;
  };
  keyboardData: string;
  currentAttempt: number;
  grid: ChartCellType[][];
  settings: {
    chartType: string;
    operative: boolean;
    showBorders: boolean;
  };
  state: string;
  callback(answers: string): void;
}

export const XcChart: React.FC<Props> = ({
  displayCorrectAnswer,
  correctAnswers,
  currentAttempt,
  grid,
  settings,
  keyboardData,
  state,
  callback,
}) => {
  const parsedKeyboardData = keyboardData
    ? JSON.parse(keyboardData)
    : undefined;
  const isTouch = isTouchDevice();
  const isMobile = isMobileDevice();

  const [chartData, setChartData] = React.useState<ChartCellType[][]>(grid);

  const inputDisabled = currentAttempt > 1 || !settings.operative;

  const modifyValue = (value: string) =>
    value.replaceAll(" ", "").replaceAll(",", ".");

  const modifyChart = (chart: ChartCellType[][]) =>
    chart.map((row) =>
      row.map((cell) => ({
        ...cell,
        value: cell.interactive ? modifyValue(cell.value) : cell.value,
      }))
    );

  const publishSelectionState = (answer: ChartCellType[][]): void => {
    callback(JSON.stringify({ answer }));
  };

  const updateChartData = (id: string, value: string) => {
    const updatedChart = chartData.map((row) =>
      row.map((cell: ChartCellType) => {
        if (cell.id === id) {
          return { ...cell, value };
        }

        return cell;
      })
    );
    setChartData(updatedChart);
    publishSelectionState(modifyChart(updatedChart));
  };

  const inputCallback = (id: string, value: string) => {
    updateChartData(id, value);
  };

  const getCorrectedState = (
    chart: ChartCellType[][],
    rowIndex: number,
    cellIndex: number
  ) => {
    return correctAnswers.correctAnswers &&
      modifyChart(chart)[rowIndex][cellIndex].value ===
        correctAnswers.correctAnswers[rowIndex][cellIndex].value
      ? "CORRECT"
      : "INCORRECT";
  };

  const getInputState = (
    chart: ChartCellType[][],
    rowIndex: number,
    cellIndex: number
  ) => {
    if (state === "CORRECT") {
      return "CORRECT";
    } else if (currentAttempt === 2) {
      return getCorrectedState(chart, rowIndex, cellIndex);
    } else return "DEFAULT";
  };

  const renderChart = (chart: ChartCellType[][]) =>
    chart.map((row, rowIndex) =>
      row.map((cell, cellIndex) => {
        if (cell.interactive && settings.operative) {
          return (
            <ChartInput
              index={cellIndex}
              chartType={settings.chartType}
              key={cell.id}
              correctedState={getInputState(chart, rowIndex, cellIndex)}
              callback={inputCallback}
              cell={cell}
              disabled={
                inputDisabled ||
                getInputState(chart, rowIndex, cellIndex) === "CORRECT"
              }
              keyboardData={parsedKeyboardData}
              isTouchDevice={isTouch && isMobile}
              showBorder={settings.showBorders}
            />
          );
        }
        if (cell.value || cell.image || cell.assets) {
          return (
            <ChartCell
              index={cellIndex}
              chartType={settings.chartType}
              key={cell.id}
              cell={cell}
              showBorder={settings.showBorders}
            />
          );
        } else {
          return (
            <StyledChartCell
              index={cellIndex}
              chartType={settings.chartType}
              minHeight={cellRowSizes[cell.row]}
              minWidth={cellColumnSizes[cell.column]}
              key={cell.id}
              correctedState="DEFAULT"
              color={cell.color}
              showBorder={settings.showBorders}
              borderBottom={cell.borderBottom}
              borderRight={cell.borderRight}
            />
          );
        }
      })
    );
  return (
    <StyledChartWrapper>
      <StyledChart
        showBorder={settings.showBorders}
        rows={grid.length}
        columns={grid[0].length}
      >
        {displayCorrectAnswer && correctAnswers.correctAnswers
          ? renderChart(correctAnswers.correctAnswers)
          : renderChart(chartData)}
      </StyledChart>
    </StyledChartWrapper>
  );
};
