import * as React from "react";
import { AudioItem } from "./AudioItem/AudioItem";
import AudioButton from "./AudioButton/AudioButton";

interface Props {
  audioUrl: string;
  audioColor?: { active: string; notActive: string };
  styles?: Object;
}

export const AudioComponent = (props: Props) => {
  const [isAudioIsPlaying, setAudioIsPlaying] = React.useState(false);
  const audioRef = React.createRef<HTMLAudioElement>();
  const audioUrlRef = React.useRef<string>();
  const [audioError, setAudioError] = React.useState(false);

  /**
   * Stops audio playback
   */
  const stopPlayback = React.useCallback(() => {
    audioRef.current && audioRef.current.pause();
    setAudioIsPlaying(false);
  }, [audioRef]);

  React.useEffect(() => {
    if (audioUrlRef.current && props.audioUrl === audioUrlRef.current) {
      return;
    }

    isAudioIsPlaying && stopPlayback();

    if (audioRef.current) {
      audioRef.current.load();
      audioRef.current.removeEventListener("ended", stopPlayback);
      audioRef.current.addEventListener("ended", stopPlayback);
    }

    audioUrlRef.current = props.audioUrl;
  }, [audioRef, isAudioIsPlaying, props.audioUrl, stopPlayback]);

  /**
   * Starts audio playback
   */
  const startPlayback = () => {
    audioRef.current && audioRef.current.play();
    setAudioIsPlaying(true);
  };

  /**
   * Stops or starts audio playback when button is pressed.
   */
  const audioButtonClicked = () => {
    if (isAudioIsPlaying) {
      stopPlayback();
    } else {
      startPlayback();
    }
  };

  return (
    <div onError={() => setAudioError(true)}>
      {!audioError ? (
        <>
          <AudioButton
            aria-label="Lyssna"
            isPlaying={isAudioIsPlaying}
            callback={audioButtonClicked}
            audioColor={props.audioColor}
            styles={props.styles}
          />
          <AudioItem reference={audioRef} source={props.audioUrl} />
        </>
      ) : (
        <p>
          <em>Ljudfilen har tagits bort</em>
        </p>
      )}
    </div>
  );
};
