import { ReducerService } from "../../services/reducer";
import { reducer } from "./reducer";
import * as actions from "./actions";
import { watchResetGameProgressSaga } from "./sagas";
import { RootState } from "..";
import { SagaService } from "../../services/saga";

/**
 * Init progress store
 */
export const init = () => {
  SagaService.set(watchResetGameProgressSaga);
  ReducerService.set("progress", reducer);
};

export const ProgressActions = actions;

/**
 * Store selector to check if user has completed the on-boarding
 * @param state current state
 */
export const getOnboardingCompleted = (state: RootState): boolean => {
  if (
    !state.progress.progress ||
    !state.progress.progress.onboarding_completed_at
  ) {
    return true;
  }
  const d = new Date(state.progress.progress.onboarding_completed_at);
  return d.getUTCFullYear() > 1;
};
