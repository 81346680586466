import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SnackBarMessage } from "./api/types";
import {
  StyledSnackbarContainer,
  StyledSnackbarWrapper,
  StyledSnackbarContent,
  StyledCheckMark,
  StyledTextWrapper,
  StyledRoundButton,
} from "./StyledSnackbar";
import useSnackbar from "./useSnackbar";
import { ReactComponent as CloseIcon } from "../../../assets/icons/icon_close.svg";

const SnackbarIcon = (
  severity: SnackBarMessage["severity"],
  renderIndex: number
) => {
  switch (severity) {
    case "error":
      return;
    case "success":
      return <StyledCheckMark render_index={renderIndex} />;
    case "warning":
      return;
  }
};

const Snackbar = () => {
  const { messagesToShow, removeMessage } = useSnackbar();
  return (
    <StyledSnackbarWrapper>
      <TransitionGroup component="div">
        {messagesToShow.map((m) => (
          <CSSTransition timeout={300} classNames="fade" key={m.addedTime}>
            <StyledSnackbarContainer
              severity={m.severity}
              index={m.renderIndex}
            >
              <StyledSnackbarContent>
                <StyledTextWrapper>
                  {SnackbarIcon(m.severity, m.renderIndex)}
                  {m.message}
                </StyledTextWrapper>
                <StyledRoundButton
                  aria-label="Stäng"
                  callback={() => removeMessage(m.renderIndex)}
                  icon={<CloseIcon />}
                />
              </StyledSnackbarContent>
            </StyledSnackbarContainer>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledSnackbarWrapper>
  );
};

export default Snackbar;
