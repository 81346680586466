import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ONBOARDING_API_ACTIONS,
  ONBOARDING_GRADES_API_ACTIONS,
} from "./api/actions";
import {
  StyledOnboardingWrapper,
  StyledOnboardingFooter,
} from "./StyledOnboarding";
import { DASHBOARD_ROUTE } from "../routes";
import { ArrowButton } from "../../shared/components/Button/ArrowButton";
import { Profile } from "../profile/api/types";
import { ProfileActions } from "../profile";
import { Progress } from "../../store/progress/types";
import { Grade } from "./api/types";
import { RouterActions } from "../../shared";
import { StudliLogo } from "../../shared/components/StudliLogo";
import { getOnboardingCompleted } from "../../store/progress";
import { Post } from "../adventurePicker/api/types";
import { isPreviewed, SelectedState } from "./helpers";
import OnboardingSelect from "./OnboardingSelect";
import OnboardingConfirm from "./OnboardingConfirm";
import OnboardingIntroduction from "./OnboardingIntroduction";

interface OnboardingProps {
  posts: Post[];
  profile: Profile;
  progress: Progress;
  grades: Grade[];
  onboardingCompleted: boolean;
  onGradeChange(grade: number): void;
  onRequestOnboarding(): void;
  onRequestGrades(): void;
  onRouteToDashboard(): void;
  onSetOnboardingCompleted(user: Profile): void;
}

const Onboarding = ({
  posts,
  profile,
  grades,
  onGradeChange,
  onRequestOnboarding,
  onRequestGrades,
  onRouteToDashboard,
  onSetOnboardingCompleted,
}: OnboardingProps) => {
  const [selectedGrade, setSelectedGrade] = useState("");
  const [gradeSelection, setGradeSelection] = useState(false);
  const [gradeSelected, setIsGradeSelected] = useState(false);
  const [confirmGradeSelection, setIsConfirmGradeSelection] = useState(
    SelectedState.NOT_SELECTED
  );

  useEffect(() => {
    onRequestGrades();
  }, [onRequestGrades]);

  useEffect(() => {
    setGradeSelection(profile.grade_change || false);
    if (isPreviewed(false) || !profile.grade_change) {
      onRequestOnboarding();
    }
  }, [onRequestOnboarding, profile.grade_change]);

  const gradeSelectedFn = () => {
    if (selectedGrade && confirmGradeSelection === SelectedState.YES) {
      const grade = parseInt(selectedGrade, 10);
      onGradeChange(grade);
      setGradeSelection(false);
      return;
    }
    if (selectedGrade && confirmGradeSelection === SelectedState.NO) {
      setIsGradeSelected(false);
      setIsConfirmGradeSelection(SelectedState.NOT_SELECTED);
      return;
    }
    if (selectedGrade) {
      setIsGradeSelected(true);
    }
  };

  return !gradeSelection || isPreviewed(false) ? (
    <OnboardingIntroduction
      posts={posts}
      onSetOnboardingCompleted={onSetOnboardingCompleted}
      onRouteToDashboard={onRouteToDashboard}
    />
  ) : (
    grades && (
      <StyledOnboardingWrapper backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg">
        {!gradeSelected && (
          <OnboardingSelect
            firstName={profile.first_name}
            newUser={profile.new_user}
            grades={grades}
            selectedGrade={selectedGrade}
            setIsSelectedGrade={setSelectedGrade}
          />
        )}
        {gradeSelected && (
          <OnboardingConfirm
            selectedGrade={selectedGrade}
            selectedState={confirmGradeSelection}
            setIsConfirmGradeSelection={setIsConfirmGradeSelection}
          />
        )}

        <ArrowButton
          width="60px"
          onClick={gradeSelectedFn}
          direction="Right"
          disabled={
            gradeSelected
              ? confirmGradeSelection === SelectedState.NOT_SELECTED
              : !gradeSelected && !selectedGrade
          }
        />
        <StyledOnboardingFooter>
          <StudliLogo />
        </StyledOnboardingFooter>
      </StyledOnboardingWrapper>
    )
  );
};

const mapStateToProps = (state: RootState) => ({
  posts: state.onboarding.posts,
  grades: state.onboarding.grades,
  profile: state.profile.profile,
  progress: state.progress.progress,
  onboardingCompleted: getOnboardingCompleted(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRequestOnboarding: () =>
    dispatch({ type: ONBOARDING_API_ACTIONS.READ.REQUEST }),
  onRouteToDashboard: () => dispatch(RouterActions.push(DASHBOARD_ROUTE)),
  onGradeChange: (grade: number) =>
    dispatch(ProfileActions.update({ grade, grade_change: false })),
  onRequestGrades: () =>
    dispatch({ type: ONBOARDING_GRADES_API_ACTIONS.READ.REQUEST }),
  onSetOnboardingCompleted: (user: Profile) =>
    dispatch(ProfileActions.setOnboardingCompleted(user)),
});

export const OnboardingView = connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding);
