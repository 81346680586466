import * as React from "react";
import { DropDownStateless } from "./DropDownStateless";
import { Item } from "./Item/Item";

export interface DropDownItem {
  value: string;
  label: string;
  secondary?: any;
  selected?: boolean;
}

interface DropDownProps {
  defaultItem?: DropDownItem;
  dropDownData: DropDownItem[];
  onChange: (arg: DropDownItem) => void;
  overrideStyles?: DropDownStyles;
  keepOpen?: boolean;
}

export interface DropDownStyles {
  fontSize?: string;
  height?: string;
  borderBottom?: string;
  width?: string;
  maxWidth?: string;
  zIndex?: number;
  backgroundColor?: string;
}

interface DropDownState {
  open: boolean;
  selectedItemValue: string;
}

export class DropDown extends React.PureComponent<
  DropDownProps,
  DropDownState
> {
  private el: any;

  constructor(props: DropDownProps) {
    super(props);

    this.state = {
      open: false,
      selectedItemValue: "",
    };
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public render() {
    const { open } = this.state;

    const selectedItems = this.props.dropDownData.filter(
      (item) => item.selected
    );

    const selected = selectedItems.length
      ? selectedItems[0]
      : this.props.defaultItem || { value: "0", label: "Välj..." };

    return (
      <DropDownStateless
        overrideStyles={this.props.overrideStyles}
        onToggle={this.toggle}
        selected={selected}
        open={open}
        ref={this.setRef}
      >
        {this.generateItems()}
      </DropDownStateless>
    );
  }

  private setActiveItem = (itemId: string) => {
    const selected = this.props.dropDownData.filter((item: any) => {
      return item.value === itemId;
    });

    this.props.onChange(selected[0]);
    this.setState({
      open: !!this.props.keepOpen ? false : true,
      selectedItemValue: itemId,
    });
  };

  private generateItems = () => {
    return this.props.dropDownData
      ? this.props.dropDownData.map((item: any) => {
          return (
            <Item
              overrideStyles={this.props.overrideStyles}
              callback={this.setActiveItem}
              value={item.value}
              key={"dropDown_item_" + item.value}
            >
              {item.label}
              {item.secondary && item.secondary}
            </Item>
          );
        })
      : null;
  };

  private toggle = () => this.setState({ open: !this.state.open });

  private handleClickOutside = (e: any) => {
    if (this.el && !this.el.wrapper.contains(e.target)) {
      this.setState({
        open: false,
      });
    }
  };

  private setRef = (el: any) => (this.el = el);
}
