import { Route } from "../services/route";
import { MarkersView } from "./markers";
import { AdventureCharactersView } from "./characters/AdventureCharacters";
import { DashboardView } from "./dashboard";
import { ExerciseView } from "./exercise";
import { OnboardingView } from "./onboarding";
import { ProfileView } from "./profile";
import { RecapView } from "./recap/Recap";
import { TestView } from "./test";
import { AtticView } from "./attic";
import { AssignmentView } from "./assignment";
import { AdventurePickerView } from "./adventurePicker";
import { AdventureStoryView } from "./adventureStory/AdventureStory";
import ItemFeedback from "./ItemFeedback";

export const ASSIGNMENT_ROUTE = "ASSIGNMENT_ROUTE";
export const ATTIC_ROUTE = "ATTIC_ROUTE";
export const MARKERS_ROUTE = "MARKERS_ROUTE";
export const CHARACTERS_ROUTE = "CHARACTERS_ROUTE";
export const DASHBOARD_ROUTE = "DASHBOARD_ROUTE";
export const EXERCISE_ROUTE = "EXERCISE_ROUTE";
export const ONBOARDING_ROUTE = "ONBOARDING_ROUTE";
export const PROFILE_ROUTE = "PROFILE_ROUTE";
export const RECAP_ROUTE = "RECAP_ROUTE";
export const STORY_ROUTE = "STORY_ROUTE";
export const ADVENTURE_STORY_ROUTE = "ADVENTURE_STORY_ROUTE";

export const TEST_ROUTE = "TEST_ROUTE";
export const ADVENTURE_PICKER_ROUTE = "ADVENTURE_PICKER_ROUTE";
export const ITEM_FEEDBACK_ROUTE = "ITEM_FEEDBACK_ROUTE";

export const routes: { [key: string]: Route } = {
  [ASSIGNMENT_ROUTE]: {
    view: AssignmentView,
    trackerUrl: "assignment",
  },
  [ATTIC_ROUTE]: {
    view: AtticView,
    trackerUrl: "attic",
  },
  [MARKERS_ROUTE]: {
    view: MarkersView,
    trackerUrl: "markers",
  },
  [DASHBOARD_ROUTE]: {
    defaultRoute: true,
    view: DashboardView,
    trackerUrl: "dashboard",
  },
  [CHARACTERS_ROUTE]: {
    view: AdventureCharactersView,
    trackerUrl: "characters",
  },
  [EXERCISE_ROUTE]: {
    view: ExerciseView,
    trackerUrl: "exercise",
  },
  [ONBOARDING_ROUTE]: {
    view: OnboardingView,
    trackerUrl: "onboarding",
  },
  [PROFILE_ROUTE]: {
    view: ProfileView,
    trackerUrl: "profile",
  },
  [RECAP_ROUTE]: {
    view: RecapView,
    trackerUrl: "recap",
  },
  [ADVENTURE_STORY_ROUTE]: {
    view: AdventureStoryView,
    trackerUrl: "adventure-story",
  },
  [ADVENTURE_PICKER_ROUTE]: {
    view: AdventurePickerView,
    trackerUrl: "adventure",
  },
  [TEST_ROUTE]: {
    view: TestView,
    trackerUrl: "test",
  },
  [ITEM_FEEDBACK_ROUTE]: {
    view: ItemFeedback,
    trackerUrl: "item-feedback",
  },
};
