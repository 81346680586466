// action types
import { 
  createApiActions, 
  ApiActions
} from "../../../shared/store/actions";
import { 
  PROFILE,
  SET_ONBOARDING_COMPLETED
} from '../../../shared/constants';
import { Profile } from "./types";

export const PROFILE_API_ACTIONS: ApiActions = createApiActions(PROFILE);
export const UPDATE_ONBOARDING_COMPLETED:ApiActions = createApiActions(SET_ONBOARDING_COMPLETED);
export const PROFILE_SET_THEME = "PROFILE_SET_THEME";

export const update = (profile: Profile) => ({
  type: PROFILE_API_ACTIONS.UPDATE.REQUEST,
  payload: profile
});

export const setOnboardingCompleted = (profile: Profile) => ({
  type: UPDATE_ONBOARDING_COMPLETED.UPDATE.REQUEST,
  payload: profile
});