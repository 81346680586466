import axios from "axios";

/**
 * Fetches answers from the API
 * @param token OAuth token
 * @param productId Product ID
 */
export function fetchAnswers(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/answers`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

/**
 * Fetches goals from the API
 * @param token OAuth token
 * @param productId Product ID
 */
export function fetchGoals(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/goals`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

/**
 * Fetches goal progress from the API
 * @param token OAuth token
 * @param productId Product ID
 */
export function fetchGoalProgress(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/goals/progress`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function updateGoalProgress(
  token: string,
  productId: number,
  goalRef?: string,
  ability?: number,
  goalScore?: number
) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {
    goalRef,
    ability,
    score: goalScore,
  };
  return axios.post(`/api/product/${productId}/progress/content`, data, config);
}

/**
 * API request to get all problem independent markers head groups
 * @param token jwt token
 * @param productId product id
 */
export function fetchProblemIndependenthMarkersHeadGroups(
  token: string,
  productId: number
) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/testdashboard/markergroups`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/**
 * API request to add amount of problem independent markers for selected groups
 * @param token jwt token
 * @param productId product id
 */
export function generateProblemIndependenthMarkersForSelectedGroup(
  token: string,
  productId: number,
  id: number,
  amountOfMarkers: number
) {
  return axios({
    method: "post",
    url: `/api/product/${productId}/testdashboard/addmarkers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { marker_group_id: id, marker_quantity: amountOfMarkers },
  });
}

/**
 * API request to delet problem independent markers
 * @param token jwt token
 * @param productId product id
 */
export function deleteMarkers(token: string, productId: number) {
  return axios({
    method: "delete",
    url: `api/product/${productId}/testdashboard/deleteMarkers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
