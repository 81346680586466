import * as React from "react";
import { useDispatch } from "react-redux";
import { AUDIO_PLAYED, LOG_MEDIA_PLAYED } from "../constants";

export interface UseAudioReturn {
  playing: boolean;
  toggle(): void;
  pauseAudio(): void;
}
export interface UseAudioParams {
  url: string;

  audioStart?(): void;
  audioEnd?(): void;
}

/**
 * This snippet is intended as an audio API. It provides its consumer with necessary functionality for handling audio
 *
 * The following code can be used for binding necessary callbacks in a component handling audio
 *
 * const { playing, toggle, currentTime, duration } = UseAudio({
 *   url: audioUrl,
 *   audioEnd: () => audioPlaying(false),
 *   audioStart: () => audioPlaying(true)
 * });
 *
 *
 *
 * @param {string} url - url to a sound
 * @param {() => any} audioStart - Callback for handling the start of a sound
 * @param {() => any} audioEnd - Callback for handling the ending of a sound
 * @returns {UseAudioReturn}
 * @constructor
 */
export const useAudio = ({ url, audioStart, audioEnd }: UseAudioParams): UseAudioReturn => {
  const audioRoot = React.useMemo(() => new Audio(url), [url]);
  const [audio] = React.useState(audioRoot);
  const [playing, setPlaying] = React.useState(false);
  const dispatch = useDispatch();
  /**
   * Play or pauses the audio
   * @returns {any}
   */
  const toggle = React.useCallback((): void => {
    !playing ? audio.play() : audio.pause();
    setPlaying(!playing);
  }, [audio, playing])

  /**
   * Pauses audio
   */
  const pauseAudio = React.useCallback((): void => {
    audio.pause();
    audio.currentTime = 0;
    setPlaying(!playing);
  }, [audio, playing])

  React.useEffect(() => {
    if (audioStart) {
      audioRoot.addEventListener('play', audioStart);

      const onAudioEnd = () => {
        setPlaying(false);
        dispatch({ type: LOG_MEDIA_PLAYED, typeOfMediaPlayed: AUDIO_PLAYED })
        if (audioEnd) {
          audioEnd();
        }
      }
      audioRoot.addEventListener("ended", onAudioEnd);

      return () => {
        audioRoot.removeEventListener('play', audioStart)
        audioRoot.removeEventListener("ended", onAudioEnd)
      };
    }
  }, [audioRoot, audioStart, audioEnd, dispatch]);

  return { playing, toggle, pauseAudio };
};

