import styled, { css } from "styled-components";
import { ButtonProps } from "./Button";
import { pulse } from "../../../styles/shared/animations";

interface LabelFirstProp {
  labelFirst?: boolean;
}

interface CircularProps {
  circular?: boolean;
}

export const StyledButtonIconContainer = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${(props: ButtonProps) => (props.circular ? "0 5px" : "0")};
  height: 100%;
  max-height: 100%;
`;

export const StyledButtonIcon = styled.img`
  height: 100%;
`;

export const StyledButtonContentContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  ${(props: LabelFirstProp) => setLabelFirst(props)};
`;

export const StyledButtonLabelContainer = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;

export const StyledButton = styled.button`
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.35);
  font-family: "Muli";
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: opacity 400ms ease;

  ${(props: CircularProps) => isCircular(props)};
  ${(props) => getSkin(props)}
  ${(props: ButtonProps) => props.animation === "pulse" && setAnimation(props)};

  &:focus {
    outline: none;
  }
`;

const isCircular = (props: CircularProps) => {
  return css`
    border-radius: ${props.circular ? "50%" : "35px"};
    ${props.circular ? "height: 40px;" : ""}
    ${props.circular ? "width: 40px;" : ""}
    padding: ${props.circular ? "10px" : "20px 40px"};
  `;
};

const setLabelFirst = (props: ButtonProps) => {
  return css`
    flex-direction: ${props.labelFirst ? "row-reverse" : "row"};
  `;
};

const setAnimation = (props: ButtonProps) => {
  return css`
    animation: ${pulse};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  `;
};

const getSkin = (props: ButtonProps) => {
  switch (props.skin) {
    case "standard":
      return props.theme.buttons.skins.standard();
    case "primary":
      return props.theme.buttons.skins.primary();
    default:
      return props.theme.buttons.skins.defaults();
  }
};
