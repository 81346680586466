interface ChipsFormat {
  width: string;
  height: string;
  fontSize: string;
}

interface ChipsFormats {
  [key: string]: ChipsFormat;
}

export const chipsFormats: ChipsFormats = {
  default: {
    width: '82px',
    height: '44px',
    fontSize: '20px',
  },
  wide: {
    width: '170px',
    height: '44px',
    fontSize: '20px',
  },
  tall: {
    width: '50px',
    height: '80px',
    fontSize: '24px',
  },
  square: {
    width: '80px',
    height: '80px',
    fontSize: '24px',
  },
  extralarge: {
    width: '124px',
    height: '124px',
    fontSize: '24px',
  }
}

export const getCellSize = (format: string) => {
  return chipsFormats[format].width;
}