import styled from 'styled-components';
import { getCellSize } from '../../../../../Chip/chipsFormats';

interface Props {
  active?: boolean;
  chipsFormat: string;
  isOver?: boolean;
}

export const StyledUnsortedBox = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 0;
  flex: 1;

  flex-wrap: wrap;
  align-items: center;
  min-height: auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(auto-fit, ${props => getCellSize(props.chipsFormat)});
  grid-gap: 4px;
  align-content: center;
  padding: 8px;
  border-radius: 12px;
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  background-color: ${({isOver}) => isOver ? 'rgba(243, 247, 246, .8)' : 'none'};
  
  &:hover {
    background-color: ${({active}) => active ? 'rgba(243, 247, 246, .8)' : 'none'};
  }
  @media (min-width: 1000px) { grid-gap: 20px; }
`;