import * as React from "react";
import { Backdrop, ModalContent } from "./StyledModal";

interface Props {
  show: boolean;
  backdropClick?: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<Props> = (props) => {
  const handleBackdropClick = (e: React.MouseEvent) => {
    props.backdropClick && props.backdropClick();
  };

  return props.show ? (
    <Backdrop onClick={handleBackdropClick}>
      <ModalContent>{props.children}</ModalContent>
    </Backdrop>
  ) : null;
};
