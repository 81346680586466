import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { Dispatch } from "redux";

import { checkNetworkConnection } from "../../store/network/actions";
import {
  StyledNetworkErrorBlocker,
  StyledContentContainer,
  StyledLogoContainer,
  StyledMessageContainer,
} from "./StyledNetworkErrorBlocker";
import { ReactComponent as TomoyoLogo } from "../../../assets/logos/tomoyo_logo_white_line.svg";

import {
  StyledLoaderCenterContainer,
  StyledLoader,
} from "../../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../../external/EddaXcomp/src/constants";

import {
  NETWORK_ERROR_MESSAGE,
  NETWORK_ERROR_MESSAGE_2,
} from "../../constants";

const START_INTERVAL = 1000;
const MAX_INTERVAL = 16000;

interface Props {
  connected: boolean;
  checkNetwork: () => {};
}

let networkChecker: number | null = null;
let interval = START_INTERVAL;

const NetworkErrorBlockerComponent: React.FC<Props> = (props) => {
  const startNetworkChecker = () => {
    if (networkChecker !== null) {
      return;
    }
    networkChecker = window.setTimeout(() => {
      props.checkNetwork();
      networkChecker = null;
      interval = Math.min(interval * 2, MAX_INTERVAL);
      startNetworkChecker();
    }, interval);
  };

  const stopNetworkChecker = () => {
    if (networkChecker === null) {
      return;
    }
    clearTimeout(networkChecker);
    networkChecker = null;
    interval = START_INTERVAL;
  };

  /**
   * Render Screen blocker with message
   * @param message
   */
  const renderNetworkErrorBlocker = () => (
    <StyledNetworkErrorBlocker>
      <StyledContentContainer>
        <StyledLogoContainer>
          <TomoyoLogo />
        </StyledLogoContainer>
        <StyledMessageContainer>
          {NETWORK_ERROR_MESSAGE}
          <br />
          {NETWORK_ERROR_MESSAGE_2}
        </StyledMessageContainer>
        <StyledLoaderCenterContainer>
          <StyledLoader size={LARGE} />
        </StyledLoaderCenterContainer>
      </StyledContentContainer>
    </StyledNetworkErrorBlocker>
  );

  props.connected ? stopNetworkChecker() : startNetworkChecker();
  return !props.connected ? renderNetworkErrorBlocker() : null;
};

const mapStateToProps = (state: RootState) => ({
  connected: state.network.connected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkNetwork: () => dispatch(checkNetworkConnection()),
});

export const NetworkErrorBlocker = connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkErrorBlockerComponent);
