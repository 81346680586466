import { useSelector } from "react-redux";
import { PRIMARY_SCHOOL } from "../../../shared/constants";
import { selectTheme } from "../../profile/api/selectors";
import { ATTIC_ROUTE } from "../../routes";
import {
  StyledContentWrapper,
  StyledBackgroundImageWrapper,
  StyledTextWrapper,
  StyledBackgroundImage,
  StyledText,
  StyledNavigator,
} from "./StyledNoMarkers";

const NoMarkersView = () => {
  const theme = useSelector(selectTheme);
  const imgUrl = theme === PRIMARY_SCHOOL 
    ? "https://media.studentlitteratur.se/image/mr/story/images/groupd_of_markers.png"
    : "https://media.studentlitteratur.se/image/mr/story/images/groupd_of_markers-4-6.png";

  return (
    <StyledContentWrapper>
      <StyledBackgroundImageWrapper>
        <StyledBackgroundImage src={imgUrl} />
      </StyledBackgroundImageWrapper>
      <StyledTextWrapper>
        <StyledText theme={theme}>
          Du har inga märken ännu. Gå till vinden och välj ett äventyr för att
          komma igång och samla.
        </StyledText>
        <StyledNavigator route={ATTIC_ROUTE} withTheme={theme}>
          Vinden
        </StyledNavigator>
      </StyledTextWrapper>
    </StyledContentWrapper>
  );
};

export default NoMarkersView;
