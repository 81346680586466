import { all, put, takeLatest, select, call } from "typed-redux-saga";
import axios from "axios";
import { ConstraintService } from "../../services/constraint";
import { APPLICATION_PRODUCT_API_ACTIONS, applicationReady } from "./actions";
import { AUTH_READY } from "../../shared/store/auth/actions";
import { getToken } from "../../shared/store/auth";
import { APPLICATION_MOUNT } from "../../shared/constants";

/**
 * Runs all sagas in ConstraintService,
 * then sends APPLICATION_READY event
 *
 */
function* setApplicationReady() {
  yield* all(
    ConstraintService.array.map((saga: () => IterableIterator<any>) =>
      call(saga)
    )
  );
  yield* put(applicationReady());
}

export function* watchApplicationProductInit() {
  yield* takeLatest(
    APPLICATION_PRODUCT_API_ACTIONS.READ.SUCCESS,
    setApplicationReady
  );
}

function fetchProductId(productName: string, token: string) {
  return axios({
    method: "get",
    url: `/api/product/${productName}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function getProductNameFromURL() {
  const path = window.location.pathname;
  const parts = path.split("/");
  if (parts && parts.length > 1 && parts[1] && parts[1] !== "index.html") {
    return parts[1];
  } else {
    // Redirect to tomoyo matematik as fallback if product not specified in URL.
    // TODO: Remove this when adding the second product.
    window.location.href =
      "/40676-WB01_tomoyo_matematik" + window.location.search;
  }
  return "";
}

function* applicationProductInit() {
  try {
    const token = yield* select(getToken);
    const productName = getProductNameFromURL();
    const response = yield* call(fetchProductId, productName, token);
    const product = response.data;

    // Set page title
    document.title = `${product.title} | Studentlitteratur`;

    // dispatch a success action to the store with the product
    yield* put({ type: APPLICATION_PRODUCT_API_ACTIONS.READ.SUCCESS, product });
  } catch (error) {
    yield* put({ type: APPLICATION_PRODUCT_API_ACTIONS.READ.FAILURE, error });
  }
}

function* watchAuthenticationReady() {
  yield* takeLatest(AUTH_READY, applicationProductInit);
}

export function* watchApplicationMount() {
  yield* takeLatest(APPLICATION_MOUNT, watchAuthenticationReady);
}
