import { useSelector } from "react-redux";
import { HomeButton } from "../../shared/components/Button/HomeButton";
import { ProfileItem } from "../../shared/components/ProfileItem";
import { selectProfile } from "../profile/api/selectors";

import { Profile } from "../profile/api/types";
import AssignmentCard from "./AssignmentCard";
import {
  StyledBackground,
  StyledHomeButtonWrapper,
  StyledProfileWrapper,
} from "./StyledAssignment";
import { useAssignment } from "./useAssignment";

export interface Props {
  profile: Profile;
}

export const AssignmentView = () => {
  const {
    selectableAssignments,
    canGoBack,
    canGoForward,
    currentAssignment,
    exerciseRoute,
    currentRoute,
    nextRoute,
    hasActiveAdventure,
    setCurrentAssignment,
  } = useAssignment();

  const profile = useSelector(selectProfile);
  return (
    <StyledBackground background_image="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg">
      <StyledHomeButtonWrapper>
        <HomeButton />
      </StyledHomeButtonWrapper>
      {profile && (
        <StyledProfileWrapper>
          <ProfileItem
            name={profile.first_name}
            picture={profile.picture}
            backPlate
          />
        </StyledProfileWrapper>
      )}
      {selectableAssignments[currentAssignment] && (
        <>
          <AssignmentCard
            key={
              selectableAssignments[currentAssignment].id +
              selectableAssignments[currentAssignment].status
            }
            assignment={selectableAssignments[currentAssignment]}
            canGoForward={canGoForward}
            canGoBack={canGoBack}
            currentAssignment={currentAssignment}
            exerciseRoute={exerciseRoute}
            currentRoute={currentRoute}
            nextRoute={nextRoute}
            hasActiveAdventure={hasActiveAdventure}
            setCurrentAssignment={setCurrentAssignment}
          />
        </>
      )}
    </StyledBackground>
  );
};
