import styled from 'styled-components';

export const StyledContentText = styled.div`
  color: inherit;
  font-size: 20px;
  letter-spacing: 0.24px;
  line-height: 30px;
  text-align: center;
  
  h1 {
    font-weight: 900;
    font-size: 36px;
    margin: 20px 0 60px 0;
  }
  
  h2 {
    color: #333333;
    font-weight: 300;
    margin: 60px 0 25px 0;
  }
  
  h3 {
    font-weight: 600;
    font-size: 20px;
    margin: 35px 0 10px 0;
  }
  
  ul {
    list-style: none;
  }
  
  li {
    font-weight: 100;
    font-size: 20px;
  }
  
  p {
    margin: 0 auto;
    max-width: 432px;
    font-size: 11px;
    line-height: 20px;
  }
  
  a {
    display: block;
    font-family: Tahoma;
    font-size: 26px;
    margin: 30px 0 60px 0;
    text-decoration: none;
  }
  a:link, a:visited, a:active {
      color: inherit;
  }
`;

export const StyledSubHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 20px;
`;

export const StyledTomoyoLogoContainer = styled.div`
  margin: 40px 0;
  svg {
    width: 220px;
    height: auto;
  }
`;

export const StyledCopyright = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 900;
  color: #333333;
`;

export const StyledStudliLink = styled.a`
  display: block;
  font-family: Tahoma;
  font-size: 26px;
  margin-bottom: 60px;
  text-decoration: none;
  
  a:link, a:visited, a:active {
    color: red;
  }
`;