import { splashDelaySaga, splashSaga, watchModalClose } from "./sagas";
import { ConstraintService } from "../../../services/constraint";
import { reducer } from "./reducer";
import { ReducerService } from "../../../services/reducer";
import { SagaService } from "../../../services/saga";

export const init = () => {
  ConstraintService.set(splashDelaySaga);
  SagaService.set(watchModalClose);
  SagaService.set(splashSaga);
  ReducerService.set("splash", reducer);
};
