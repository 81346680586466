import styled from 'styled-components';

interface CellProps {
  borderBottom?: boolean;
  clickable?: boolean;
}

export const StyledFractionCell = styled.div<CellProps>`
  position: relative;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.clickable ? 'pointer' : 'auto'};
  ${props => props.borderBottom && 'border-bottom: 2px solid #1D7170'};
`;
