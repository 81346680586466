import { RootState } from "../../../store";

export const selectTheme = (state: RootState) => state.profile.profile.theme;

export const selectProfile = (state: RootState) => state.profile.profile;

export const selectProfileFetching = (state: RootState) =>
  state.profile.fetching;

export const selectOnboardingCompleted = (state: RootState) =>
  state.profile.profile.onboarding_completed;
