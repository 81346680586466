import { ReducerService } from "../../services/reducer";
import { SagaService } from "../../services/saga";
import { reducer } from "./api/reducer";
import {
  watchGetAssignmentsSaga,
  watchAssignmentUpdatedSaga
} from "./api/sagas";

export * from "./Assignment";

export const init = () => {
  SagaService.set(watchGetAssignmentsSaga);
  SagaService.set(watchAssignmentUpdatedSaga);
  ReducerService.set("assignments", reducer);
};
