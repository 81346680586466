import styled from 'styled-components';
import { Props } from './ProgressBar';

export const StyledProgressBarContainer = styled.div`
  width: 100%;
  height: 16px;
  padding: 2px;
  border-radius: 15px;
  background-color: #227574;
`;

export const StyledProgressBar = styled.div`
  width: ${(props: Props) => props.progress}%;
  height: 100%;
  background: ${ (props:Props) => props.barColor };
  border-radius: 15px;
  transition: width 200ms ease-in-out;
`;