import styled from "styled-components";
import { ToggleProps } from "./Toggle";

export const StyledToggleWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  z-index: 0;
`;

export const StyledToggleInput = styled.input`
  border: 0;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
  cursor: inherit;
`;

export const StyledToggleHandle = styled.div<ToggleProps>`
  display: block;
  position: absolute;
  top: -2px;
  left: -2px;
  background-color: ${props => props.theme.white};
  width: 20px;
  height: 20px;
  transition: all 90ms ease-out;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
  ${props =>
    props.checked
      ? `
    background-color: ${props.theme.themePrimary};
    transform: translateX(20px);
  `
      : null};
`;

export const StyledToggle = styled.div<ToggleProps>`
  display: inline-block;
  position: relative;
  background-color: ${props => props.theme.neutralTertiaryAlt};
  border-radius: 11px;
  width: 34px;
  padding: 0;
  height: 16px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;

  ${props =>
    props.checked
      ? `
    background-color: ${props.theme.themeLight};
    border: none;
  `
      : null};
`;