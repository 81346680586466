import * as React from 'react';
import {
  StyledCell,
  StyledCrossLine,
  StyledSmallCrossLine
} from './StyledLineupCell';

interface Props {
  borderBottom: boolean;
  memoryCellId?: string;
  crossed?: boolean;
  id?: string;
  hidden?: boolean;
  displayCorrectAnswer?: boolean;
  isMemoryDigit?: boolean;
  calculateType?: string;
  offsetCell?: boolean;
  isFillerCell?: boolean;
  callback?: (param: string, param2: string) => void;
}

export const LineupCell: React.FC<Props> = (props) => {

  const handleOnClick = () => {
    if (props.callback && props.id && props.memoryCellId && !props.hidden) {
      props.callback(props.id, props.memoryCellId);
    }
  }

  const renderCrossedLine = () => ( props.isMemoryDigit
    ? <StyledSmallCrossLine />
    : <StyledCrossLine />
  )
  
  const isCrossLineShowing = () => {
    return (props.crossed && !props.hidden) || (props.displayCorrectAnswer && props.memoryCellId) ?
    renderCrossedLine()
    : null
  }

  const isHiddenCell = () => {
    const calcType = props.calculateType === 'division' ? true : false;
    return props.hidden && calcType;

  }

  return !isHiddenCell() ?
  (
    <StyledCell
      onClick={handleOnClick}
      offsetCell={props.offsetCell}
      fillerCell={props.isFillerCell}
      borderBottom={props.borderBottom}
      clickable={!!props.memoryCellId}
    >
      {isCrossLineShowing()}
      {props.children}
    </StyledCell>
  )
  :
  null;
}
