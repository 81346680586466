import * as React from 'react';
import {TrackItem} from "react-compound-slider/Tracks/Tracks";
import {Handler} from "./Handler";
import { Track } from "./Track";
import { Tick } from "./Tick";
import {
  Handles,
  Tracks,
  Ticks,
  Rail,
  SliderItem,
  TicksObject
} from 'react-compound-slider';
import {
  StyledRail,
  StyledSlider
} from './StyledXcNumberLine';
import {
  findStartPosition,
  findJumpStart,
} from './helpers';
import {
  ArrowObject,
  JumpInterface,
  NumberLineProps,
  NumberLineSettings,
  NumberLineStep,
  RailPropsCallback,
  RenderHandleProps,
  RenderTrackProps,
  FixedJumpData
} from "./types";
import {NORMAL} from "../../../../../../../shared/constants";
import {
  defaultArrowObject,
  defaultJumpObject
} from "./DefaultDataGenerator";

const noop = () => { /* empty */ }

export const XcNumberLine: React.FC<NumberLineProps> = ({ steps, callback = noop, settings, correctAnswers, displayCorrectAnswer, state, currentAttempt }) => {
  const {
    lineType = NORMAL,
    jump = defaultJumpObject() as JumpInterface,
    showArrow = defaultArrowObject(),
    interval = 1,
    smooth,
    marking,
    startValue = 0,
    operative = true,
  }: NumberLineSettings = settings;

  const {
    left: leftArrow,
    right: rightArrow
  }: ArrowObject = showArrow;

  const {
    auto,
    image,
    turnAsset,
    jumpLength,
    fixed,
  }: JumpInterface = jump;

  const smoothFactor:number = 0.05;
  const domain:number[] = [0, steps.length - 1];
  const startingPosition:number = (steps.findIndex(findStartPosition) === -1)
      ? 0
      : steps.findIndex(findStartPosition);

  const jumpStartPosition: number = steps.findIndex(findJumpStart);
  const totalSteps:number = steps.length - 1;
  const startingPercent = (startingPosition / totalSteps) * 100;
  
  const [currentPosition, setCurrentPosition] = React.useState(startingPosition);
  
/**
 * Check if provided step is correct answer
 * @param {NumberLineStep} step
 * @returns {boolean}
 */
  const isCorrectPosition = (step: NumberLineStep): boolean => {
    if (correctAnswers) {
      return step.id === correctAnswers.correctAnswers[0];
    }
    return false;
  }
  
  /**
   * Get position of correct step
   * @returns {number}
   */
  const getCorrectPosition = (): number | undefined => {
    if(correctAnswers) {
      const pos =  steps.findIndex(isCorrectPosition);
      return (pos / totalSteps) * 100; 
    }
    return undefined;
  }

  /**
   * Finds start position of jumps
   * @param {NumberLineStep} step
   * @returns {boolean}
   */
  const getJumpStart = (step:NumberLineStep): boolean => step.id === jump.fixed.start;
  
  /**
   * Finds end position of jumps
   * @param {NumberLineStep} step
   * @returns {boolean}
   */
  const getJumpEnd = (step:NumberLineStep): boolean => step.id === jump.fixed.end;

  /**
   * Creates jump data object
   * @returns {{}}
   */
  const createJumpData = (): FixedJumpData => {
    const jumpStartStep = steps.findIndex(getJumpStart);
    const fixedJumpStart = (jumpStartStep / totalSteps) * 100;
    const jumpEndStep = steps.findIndex(getJumpEnd);
    const fixedJumpEnd = (jumpEndStep / totalSteps) * 100;
    const fixedJumpSteps = (jumpEndStep - jumpStartStep);
    
    return {
      fixedJumpStart,
      fixedJumpEnd,
      fixedJumpSteps,
    }
  };

const getFixedJumpData = ():FixedJumpData | undefined => fixed.isActive
  ? createJumpData()
  : undefined;

  /**
   * Renders track
   * @param {TrackItem[]} tracks
   * @param {GetTrackProps} getTrackProps
   * @returns {JSX.Element[]}
   */
  const renderTracks = ({tracks, getTrackProps}: RenderTrackProps): JSX.Element[] => tracks.map(({id, source, target}: TrackItem) => {
    return (
      <Track
        fixedJumpData={{ startIndex: steps.findIndex(({ id: stepId }) => fixed.start === stepId), endIndex: steps.findIndex(({ id: stepId }) => fixed.end === stepId )}}
        startingPosition={startingPosition}
        currentPosition={currentPosition}
        key={id}
        source={source}
        target={target}
        currentStepNumbers={target.value - startingPosition}
        totalSteps={totalSteps}
        image={image}
        turnImage={turnAsset}
        showRightArrow={rightArrow}
        showLeftArrow={leftArrow}
        showAutoJumps={auto}
        filledLine={lineType === 'additive'}
        jumpInterval={jumpLength}
        startingPercent={startingPercent}
        getTrackProps={getTrackProps}
        fixedJump={getFixedJumpData()}
      />
    )
  });

  /**
   * Renders Handles
   * @param {SliderItem[]} handles
   * @param {GetHandleProps} getHandleProps
   * @returns {JSX.Element[]}
   */
  const renderHandles = ({ handles, getHandleProps }:RenderHandleProps): JSX.Element[] => handles.map((handle:SliderItem) => (
    <Handler
      correctPosition={getCorrectPosition()}
      displayCorrectAnswer={displayCorrectAnswer}
      exeState={state}
      key={handle.id}
      handle={handle}
      startingPosition={startingPosition}
      hasLatex={hasLatex()}
      getHandleProps={getHandleProps}
    />
  ));

  /**
   * Get label tick text
   * @param {string} text
   * @param {boolean} hasCustomValue
   * @param {number} index
   * @returns {any}
   */
  const getLabelTick = ({ text, hasCustomValue }: NumberLineStep, index: number) => hasCustomValue
    ? text
    : (Math.round(((interval * index) + startValue) * 1000 ) / 1000).toString();

  /**
   * Renders ticks
   * @param {Array<SliderItem>} ticks
   * @returns {JSX.Element[]}
   */
  const renderTicks = ({ticks}: TicksObject): JSX.Element[] => ticks.map((tick: SliderItem, index: number) => (
    <Tick
      key={tick.id}
      tick={tick}
      count={ticks.length}
      label={getLabelTick(steps[index], index)}
      image={steps[index].stepImage}
      showLabel={steps[index].showLabel}
      marking={marking}
    />
  ));

  /**
   * Renders a Fragment to wrap elements
   * @param renderFunc
   * @returns {(props: any) => JSX.Element}
   */
  const renderDivWrap = (renderFunc: any) => (props: any): JSX.Element => (
    <React.Fragment>
      { renderFunc(props) }
    </React.Fragment>
  );

  /**
   * Handle can be set not to jump in discrete steps
   * @returns {number}
   */
  const smoothStep = ():number => smooth
    ? smoothFactor
    : 1;

  /**
   * Tests in there is a '$' in any of the labels.
   * If so, latex is assumed
   * @returns {boolean}
   */
  const hasLatex = ():boolean => steps
    .map(step => (step.text.indexOf('$') > -1))
    .filter(latexRes => latexRes)
    .length > 0;

  /**
   * With a disabled numberline, the handle start position is set to DisabledPosition
   * @returns {number}
   */
  const adjustedStartPosition = ():number => !operative
    ? jumpStartPosition
    : startingPosition;

  /**
   * Handler for the onChange event from the Slider
   * returns value from closest index when using smooth handle
   * @param {ReadonlyArray<number>} selectedSteps
   */
  const handleSliderChange = (selectedSteps: ReadonlyArray<number>) => {
    if (selectedSteps && selectedSteps.length) {
      const selectedStepIndex = Math.floor(selectedSteps[0] + 0.5);
      const answer = steps[selectedStepIndex].id;
      callback(answer);
    }
  };

  /**
   * Updates current position index
   * @param {any} newPosition
   * @returns {any}
   */
  const updateCurrentPosition = ([newPosition]: any) => setCurrentPosition(newPosition);

  /**
   * Renders styled rail
   * @param {() => {}} getRailProps
   * @returns {JSX.Element}
   */
  const renderStyledRail = ({ getRailProps }: RailPropsCallback):JSX.Element => <StyledRail {...getRailProps()} />;

  /**
   * Show handle if operative
   * @returns {JSX.Element}
   */
  const showHandle = (): JSX.Element | null => ( operative
    ? <Handles children={renderDivWrap(renderHandles)} /> 
    : null
  );

  return (
    <StyledSlider
      domain={domain}
      step={smoothStep()}
      mode={1}
      disabled={!operative || currentAttempt === 2 || state === 'CORRECT'}
      values={[adjustedStartPosition()]}
      onChange={handleSliderChange}
      onUpdate={updateCurrentPosition}
    >
      <Rail>
        { renderStyledRail }
      </Rail>
      {showHandle()}
      <Tracks left={true} right={false} children={renderDivWrap(renderTracks)} />
      <Ticks count={steps.length-1} children={renderDivWrap(renderTicks)} />
    </StyledSlider>
  );
};

export default XcNumberLine;
