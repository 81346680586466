import { 
  AUTH_TOKEN_API_ACTIONS, 
  AUTH_TOKEN_UPDATE_SUCCESS,
  CAMPAIGN_REQUEST,
  CAMPAIGN_REQUEST_SUCCESS,
  CAMPAIGN_REQUEST_FAILURE
} from "./actions";

const initialState = {
  fetching: false,
  jwt: null,
  error: null
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case AUTH_TOKEN_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case AUTH_TOKEN_API_ACTIONS.READ.SUCCESS:
      return { ...state, fetching: false, jwt: action.jwt };
    case AUTH_TOKEN_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, jwt: null, error: action.error };
    case AUTH_TOKEN_UPDATE_SUCCESS:
      return { ...state, jwt: action.jwt };
        
    case CAMPAIGN_REQUEST:
      return { ...state, fetching: true, error: null };
    case CAMPAIGN_REQUEST_SUCCESS:
      return { ...state, fetching: false, campaign: action.campaign };
    case CAMPAIGN_REQUEST_FAILURE:
      return { ...state, fetching: false, campaign: null, error: action.error };

    default:
      return state;
  }
}