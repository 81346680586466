import { 
  APPLICATION_PRODUCT_API_ACTIONS
} from "./actions";
import { APPLICATION_READY } from "../../shared/constants";

const initialState = {
  fetching: false,
  product: {},
  ready: false,
  error: null
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case APPLICATION_PRODUCT_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case APPLICATION_PRODUCT_API_ACTIONS.READ.SUCCESS:
      return { ...state, fetching: false, product: action.product };
    case APPLICATION_PRODUCT_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, product: null, error: action.error };
    case APPLICATION_READY:
      return { ...state, ready: true };
    default:
      return state;
  }
}