import styled from 'styled-components';
import {CorrectionBtnStates} from "../CorrectionButton";
import {stateColors} from "./StatusDots";

interface DotProps {
  dotState: any;
  isActive: boolean;
}

export const StyledStatusDotsWrapper = styled.div`
  display:flex;
  flex-direction: row;
`;

export const StyledStatusDotWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background: #fff;
  
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  
  transition: border-width 200ms, border-color 200ms;
  
  ${({dotState, isActive}: DotProps) => {
    const { DEFAULT } = CorrectionBtnStates;
    
    return (dotState === DEFAULT && isActive) || dotState !== DEFAULT
      ? `border: 2px solid ${stateColors[dotState]}`
      : 'border: none';
    }
  };
`;

export const StyledStatusDot = styled.div`
  width: 75%;
  height: 75%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledActiveDot = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color: ${() => stateColors[CorrectionBtnStates.DEFAULT]};
`;