import { useSelector } from "react-redux";
import { selectTheme } from "../../../views/profile/api/selectors";
import { PRIMARY_SCHOOL } from "../../constants";
import {
  StyledAdventureInfoText,
  StyledAdventureInfoTextContainer,
} from "./StyledAdventureModal";

type AdventureInfoTextProps = {
  adventureGroupTitle?: string;
  adventureTitle?: string;
  isImage: boolean;
  active?: boolean;
  nextRoute: string;
};

interface ModalTextRoute {
  [index: string]: string;

  ADVENTURE_STORY_ROUTE: string;
  ADVENTURE_PICKER_ROUTE_PRIMARY_SCHOOL: string;
  ADVENTURE_PICKER_ROUTE_MIDDLE_SCHOOL: string;
  EXERCISE_ROUTE_PRIMARY_SCHOOL: string;
  EXERCISE_ROUTE_MIDDLE_SCHOOL: string;
}

const noStory = "Du har inte sett klart berättelsen.";
const noAdventure = "Du har inte valt något äventyr.";
const watchStory = "Se klart berättelsen för att kunna fortsätta!";
const selectAdventurePrimary =
  "Gå in i teleporteraren och välj ett äventyr för att fortsätta!";
const selectAdventureMiddle =
  "Gå in i tidsmaskinen och välj ett äventyr för att fortsätta!";

const headerText: ModalTextRoute = {
  ADVENTURE_PICKER_ROUTE_PRIMARY_SCHOOL: noAdventure,
  ADVENTURE_PICKER_ROUTE_MIDDLE_SCHOOL: noAdventure,
  EXERCISE_ROUTE_PRIMARY_SCHOOL: noAdventure,
  EXERCISE_ROUTE_MIDDLE_SCHOOL: noAdventure,
  ADVENTURE_STORY_ROUTE: noStory,
};

const modalText: ModalTextRoute = {
  ADVENTURE_PICKER_ROUTE_PRIMARY_SCHOOL: selectAdventurePrimary,
  ADVENTURE_PICKER_ROUTE_MIDDLE_SCHOOL: selectAdventureMiddle,
  EXERCISE_ROUTE_PRIMARY_SCHOOL: selectAdventurePrimary,
  EXERCISE_ROUTE_MIDDLE_SCHOOL: selectAdventureMiddle,
  ADVENTURE_STORY_ROUTE: watchStory,
};

export const AdventureInfoText = ({
  adventureGroupTitle,
  adventureTitle,
  isImage,
  active,
  nextRoute,
}: AdventureInfoTextProps) => {
  const theme = useSelector(selectTheme);
  const formattedTheme =
    theme === PRIMARY_SCHOOL ? "PRIMARY_SCHOOL" : "MIDDLE_SCHOOL";

  return (
    <StyledAdventureInfoTextContainer>
      <StyledAdventureInfoText isImage={isImage}>
        {active && nextRoute === undefined
          ? `Du har åkt till "${adventureGroupTitle}" och valt "${adventureTitle}".`
          : headerText[nextRoute]}
      </StyledAdventureInfoText>
      <StyledAdventureInfoText isImage={isImage}>
        {active && nextRoute === undefined
          ? "Fortsätt att räkna för att klara äventyret!"
          : modalText[`${nextRoute}_${formattedTheme}`.toUpperCase()]}
      </StyledAdventureInfoText>
    </StyledAdventureInfoTextContainer>
  );
};

