import {
  StyledGreyMarker,
  StyledMarkerImg,
  StyledMarkerContainerPrimarySchool,
} from "./StyledMarkers";

interface MarkerImageProps {
  image?: string;
  border?: boolean;
  shadow?: boolean;
  color?: string;
  noPadding?: boolean;
}

const MarkerImagePrimarySchool: React.FC<MarkerImageProps> = ({
  image,
  border,
  shadow,
  color,
  noPadding,
}) => (
  <StyledMarkerContainerPrimarySchool
    border={border}
    shadow={shadow}
    color={color}
  >
    {image ? (
      <StyledMarkerImg src={image} noPadding={noPadding} />
    ) : (
      <StyledGreyMarker />
    )}
  </StyledMarkerContainerPrimarySchool>
);

export default MarkerImagePrimarySchool;
