import React, { useRef } from "react";
import { ParsedAssignment } from "./api/types";
import { useSelector } from "react-redux";
import { isAfter } from "date-fns";
import sanitize from "sanitize-html";
import {
  StyledCard,
  StyledCardInfoWrapper,
  StyledAssignmentInfo,
  StyledAssignmentDurationWrapper,
  StyledAssignmentImageWrapper,
  StyledAssignmentTitle,
  StyledDescriptionWrapper,
  StyledNavigateIcon,
  StyledNavigateButton,
  StyledBadge,
  StyledBadgeWrapper,
  StyledDate,
  StyledProgress,
  StyledProgressFiller,
  StyledProgressTime,
  StyledProgressWrapper,
  StyledCompletedText,
  StyledAudioWrapper,
  StyledExtraTime,
  StyledMedal,
  StyledHappyPim,
  StyledNeutralPim,
  StyledCountButton,
  StyledCalendarIcon,
  StyledMedalIcon,
  StyledCalendarBg,
  StyledAssignmentImageDescWrapper,
  StyledAssignmentBlob,
} from "./StyledAssignment";
import { AudioComponent } from "../../shared/components/Audio";
import { getTimeAndDateString } from "../../shared/tools/timeAndDate";
import { calcProgressPercentage } from "./api/helpers";
import { selectTheme } from "../profile/api/selectors";
import AdventureInfoModal from "../../shared/components/AdventureInfoModal/AdventureInfoModal";
import { MIDDLE_SCHOOL } from "../../shared/constants";

import CalendarIcon from "./icon-calendar.svg";
import MedalIcon from "./icon-medal.svg";

type Props = {
  assignment: ParsedAssignment;
  canGoBack: boolean;
  canGoForward: boolean;
  currentAssignment: number;
  exerciseRoute: string;
  currentRoute: string;
  nextRoute: { route: string; text: string } | undefined;
  hasActiveAdventure: boolean;
  setCurrentAssignment: (index: number) => void;
};

const AssignmentCard = ({
  assignment,
  canGoForward,
  canGoBack,
  currentAssignment,
  exerciseRoute,
  currentRoute,
  nextRoute,
  hasActiveAdventure,
  setCurrentAssignment,
}: Props) => {
  const theme = useSelector(selectTheme);

  const description = useRef<HTMLDivElement>(null);

  const [showAdventureModal, setShowAdventureModal] = React.useState(false);
  const renderAdventureInfoModal = () => setShowAdventureModal(true);

  const renderProgressImage = () => {
    if (assignment.progress >= assignment.threshold) {
      return theme === "middle-school" ? (
        <StyledMedal
          src="https://media.studentlitteratur.se/image/mr/story/images/medal_gold.svg"
          alt="Guldmedalj"
        />
      ) : (
        <StyledHappyPim
          src="https://media.studentlitteratur.se/image/mr/story/images/_characters/pim_happy_2.svg"
          alt="glad Pim"
        />
      );
    }
    if (assignment.progress && assignment.status === "previous") {
      return theme === "middle-school" ? (
        <StyledMedal
          src="https://media.studentlitteratur.se/image/mr/story/images/medal_silver.svg"
          alt="Silvermedalj"
        />
      ) : (
        <StyledNeutralPim
          src="https://media.studentlitteratur.se/image/mr/story/images/_characters/pim_point_2.svg"
          alt="Pim pekar"
        />
      );
    }
  };

  const renderDescription = () => {
    return (
      <StyledDescriptionWrapper
        ref={description}
        key={assignment.id}
        dangerouslySetInnerHTML={{
          __html: sanitize(assignment.instruction.text),
        }}
      />
    );
  };

  const renderTimeAndAudio = () => {
    const formattedDate = getTimeAndDateString(assignment.endDate);
    const isAssignmentEnded =
      isAfter(new Date(), new Date(assignment.endDate)) ||
      assignment.status === "previous";

    const hasStudentFinished = assignment.progress >= assignment.threshold;
    return (
      <>
        <StyledCalendarIcon
          src={CalendarIcon}
          alt="Kalender ikon för att visa datum"
        />

        <StyledCalendarBg>
          <StyledDate>{formattedDate}</StyledDate>
        </StyledCalendarBg>

        {(isAssignmentEnded || hasStudentFinished) && (
          <StyledCompletedText
            is_finished={hasStudentFinished}
            assignment_ended={isAssignmentEnded}
          >
            {hasStudentFinished ? "Klart!" : "Avslutat"}
          </StyledCompletedText>
        )}

        {assignment.instruction.audio && (
          <StyledAudioWrapper>
            <AudioComponent audioUrl={assignment.instruction.audio} />
          </StyledAudioWrapper>
        )}
      </>
    );
  };

  return (
    <StyledCard>
      <StyledCardInfoWrapper>
        <StyledAssignmentDurationWrapper />
        <StyledAssignmentInfo>
          <StyledAssignmentTitle>{assignment.title}</StyledAssignmentTitle>
          <StyledAssignmentImageDescWrapper>
            {assignment?.instruction?.text && renderDescription()}
            <StyledAssignmentImageWrapper>
              <StyledBadgeWrapper color={assignment.markerColor}>
                <StyledAssignmentBlob />
                <StyledBadge alt="märke" src={assignment.markerURL} />
              </StyledBadgeWrapper>
            </StyledAssignmentImageWrapper>
          </StyledAssignmentImageDescWrapper>
          <StyledProgressWrapper>
            <StyledProgress>
              <StyledProgressFiller
                percentage={calcProgressPercentage(
                  assignment.progress,
                  assignment.threshold
                )}
              />
            </StyledProgress>
            <StyledProgressTime>
              Du har räknat{" "}
              {assignment.progress > assignment.threshold
                ? assignment.threshold
                : assignment.progress}{" "}
              av {assignment.threshold} minuter
            </StyledProgressTime>
            {assignment.progress > assignment.threshold && (
              <StyledExtraTime>
                +{assignment.progress - assignment.threshold} min extra!{" "}
                <StyledMedalIcon
                  src={MedalIcon}
                  alt="Medalj för extra tid på uppdraget"
                />
              </StyledExtraTime>
            )}
          </StyledProgressWrapper>
        </StyledAssignmentInfo>
        <StyledAssignmentDurationWrapper>
          {renderTimeAndAudio()}
        </StyledAssignmentDurationWrapper>

        {renderProgressImage()}
      </StyledCardInfoWrapper>
      {assignment.status === "current" && !showAdventureModal && (
        <StyledCountButton
          circular={false}
          route={
            hasActiveAdventure || theme === MIDDLE_SCHOOL ? exerciseRoute : ""
          }
          onClick={renderAdventureInfoModal}
        >
          {(nextRoute || {}).text as string}
        </StyledCountButton>
      )}
      {canGoForward && (
        <StyledNavigateButton
          onClick={() => setCurrentAssignment(currentAssignment - 1)}
          direction="right"
          aria-label="Gå till nästa"
        >
          <StyledNavigateIcon should_rotate={0} />
        </StyledNavigateButton>
      )}
      {canGoBack && (
        <StyledNavigateButton
          onClick={() => setCurrentAssignment(currentAssignment + 1)}
          direction="left"
          aria-label="Gå till föregående"
        >
          <StyledNavigateIcon should_rotate={1} />
        </StyledNavigateButton>
      )}
      <AdventureInfoModal
        show={showAdventureModal}
        theme={theme}
        currentRoute={currentRoute}
        nextRoute={(nextRoute || {}).route as string}
        onAdventureInfoModalClose={setShowAdventureModal}
      />
    </StyledCard>
  );
};

export default AssignmentCard;
