import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { useAudio } from "../../effects/Effects";
import { StyledSoundWrapper, StyledSoundButton } from "./StyledSoundWrapper";
import { setAsPlaying, toggleAudioPlaying } from "./store/actions";

export interface SoundWrapperProps {
  data?: SoundWrapperData;
  audioOverlayActive: boolean;
  children?: JSX.Element;
  currentlyPlayingAudioId: string;
  Component: React.ReactNode;
  url: string;

  audioPlaying(isPlaying: boolean): void;
  setSelfAsPlaying(id: string): void;
}

export interface SoundWrapperData {
  audio: string;
}

const SoundWrapperContainer = ({
  Component,
  url,
  audioOverlayActive,
  children,
  currentlyPlayingAudioId,
  audioPlaying,
  setSelfAsPlaying,
}: SoundWrapperProps) => {
  const { playing, toggle, pauseAudio } = useAudio({
    url,
    audioEnd: () => audioPlaying(false),
    audioStart: () => audioPlaying(true),
  });
  const [guiId] = useState(uuid());

  /**
   * Starts or stop the audio and do necessary operations surrounding that outcome
   */
  const startOrStopAudio = (): void => {
    toggle();

    if (!playing) {
      setSelfAsPlaying(guiId);
    }
  };

  if (playing && currentlyPlayingAudioId !== guiId) {
    startOrStopAudio();
  }

  /**
   * Will pause all running audio when unmounted
   */
  useEffect(() => {
    return () => {
      audioOverlayActive && pauseAudio();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (audioOverlayActive) {
    return (
      <StyledSoundWrapper>
        <StyledSoundButton onClick={startOrStopAudio} playing={playing}>
          {Component}
          {children}
        </StyledSoundButton>
      </StyledSoundWrapper>
    );
  }

  return (
    <>
      {playing ? pauseAudio() : null}
      {Component}
      {children}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  audioOverlayActive: state.audio.audioOverlayActive,
  currentlyPlayingAudioId: state.audio.currentlyPlayingAudioId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  audioPlaying: (isPlaying: boolean) => dispatch(toggleAudioPlaying(isPlaying)),
  setSelfAsPlaying: (id: string) => dispatch(setAsPlaying(id)),
});

const SoundWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(SoundWrapperContainer);

export default SoundWrapper;
