import * as React from "react";
import { StyledGameItemContainer, StyledItemImg } from "./StyledGameItem";

interface GameItemProps {
  imgUrl: string;
  obtained: boolean;
}

export const GameItem: React.FC<GameItemProps> = (props) => {
  return (
    <StyledGameItemContainer>
      <StyledItemImg
        src={props.imgUrl}
        obtained={props.obtained}
        alt="Märkesbild"
      />
    </StyledGameItemContainer>
  );
};
