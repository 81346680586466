import * as React from "react";
import {
  StyledGridWrapper,
  StyledGridRow,
  StyledItemCell,
  StyledMarkersCell,
  StyledMarkerItem,
  StyledTextCell,
} from "./StyledMarkersGrid";
import { GameItem } from "../GameItem";
import { Marker } from "../../../views/markers/api/types";
import { Themes } from "../../../views/profile/api/types";
import MarkerImagePrimarySchool from "../Markers/MarkerImagePrimarySchool";
interface MarkersGridProps {
  items: Marker[];
  theme: Themes;
  isFilterActive?: boolean;
}

export const MarkersGrid: React.FunctionComponent<MarkersGridProps> = ({
  items,
  isFilterActive,
}) => {
  const emptyRowsToRender: number = 4 - items.length;

  return (
    <StyledGridWrapper>
      {items &&
        items.map((item: Marker) => (
          <StyledGridRow dynamicHeight={!!isFilterActive} key={item.unique_id}>
            <StyledTextCell>
              <span>{item.label}</span>
            </StyledTextCell>
            <StyledMarkersCell>
              {(item.sub_rewards || []).map((marker: Marker) => (
                <StyledMarkerItem key={marker.unique_id}>
                  <MarkerImagePrimarySchool
                    color={marker.color}
                    image={marker.obtained ? marker.thumbnail_url : ""}
                    border
                    noPadding
                  />
                </StyledMarkerItem>
              ))}
            </StyledMarkersCell>
            <StyledItemCell>
              <GameItem imgUrl={item.thumbnail_url} obtained={item.obtained} />
            </StyledItemCell>
          </StyledGridRow>
        ))}
      {!isFilterActive &&
        new Array(emptyRowsToRender)
          .fill(null)
          .map((_, i) => <StyledGridRow key={i} />)}
    </StyledGridWrapper>
  );
};
