import { useSelector } from "react-redux";
import { selectActiveAssignment } from "../../assignment/api/selectors";

import {
  StyledItemContainer,
  StyledItemText,
  StyledMainImg,
  StyledImage,
  StyledImageContainer,
  StyledNavigator,
  StyledAssignmentBadge,
  StyledAssignmentBadgeWrapper,
} from "./StyledDashboardMenuItem";

export interface ImageData {
  url: string;
  width?: number;
  left?: number;
  bottom?: number;
}

interface DashboardItemProps {
  route: string;
  main?: boolean;
  label: string;
  width?: string;
  height?: string;
  radius?: string;
  bgPosition?: string;
  imageData?: ImageData;
  isAssignment?: boolean;
  withAnimations?: boolean;
  onClick?: () => void;
}

const DashboardMenuItem = (props: DashboardItemProps) => {
  const {
    main,
    label,
    width,
    height,
    radius,
    bgPosition,
    imageData,
    route,
    isAssignment = false,
    withAnimations,
    onClick,
  } = props;

  const activeAssignment = useSelector(selectActiveAssignment);

  const renderMainImg = () => (
    <StyledMainImg src={imageData && imageData.url} alt="main image" />
  );

  const renderImg = () =>
    imageData && (
      <StyledImageContainer>
        {isAssignment && activeAssignment ? (
          <StyledAssignmentBadgeWrapper
            left={imageData.left}
            bottom={imageData.bottom}
            width={imageData.width}
            color={activeAssignment.markerColor}
          >
            <StyledAssignmentBadge
              alt="märke"
              src={activeAssignment.markerURL}
            />
          </StyledAssignmentBadgeWrapper>
        ) : (
          <StyledImage
            src={imageData.url}
            left={imageData.left}
            bottom={imageData.bottom}
            width={imageData.width}
            alt="image"
          />
        )}
      </StyledImageContainer>
    );

  return (
    <StyledItemContainer
      withAnimations={withAnimations}
      main={main}
      width={width}
      bgPosition={bgPosition}
      height={height}
      radius={radius}
    >
      <StyledNavigator
        route={route}
        circular
        withAnimations={withAnimations}
        onClick={onClick}
      >
        {main ? renderMainImg() : renderImg()}
        <StyledItemText main={main}>{label}</StyledItemText>
      </StyledNavigator>
    </StyledItemContainer>
  );
};

export default DashboardMenuItem;
