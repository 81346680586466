import styled from "styled-components";
import { ReactComponent as ExpandIcon } from "../../assets/icons/icon_arrow_slim.svg";
import { Navigator } from "../../shared/components/Navigator";
import { BlobSvg, BlobSvgSecond, BlobSvgThird } from "./Blob";

const components = [BlobSvg, BlobSvgSecond, BlobSvgThird];
const RandomBlob = components[Math.floor(Math.random() * components.length)];

export const StyledBackground = styled.div<{ background_image: string }>`
  background-image: url(${(props) => props.background_image});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCountButton = styled(Navigator)`
  height: 35px;
  min-width: 100px;
  padding: 16px;
  font-size:1.2rem;
  font-weight:600;
  text-align:center;
  border-radius:1rem;
  position:absolute;
  right:-0.5rem;
  bottom:-3.5rem;
  background-color: ${(props) => props.theme.themePrimary};
  color: ${(props) => props.theme.white}
  cursor:pointer;

  &:hover {
    background-color: ${(props) => props.theme.themePrimary};
    color: ${(props) => props.theme.white}
    }
`;

export const StyledHappyPim = styled.img`
  width: 12rem;
  height: 14rem;
  right: -100px;
  bottom: -30px;
  position: absolute;

  @media (max-width: 900px) {
    right: -10px;
  }
`;
export const StyledNeutralPim = styled.img`
  width: 16rem;
  height: 9rem;
  right: -80px;
  bottom: 0px;
  position: absolute;
`;
export const StyledMedal = styled.img`
  width: 8rem;
  height: 9rem;
  right: 0;
  bottom: 10px;
  position: absolute;
`;

export const StyledCalendarIcon = styled.img`
  width: 100%;
`;

export const StyledMedalIcon = styled.img`
  width: 100%;
  max-width: 40px;
  margin: 0 0.4rem;
`;

export const StyledCalendarBg = styled.div`
  padding: 0.8rem;
  box-shadow: inset 0px 0px 0px 2px #d3d2d2, 0 2px 4px -2px rgb(0 0 0 / 0.25);
  width: 100%;
`;

export const StyledProfileWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledAudioWrapper = styled.div`
  margin-top: 0.5em;
`;

export const StyledCompletedText = styled.span<{
  is_finished: boolean;
  assignment_ended: boolean;
}>`
  margin-top: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.25), 0 2px 4px -2px rgb(0 0 0 / 0.25);
  padding: 0.4rem;
  font-size: 1rem;
  background-color: ${(props) =>
    getAssignmentStatusColor(props.is_finished, props.assignment_ended)};
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

export const StyledGoBack = styled(ExpandIcon)`
  position: absolute;
  width: 30px;
  left: -50px;
  top: 200px;
`;

export const StyledNavigateButton = styled.button<{
  direction: "left" | "right";
}>`
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  width: 40px;
  ${(props) => (props.direction === "right" ? "right: -70px;" : "left:-70px;")}
  top: 200px;
  height: 40px;
  padding: 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledNavigateIcon = styled(ExpandIcon)<{ should_rotate: number }>`
  height: 25px;
  color: ${(props) => props.theme.themePrimary};
  ${(props) => props.should_rotate && "transform: rotate(180deg)"}
`;

export const StyledExpandIcon = styled(ExpandIcon)<{ is_expanded: boolean }>`
  transform: rotate(${(props) => (props.is_expanded ? "270deg" : "90deg")});
  height: 16px;
  color: ${(props) => props.theme.themePrimary};
  margin-left: 0.5em;
`;

export const StyledCardInfoWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const StyledAssignmentImageDescWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

export const StyledAssignmentImageWrapper = styled.div`
  margin: 0 auto;
`;

export const StyledAssignmentDurationWrapper = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1em;
`;

export const StyledAssignmentInfo = styled.div`
  width: 60%;
  height: 100%;
  padding: 3em 1em;
`;

export const StyledProgress = styled.div`
  height: 2.3em;
  width: 100%;
  border-radius: 1.15em;
  background-color: #8ec4c3;
  padding: 3px;
`;

export const StyledProgressFiller = styled.div<{ percentage: number }>`
  height: 100%;
  width: ${(props) => (props.percentage > 5 ? `${props.percentage}%` : "2em")};
  background: ${(props) =>
    props.percentage === 100
      ? "linear-gradient(-45deg, #24E82C, #88f6c1, #4294f6, #24E82C)"
      : props.theme.themePrimary};
  border-radius: 1em;
  background-size: 400% 400%;
  animation: ${(props) =>
    props.percentage === 100 ? "gradient 15s ease infinite" : ""};
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const StyledExtraTime = styled.span`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.themePrimary};
`;

export const StyledAssignmentBlob = styled(RandomBlob)`
  position: absolute;
  top: 0;
`;

export const StyledDescriptionWrapper = styled.div<{
  should_overflow: boolean;
}>`
  max-width: 50%;
  word-break: break-word;
  > ul {
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1rem;
    -webkit-margin-after: 1rem;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
  }
`;

export const StyledProgressTime = styled.span`
  color: ${(props) => props.theme.themePrimary};
  margin-top: 1em;
  font-size: 1.2em;
`;

export const StyledProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
`;

export const StyledButtonText = styled.span`
  color: ${(props) => props.theme.themePrimary};
  font-size: 16px;
  width: 100px;
`;

export const StyledShowMoreButton = styled.button`
  display: flex;
  border: 0px;
  width: 140px;
  background: white;
  cursor: pointer;
  margin-left: 35%;
  margin-top: 1em;
  white-space: nowrap;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledAssignmentTitle = styled.h1`
  color: ${(props) => props.theme.themePrimary};
  text-align: center;
  font-size: 1.5em;
`;

export const StyledCard = styled.div`
  width: 750px;
  position: relative;
  min-height: 450px;
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  @media (max-width: 900px) {
    width: 600px;
  }
`;

export const StyledBadgeWrapper = styled.div<{ color: string }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
  width: 200px;
  height: 200px;
  background-color: ${(props) => props.color};
`;

export const StyledDate = styled.span`
  color: ${(props) => props.theme.themePrimary};
  font-size: 0.9rem;
  font-weight: 700;
`;

export const StyledBadge = styled.img`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: 120px;
  padding: 0.2rem;
  margin: 0 auto;
`;

export const StyledHomeButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const getAssignmentStatusColor = (
  isFinished: boolean,
  assignmentEnded: boolean
) => {
  if (isFinished) {
    return "#f2791c";
  }
  if (assignmentEnded) {
    return "#2f7a7a";
  }
  return "#2f7a7a";
};
