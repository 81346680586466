import styled, {keyframes} from 'styled-components';
import { getBorderColor } from '../../helpers';

interface InputProps {
  correctedState?: string;
  isVertical?: boolean;
  isInteger?: boolean;
  isHorizontal?: number;
}
interface CaretProps {
  margin: number|null;
  isHorizontal?: number;
}

export const StyledFractionInput = styled.input<InputProps>`
  height: 100%;
  border-radius: 0;
  width: 100%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${ props => props.correctedState !== undefined ? getBorderColor(props.correctedState) : getBorderColor('DEFAULT')};
  color: #277575;
  caret-color: #333333;
  text-align: center;
  font-family: inherit;
  font-size: ${props => props.isInteger ? '48' : '32'}px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  transition: border-color .2s ease-in-out;
  padding: 0;
  margin: 0;
  margin-left: ${props => props.isHorizontal ? (props.isHorizontal * 33) * 0.5 : 0}px;
  z-index: 1;

  &:focus {
    outline: none;
    border-color: #277575;
  }

  &:disabled {
    opacity: 1;
    color: #333333;
    -webkit-text-fill-color: #333333;
  }
`;

const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

export const StyledCaret = styled.div<CaretProps>`
  opacity: 0;
  height: 65%;
  width: 2px;
  background-color: ${props => props.theme.themePrimary};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: ${props => props.isHorizontal ? (props.isHorizontal * 33) * 0.5 : 0}px;
  transform: ${props => props.margin ? `translate(${props.margin}px, -50%)` : `translate(-50%, -50%)`};
  animation: ${blink} 1.25s cubic-bezier(.215, .61, .355, 1) forwards infinite;
`;
