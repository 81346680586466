import * as React from "react";
import { StyledMemoryCell, StyledCrossLine } from "./StyledLineupMemoryCell";
import { StyledInputContainer } from "../../StyledLineup";
import { renderCross } from "../../helpers";

interface Props {
  borderBottom: boolean;
  crossed?: boolean;
  id: string;
  displayCorrectAnswer?: boolean;
  correctedState?: string;
  isMemoryDigit?: boolean;
  inContainer?: boolean;
  cross?: boolean;
  cellIndex?: number;
  callback: (param: string) => void;
  callback2: (param: string) => void;
}

export const LineupMemoryCell: React.FC<Props> = (props) => {
  const onClick = () => {
    props.crossed ? props.callback2(props.id) : props.callback(props.id);
  };

  const renderCrossedLine = () => (props.crossed ? <StyledCrossLine /> : null);

  return (
    <StyledInputContainer
      key={"container_" + props.cellIndex}
      correctedState={props.correctedState}
      borderBottom={props.borderBottom}
    >
      <StyledMemoryCell
        onClick={onClick}
        borderBottom={props.borderBottom}
        correctedState={props.correctedState}
        inContainer={props.inContainer}
      >
        {renderCrossedLine()}
        {props.children}
      </StyledMemoryCell>
      {renderCross(props.correctedState, props.isMemoryDigit)}
    </StyledInputContainer>
  );
};
