import * as React from "react";
import {
  StyledAnswersTable,
  StyledTableWrapper,
  StyledJumpButton,
  StyledLinkContainer,
} from "./StyledTestTable";
import { Answer } from "../api/types";
import { StyledSectionHeading } from "../StyledTest";
import { ReactComponent as JumpIcon } from "../../../assets/icons/icon_jump.svg";

type DateTimeFormatOptions =
  | "short"
  | "numeric"
  | "2-digit"
  | "long"
  | "narrow";

interface Props {
  answers: Answer[];
  pageIndex: number;
  pageSize: number;
}

export const TestTable: React.FC<Props> = (props) => {
  const { answers, pageIndex, pageSize } = props;

  /**
   * Renders one page of answer rows in the answers table
   * @param answers Array of answers
   * @param pageIndex Current page index
   * @param pageSize Page size
   */
  const renderAnswerRows = () => {
    if (!answers) {
      return;
    }
    const pageAnswers = answers.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );
    return pageAnswers.map((answer) => renderAnswerRow(answer));
  };

  /**
   * Renders one answer row
   * @param answer One answer
   */
  const renderAnswerRow = (answer: Answer) => {
    return (
      <tr key={answer.id}>
        <td>
          <StyledLinkContainer>
            <a
              href={"/?preview=" + answer.exercise_id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledJumpButton>
                <JumpIcon />
              </StyledJumpButton>
            </a>
            {answer.exercise_id}
          </StyledLinkContainer>
        </td>
        <td>{formatDateTime(answer.created_at)}</td>
        <td>{answer.correct ? "Ja" : "Nej"}</td>
        <td>{answer.attempts}</td>
        <td>{answer.elapsed_time_millis}</td>
        <td>{answer.points}</td>
        <td>{answer.ability}</td>
        <td>{answer.difficulty}</td>
        <td>{answer.goalTitle}</td>
        <td>{answer.isRepetition ? "Ja" : "Nej"}</td>
        <td>{answer.score}</td>
      </tr>
    );
  };

  /**
   * Formats a datetime string for view
   * If the date is within the current year. The year is omitted from the output.
   * Ex: 2019-08-27 11:06:00 becomes 27 aug. 11:06
   * Ex:
   * @param s Datetime string
   */

  const formatDateTime = (s: string) => {
    const dt = new Date(s);
    const formatThisYear: { [key: string]: DateTimeFormatOptions } = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formatPrevYears: { [key: string]: DateTimeFormatOptions } = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat(
      "sv-SE",
      dt.getFullYear < new Date().getFullYear ? formatPrevYears : formatThisYear
    ).format(dt);
  };

  return (
    <React.Fragment>
      <StyledSectionHeading>Historik</StyledSectionHeading>
      <StyledTableWrapper>
        <StyledAnswersTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Datum</th>
              <th>Rätt</th>
              <th>Försök</th>
              <th>Tid</th>
              <th>Storypoäng</th>
              <th>Färdighet</th>
              <th>Svårighetsgrad</th>
              <th>Måltitel</th>
              <th>Repetition</th>
              <th>Målpoäng</th>
            </tr>
          </thead>
          <tbody>{renderAnswerRows()}</tbody>
        </StyledAnswersTable>
      </StyledTableWrapper>
    </React.Fragment>
  );
};
