import styled from 'styled-components';

const primary1 = '#277575';

export const StyledNewLine = styled.div`
  width: 100%;
`;

export const StyledTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledTextLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0;
  max-width: 100%;
  &:first-child {
    margin: 0;
  }
`;

export const StyledHeading = styled.div`
  font-family: 'Muli', sans-serif;
  font-size: 3.6vmin;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.28px;
  line-height: normal;
  color: ${primary1};
  padding: 0 10px;
  word-break: break-word;
  hyphens: auto;
`;
