import  styled from "styled-components";

interface ProfileItem {
  backPlate: boolean;
}

const getBackPlateStyles = () => (`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  border: 1px solid #277575;
  border-right: none;
  max-height: 40px;
  padding-left: 20px;
`);

export const StyledProfileItemWrapper = styled.button<ProfileItem>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }

  ${ props => props.backPlate ? getBackPlateStyles() : ''}
`;

export const StyledProfileText = styled.div`
  margin-right: 12px;
  color: #333333;
  font-size: 20px;
  letter-spacing: 0.16px;
  line-height: 1;
  font-family: 'Muli';
`;

export const StyledProfileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: ${ props => props.theme.themeSecondary };
  border: 1px solid  ${ props => props.theme.themePrimary };
  
  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  svg {
    padding: 6px;
    color: inherit;
    width: 100%;
    height: auto;
  }
`;


export const StyledProfileMenuWrapper= styled.div`
  position: relative;
  height: 100%;
  cursor: auto;
`
export const StyledProfileMenu= styled.div`
  min-width: 200px;
  position: absolute;
  right: 0px;
  background-color: rgba(255,255,255,0.8);
  border: 1px solid #277575;
  border-radius: 20px;
  top: 2.5rem;
  font-size: 20px;
  line-height: 1.8em;
  white-space: nowrap;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);

  ul, ol {
    padding: 12px 0px;
  }
  li {
    cursor: pointer;
    display: block;
    padding: 4px 16px;
    :hover {
      background-color: rgba(0,0,0,0.08);
    }
  }
`