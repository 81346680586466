export const translateMap: Map<string, string> = new Map([
  ["dashboard", "Tomoyo startsida"],
  ["exercise", "Tomoyo övningar"],
  ["markers", "Tomoyo märken"],
  ["test", "Testsida"],
  ["characters", "Tomoyo figurer"],
  ["recap", "Tomoyo berättelse"],
  ["attic", "Tomoyo vinden"],
  ["item-feedback", "Tomoyo äventyr"],
  ["story", "Tomoyo äventyr"],
  ["adventure", "Tomoyo äventyr"],
  ["adventure-story", "Tomoyo äventyr"],
  ["assignment", "Tomoyo uppdrag"],
  ["facts", "Tomoyo fakta"],
]);
