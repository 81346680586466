import axios from "axios";

/**
 * API request to fetch access for user and product
 * @param token jwt token
 * @param productId product id
 */
export function fetchAccess(token: string, productId: number) {
  return axios({
    method: "get",
    url: `/api/product/${productId}/access`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

/**
 * API request to inform that the expiry notice has been read.
 * @param token jwt token
 * @param productId product id
 */
export function expiryNoticeReadRequest(token: string, productId: number, code: string | undefined) {
  return axios({
    method: "post",
    url: `/api/product/${productId}/expiry-notice-read/${code}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}