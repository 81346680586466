import * as React  from "react";
import { 
  FeedbackDisplayProps, 
  FeedbackStatesType 
} from "./FeedbackDisplayTypes";
import { 
  StyledFeedbackText, 
  StyledIconContainer, 
  StyledFeedbackDisplay
} from "./StyledFeedbackDisplay";
import { ReactComponent as WrongIcon } from "../../assets/icons/icon_x.svg";
import { ReactComponent as CorrectIcon } from "../../assets/icons/icon_correct.svg";
import { ReactComponent as TryAgainIcon } from "../../assets/icons/icon_pen.svg";

export const labelStates: FeedbackStatesType = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  TRY_AGAIN: 'TRY_AGAIN'
};

class FeedbackDisplay extends React.PureComponent<FeedbackDisplayProps> {
  
  public render() {
    return (
      <StyledFeedbackDisplay>
        {this.getContent()}
      </StyledFeedbackDisplay>
    );
  }
  
  /**
   * Renders icon and text
   * @param icon
   * @param iconColor
   * @param label
   */
  private renderContent = (icon: JSX.Element, iconColor: string, label: string): JSX.Element => {
    return(
      <React.Fragment> 
        <StyledIconContainer color={iconColor}>
          { icon }
        </StyledIconContainer>
        <StyledFeedbackText>
          { label }
        </StyledFeedbackText>
      </React.Fragment>
    );
  }
  
 /**
  * Gets the right content
  */
  private getContent = (): (JSX.Element | null) => {
    const {CORRECT, INCORRECT, TRY_AGAIN} = labelStates;
    
    switch (this.props.state) {
      case CORRECT:
        return this.renderContent(<CorrectIcon />, '#1FC84C', 'Rätt svar!');
        
      case INCORRECT:
        return this.renderContent(<WrongIcon />, '#EC4C3F', 'Fel svar, tyvärr!');
        
      case TRY_AGAIN:
        return this.renderContent(<TryAgainIcon />, '#FDAF2D', 'Ändra och försök igen!');
      
      default:
        break;
    }
    return null;
  };
}

export default FeedbackDisplay;