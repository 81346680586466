import * as React from 'react';
import * as Exercises from './ExerciseComponents';
import {
  ExerciseData,
  Meta,
  Answer,
} from "../ExerciseRenderer/ExerciseRendererTypes";
import {AUDIO} from "../../../../../shared/constants";
import { StyledExerciseAudioPlayer } from '../../../../../shared/components/ResultSlide/StyledResultSlide';
import SoundWrapper from '../../../../../shared/containers/SoundWrapper';
import { AudioPlayerComponent } from '../../../../../shared/components/Audio';

export interface XcExerciseProps {
  blockType: string;
  blockData: ExerciseData[];
  state: string;
  operative: boolean;
  settings: object;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  correctAnswers: Answer;
  size: string;
  isHelpShowing: boolean;
  focusInput: boolean;

  callback?(state: string, res: ExerciseData, meta: Meta): void;
}

interface BlockSettings {
  calculateType?: string;
  operative?: boolean;
}

interface ExerciseBlockTypes {
  [key: string]: any;
}

const BlockLoader: React.FC <XcExerciseProps> = ({blockType, blockData, state, operative, callback, settings, currentAttempt, displayCorrectAnswer, correctAnswers, size, isHelpShowing, focusInput }) => {
  /**
   * Translates types
   * @param {string} type
   * @returns {string}
   */
  const typeTranslator = (type: string, blocksettings: BlockSettings):string => {

    interface BlockTypes {
      [key: string]: string;
    }

    if(type === 'lineup'){
      switch(blocksettings.calculateType){
        case 'multiplication':
          type = 'lineupMultiplication'
          break;
        case 'division':
          type = 'lineupDivision'
          break;
        default:
      }
    }
    
    const blockTypes: BlockTypes= {
      image: 'XcImage',
      multiimage: 'XcImage',
      audio: 'XcAudio',
      text: 'XcText',
      multiplechoice: 'XcMultipleChoice',
      blanks: 'XcBlanks',
      numberline: 'XcNumberLine',
      sortmachine: 'XcSort',
      order: 'XcOrder',
      lineup: 'XcLineup',
      lineupmultiplication: 'XcLineupMultiplication',
      lineupdivision: 'XcLineupDivision',
      fraction: 'XcFraction',
      chart: "XcChart"
    };
    return blockTypes[type.toLowerCase()] || 'NOTIMPLEMENTED';
  };

  /**
   * Check if block contains audio
   * @param {JSX.Element} Exercise
   * @param blockD
   * @returns {JSX.Element}
   */
  const hasAudio = (Exercise: JSX.Element, blockD: any): JSX.Element =>
    blockD[AUDIO]
    && blockD.audio.src
    && blockD[AUDIO].src !== ''
    && blockType !== 'blanks'
      ? renderWithSound(Exercise, blockD)
      : Exercise;

  /**
   * Check if block is audio
   * @param {JSX.Element} Exercise
   * @param blockD
   * @returns {JSX.Element}
   */
  const renderWithSound = (Exercise: JSX.Element, blockD: any): JSX.Element => blockType === AUDIO
      ? renderAudioPlayer(blockD)
      : audioWithSoundWrapper(Exercise, blockD);

  /**
   * Wrap block with sound wrapper, this block contains audio
   * @param {JSX.Element} Exercise
   * @param blockD
   * @returns {JSX.Element}
   */
  const audioWithSoundWrapper = (Exercise: JSX.Element, blockD: any): JSX.Element => {
    return <SoundWrapper Component={Exercise} url={blockD.audio.src}/>
  }

  /**
   * Render audio player, this block is audio
   * @param data
   * @returns {JSX.Element}
   */
  const renderAudioPlayer = (data: any): JSX.Element => (
    <StyledExerciseAudioPlayer>
        { data.audio
        ? <AudioPlayerComponent url={data.audio.src} condition={isHelpShowing} />
        : null }
    </StyledExerciseAudioPlayer>
  )

  const exerciseTypeKey = typeTranslator(blockType, settings);
  if (exerciseTypeKey in Exercises === false) {
    // If block type is not implemented (Typically XcInstruction), just render empty component
    return <React.Fragment />;
  }

  const blocks = Exercises as ExerciseBlockTypes;
  const ExerciseEl = blocks[exerciseTypeKey];
  const exercises = [
    "XcBlanks",
    "XcMultipleChoice",
    "XcNumberLine",
    "XcSort",
    "XcOrder",
    "XcLineup",
    "XcLineupMultiplication",
    "XcLineupDivision",
    "XcFraction",
    "XcChart"
  ];


  /**
   * Checks if is exercise
   * @param {string} key
   * @returns {boolean}
   */
  const isExercise = (key:string):boolean => exercises.indexOf(key) > -1;


  /**
   * Checks if block is operative
   * @param {BlockSettings} settings
   * @param {string} key
   * @returns {boolean}
   */
  const isOperative = (blocksettings:BlockSettings, key:string):boolean => blocksettings.operative !== undefined ? blocksettings.operative : isExercise(key);

  /**
   * Renders  the exercise
   * @returns {any}
   */
  const renderExercise = () => isOperative(settings, exerciseTypeKey)
    ?
    <ExerciseEl
      {...blockData}
      settings={settings}
      state={state}
      callback={callback}
      currentAttempt={currentAttempt}
      displayCorrectAnswer={displayCorrectAnswer}
      correctAnswers={correctAnswers}
      focusInput={focusInput}
      size={size}
    />
    :
    <ExerciseEl {...blockData} settings={settings} />;

  // TODO: Change back to operative when that data is ready from CMS! SKL-1253
  // return operative
  return hasAudio(renderExercise(), blockData);
};

export default BlockLoader;
