import * as React from "react";
import { StyledInputContainer } from "../../StyledLineup";
import { renderCross, renderComma } from "../../helpers";
import { StyledLineupInput, StyledCaret } from "./StyledLineupInput";
import { isIOSDevice } from "../../../../../../../../../shared/platform/Platform";
import { VirtualKeyboard } from "../../../../../VirtualKeyboard/VirtualKeyboard";
import {
  getKeyboardLayout,
  getKeyboardPosition,
} from "../../../../../VirtualKeyboard/helpers";
import { KeyboardData } from "../../../../../VirtualKeyboard/types";

export interface LineupInputProps {
  tabOrderPos?: number;
  hidden?: boolean;
  id: string;
  refObj: any;
  refIndex?: number;
  value: string;
  correctedStateComma?: string;
  correctedStateValue?: string;
  disabled: boolean;
  inMemory?: boolean;
  inContainer?: boolean;
  keyboardData?: KeyboardData;
  keyboardType?: number;
  type?: string;
  comma?: boolean;
  isCommaSelected?: boolean;
  commaRenderedInGrid?: boolean;
  cross?: boolean;
  cellIndex?: number;
  rowIndex?: number;
  isVertical?: boolean;
  isHorizontal?: number;
  borderBottom?: boolean;
  gridLength?: number;
  isTouchDevice: boolean;
  updateCommaCallback?: (id: string) => void;
  callback: (param: string, param2: number, param3: string) => void;
}

export const LineupInput: React.FC<LineupInputProps> = (props) => {
  React.useEffect(() => {
    const onResize = () => {
      props.isTouchDevice && props.refObj.current.blur();
    };

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [props.refObj, props.isTouchDevice]);

  const [showNumpad, setShowNumpad] = React.useState<boolean>(false);

  const updateValue = (value: string) => {
    props.callback(value, props.refIndex ? props.refIndex : 0, props.id);
  };

  const updateComma = () =>
    props.updateCommaCallback && props.updateCommaCallback(props.id);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length <= 1) {
      updateValue(e.currentTarget.value);
    }
  };

  /**
   * Checks if the lineup is of the type multiplication and has extra addition rows.
   */
  const multiplicationGridWithExtraColumns = () =>
    props.type === "multiplication" &&
    props.gridLength &&
    props.gridLength === 5
      ? true
      : false;

  /**
   * If the multiplication lineup generates 2 extra row for addition.
   * We should not show comma fields for these inputs.
   */
  const dontShowComma = () =>
    multiplicationGridWithExtraColumns() &&
    (props.rowIndex === 2 || props.rowIndex === 3);

  /**
   * Number 9 represents "Numerisk komma uppställning" in db
   */
  const isKeyboardNumericComma = () => props.keyboardType === 9;

  /**
   * Select all text on focus
   * @param {MouseEvent} event
   */
  const selectText = (event: React.FocusEvent) => {
    const inputEl = event.target as HTMLInputElement;
    /*Delay here is so text wont be instantly deselected */
    setTimeout(() => {
      isIOSDevice()
        ? inputEl.setSelectionRange(0, inputEl.value.length)
        : inputEl.select();
    }, 0);
  };

  const handleOnFocus = (e: React.FocusEvent) => {
    selectText(e);
    setShowNumpad(true);
  };

  const handleOnBlur = (e: React.FocusEvent) => {
    setShowNumpad(false);
  };

  const numpadCallback = (value: string) => {
    value === "%&" ? updateValue("") : updateValue(value); // '%&' means backspace
  };

  return (
    <>
      <StyledInputContainer
        key={"container_" + props.cellIndex}
        correctedState={props.correctedStateValue}
        isVertical={props.isVertical}
        isHorizontal={props.isHorizontal}
        borderBottom={props.borderBottom}
      >
        {
          // cursor in input field
          props.isTouchDevice && showNumpad && props.value.length < 1 && (
            <StyledCaret margin={null} />
          )
        }
        <StyledLineupInput
          tabIndex={props.tabOrderPos}
          ref={props.refObj}
          inputMode="numeric"
          onChange={handleOnChange}
          maxLength={1}
          value={props.value}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          inContainer={props.inContainer}
          correctedState={props.correctedStateValue}
          disabled={props.disabled}
          inMemory={props.inMemory}
          readOnly={props.isTouchDevice}
          type={props.type}
        />
        {renderComma(
          props.disabled,
          props.comma,
          !props.inMemory && !dontShowComma() && isKeyboardNumericComma(),
          props.correctedStateComma,
          props.isCommaSelected,
          updateComma
        )}
        {renderCross(props.correctedStateValue, props.inMemory)}
      </StyledInputContainer>
      {props.isTouchDevice && showNumpad ? (
        <VirtualKeyboard
          buttonLayout={
            props.keyboardData
              ? getKeyboardLayout(props.keyboardData)
              : undefined
          }
          callback={numpadCallback}
          position={getKeyboardPosition()}
        />
      ) : null}
    </>
  );
};
