import styled from 'styled-components';

export const StyledToggleButton = styled.button`
  pointer-events: auto;
  background-color: #F9F6F6;
  border: 1px solid #277575;
  outline: none;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  min-width: 196px;
  cursor: pointer;
  
  &:focus {
    border: 1px solid #8AB4B3;
  }
`;

export const StyledLabel = styled.span`
  color: #277575;
  font-family: Muli;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.14px;
  line-height: 25px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #277575;
  margin-left: 8px;
`;