import * as React from "react";
import {
  StyledCard,
  StyledCardBack,
  StyledCardBody,
  StyledCardFront,
  StyledCardBackBody,
} from "./StyledCard";
import { StyledTopText, StyledTopHeading } from "../Help/StyledHelp";
import { connect } from "react-redux";
import { isIOSDevice } from "../../platform/Platform";

export interface Props {
  children?: any;
  isHelpShowing: boolean;
  exercise?: any;
}

export interface CardState {
  showHelp: boolean;
}

const Card = ({ children, isHelpShowing, exercise }: Props) => {
  /**
   * Renders child
   * @param {number} index
   * @returns {JSX.Element | null}
   */
  const renderChild = (index: number): JSX.Element | null =>
    children && children[index] ? children[index] : null;

  /**
   * Renders top text "Tips från mentorn"
   */
  const renderTopText = () => (
    <StyledTopText>
      <StyledTopHeading>Tips från Mentorn</StyledTopHeading>
    </StyledTopText>
  );

  const showHelp = isHelpShowing && children;
  const isIOS = isIOSDevice();

  return (
    <StyledCard>
      <StyledCardBody showHelp={showHelp}>
        <StyledCardFront showHelp={showHelp} isIOS={isIOS}>
          {renderChild(0)}
        </StyledCardFront>

        <StyledCardBack showHelp={showHelp} isIOS={isIOS}>
          <StyledCardBackBody isTablet={isIOS}>
            {renderTopText()}
            {renderChild(1)}
          </StyledCardBackBody>
        </StyledCardBack>
      </StyledCardBody>
    </StyledCard>
  );
};

export const UnconnectedCard = Card;

const mapStateToProps = (state: any) => ({
  isHelpShowing: state.exercise.isHelpShowing,
  exercise: state.exercise,
});

export default connect(mapStateToProps)(Card);
