import styled from 'styled-components';

interface TopBarProps {
  position?: string
}

interface TopBarColumnProps {
  alignment?: string,
  left?: any,
  right?: any
}

export const StyledTopBar = styled.div`
  position: ${(props: TopBarProps) => props.position ? props.position : 'relative'};
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  top: 0;
  z-index: 20;
  flex-shrink: 0;
`;

export const TopBarColumn = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${(props: TopBarColumnProps) => {
    if ( props.alignment === 'left' ) {
      return 'flex-start';
    } else if ( props.alignment === 'center' ) {
      return 'center';
    } else if ( props.alignment === 'right' ) {
      return 'flex-end';
    } else {
      return false
    }
  }};
`;