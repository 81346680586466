import {
  StyledFormWrapper,
  OnboardingHeadingContainer,
  OnboardingHeading,
  OnboardingParagraph,
  StyledAudioContainer,
  StyledOnboardingContent,
  StyledCheckMark,
  OnboardingSelection,
  OnboardingSelectionWrapper,
  StyledIconButton,
} from "./StyledOnboarding";
import { AudioComponent } from "../../shared/components/Audio";
import { Grade } from "./api/types";

interface SelectGradeProps {
  firstName?: string;
  newUser?: boolean;
  grades: Grade[];
  selectedGrade: string;
  setIsSelectedGrade: (grade: string) => void;
}

const OnboardingSelect = ({
  firstName,
  newUser,
  grades,
  selectedGrade,
  setIsSelectedGrade,
}: SelectGradeProps) => {
  const items = grades.map((grade: Grade) => ({
    value: grade.value.toString(),
    label: `Årskurs ${grade.value}`,
  }));

  return (
    <StyledOnboardingContent>
      <OnboardingHeadingContainer>
        <StyledAudioContainer>
          <AudioComponent
            audioUrl={
              newUser
                ? "https://media.studentlitteratur.se/audio/mr/story/audio/st01_onge_14.mp3"
                : "https://media.studentlitteratur.se/audio/mr/story/audio/st01_onge_15.mp3"
            }
          />
        </StyledAudioContainer>
        <OnboardingHeading>Hej {firstName}!</OnboardingHeading>
        <OnboardingParagraph>
          {newUser
            ? "Berätta lite om dig själv. Välj vilken klass du går i här."
            : "Kul att ses igen. Vilken årskurs går du nu i?"}
        </OnboardingParagraph>
      </OnboardingHeadingContainer>
      <StyledFormWrapper>
        {items.map((item) => (
          <OnboardingSelectionWrapper key={item.value}>
            <OnboardingSelection>{item.label}</OnboardingSelection>
            <StyledIconButton onClick={() => setIsSelectedGrade(item.value)}>
              {selectedGrade === item.value && <StyledCheckMark />}
            </StyledIconButton>
          </OnboardingSelectionWrapper>
        ))}
      </StyledFormWrapper>
    </StyledOnboardingContent>
  );
};
export default OnboardingSelect;
