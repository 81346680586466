
import { StudliLogo } from "../../shared/components/StudliLogo";
import { StyledFooter, StyledMenuContainer } from "./StyledDashboard";

export const renderMainMenu = (items: JSX.Element[]) => (
  <StyledMenuContainer>
    {items}
  </StyledMenuContainer>
);

export const renderFooter = () => (
  <StyledFooter>
    <StudliLogo />
  </StyledFooter>
);