import * as React from "react";
import {
  StyledBtnLabel,
  StyledBtnState,
  StyledContinueState,
  StyledCorrBtn,
  StyledLoader,
} from "./StyledCorrectionButton";
import {
  CorrectionButtonProps,
  CorrectionButtonState,
} from "./CorrectionButtonTypes";
import { ReactComponent as NextArrow } from "../../assets/icons/next-arrow.svg";
import { CorrectionBtnStates } from "./CorrectionButtonConstants";
import { SMALL } from "../../constants";

class CorrectionButton extends React.PureComponent<
  CorrectionButtonProps,
  CorrectionButtonState
> {
  constructor(props: CorrectionButtonProps) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  public onHover = (): void => this.setState({ hover: true });

  public onLeave = (): void => this.setState({ hover: false });

  public render(): JSX.Element {
    return this.props.disabled ? this.renderLoader() : this.renderButton();
  }

  private getButtonState = (): boolean =>
    this.props.state === CorrectionBtnStates.DEFAULT ||
    this.props.state === CorrectionBtnStates.TRY_AGAIN;

  private handleClick = (): void => {
    if (this.props.disabled) {
      return;
    }
    this.props.callback();
  };

  private renderButton = () => (
    <StyledCorrBtn
      aria-label={this.getButtonState() ? "Rätta" : "Gå vidare"}
      hover={this.state.hover}
      onMouseEnter={this.onHover}
      onMouseLeave={this.onLeave}
      onClick={this.handleClick}
      state={this.props.state}
      disabled={this.props.disabled}
      label=""
    >
      {this.getButtonState() ? this.renderLabel() : this.renderNextArrow()}
    </StyledCorrBtn>
  );

  private renderLoader = () => <StyledLoader withMargin size={SMALL} />;

  private renderNextArrow = () => (
    <StyledContinueState>
      <NextArrow />
    </StyledContinueState>
  );

  private renderLabel = (): JSX.Element => (
    <StyledBtnState>
      <StyledBtnLabel>Rätta</StyledBtnLabel>
    </StyledBtnState>
  );
}

export default CorrectionButton;
