import {
  GET_ADVENTURES_REQUEST_SUCCESS,
  SET_ITEM_FEEDBACK_INDEX,
  SET_PROBLEMS,
  SET_PROGRESS,
  START_ADVENTURE_PROBLEM_REQUEST_SUCCESS,
  START_ADVENTURE_REQUEST_SUCCESS,
  GET_MARKERS_TO_COMPLETE_SUCCESS,
  GET_ALL_ADVENTURES_REQUEST_SUCCESS,
} from "../../../shared/constants";
import { ADVENTURE_GROUPS_API_ACTIONS } from "./actions";
import { AdventureGroupState } from "./types";

const initialState: AdventureGroupState = {
  adventureGroups: [],
  adventures: [],
  allAdventures: [],
  problems: [],
  progress: {
    adventureId: null,
    problemId: null,
    nextProblemId: null,
    itemFeedBackIndex: null,
    prevProblemId: null,
  },
  markersToComplete: [],
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ADVENTURE_GROUPS_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        adventureGroups: [...action.adventureGroups],
      };
    case GET_ADVENTURES_REQUEST_SUCCESS:
      return {
        ...state,
        adventures: [...action.adventures],
      };
    case GET_ALL_ADVENTURES_REQUEST_SUCCESS:
      return {
        ...state,
        allAdventures: [...action.adventures],
      };
    case SET_PROBLEMS:
      return {
        ...state,
        problems: [...action.problems],
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          ...action.progress,
          prevProblemId: !action.progress.problemId
            ? state.progress.problemId
            : state.progress.prevProblemId,
            itemFeedBackIndex: null
        },
      };
    case START_ADVENTURE_REQUEST_SUCCESS:
    case START_ADVENTURE_PROBLEM_REQUEST_SUCCESS:
      return {
        ...state,
        progress: {
          ...state.progress,
          ...action.progress,
          prevProblemId: !action.progress.problemId
            ? state.progress.problemId
            : state.progress.prevProblemId,
        },
      };
    case SET_ITEM_FEEDBACK_INDEX:
      return {
        ...state,
        progress: {
          ...state.progress,
          itemFeedBackIndex: action.index,
        },
      };
    case GET_MARKERS_TO_COMPLETE_SUCCESS:
      return {
        ...state,
        markersToComplete: action.markersToComplete && [
          ...action.markersToComplete,
        ],
      };
    default:
      return state;
  }
}
