import * as React from "react";
import { RoundButton } from "../../../Button/RoundButton";
import {
  StyledHelpButtonRack,
  StyledButtonContainer,
  StyledButtonText,
} from "./StyledHelpButtonRack";
import { Image } from "react-feather";
import { Key } from "react-feather";
import { BookOpen } from "react-feather";
import { FileText } from "react-feather";
import { ReactComponent as SoundIcon } from "../../../../../assets/icons/icon_speaker.svg";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/icon_camera.svg";
import { HelpItem } from "../../../../../views/exercise/api/types";

interface Props {
  help: HelpItem[];
  activeIndex: number;
  callback: (e: any) => void;
}

export const HelpButtonRack: React.FC<Props> = ({
  help,
  activeIndex,
  callback,
}) => {
  const getButtonText = (type: string): string => {
    switch (type) {
      case "about":
        return "Om";

      case "helpaudio":
        return "Ljud";

      case "helpimage":
        return "Bild";

      case "tips":
        return "Tips";

      case "mathword":
        return "Matteord";

      case "helpvideo":
        return "Video";

      default:
        return "Hjälp";
    }
  };

  const getIcon = (type: string): JSX.Element => {
    switch (type) {
      case "about":
        return <FileText />;

      case "helpaudio":
        return <SoundIcon />;

      case "helpimage":
        return <Image />;

      case "tips":
        return <Key />;

      case "mathword":
        return <BookOpen />;

      case "helpvideo":
        return <VideoIcon />;

      default:
        return <SoundIcon />;
    }
  };

  const renderButton = (helpItem: HelpItem, index: number): JSX.Element => {
    return (
      <StyledButtonContainer key={"ID" + index}>
        <RoundButton
          key={"ID" + index}
          id={index}
          skin={index === activeIndex ? "primary" : "standard"}
          callback={callback}
          icon={getIcon(helpItem.type)}
          overrideStyles={{ padding: "23px" }}
          aria-label="Hjälp"
        />
        <StyledButtonText>{getButtonText(helpItem.type)}</StyledButtonText>
      </StyledButtonContainer>
    );
  };

  return <StyledHelpButtonRack>{help.map(renderButton)}</StyledHelpButtonRack>;
};
