import styled from 'styled-components';
import {
  themePrimary,
  themeLight,
  themeLightActive
} from "../../../styles/shared/colors";

export interface BgBarProps {
  width: number;
}

export interface SoundButtonProps {
  playing: boolean;
}

const transitionSpeed: number = 100;

export const StyledSoundWrapper = styled.div`
  display: contents;
 `;

export const StyledSoundButton = styled.button`
  font-family: 'Muli';
  color: ${themePrimary};
  background: ${( {playing}: SoundButtonProps) => playing ? themeLightActive : themeLight} ;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  margin: 0;
`;
  
export const StyledSoundBackgroundBar = styled.div`
  display: contents;
  background: ${themePrimary};
  height: 5px;
  width: ${({ width }: BgBarProps) => width}%;
  align-self: bottom;
  
  -webkit-transition: width ${transitionSpeed}ms;
  transition: width ${transitionSpeed}ms;
`;