import * as actions from "./actions";
import { reducer } from "./reducer";
import { watchAddFlashSaga } from "./sagas";


const FlasherStore = {
  actions,
  reducer,
  watchAddFlashSaga
};

export default FlasherStore;