import React from "react";
import HtmlRenderer from "../HtmlRenderer";
import { StyledDetailsWrapper } from "./StyledAdventureCarousel";

type Props = {
  details: string | undefined;
  viewSize: "tablet" | "desktop";
};

const AdventureDetails = ({ details = "", viewSize }: Props) => {
  return (
    <StyledDetailsWrapper viewsize={viewSize}>
      <HtmlRenderer html={details} />
    </StyledDetailsWrapper>
  );
};

export default AdventureDetails;
