import * as React from "react";
import { ReactComponent as StudliBow } from "../../../../assets/logos/studli_bow.svg";
import { ReactComponent as StudliLogo } from "../../../../assets/logos/studli_logo.svg";
import { ReactComponent as TomoyoLogo } from "../../../../assets/logos/tomoyo_logo_color.svg";
import {
  StyledCampaignWrapper,
  StyledHeading,
  StyledTiledBG,
  StyledLogoContainer,
  StyledCampaignLinkContainer,
  StyledCampaignContainer,
  StyledInfoText,
  StyledSmallSubHeading,
  StyledLinksContainer,
  StyledLink,
  StyledCampaignLink,
  StyledLinkText,
  StyledCampaignImage,
  StyledBowContainer,
  StyledStudliLogoContainer,
} from "../FreeTomoyo/StyledFreeTomoyo";
import { Campaign } from "../../../store/auth/types";
import { CampaignError } from "../CampaignError/CampaignError";
import { Modal } from "../../Modal";
import MetaTags from "react-meta-tags";

const renderOGTags = () => (
  <div className="wrapper">
    <MetaTags>
      <title>Tomoyo</title>
      <meta property="og:title" content="Tomoyo" />
      <meta
        property="og:image"
        content="https://media.studentlitteratur.se/product_images/pcatimg/9789144135854.jpg"
      />
    </MetaTags>
  </div>
);

interface Props {
  campaignData: Campaign;
  error: boolean;
  url: string;
}

export const TomoyoDemo: React.FC<Props> = (props) => {
  const [showErrorMessage, setShowErrorMessage] = React.useState(props.error);

  const errorHeading = "Vad roligt att du vill prova Tomoyo!";
  const firstInfo = (
    <StyledInfoText>
      Du har redan använt en provperiod med det här kontot.
    </StyledInfoText>
  );
  const secondInfo = (
    <StyledInfoText>
      Vill du prova igen? Kontakta oss så hjälper vi dig.
    </StyledInfoText>
  );
  const thirdInfo = (
    <StyledInfoText>
      Du kan också gå till Min bokhylla för att se dina läromedel eller aktivera
      nya.
    </StyledInfoText>
  );

  const getDestLink = (destination: string) => {
    const dest = btoa(destination);
    return `${props.url}?dest=${dest}`;
  };

  const removeHashFromUrl = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    );
  };

  const closeErrorMessage = () => {
    removeHashFromUrl();
    setShowErrorMessage(false);
  };

  const renderModal = () => (
    <Modal show={showErrorMessage}>
      <CampaignError
        heading={errorHeading}
        firstInfo={firstInfo}
        secondInfo={secondInfo}
        thirdInfo={thirdInfo}
        renderLinks={true}
        bookShelf={props.campaignData.bookshelf_host}
        callback={closeErrorMessage}
      />
    </Modal>
  );

  const renderContent = () => (
    <>
      <StyledHeading>Prova Tomoyo</StyledHeading>
      <StyledInfoText fontWeight="bold">
        – ett nytt sätt att räkna matte!
      </StyledInfoText>
      <StyledInfoText>
        Här kan du som är lärare prova Tomoyo i 30 dagar helt kostnadsfritt.
        Allt du behöver göra är att skapa ett konto. Därefter har du möjlighet
        att prova Tomoyo som elev i någon av årskurserna 1–6.
      </StyledInfoText>
      <StyledCampaignLinkContainer justifyContent="center">
        <StyledCampaignLink>
          <a href={getDestLink(props.campaignData.bookshelf_host)}>
            <StyledLinkText>PROVA TOMOYO</StyledLinkText>
          </a>
        </StyledCampaignLink>
      </StyledCampaignLinkContainer>
      <StyledInfoText>
      Tomoyo är ett spelifierat digitalt läromedel i matematik och ingår kostnadsfritt för alla användare av Favorit matematik åk 1–6, Rik matematik och Matteblixt åk 1–3. Med motivationshöjande inslag från spelens värld ökar eleverna sina
        kunskaper inom grundläggande matematiska moment.
      </StyledInfoText>
      <StyledCampaignImage
        src="https://media.studentlitteratur.se/image/mr/story/images/promo/free-tomoyo-tablet.png"
        alt="kampanj-bild"
      />

      <StyledCampaignLinkContainer>
        <StyledCampaignLink>
          <a href="https://www.studentlitteratur.se/tomoyo">
            <StyledLinkText>LÄS MER OM TOMOYO</StyledLinkText>
          </a>
        </StyledCampaignLink>
        <StyledCampaignLink>
          <a href="https://www.studentlitteratur.se/favoritmatematik">
            <StyledLinkText>LÄS MER OM FAVORIT MATEMATIK</StyledLinkText>
          </a>
        </StyledCampaignLink>
        <StyledCampaignLink>
          <a href="https://www.studentlitteratur.se/matteblixt">
            <StyledLinkText>LÄS MER OM MATTEBLIXT</StyledLinkText>
          </a>
        </StyledCampaignLink>
        <StyledCampaignLink>
          <a href="https://www.studentlitteratur.se/serier/rik-matematik/">
            <StyledLinkText>LÄS MER OM RIK MATEMATIK</StyledLinkText>
          </a>
        </StyledCampaignLink>

      </StyledCampaignLinkContainer>
      <StyledInfoText>
      Har du redan Favorit matematik åk 1–6, Rik matematik eller Matteblixt åk 1–3? Du kommer åt Tomoyo genom din digitala bokhylla.
      </StyledInfoText>

      <StyledLink href="https://minbokhylla.studentlitteratur.se/">
        Öppna Min bokhylla
      </StyledLink>

      <StyledLinksContainer>
        <StyledSmallSubHeading>MER INFORMATION</StyledSmallSubHeading>
        <StyledLink href="https://www.studentlitteratur.se/tomoyo-faq">
          Tomoyo FAQ
        </StyledLink>
        <StyledLink href="https://www.studentlitteratur.se/om-oss/kontakta-oss/kundservice/">
          Kontakta kundservice
        </StyledLink>
      </StyledLinksContainer>
    </>
  );

  return (
    <StyledCampaignWrapper>
      {renderOGTags()}
      <StyledTiledBG backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg">
        {props.error && renderModal()}
        <StyledStudliLogoContainer>
          <StudliLogo fill="#FFFFFF" />
        </StyledStudliLogoContainer>
        <StyledBowContainer>
          <StudliBow />
        </StyledBowContainer>
        <StyledCampaignContainer>
          <StyledLogoContainer>
            <TomoyoLogo />
          </StyledLogoContainer>
          {props.campaignData.enabled ? (
            renderContent()
          ) : (
            <p>Kampanjen har utgått</p>
          )}
        </StyledCampaignContainer>
      </StyledTiledBG>
    </StyledCampaignWrapper>
  );
};
