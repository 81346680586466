import styled from 'styled-components';
import { getBorderColor } from '../../helpers';

interface CellProps {
  correctedState?: string;
  borderBottom?: boolean;
  clickable?: boolean;
  inContainer?: boolean;
}

export const StyledMemoryCell = styled.div<CellProps>`
  position: relative;
  height: auto;
  width: 100%;
  top: 48%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.clickable ? 'pointer' : 'auto'};
  ${props => props.borderBottom && 'border-bottom: 2px solid #979797'};
  border: ${ props => props.inContainer ? '0px' : props.correctedState !== undefined ? getBorderColor(props.correctedState) : getBorderColor('DEFAULT')};
`;

export const StyledCrossLine = styled.div`
  position: absolute;
  width: 2px;
  height: 90%;
  background-color: #277575;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(135deg);
`;
