import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as qs from "query-string";
import { RootState } from "../../../store";
import {
  AUTH_TOKEN_API_ACTIONS,
  CAMPAIGN_REQUEST,
} from "../../store/auth/actions";
import {
  AUTH_LOCALSTORAGE_ORIGINAL_URL_KEY,
  CAMPAIGN_URL_IDENTIFIER,
} from "../../../shared/constants";
import { Campaign } from "../../store/auth/types";
import { FreeTomoyo } from "../../components/Campaign/FreeTomoyo";
import { TomoyoDemo } from "../../components/Campaign/TomoyoDemo/TomoyoDemo";

export interface AuthProps {
  children?: React.ReactChild;
  jwt: string;
  campaign: Campaign;
  onRequestAuth(code: string, state: string, redirectUriParam: string): void;
  onCampaignRequest(url: string): void;
}

const authUrl =
  process.env.REACT_APP_AUTH_URL ||
  "https://loggain.test.studentlitteratur.se/oauth2/authorize.php";
const redirectUri =
  process.env.REACT_APP_AUTH_REDIRECT_URI ||
  `${window.location.protocol}//${window.location.host}/`;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "products";
const scope = "openid%20offline_access";
const tokenUrl = process.env.REACT_APP_AUTH_TOKEN_URL || "/token";

const mapStateToProps = (state: RootState) => ({
  jwt: state.auth.jwt,
  campaign: state.auth.campaign,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRequestAuth: (code: string, state: string, redirectUriParam: string) =>
    dispatch({
      type: AUTH_TOKEN_API_ACTIONS.READ.REQUEST,
      payload: { tokenUrl, code, state, redirectUri: redirectUriParam },
    }),

  onCampaignRequest: (campaignName: string) =>
    dispatch({
      type: CAMPAIGN_REQUEST,
      payload: {
        campaignName,
      },
    }),
});

class AuthComponent extends React.Component<AuthProps> {
  public componentDidMount() {
    const queryParams = qs.parse(window.location.search);
    const pathname = window.location.pathname;

    if (pathname.includes(CAMPAIGN_URL_IDENTIFIER)) {
      const campaignUrl = pathname.split(CAMPAIGN_URL_IDENTIFIER).pop();

      if (campaignUrl) {
        const campaignName = campaignUrl.split("/").shift();
        campaignName && this.props.onCampaignRequest(campaignName);
      }
    } else {
      // Check if auth code is present in querystring
      if (queryParams && queryParams.code && queryParams.state) {
        // If so, then get token from token url
        this.props.onRequestAuth(
          queryParams.code.toString(),
          queryParams.state.toString(),
          redirectUri
        );
        // Get original URL and set it now when auth is done
        const originalUrl =
          window.localStorage.getItem(AUTH_LOCALSTORAGE_ORIGINAL_URL_KEY) ||
          "/";
        window.localStorage.removeItem(AUTH_LOCALSTORAGE_ORIGINAL_URL_KEY);
        window.history.replaceState({}, document.title, originalUrl);
      } else if (!this.props.jwt) {
        // Store original URL for redirect after auth is done
        window.localStorage.setItem(
          AUTH_LOCALSTORAGE_ORIGINAL_URL_KEY,
          window.location.href
        );
        // Redirect to authorize url
        window.location.href = this.getLoginUrl();
      }
    }
  }

  public render() {
    const { campaign, children } = this.props;
    return campaign ? (
      this.getCampaignComponent(campaign.id)
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );
  }

  private getCampaignRedirectUrl(): string {
    const state = this.generateState();
    return `${authUrl}?state=${state}&scope=openid%20offline_access&response_type=code&approval_prompt=auto&client_id=products&redirect_uri=${this.props.campaign.redirect_url}`;
  }

  private getLoginUrl(): string {
    const state = this.generateState();
    return `${authUrl}?state=${state}&scope=${scope}&response_type=code&approval_prompt=auto&redirect_uri=${redirectUri}&client_id=${clientId}`;
  }

  private generateState() {
    let state = "";
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 30; i++) {
      state += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return state;
  }

  private getCampaignComponent = (campaignId: string) => {
    const hashParams = qs.parse(window.location.hash);

    switch (campaignId) {
      case "CampaignFreeTomoyo":
        return (
          <FreeTomoyo
            error={hashParams && !!hashParams.fel}
            currentHostUrl={redirectUri}
            campaignData={this.props.campaign}
            url={this.getCampaignRedirectUrl()}
          />
        );
      case "CampaignTomoyoDemo":
        return (
          <TomoyoDemo
            campaignData={this.props.campaign}
            error={hashParams && !!hashParams.fel}
            url={this.getCampaignRedirectUrl()}
          />
        );
    }
  };
}

export const Auth = connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
