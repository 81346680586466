import * as React from "react";
import { RoundButton } from "../../Button/RoundButton";
import { ReactComponent as SoundIcon } from "../../../../assets/icons/icon_speaker.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/icons/icon_pause.svg";

interface Props {
  isPlaying: boolean;
  styles?: Object;
  audioColor?: { active: string; notActive: string };
  callback: () => void;
}

const defaultAudioColor = { active: "#F9F6F6", notActive: "#277575" };

export const AudioButton: React.FC<Props> = ({
  isPlaying,
  styles,
  audioColor,
  ...rest
}) => {
  const buttonStyles = {
    flexShrink: "0",
    padding: "6px",
    height: "42px",
    width: "42px",
    boxShadow: "inset 0 1px 0 0 #DFDCDC, 0 0 14px 0 rgba(0,0,0,0.35)",
  };

  const selectedAudioColor = audioColor ? audioColor : defaultAudioColor;

  const soundIcon = (
    <SoundIcon
      color={
        isPlaying ? selectedAudioColor.active : selectedAudioColor.notActive
      }
    />
  );
  const pauseIcon = (
    <PauseIcon
      color={
        isPlaying ? selectedAudioColor.active : selectedAudioColor.notActive
      }
    />
  );

  return (
    <RoundButton
      icon={isPlaying ? pauseIcon : soundIcon}
      skin={isPlaying ? "primary" : "standard"}
      overrideStyles={{ ...buttonStyles, ...styles }}
      {...rest}
    />
  );
};

export default AudioButton;
