import * as React from "react";
import {
  StyledButton,
  StyledButtonContentContainer,
  StyledButtonIcon,
  StyledButtonIconContainer,
  StyledButtonLabelContainer
} from './StyledButton';

export interface ButtonProps {
  align?: string;
  animation?: string,
  children?: React.ReactNode;
  onClick?: () => void;
  skin?: string;
  theme?: any;
  label?: string;
  icon?: any;
  labelFirst?: boolean;
  circular?: boolean;
  overrideStyles?: object;
  disabled?: boolean;
  active?: boolean;
  onFocus?: () => void;
}

export const Button: React.FC<ButtonProps> = ({ children, onClick, icon, label, labelFirst, overrideStyles, disabled, active = false, onFocus, ...rest }) => {
  /**
   * Icon renderer
   * @returns {null}
   */
  const iconRenderer = () =>
    <StyledButtonIconContainer>
      {typeof icon === 'string'
        ? <StyledButtonIcon src={icon} alt="" />
        : icon}
    </StyledButtonIconContainer>

  /**
   * Render label
   * @returns {null}
   */
  const labelRenderer = () =>
    <StyledButtonLabelContainer>
      {label}
    </StyledButtonLabelContainer>

  /**
   * Handles user click
   */
  const handleOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const handleOnFocus = () => {
    if (!disabled && onFocus) {
      onFocus()
    }
  }

  /**
   * Check for rendering label first
   * @returns {boolean}
   */
  const isLabelFirst = () => typeof labelFirst !== 'undefined';

  return (
    <StyledButton
      style={overrideStyles}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      active={active}
      {...rest}
    >
      <StyledButtonContentContainer labelFirst={isLabelFirst()}>
        {icon && iconRenderer()}
        {label && labelRenderer()}
        {children}
      </StyledButtonContentContainer>
    </StyledButton>
  );
};