import styled, {keyframes} from 'styled-components';
import { getBorderColor } from '../../helpers';

interface InputProps {
  correctedState?: string;
  inContainer?: boolean;
  inMemory?: boolean;
  type?: string;
}
interface CaretProps {
  margin: number|null;
}

export const StyledLineupInput = styled.input<InputProps>`
  height: 100%;
  border-radius: 0;
  width: 100%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${ props => props.inContainer ? '0px' : props.correctedState !== undefined ? getBorderColor(props.correctedState) : getBorderColor('DEFAULT')};
  color: #277575;
  caret-color: #333333;
  text-align: ${ props => props.inMemory && props.type === 'division'  ? 'left' : 'center' };
  font-family: inherit;
  font-size: ${ props => props.inMemory ? '18px' : '32px' };
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  transition: border-color .2s ease-in-out;
  padding: 0;
  padding-top: ${ props => props.inMemory ? '50%' : '0%'};
  margin: 0;
  z-index: 1;

  &:focus {
    outline: none;
    border-color: #277575;
  }

  &:disabled {
    opacity: 1;
    color: #333333;
    -webkit-text-fill-color: #333333;
  }
`;

export const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

export const StyledCaret = styled.div<CaretProps>`
  opacity: 0;
  height: 65%;
  width: 2px;
  background-color: ${props => props.theme.themePrimary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${props => props.margin ? `translate(${props.margin}px, -50%)` : `translate(-50%, -50%)`};
  animation: ${blink} 1.25s cubic-bezier(.215, .61, .355, 1) forwards infinite;
`;
