import * as React from 'react';

import { DASHBOARD_ROUTE } from "../../../views/routes";
import startIcon from '../../../assets/icons/start.svg';
import { StyledNavigatorHome } from './StyledHomeRoute'

export const HomeButton: React.FC = ({ ...rest }) => {
  const icon = <img src={startIcon} alt="Hem ikon" style={{ height: '25px' }} />;

  return (
    <StyledNavigatorHome route={DASHBOARD_ROUTE} icon={icon} circular />
  )
};