import * as React from "react";
import { StyledSortItemContainer } from "./StyledSortItem";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Chip } from "./Chip";

interface Props {
  chipsFormat: string;
  id: string;
  image?: {
    src: string;
  };
  assets?: {
    icons: any;
    format: any;
  };
  label?: string;
  state: string;
  correctedState?: string;
  isDisabled: boolean;
  prefilled?: boolean;
  callback: (param: string) => void;
}

export const SortItem = (props: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.prefilled) {
      return;
    }
    e.stopPropagation();
    props.callback(props.id);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: "sort-item",
      id: props.id,
      label: props.label,
      image: props.image,
      assets: props.assets,
    },
    type: "sort-item",
    canDrag: props.isDisabled && !props.prefilled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  /*
   * Remove default drag-preview image since we are
   * displaying a custom drag layer instead
   */
  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <StyledSortItemContainer
      ref={drag}
      onMouseUp={handleClick}
      chipsFormat={props.chipsFormat}
    >
      <Chip
        chipsFormat={props.chipsFormat}
        label={props.label}
        state={props.state}
        image={props.image}
        assets={props.assets}
        prefilled={props.prefilled}
        correctedState={props.correctedState}
        isDragging={isDragging}
      />
    </StyledSortItemContainer>
  );
};
