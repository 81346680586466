import * as React from "react";
import {
  StyledVideo,
  StyledHelpHeading,
  StyledColumnContent
} from "../StyledHelp";
import { HelpData } from "../../../../views/exercise/api/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { LOG_MEDIA_PLAYED, VIDEO_PLAYED } from "../../../constants";

const selectIsHelpShowing = (state: RootState) => state.exercise.isHelpShowing;

interface Props {
  data: HelpData;
}

const HelpVideo: React.FunctionComponent<Props> = ({ data }) => {
  const ref = React.useRef<HTMLVideoElement>(null);
  const contextMenus = (e: any) => e.preventDefault();
  const isHelpShowing = useSelector(selectIsHelpShowing);
  const dispatch = useDispatch();

  /**
   * According to Apple, hardware H264 decoder do not release the
   * video resources, we need to explicitly remove the video from
   * the decoder buffer or else it will not be able to decode more
   * videos. This is the solution provided from Apple.
   */
  React.useEffect(() => {
    const currentVideo = ref.current;
    return () => {
      if (currentVideo) {
        currentVideo.remove();
        currentVideo.src = "";
        currentVideo.srcObject = null;
        currentVideo.load();
      }
    };
  }, []);

  React.useEffect(() => {
    const videoCallback = () =>
      dispatch({ type: LOG_MEDIA_PLAYED, typeOfMediaPlayed: VIDEO_PLAYED });
    const videoNode = ref.current;
    if (videoNode) {
      videoNode.addEventListener("ended", videoCallback);
      return () => videoNode.removeEventListener("ended", videoCallback);
    }
  }, [ref, dispatch]);

  /**
   *  Reload video when source changes.
   * Necessary for Safari iOS 10
   */
  const hasVideoSrc = data.video && data.video.src;
  React.useEffect(() => {
    ref.current && ref.current.load();
  }, [hasVideoSrc]);

  const pauseVideo = () => {
    ref.current!.pause();
  };

  if (ref.current && !isHelpShowing) {
    pauseVideo();
  }

  return (
    <StyledColumnContent>
      <StyledVideo>
        <StyledHelpHeading>{data.title}</StyledHelpHeading>
        {data.video ? (
          <video
            ref={ref}
            onContextMenu={contextMenus}
            controls={true}
            controlsList={"nodownload"}
          >
            <source
              src={data.video.src + "#t=0.1"} // force predownload and set first frame.
              type={"video/mp4"}
            />
          </video>
        ) : null}
      </StyledVideo>
    </StyledColumnContent>
  );
};
export default HelpVideo;
