import styled from 'styled-components'

export const StyledButtonTextWrapper = styled.span`
padding: 0px 5px;
display: flex;
align-items: center;
justify-content: center;
line-height: 1
height: 100%;
max-height: 100%;
`
