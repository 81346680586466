import { ErrorType, GAEvent } from "./types";

export const RECAP: string = "RECAP";
export const ONBOARDING: string = "ONBOARDING";
export const ONBOARDING_GRADES: string = "ONBOARDING_GRADES";
export const SET_ONBOARDING_COMPLETED = "SET_ONBOARDING_COMPLETED";
export const ADVENTURE_GROUPS = "ADVENTURE_GROUPS";
export const START_ADVENTURE_REQUEST = "START_ADVENTURE_REQUEST";
export const START_ADVENTURE_PROBLEM_REQUEST =
  "START_ADVENTURE_PROBLEM_REQUEST";
export const GET_ADVENTURES_REQUEST = "GET_ADVENTURES_REQUEST";
export const GET_ADVENTURES_REQUEST_SUCCESS = "GET_ADVENTURES_REQUEST_SUCCESS";
export const GET_ADVENTURE_CARD_REQUEST = "GET_ADVENTURE_CARD_REQUEST";
export const GET_ADVENTURE_CARD_REQUEST_SUCCESS =
  "GET_ADVENTURE_CARD_REQUEST_SUCCESS";
export const START_ADVENTURE_REQUEST_SUCCESS =
  "START_ADVENTURE_REQUEST_SUCCESS";
export const GET_ALL_ADVENTURES_REQUEST = "GET_ALL_ADVENTURES_REQUEST";
export const GET_ALL_ADVENTURES_REQUEST_SUCCESS =
  "GET_ALL_ADVENTURES_REQUEST_SUCCESS";
export const START_ADVENTURE_PROBLEM_REQUEST_SUCCESS =
  "START_ADVENTURE_PROBLEM_REQUEST_SUCCESS";
export const GET_MARKERS_TO_COMPLETE_SUCCESS =
  "GET_MARKERS_TO_COMPLETE_SUCCESS";
export const GET_MARKERS_TO_COMPLETE_ERROR = "GET_MARKERS_TO_COMPLETE_ERROR";
export const START_ADVENTURE_PROBLEM_REQUEST_ERROR =
  "START_ADVENTURE_PROBLEM_REQUEST_ERROR";
export const SET_ACTIVE_ADVENTURE = "SET_ACTIVE_ADVENTURE";
export const COMPLETE_PROBLEM = "COMPLETE_PROBLEM";
export const RESET_PROBLEM = "RESET_PROBLEM";
export const RESET_GAME_PROGRESS_REQUEST = "RESET_GAME_PROGRESS_REQUEST";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_ITEM_FEEDBACK_INDEX = "SET_ITEM_FEEDBACK_INDEX";
export const ADD_SNACKBAR_MESSAGE = "ADD_SNACKBAR_MESSAGE";
export const REMOVE_SNACKBAR_MESSAGE = "REMOVE_SNACKBAR_MESSAGE";
export const REMOVE_SNACKBAR_MESSAGES = "REMOVE_SNACKBAR_MESSAGES";
export const CLEAR_SNACKBAR_MESSAGES = "CLEAR_SNACKBAR_MESSAGES";
export const REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE =
  "REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE";
export const SET_PROBLEMS = "SET_PROBLEMS";
export const ASSIGNMENTS = "ASSIGNMENTS";
export const ASSIGNMENT_UPDATED = "assignment_updated";
export const CHARACTERS: string = "CHARACTERS";
export const STORY: string = "STORY";
export const STORY_FROMGRADE: string = "STORY_FROMGRADE";
export const EXERCISE: string = "EXERCISE";
export const PROFILE: string = "PROFILE";
export const AUTH_TOKEN: string = "AUTH_TOKEN";
export const PROGRESS_RESET_FAILURE: string = "PROGRESS_RESET_FAILURE";
export const INTERMEDIATE: string = "INTERMEDIATE";
export const ITEM: string = "ITEM";
export const MARKERS: string = "MARKERS";
export const EMPTY: string = "EMPTY";
export const APPLICATION_PRODUCT = "APPLICATION_PRODUCT";
export const APPLICATION_MOUNT = "APPLICATION_MOUNT";
export const APPLICATION_READY = "APPLICATION_READY";
export const FIRST: string = "FIRST";
export const NEXT: string = "NEXT";
export const PREVIEW: string = "PREVIEW";
export const RESULT: string = "RESULT";
export const NOT_READY: string = "NOT_READY";
export const READY: string = "READY";
export const DECK_FINISHED: string = "DECK_FINISHED";
export const NEXT_DECK_STATUS: string = "NEXT_DECK_STATUS";
export const EXERCISE_FINISHED: string = "EXERCISE_FINISHED";
export const SPLASH_SET_VISIBILITY: string = "SPLASH_SET_VISIBILITY";
export const SPLASH_MODAL_VISIBILITY: string = "SPLASH_MODAL_VISIBILITY";
export const DIALOG_SET_VISIBILITY: string = "DIALOG_SET_VISIBILITY";
export const ADD_SLIDE_INDEX_TYPES: string = "ADD_SLIDE_INDEX_TYPES";
export const UPDATE_SLIDE_STATE: string = "UPDATE_SLIDE_STATES";
export const LOADED: string = "LOADED";
export const ENTERING: string = "ENTERING";
export const IN_VIEW: string = "IN_VIEW";
export const LEAVING: string = "LEAVING";
export const OUT_OF_VIEW: string = "OUT_OF_VIEW";
export const EXPIRY_NOTICE_READ_SUCCESS: string = "EXPIRY_NOTICE_READ_SUCCESS";
export const EXPIRY_NOTICE_READ_FAILURE: string = "EXPIRY_NOTICE_READ_FAILURE";

export const CHANGE_EXERCISE_VIEW_SLIDE: string = "CHANGE_EXERCISE_VIEW_SLIDE";
export const CREATE_NEW_DECK: string = " CREATE_NEW_DECK";
export const HANDLE_NEW_EXERCISE_DATA: string = "HANDLE_NEW_EXERCISE_DATA";
export const WATCH_CARD_UPDATE: string = "WATCH_CARD_UPDATE";
export const HANDLE_CARD_STATE_CHANGE: string = "HANDLE_CARD_STATE_CHANGE";
export const START_EXERCISE: string = "START_EXERCISE";
export const SET_ANSWER_ID: string = "SET_ANSWER_ID";
export const EXERCISE_INITIATE_ANSWER_FAILURE: string =
  "EXERCISE_INITIATE_ANSWER_FAILURE";
export const EXERCISE_SUBMIT_ANSWER_FAILURE: string =
  "EXERCISE_SUBMIT_ANSWER_FAILURE";
export const EXERCISE_SUBMIT_ANSWER_SUCCESS: string =
  "EXERCISE_SUBMIT_ANSWER_SUCCESS";
export const EXERCISE_SUBMIT_ANSWER_CALL: string =
  "EXERCISE_SUBMIT_ANSWER_CALL";
export const SET_OBTAINED_ITEM_ID: string = "SET_OBTAINED_ITEM_ID";
export const TOGGLE_HELP: string = "TOGGLE_HELP";

export const ANSWERING: string = "ANSWERING";
export const VIDEO_PLAYED: string = "VIDEO_PLAYED";
export const AUDIO_PLAYED: string = "AUDIO_PLAYED";

export const ID: string = "ID";
export const LEFT: string = "left";
export const RIGHT: string = "right";
export const STEPS: string = "steps";
export const START: string = "start";
export const END: string = "end";
export const TOP: string = "top";
export const MIDDLE: string = "middle";
export const BOTTOM: string = "bottom";
export const NUMBER: string = "number";
export const NORMAL: string = "normal";
export const ERROR_MARGIN: string = "errorMargin";
export const IS_ACTIVE: string = "isActive";
export const INTERVAL: string = "interval";
export const MARKING: string = "marking";
export const LINES: string = "lines";
export const NO_LINES: string = "noLines";
export const NUMBER_LINE: string = "numberLine";
export const JUMP: string = "jump";
export const FIXED: string = "fixed";
export const AUTO: string = "auto";
export const AUDIO: string = "audio";

export const CORRECT_ANSWER: string = "CORRECT_ANSWER";

export const CREATE: string = "CREATE";
export const READ: string = "READ";
export const UPDATE: string = "UPDATE";
export const DELETE: string = "DELETE";

export const CREATE_DECK: string = "CREATE_DECK";
export const NEXT_CARD: string = "NEXT_CARD";
export const UPDATE_CARD: string = "UPDATE_CARD";

export const AUTH_LOCALSTORAGE_ORIGINAL_URL_KEY = "AUTH_ORIGINAL_URL_KEY";

export const FLASHER_ADD_FLASH: string = "FLASHER_ADD_FLASH";
export const FLASHER_CLEAR_FLASH: string = "FLASHER_CLEAR_FLASH";

export const ERROR_TYPES: ErrorType = {
  INFO: "INFO",
  ERROR: "ERROR",
};

export const ITEM_STORY: string = "ITEM_STORY";

export const WS_SOCKET_OPENED: string = "WS_SOCKET_OPENED";
export const WS_SOCKET_ERROR: string = "WS_SOCKET_ERROR";
export const WS_SOCKET_CLOSED: string = "WS_SOCKET_CLOSED";

export const NETWORK_CONNECTED: string = "NETWORK_CONNECTED";
export const NETWORK_DISCONNECTED: string = "NETWORK_DISCONNECTED";
export const NETWORK_DISCONNECTED_DELAYED: string =
  "NETWORK_DISCONNECTED_DELAYED";
export const NETWORK_CHECK_CONNECTION: string = "NETWORK_CHECK_CONNECTION";

export const TEST_ANSWERS_NEXT_PAGE: string = "TEST_ANSWERS_NEXT_PAGE";
export const TEST_ANSWERS_PREVIOUS_PAGE: string = "TEST_ANSWERS_PREVIOUS_PAGE";
export const TEST_UPDATE_GOAL_PROGRESS_REQUEST: string =
  "TEST_UPDATE_GOAL_PROGRESS_REQUEST";
export const TEST_UPDATE_GOAL_PROGRESS_SUCCESS: string =
  "TEST_UPDATE_GOAL_PROGRESS_SUCCESS";
export const TEST_UPDATE_GOAL_PROGRESS_FAILURE: string =
  "TEST_UPDATE_GOAL_PROGRESS_FAILURE";
export const TEST_ANSWERS: string = "TEST_ANSWERS";
export const TEST_GOALS_SET_SELECTED: string = "TEST_GOALS_SET_SELECTED";
export const TEST_SET_ABILITY: string = "TEST_SET_ABILITY";
export const TEST_SET_GOAL_SCORE: string = "TEST_SET_GOAL_SCORE";
export const TEST_GOALS: string = "TEST_GOALS";
export const TEST_CONTENT_PROGRESS: string = "TEST_CONTENT_PROGRESS";
export const TEST_MARKER_GROUPS = "TEST_MARKER_GROUPS";
export const TEST_SET_MARKER_GROUP_SELECTED = "TEST_SET_MARKER_GROUP_SELECTED";

export const GA_EVENT: GAEvent = {
  EVENT_CATEGORY: "Tomoyo",
  EVENT_ACTIONS: {
    CORRECT_ANSWER: "Korrekt svar",
    INCORRECT_ANSWER: "Felaktigt svar",
    BADGE_COMPLETED: "Fick märke",
    ITEM_COMPLETED: "Slutförde föremål",
  },
};

export const ACCESS: string = "ACCESS";
export const MINIMUM_DEVICE_WIDTH = 690;
export const MINIMUM_DEVICE_HEIGHT = 450;

export const SCREEN_BLOCKER_MESSAGE: string =
  "Tomoyo är inte tillgänglig på för liten skärmyta. Prova att vända på enheten eller använd en med större skärm, till exempel en surfplatta eller dator.";
export const NETWORK_ERROR_MESSAGE: string = "Vi förlorade visst kontakten.";
export const NETWORK_ERROR_MESSAGE_2: string = "Försöker återansluta.";

export const CAMPAIGN_URL_IDENTIFIER: string = "/prova/";

export const PRIMARY_SCHOOL: string = "primary-school";
export const MIDDLE_SCHOOL: string = "middle-school";

export const PROBLEM_SPECIFIC: string = "problem_specific";
export const PROBLEM_INDEPENDENT: string = "problem_independent";

export const LOG_VIDEO_PLAYED: string = "LOG_VIDEO_PLAYED";
export const LOG_MEDIA_PLAYED: string = "LOG_MEDIA_PLAYED";

export const GET_MARKERS_REQUEST = "GET_MARKERS_REQUEST";
export const GET_MARKERS_REQUEST_SUCCESS = "GET_MARKERS_REQUEST_SUCCESS";
export const GET_MARKERS_REQUEST_FAILURE = "GET_MARKERS_REQUEST_FAILURE";

interface Numbers {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: string;
  [key: number]: string;
}

export const NumberTranslation: Numbers = {
  1: "ett",
  2: "två",
  3: "tre",
  4: "fyra",
  5: "fem",
  6: "sex",
  7: "sju",
  8: "åtta",
  9: "nio",
  10: "tio",
  11: "elva",
  12: "tolv",
  13: "tretton",
  14: "fjorton",
  15: "femton",
  16: "sexton",
  17: "sjutton",
  18: "arton",
  19: "nitton",
  20: "tjugo",
};
