import * as React from 'react';
import {
  StyledDragPreview,
  StyledSortItemLabel,
  StyledDragPreviewWrapper,
  StyledDragPreviewContainer,
  StyledSortItemImageLabel
} from './StyledSortItem';
import { useDragLayer } from 'react-dnd';
import AutoLaTeX from 'react-autolatex';
import XcImage from '../BlockLoader/ExerciseComponents/XcImage';

interface Props {
  chipsFormat: string;
  label?: string;
  image?: {
    src: string
  },
  assets?: {
    icons: any,
    format: any
  }
}

/**
 * This is the component that is displayed
 * when a drag operation is in progress.
 * @param props 
 */

export const CustomDragLayer: React.FC<Props> = (props) => {
  
  const {
    isDragging,
    item,
    currentOffset,
  } = useDragLayer(monitor => (
    {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }
  ))

  const renderLabel = React.useMemo(() => {
    if (item) {
      if (item.image) {
        return <StyledSortItemImageLabel src={item.image.src} />
      }
      else if (item.assets) {
        return <XcImage icons={item.assets.icons} format={item.assets.format} />
      }
      else {
        return (
          <StyledSortItemLabel chipsFormat={props.chipsFormat}>
            <AutoLaTeX>{item.label}</AutoLaTeX>
          </StyledSortItemLabel>
        )
      }
    }
  },[item, props.chipsFormat]);

  return (
    isDragging ?
    <StyledDragPreviewWrapper>
        <StyledDragPreviewContainer currentOffset={currentOffset}>
          <StyledDragPreview chipsFormat={props.chipsFormat}>
            {renderLabel}
          </StyledDragPreview>
        </StyledDragPreviewContainer>
    </StyledDragPreviewWrapper>
    : null
  )
}