import * as actions from "./actions";
import { createRouterReducer } from "./reducer";
import { routerSaga } from "./sagas";

const RouterStore = {
  actions,
  createReducer: createRouterReducer,
  saga: routerSaga
};

export default RouterStore;
