import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid #979797;
  box-shadow: inset 0 1px 6px 1px rgba(0,0,0,0.2), 0 0 5px 0 rgba(0,0,0,0.15);
  margin-bottom: 12px;
`;

export const StyledAnswersTable = styled.table`
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  height: 100%;
  color: #333333;

  a {
    text-decoration: none;
    color: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    &:visited {
      color: inherit;
    }
  }

  th {
    font-size: 12px;
    font-weight: 300;
    padding: 12px 0;
  }
  
  tr {
  }
  
  tr:hover {
    color: #FFFFFF;
    background-color: #8AB4B3;
  }
  
  td {
    padding: 4px;
    font-size: 14px;
    text-align: center;
  }
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledJumpButton = styled.div`
  color: #FFFFFF;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.themePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
`;