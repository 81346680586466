import { css, keyframes } from "styled-components";

const shine = keyframes`{
  100% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 100%;
    left: 100%;
  }
}`;

const transition = keyframes`{
  0% {
    background-position: 90% 90%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 20% 20%;
  }
}`;

const pulse = keyframes`{
  70% {
    box-shadow: 0 0 0 50px rgba(39, 117, 117, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(39, 117, 117, 0);
  }
}`;

export const shineAnimation = css`
  &:after {
    animation: ${shine} 4s infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: 0%;
    left: -50%;
    width: 50%;
    height: 50%;
    opacity: 1;
    transform: rotate(315deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const pulseAnimation = css`
  box-shadow: 0 0 0 0 rgba(39, 117, 117, 0.5);
  animation: ${pulse} 1.5s infinite, ${transition} 10s linear infinite;
`;
