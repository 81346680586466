import { createSelector } from "reselect";
import {
  selectProgress,
  selectProblems,
} from "../adventurePicker/api/selectors";

const selectActiveProblemId = createSelector(
  selectProgress,
  (progress) => progress.problemId
);

export const selectAdventureCharacters = createSelector(
  selectActiveProblemId,
  selectProblems,
  (problemId, problems) =>
    problems.find((problem) => problem.id === problemId)?.data?.characters
);
