import * as React from "react";
import { useDrop } from "react-dnd";
import { StyledSortItemPlaceholder } from "../../../../../Chip/StyledSortItem";
import { StyledOrderDropZone } from "./StyledOrderDropZone";

interface Props {
  id: string;
  canAcceptItem: boolean;
  position: number;
  chipsFormat: string;
  itemIsSelected?: boolean;
  callback: (param: number) => void;
  onDropCallback: (param: number, param2: string, param3: string) => void;
}

export const OrderDropZone: React.FC<Props> = (props) => {
  const [{ isOver, item }, drop] = useDrop({
    accept: "sort-item",
    drop: () => onDrop(),
    collect: (monitor) => ({
      item: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: props.canAcceptItem,
    }),
  });
  const tempItem: any = item;
  const handleClick = (e: any) => {
    e.stopPropagation();
    return props.canAcceptItem && props.callback(props.position);
  };

  const onDrop = () => {
    props.canAcceptItem &&
      props.onDropCallback(props.position, tempItem.id, props.id);
  };

  return (
    <StyledOrderDropZone ref={drop} onMouseUp={handleClick}>
      {props.children ? (
        props.children
      ) : (
        <StyledSortItemPlaceholder
          chipsFormat={props.chipsFormat}
          visible={true}
          isOver={isOver}
          itemIsSelected={props.itemIsSelected}
        />
      )}
    </StyledOrderDropZone>
  );
};
