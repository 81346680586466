import * as React from "react";
import {
  StyledDropDownContent,
  StyledDropDownContainer,
} from "./StyledDropDownStateless";
import { DropDownButton } from "./DropDownButton/DropDownButton";
import arrowIcon from "../../../assets/icons/icon_dropdown_arrow.svg";

export interface DropDownStatelessProps {
  selected?: any;
  onChange?: (arg: any) => void;
  open: boolean;
  onToggle?: () => void;
  overrideStyles?: {};
  children: React.ReactNode;
}

export class DropDownStateless extends React.PureComponent<DropDownStatelessProps> {
  private wrapper: any;

  constructor(props: DropDownStatelessProps) {
    super(props);
    this.wrapper = null;
  }

  public setRef = (el: any) => (this.wrapper = el);

  public render() {
    const { selected, open, onToggle, children, overrideStyles } = this.props;

    return (
      <StyledDropDownContainer
        overrideStyles={overrideStyles}
        ref={this.setRef}
      >
        <DropDownButton
          overrideStyles={overrideStyles}
          label={selected.label}
          icon={arrowIcon}
          open={open}
          onClick={onToggle}
        />
        <StyledDropDownContent overrideStyles={overrideStyles} active={open}>
          {children}
        </StyledDropDownContent>
      </StyledDropDownContainer>
    );
  }
}
