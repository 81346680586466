import * as React from "react";
import { ReactComponent as Icon } from "../../../assets/icons/anonymous.svg";
import {
  StyledProfileItemWrapper,
  StyledProfileButton,
  StyledProfileText,
  StyledProfileMenuWrapper,
  StyledProfileMenu,
} from "./StyledProfileItem";

import { MenuList } from "../Menu/MenuList";
import { MenuItem } from "../Menu/MenuItem";

interface MenuProps {
  open: boolean;
  closeMenu: () => void;
}

const goToProfile = () => {
  const profileUrl = process.env.REACT_APP_PROFILE_URL || "/#profil";
  window.open(profileUrl);
};

const logout = () => {
  const logoutUrl =
    process.env.REACT_APP_LOGOUT_URL ||
    "https://loggain.test.studentlitteratur.se/logout.php";
  const redirectUrl = window.location.href;
  window.location.href = `${logoutUrl}?redirect_url=${redirectUrl}`;
};

const ProfileMenu: React.FC<MenuProps> = ({ open, closeMenu }) => {
  return open ? (
    <StyledProfileMenu>
      <MenuList>
        <MenuItem onClick={goToProfile}>Min profil</MenuItem>
        <MenuItem onClick={logout}>Logga ut</MenuItem>
      </MenuList>
    </StyledProfileMenu>
  ) : null;
};

interface Props {
  name?: string;
  picture?: string;
  backPlate: boolean;
}
const ProfileItem: React.FC<Props> = ({ name, picture, backPlate }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const isClickInMenu = (eventTarget: EventTarget | null) => {
    console.log(eventTarget, menuRef.current);
    const target = eventTarget !== null ? (eventTarget as Element) : null;
    return menuRef.current && menuRef.current.contains(target);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    document.removeEventListener("click", handleClicksOutsideMenu);
  };

  const handleClicksOutsideMenu: EventListener = (event: Event) => {
    if (!isClickInMenu(event.target)) {
      closeMenu();
    }
  };

  const toggleMenu = (
    event: React.KeyboardEvent<HTMLButtonElement> &
      React.MouseEvent<HTMLButtonElement>
  ) => {
    if (menuOpen) {
      if (!isClickInMenu(event.target)) {
        closeMenu();
      }
    } else {
      setMenuOpen(true);
      document.addEventListener("click", handleClicksOutsideMenu);
      event.stopPropagation();
    }
  };

  return (
    <React.Fragment>
      <StyledProfileItemWrapper backPlate={backPlate} onClick={toggleMenu}>
        <StyledProfileText>{name}</StyledProfileText>
        <StyledProfileButton>
          {picture ? <img src={picture} alt="Profilbild" /> : <Icon />}
        </StyledProfileButton>
      </StyledProfileItemWrapper>
      <StyledProfileMenuWrapper ref={menuRef}>
        <ProfileMenu open={menuOpen} closeMenu={closeMenu} />
      </StyledProfileMenuWrapper>
    </React.Fragment>
  );
};

export { ProfileItem };
