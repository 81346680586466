import styled, {keyframes} from 'styled-components';
import {
  CorrectionBtnStates
} from './CorrectionButtonConstants';
import { SMALL, LARGE } from '../../constants';

interface BtnState {
  state: string;
}

interface StyleCorrBtnProps extends BtnState {
  hover: boolean;
  label: string;
  disabled: boolean;
}

interface StyledLoaderProps {
  size: string;
  withMargin?: boolean;
}

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const loaderSize = (size: string) => {
  switch(size) {
    case SMALL:
      return '2.28571429rem';
    case LARGE:
      return '6rem';
    default:
      return '2.28571429rem';
  }
}

export const StyledLoader = styled.div`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #767676;
  border-radius: 50%;
  height: ${(props: StyledLoaderProps) => loaderSize(props.size)};
  width: ${(props: StyledLoaderProps) => loaderSize(props.size)};
  margin-left: ${(props: StyledLoaderProps) => props.withMargin ? '16px' : ''};
  animation: ${spin} 0.6s linear infinite;
`

export const StyledLoaderCenterContainer = styled.div `
  width: auto;
  height: auto;
  margin: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledCorrBtn = styled.button`
  min-width: 50px;
  height: 50px;
  border-radius: 200px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: initial;
  margin-left: 16px;
 
  -webkit-transition: background 200ms;
  transition: background 200ms;
  
  &:hover {
    cursor: ${(props: StyleCorrBtnProps) => props.disabled ? 'not-allowed' : 'pointer'};
  }
  
  ${(props: StyleCorrBtnProps) => {
    if (props.state === CorrectionBtnStates.DEFAULT || props.state === CorrectionBtnStates.TRY_AGAIN) {
      return `padding: 0 32px;`;
    } else {
      return;
    }
  }};
  background-color: #277575;
  color: #FFFFFF;
  
  &:active {
    background-color: #47a7a7;
  }
`;

export const StyledBtnState = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  left: 0;
  font-weight: 900;
  
  -webkit-transition: left 200ms;
  transition: left 200ms;
`;
export const StyledContinueState = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBtnLabel = styled.span`
    font-family: 'Muli',sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
`;

export const StyledBtnImg = styled.img`
  height: 20px;
`;
