import { SagaService } from "../../services/saga";
import {
  watchGetOnboardingSaga,
  watchGetOnboardingGradesSaga,
} from "./api/sagas";
import { ReducerService } from "../../services/reducer";
import { reducer } from "./api/reducer";
export * from "./Onboarding";

export const init = () => {
  SagaService.set(watchGetOnboardingSaga);
  SagaService.set(watchGetOnboardingGradesSaga);
  ReducerService.set("onboarding", reducer);
};
