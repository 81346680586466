import { 
  createApiActions, 
  ApiActions 
} from '../../../shared/store/actions';
import { 
  ONBOARDING, 
  ONBOARDING_GRADES 
} from '../../../shared/constants';

export const ONBOARDING_API_ACTIONS: ApiActions = createApiActions(ONBOARDING);
export const ONBOARDING_GRADES_API_ACTIONS: ApiActions = createApiActions(ONBOARDING_GRADES);
