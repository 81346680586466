import {StyledButtonTextWrapper} from './StyledButtonLabelText'

type RenderLabelProps = {
  label: string
}

export const RenderLabelText = ({label}: RenderLabelProps) => {
return (
    <StyledButtonTextWrapper>
        {label}
    </StyledButtonTextWrapper>
  );
}