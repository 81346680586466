import styled, { keyframes } from "styled-components";
import { ViewSlideState } from "../../../views/exercise/api/types";
import { viewSlideStates } from "../../../views/exercise";
import { deviceSize } from "../../../styles/theme";
import { PRIMARY_SCHOOL } from "../../constants";
import { Navigator } from "../Navigator";

export interface StyledItemProps {
  obtained: boolean;
  viewSlideState: ViewSlideState;
  imgUrl: string;
}
export interface StyledImgProps {
  imgUrl: string;
}

export interface StyledMarkerImgProp {
  viewSlideState: ViewSlideState;
  markerIndex: number;
}

export interface StyledModalItemImgProps {
  imgUrl: string;
}

export interface StyledThemesProps {
  theme: string;
}
export interface StyledMarkerImg {
  adventure?: boolean;
  obtained?: boolean;
  color?: string;
  seen?: boolean;
}

export const StyledItemSlideContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: ${({ theme }: StyledThemesProps) =>
    theme === PRIMARY_SCHOOL ? "530px" : "800px"};
  max-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: ${({ theme }: StyledThemesProps) =>
    theme === PRIMARY_SCHOOL ? "center" : "flex-end"};
  flex-direction: column;
`;

export const StyledItemName = styled.span`
  color: #227574;
  font-weight: 900;
  font-size: 32px;
  letter-spacing: 0.26px;
  line-height: 28px;
`;

export const StyledItemWrapper = styled.div`
  position: relative;
  margin: 4px 0;
  width: 100%;
  max-height: 500px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledItemText = styled.div`
  color: ${(props) => props.theme.themePrimary};
  font-size: 24px;
  margin-bottom: 20px;
`;

export const itemAnimation = keyframes`
  0%,100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
  
`;

export const StyledItemImg = styled.div`
  background-image: url(${(props: StyledItemProps) => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 1;
  width: 100%;
  min-width: 50px;
  min-height: 80px;
  max-width: 300px;
  max-height: 150px;
  margin-bottom: 20px;
  transition: opacity 250ms;
  transition-delay: 2200ms;

  cursor: ${({ obtained }: StyledItemProps) =>
    obtained ? "pointer" : "inherit"};
  opacity: ${({ viewSlideState, obtained }: StyledItemProps) =>
    viewSlideState === viewSlideStates.IN_VIEW && obtained ? "1" : ".25"};
  animation: ${({ viewSlideState, obtained }: StyledItemProps) =>
    viewSlideState === viewSlideStates.IN_VIEW && obtained && itemAnimation};
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-delay: 1800ms;
  animation-fill-mode: both;
`;

export const StyledItemImgLarge = styled.div<StyledImgProps>`
  background-image: url(${(props) => props.imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 1;
  width: 100%;
  min-width: 50px;
  min-height: 80px;
  max-width: 500px;
`;

export const StyledLargeImgCard = styled.div`
  max-height: 512px;
  max-width: 900px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f9f6f6;
  box-shadow: 0 0 96px 0 rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.themePrimary};
  overflow: hidden;
  padding: 30px 10px;

  @media (max-width: ${deviceSize.phone}px) {
    max-height: 100%;
  }
`;

export const StyledAdventureDetailsWrapper = styled.div`
  display: flex;
  margin: 10px;
  width: 100%;
  padding: 10px;
  @media (max-width: 1024px) { margin: 5px; padding: 5px; }
`;

export const StyledTitleWrapper = styled.div`
  color: #227574;
  margin-left: 20px;
  align-self: center;
`;

export const StyledTrophyWrapper = styled.div`
  display: flex;
`;

export const StyledFeedbackWrapper = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: #227574;
  width: 100%;
  @media (max-height: 811px) { height:100px; }

`;

export const StyledNavigatorCount = styled(Navigator)`
  background-color: rgb(34, 117, 116);
  font-weight: 600;
  color: white;
  padding: 7px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 50px;
`;
