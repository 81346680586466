import { createSelector } from "reselect";
import { RootState } from "../../../store";
import { Adventure, Adventures } from "./types";

export const selectAdventureGroups = (state: RootState) =>
  state.adventureGroup.adventureGroups;

export const selectAdventures = (state: RootState) =>
  state.adventureGroup.adventures;

export const selectProblems = (state: RootState) =>
  state.adventureGroup.problems;

export const selectProgress = (state: RootState) =>
  state.adventureGroup.progress;

export const selectAllAdventures = (state: RootState) =>
  state.adventureGroup.allAdventures;

export const selectMarkersToComplete = (state: RootState) =>
  state.adventureGroup.markersToComplete;

export const selectNextActiveProblem = createSelector(
  selectProgress,
  selectProblems,
  ({ nextProblemId }, problems) => problems.find(p => p.id === nextProblemId)
);

export const selectPrevActionProblem = createSelector(
  selectProgress,
  selectProblems,
  ({ prevProblemId }, problems) => problems.find(p => p.id === prevProblemId)
);

export const selectCurrentProblem = createSelector(
  selectProgress,
  selectProblems,
  ({ problemId }, problems) => problems.find(p => p.id === problemId)
);

export const selectAdventureCardPosts = createSelector(
  selectNextActiveProblem,
  problem => {
    if (!problem || !problem.data) {
      return [];
    }

    return problem.data.posts;
  }
);

export const selectAllIds = createSelector(
  selectAdventures,
  selectNextActiveProblem,
  (adventures, problem) => {
    if (!problem) {
      return {
        adventureGroupId: -1,
        adventureId: -1,
        adventureCardId: -1
      };
    }

    const adventure = adventures.find(a => a.id === problem.parent_id);

    const adventureGroupId = adventure ? adventure.parent_id : -1;

    return {
      adventureGroupId,
      adventureId: problem.parent_id,
      adventureCardId: problem.id
    };
  }
);

export const selectActiveAdventureId = createSelector(
  selectProgress,
  ({ adventureId }) => adventureId
);

export const selectActiveAdventure = createSelector(
  selectAdventures,
  selectActiveAdventureId,
  (adventures, activeAdventureId) =>
    adventures.find(a => a.id === activeAdventureId)
);

export const selectHasActiveAdventure = createSelector(
  selectProgress,
  ({ adventureId }) => !!adventureId
);

export const selectCurrentOrPrevProblem = createSelector(
  selectCurrentProblem,
  selectPrevActionProblem,
  (prob, prevProb) => {
    if (!prob && !prevProb) {
      return null;
    }

    return prob || prevProb;
  }
);

export const selectCurrentItemFeedback = createSelector(
  selectCurrentOrPrevProblem,
  selectProgress,
  (prob, { itemFeedBackIndex }) => {
    if (!prob) {
      return null;
    }

    if (itemFeedBackIndex === null) {
      return null;
    }

    if (!prob.data) {
      return null;
    }

    if (!prob.data.items) {
      return null;
    }

    return prob.data.items[itemFeedBackIndex];
  }
);

export const selectCurrentAdventure = createSelector(
  selectAdventures,
  selectProgress,
  (adventures, { adventureId }) => adventures.find(a => a.id === adventureId)
);

export const isLastProblem = createSelector(
  selectCurrentAdventure,
  selectProblems,
  selectProgress,
  (adventure, problems, { problemId }) => {
    if (!adventure || problems.length === 0) {
      return false;
    }

    const last = getLastProblem(adventure.id, problems);

    return problemId === last.id;
  }
);

export const isNextLastProblem = createSelector(
  selectCurrentAdventure,
  selectProblems,
  selectProgress,
  (adventure, problems, { nextProblemId }) => {
    if (!adventure || problems.length === 0) {
      return false;
    }

    const last = getLastProblem(adventure.id, problems);

    return nextProblemId === last.id;
  }
);

const getLastProblem = (adventureId: number, problems: Adventures) =>
  problems
    .filter(p => p.parent_id === adventureId)
    .reduce((l, p) => (p.weight > l.weight ? p : l), {
      weight: 0
    } as Adventure);

export const selectAdventureGroupForActiveAdventure = createSelector(
  selectCurrentAdventure,
  selectAdventureGroups,
  (adventure, groups) => groups.find(group => group.id === adventure?.parent_id)
);
