import {
  TEST_ANSWERS_NEXT_PAGE,
  TEST_ANSWERS_PREVIOUS_PAGE,
  TEST_GOALS_SET_SELECTED,
  TEST_SET_ABILITY,
  TEST_SET_GOAL_SCORE,
  TEST_SET_MARKER_GROUP_SELECTED,
} from "../../../shared/constants";
import { TestState, Goal, MarkerGroup } from "./types";
import {
  TEST_ANSWERS_API_ACTIONS,
  TEST_GOALS_API_ACTIONS,
  TEST_CONTENT_PROGRESS_API_ACTIONS,
  TEST_MARKER_GROUPS_API_ACTIONS,
} from "./actions";

const initialState: TestState = {
  fetching: false,
  pageIndex: 0,
  answers: [],
  goals: [],
  markerGroups: [],
  selectedMarkerGroupId: 0,
  error: null,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case TEST_ANSWERS_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case TEST_ANSWERS_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        answers: action.answers,
      };
    case TEST_ANSWERS_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case TEST_ANSWERS_NEXT_PAGE:
      return { ...state, pageIndex: state.pageIndex + 1 };
    case TEST_ANSWERS_PREVIOUS_PAGE:
      return { ...state, pageIndex: state.pageIndex - 1 };

    case TEST_GOALS_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case TEST_GOALS_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        goals: action.goals.map((goal: Goal, index: number) => ({
          value: goal.ref,
          label: `${index + 1}. ${goal.title}`,
        })),
      };
    case TEST_GOALS_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case TEST_CONTENT_PROGRESS_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case TEST_CONTENT_PROGRESS_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        ability: action.progress.ability,
        goalScore: action.progress.score,
        selectedGoal: action.progress.goalRef,
      };
    case TEST_CONTENT_PROGRESS_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case TEST_GOALS_SET_SELECTED:
      return { ...state, selectedGoal: action.payload.goalRef };

    case TEST_SET_ABILITY:
      return { ...state, ability: action.payload.ability };

    case TEST_SET_GOAL_SCORE:
      return { ...state, goalScore: action.payload.goalScore };

    case TEST_MARKER_GROUPS_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case TEST_MARKER_GROUPS_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        markerGroups: action.markerGroups.map((group: MarkerGroup) => ({
          id: group.id,
          label: group.label,
          available_markers: group.available_markers,
          selected: false,
        })),
      };
    case TEST_MARKER_GROUPS_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case TEST_SET_MARKER_GROUP_SELECTED:
      return {
        ...state,
        fetching: false,
        error: null,
        selectedMarkerGroupId: action.payload.id,
      };
    case TEST_MARKER_GROUPS_API_ACTIONS.UPDATE.REQUEST:
      return { ...state, fetching: true, error: null };

    case TEST_MARKER_GROUPS_API_ACTIONS.UPDATE.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case TEST_MARKER_GROUPS_API_ACTIONS.DELETE.REQUEST:
      return { ...state, fetching: true, error: null };

    case TEST_MARKER_GROUPS_API_ACTIONS.DELETE.FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
}
