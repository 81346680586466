import styled from "styled-components";
import { Navigator } from "../../../shared/components/Navigator";
import {
  pulseAnimation,
  shineAnimation,
} from "../../../shared/components/Navigator/StyledNavigator";
import { deviceSize } from "../../../styles/theme";
interface MenuItemProps {
  main?: boolean;
  width?: string;
  height?: string;
  radius?: string;
  bgPosition?: string;
  withAnimations?: boolean;
}

interface ImageProps {
  width?: number;
  left?: number;
  bottom?: number;
}

export const StyledAssignmentBadgeWrapper = styled.div<
  ImageProps & { color: string }
>`
  height: 90px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  width: 90px;
  position: absolute;
  left: 0;
  bottom: ${({ bottom }) => (bottom ? bottom + "px" : 0)};
  width: ${({ width }) => (width ? width + "px" : "100%")};
`;

export const StyledAssignmentBadge = styled.img`
  height: 100%;
  width: 100%;
`;

export const StyledNavigator = styled(Navigator)<MenuItemProps>`
  background: transparent;
  &:hover {
    background: none;
  }
  &:focus {
    background: none;
  }
  &:active {
    background: none;
  }
`;

export const StyledItemContainer = styled.div<MenuItemProps>`
  position: relative;
  overflow: ${(props) => (props.main ? "visible" : "hidden")};
  display: flex;
  flex: 1;
  flex-basis: ${(props) => (props.main ? "100%" : "auto")};
  height: ${(props) => props.height || "auto"};
  background-color: #101626;
  background-image: url("https://media.studentlitteratur.se/image/mr/story/images/stars.png");
  background-size: ${(props) => (props.main ? "200%" : "500%")};
  background-position: ${(props) => props.bgPosition};
  border: 6px solid #3e4c68;
  border-radius: ${(props) => props.radius || "30px"};
  transition: border 65ms ease-in-out;
  margin-right: 12px;
  box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.3);
  min-width: 47%;

  > button {
    display: flex;
    align-items: flex-end;
    justify-content: ${(props) => (props.main ? "flex-start" : "flex-end")};
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;
  }

  &:first-child {
    margin: 0 0 12px 0;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    border-color: #426991;
    cursor: pointer;
  }

  @media (max-width: ${deviceSize.tablet}px) {
    margin: 0 0 12px 0;
    flex-basis: 100%;
  }

  ${(props) => props.withAnimations && pulseAnimation}
  ${(props) => props.withAnimations && shineAnimation}
`;

export const StyledImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`;

export const StyledTextContainer = styled.div`
  flex: 1;
  max-width: min-content;
  background-color: lawngreen;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StyledItemText = styled.span`
  align-self: flex-end;
  font-size: ${(props: MenuItemProps) => (props.main ? "82px" : "30px")};
  font-family: "Roboto", "Muli";
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  margin: ${(props: MenuItemProps) =>
    props.main ? "0 0 4px 14px" : "0 10px 4px 6px"};
  z-index: 1;
`;

export const StyledMainImg = styled.img`
  position: absolute;
  width: 225px;
  right: 35px;
  bottom: -75px;
  clip-path: inset(0 0 75px 0);
`;

export const StyledImage = styled.img<ImageProps>`
  position: absolute;
  left: 0;
  left: ${({ left }) => (left ? left + "px" : 0)};
  bottom: ${({ bottom }) => (bottom ? bottom + "px" : 0)};
  width: ${({ width }) => (width ? width + "px" : "100%")};
  height: auto;
`;
