import * as React from 'react';
import {
  StyledSortWrapper,
  StyledItemsRowWrapper,
} from '../XcSort/StyledSort';
import {
  OrderItemType, 
  OrderReturnedAnswer,
  OrderItemList
} from './types';
import { CustomDragLayer } from '../../../Chip/CustomDragLayer';
import {
  StyledOrderBox, 
  StyledOrderBoxGraphic,
  StyledOrderBoxContainer
} from './StyledOrder';
import { OrderDropZone } from './components/OrderDropZone/OrderDropZone';
import { StyledSortItemPlaceholder } from '../../../Chip/StyledSortItem';
import { shuffleArray, hasAttemptsLeft } from '../../../../../Helpers';
import { UnOrderedDropZone } from './components/UnOrderedDropZone/UnOrderedDropZone';
import { UNORDERED, ORDERED } from '../../../../constants';
import { CorrectionBtnStates } from '../../../CorrectionButton';
import { SortItem } from '../../../Chip/SortItem';

interface Props {
  settings: any;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  correctAnswers: OrderReturnedAnswer;
  items: OrderItemType[]
  maxItems: number,
  state: string;
  callback(answers: string): void;
}

interface State {
  orderedItemsBox: OrderItemList
  unorderedItemsBox: OrderItemList
  selectedItemId: string|null;
  dirty: boolean;
}

export const XcOrder: React.FC<Props> = (props) => {
  /**
   * Prepare items:
   * 1: Shuffle the items
   * 2: Add new indexes
   * 3: create empty slots in ordered array
   * 4: If there are any prefilled items, place in ordered array in the right positions.
   */
  const shuffledItems: OrderItemType[] = shuffleArray(props.items)

  // Put all items with index === -1 to the unordered items list. -1 represent prefilled === false.
  // Replace -1 with new indexes
  const [unorderedItems] = React.useState<OrderItemList>(
    { id: UNORDERED,
      items: shuffledItems.filter(item => item && item.index === -1).map((itm, index) => ({...itm, index}))
    }
  );

  // Put all items with index <= 0 to ordered list. index <= 0 represent prefilled === true.
  const [orderedItems] = React.useState(
    function getInitialState() {
      const orderArray: Array<(OrderItemType | null)> = Array(props.maxItems).fill(null).map((elem, index) => {
        const foundItem = props.items.find(item => item.index === index)
        return foundItem ? {...foundItem, prefilled: true} : elem
      })
      return {id: ORDERED, items: orderArray};
  });

  /**
   * Set state with initial values.
   */
  const [state, setState] = React.useState<State>({
    unorderedItemsBox: {...unorderedItems},
    orderedItemsBox: {...orderedItems},
    selectedItemId: null,
    dirty: false
  });

  /**
   * Moves an item from its previous position to a new position.
   * Positions allowed: unsorted -> sorted, sorted -> unsorted, sorted -> sorted
   *
   * This function handle transistions dropped & clicked to.
   *
   * @param dropZonePosition
   * @param droppedItemId
   * @param boxId
   */
  const onDrop = (dropZonePosition: number, droppedItemId: string, boxId: string) => {
    const newUnorderedList = JSON.parse(JSON.stringify(state.unorderedItemsBox.items));
    const newOrderedItems = JSON.parse(JSON.stringify(state.orderedItemsBox.items));

    if(boxId === state.orderedItemsBox.id) {
      const movingInternally = newOrderedItems.find((item: OrderItemType|null) => item && item.id === droppedItemId);
      const list = movingInternally ? newOrderedItems : newUnorderedList
      const indexToMove = list.findIndex((item: OrderItemType|null) => item && item.id === droppedItemId);
      const itemToMove = list[indexToMove];

      if (itemToMove && newOrderedItems[dropZonePosition] === null) {
        // add unordered item to ordered position
        newOrderedItems[dropZonePosition] = itemToMove;
        list[indexToMove] = null;
      } else {
        // ordered postion is not empty
        const itemToMoveBack = newOrderedItems[dropZonePosition]
        // return item back to its original position
        newUnorderedList[itemToMoveBack.index] = itemToMoveBack;
        // add unordered item to ordered position
        newOrderedItems[dropZonePosition] = itemToMove;
        list[indexToMove] = null;
      }
    }
    if(boxId === state.unorderedItemsBox.id) {
      const indexToMove = newOrderedItems.findIndex((item: OrderItemType|null) => item && item.id === droppedItemId);
      const itemToMove = newOrderedItems[indexToMove];
      newOrderedItems[indexToMove] = null;

      if (itemToMove) {
        newUnorderedList[itemToMove.index] = itemToMove;
      }
    }

    return {
      unorderedItemsBox: { ...state.unorderedItemsBox, items: newUnorderedList},
      orderedItemsBox: {...state.orderedItemsBox, items: newOrderedItems}
    }
  }

  /**
   * Returns the box name depeding on where the item is located.
   * @param itemId
   */
  const getSelectedItemsBoxId = (itemId: string) => {
    const foundItem = state.unorderedItemsBox.items.find((item: OrderItemType|null) => item && item.id === itemId)
    return foundItem ? UNORDERED : ORDERED
  }

  /**
   * Callback for dropped items.
   * @param dropZonePosition
   * @param droppedItemId
   * @param destinationBoxID
   */
  const onDropCallbacks = (dropZonePosition: number, droppedItemId: string, destinationBoxID: string) => {
    const selectedBoxID = getSelectedItemsBoxId(droppedItemId)

    if (!(selectedBoxID === UNORDERED && destinationBoxID === UNORDERED)) {
      const dropList = onDrop(dropZonePosition, droppedItemId, destinationBoxID)
      setState({ 
        unorderedItemsBox: {...dropList.unorderedItemsBox},
        orderedItemsBox: {...dropList.orderedItemsBox},
        selectedItemId: null,
        dirty: true
      })
    }
  }

  /**
   * Renders a single item
   * @param item
   * @param index
   * @param type
   */
  const renderItem = (item: OrderItemType, index: number, type: string) => (
    <SortItem
      key={item.id}
      chipsFormat={props.settings.chips}
      id={item.id}
      label={item.label}
      image={item.image}
      assets={item.assets}
      prefilled={item.prefilled}
      state={(item.id === state.selectedItemId && hasAttemptsLeft(props.currentAttempt, 2)) ? 'SELECTED' : 'DEFAULT'}
      correctedState={(props.currentAttempt === 2 || props.state === 'CORRECT') && props.correctAnswers ? getCorrectedState(item, index, type) : 'DEFAULT'}
      isDisabled={hasAttemptsLeft(props.currentAttempt, 2)}
      callback={itemCallback}
    />
  );

  /**
   * Renders the unsorted row.
   * @param items
   */
  const renderItemsRow = (items: Array<(OrderItemType | null)>) => (
    <StyledItemsRowWrapper>
      <UnOrderedDropZone
        id={state.unorderedItemsBox.id}
        callback={unorderedBoxClickCallback}
        onDropCallback={onDropCallbacks}
        chipsFormat={props.settings.chips}
        itemIsSelected={state.selectedItemId !== null}
      >
      { 
        items.map((item: OrderItemType|null, index: number) => (item
          ? renderItem(item, index, UNORDERED)
          : <StyledSortItemPlaceholder
              chipsFormat={props.settings.chips}
              visible={true}
              key={index}
            />))
      }
      </UnOrderedDropZone>
    </StyledItemsRowWrapper>
  );

  /**
   * Renders the dropzone for the ordered items.
   * @param item
   * @param index
   */
  const renderDropZone = (item: OrderItemType | null, index: number) => (
    <OrderDropZone
      id={state.orderedItemsBox.id}
      position={index}
      canAcceptItem={item?.prefilled ? false : true}
      callback={orderedBoxClickCallback}
      onDropCallback={onDropCallbacks}
      key={item ? item.id : index}
      chipsFormat={props.settings.chips}
      itemIsSelected={state.selectedItemId !== null}
    >
      {
      item !== null
        ? renderItem(item, index, ORDERED)
        : null
      }
    </OrderDropZone>
  );

  /**
   * Renders the sorted row.
   * @param items
   */
  const renderOrderRow = (items: Array<(OrderItemType | null)>) => (
    <StyledOrderBoxGraphic chipsFormat={props.settings.chips}>
      {items.map( (item, index) => renderDropZone(item, index))}
    </StyledOrderBoxGraphic>
  );

  /**
   * Callback for item click
   * @param itemId
   */
  const itemCallback = (itemId: string) => {
    if (hasAttemptsLeft(props.currentAttempt, 2)) {
      state.selectedItemId === itemId
      ? setState(prevState => ({ ...prevState, selectedItemId: null}))
      : setState(prevState => ({ ...prevState, selectedItemId: itemId}))
    }
  }

  /**
   * Callback for unordered box click
   * @param boxId
   */
  const unorderedBoxClickCallback = () => (
    state.selectedItemId !== null &&
    hasAttemptsLeft(props.currentAttempt, 2) &&
    onDropCallbacks(-1, state.selectedItemId, state.unorderedItemsBox.id)
  )

  /**
   * Callback for ordered box click
   * @param boxId
   */
  const orderedBoxClickCallback = (position: number) => {
    if (state.selectedItemId !== null && hasAttemptsLeft(props.currentAttempt, 2)) {
      return onDropCallbacks(position, state.selectedItemId, state.orderedItemsBox.id)
    }
  }

  /**
   * Prepares answer to be sent to backend for correction.
   * Returns an array of ids
   */
  const extractAnswers = React.useCallback(() => (
     state.orderedItemsBox.items.map(item => item && item.id)
  ), [state.orderedItemsBox.items])

  /**
   * Collect answer and run callback
   */
  const publishSelectionState = React.useCallback(():void => {
    const answers = extractAnswers();

    props.callback(JSON.stringify({answers}));
  }, [extractAnswers, props])


  /**
   * Returns the corrected state.
   * @param itemId
   * @param boxId
   */
  const getCorrectedState = (renderedItem: OrderItemType, renderedIndex: number, type: string) => {
    if (type === UNORDERED && !renderedItem.inUse) { return }
    const { correctAnswers } = props.correctAnswers
    const foundItem = type === ORDERED ? correctAnswers[renderedIndex] : null
    return foundItem === renderedItem.id
      ? renderedItem.prefilled ? CorrectionBtnStates.DEFAULT : CorrectionBtnStates.CORRECT
      : CorrectionBtnStates.INCORRECT
  }

  const correctUnorderedList = () => {
    const { correctAnswers } = props.correctAnswers
    return unorderedItems.items.map(item => {
      const found = correctAnswers.find(id => id === (item && item.id))
      return found ? null : item
    })
  }

  const correctOrderedList = () => {
    const { correctAnswers } = props.correctAnswers
    return correctAnswers.map(id => {
      const found = props.items.find(item => item.id === id)
      return found
        ? found.inOrderBox ? {...found, prefilled: true} : found
        : null
    })
  }

  /**
   * Runs when a change has occured
   */
  React.useEffect( () => {
    if (state.dirty) {
      publishSelectionState();
      setState(prevState => ({ ...prevState, dirty: false}))
    }
  }, [state.dirty, publishSelectionState])

  /**
   * Render component
   */
  return (
    <StyledSortWrapper>
      <CustomDragLayer chipsFormat={props.settings.chips} />
      {props.displayCorrectAnswer
        ? renderItemsRow(correctUnorderedList())
        : renderItemsRow(state.unorderedItemsBox.items)}
      <StyledOrderBoxContainer >
        <StyledOrderBox>
          {props.displayCorrectAnswer
            ? renderOrderRow(correctOrderedList())
            : renderOrderRow(state.orderedItemsBox.items)}
        </StyledOrderBox>
      </StyledOrderBoxContainer>
    </StyledSortWrapper>
  )
}