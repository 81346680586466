import styled, { css } from "styled-components";
import {L, M, S, XL} from "../../../../constants";

const primaryColor = '#277575';

interface InputProps {
  inputWidth: string;
  state: string;
  textSize: string;
  isCorrect: boolean;
  displayCorrectAnswer: boolean;
}

interface MirrorSpanProps {
  textSize: string;
}

interface IconContainerProps {
  color: string;
}

interface InputContainerProps {
  textSize: string;
}

export const getFontSize = (size: string) => size in textSizes
  ? textSizes[size].fontSize
  : textSizes[S].fontSize;

export const getInputWidth = (size: string ) => size in textSizes
  ? textSizes[size].baseWidth
  : textSizes[M].baseWidth;

export const textSizes: any = {
  [S]: {
    fontSize: '2vmin',
    baseWidth: '4.6vmin'
  },
  [M]: {
    fontSize: '3vmin',
    baseWidth: '6.4vmin'
  },
  [L]: {
    fontSize: '4vmin',
    baseWidth: '8.4vmin'
  },
  [XL]: {
   fontSize: '4.5vmin',
   baseWidth: '9.4vmin'
  }
};

export const StyledBlanksWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  max-height: 100%;
  overflow: auto;
  margin: auto 0;
  min-height: 0;
`;

export const StyledInputContainer = styled.div`
  display: inline-block;
  max-height: ${({ textSize }: InputContainerProps) => getInputWidth(textSize)};
  height: ${({ textSize }: InputContainerProps) => getInputWidth(textSize)};
  min-width: ${({ textSize }: InputContainerProps) => getInputWidth(textSize)};
  max-width: 100%;
  position: relative;
  margin: 10px 8px 0 8px;
`;

export const StyledCard = styled.div`
  border: 1px solid #277575;
  border-radius: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Muli', sans-serif;
  font-size: ${({ textSize }: InputContainerProps) => getFontSize(textSize)};
  letter-spacing: 0.22px;
  line-height: 28px;
  color: ${primaryColor};
  height: 100%;
  max-height: ${({ textSize }: InputContainerProps) => getInputWidth(textSize)};
  min-width: ${({ textSize }: InputContainerProps) => getInputWidth(textSize)};
  max-width: 100%;
  background-color: #FFFFFF;
  text-align: center;
`;

export const StyledInput = styled.input<InputProps>`
  font-family: 'Muli', sans-serif;
  font-size: ${({ textSize }) => getFontSize(textSize)};
  font-weight: 700;
  letter-spacing: 0.22px;
  line-height: 28px;
  color: ${primaryColor};
  height: 100%;
  padding: 0;
  max-height: ${({ textSize }) => getInputWidth(textSize)};
  min-width: ${({ textSize }) => getInputWidth(textSize)};
  max-width: 100%;
  width: ${ (props) => props.inputWidth };
  background-color: #FFFFFF;
  text-align: center;
  border-radius: 2px;
  transition: all 200ms cubic-bezier(0.235, 0.260, 0.120, 1.000);
  ${(props) => getStateSpecificStyles(props)};
  
  &:focus, &:active {
    background-color: #F9F6F6;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.3);
    outline: none;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const getStateSpecificStyles = ({state, isCorrect, displayCorrectAnswer}: InputProps) => {
  if (state === 'CORRECT' || (state === 'INCORRECT' && isCorrect) || displayCorrectAnswer ) {
    return `
      color: #191717;
      border: 1px solid #1FC84C;
      border-bottom: 4px solid #1FC84C;
    `;
  }  
  if (state === 'INCORRECT' && !isCorrect) {
    return css`
      color: #191717;
      border: 1px solid #EC4C3F;
      border-bottom: 4px solid #EC4C3F;
    `;
  }
  else {
    return css`
      border: 1px solid ${primaryColor};
      border-bottom: 4px solid ${primaryColor};
    `;
  }
};

export const StyledText = styled.span`
  font-family: 'Muli', sans-serif;
  font-size: ${({ textSize }: InputContainerProps) => getFontSize(textSize)};
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.28px;
  line-height: normal;
  color: ${primaryColor};
  padding: 0 10px;
  align-self: center;

  .katex {
    font-size: 3.6vmin;
  }
`;

export const StyledBlanksLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0;
  max-width: 100%;
  &:first-child {
    margin: 0;
  }
`;

export const MirrorSpan = styled.span`
  font-family: 'Muli', sans-serif;
  white-space: pre;
  position: absolute;
  visibility: hidden;
  left: 0;
  padding: 0;
  font-size: ${({ textSize }: MirrorSpanProps) => getFontSize(textSize)};
  font-weight: 700;
  letter-spacing: 0.22px;
  line-height: 28px;
`;

export const StyleldIconContainer = styled.div`
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  right: 5px;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${(props: IconContainerProps) => props.color};
  transition: all 200ms cubic-bezier(0.235, 0.260, 0.120, 1.000);
`;