import {
  TEST_ANSWERS_PREVIOUS_PAGE,
  TEST_ANSWERS_NEXT_PAGE,
  TEST_ANSWERS,
  TEST_GOALS,
  TEST_GOALS_SET_SELECTED,
  TEST_CONTENT_PROGRESS,
  TEST_SET_GOAL_SCORE,
  TEST_SET_ABILITY,
  TEST_UPDATE_GOAL_PROGRESS_REQUEST,
  TEST_MARKER_GROUPS,
  TEST_SET_MARKER_GROUP_SELECTED,
} from "../../../shared/constants";
import { ApiActions, createApiActions } from "../../../shared/store/actions";

export const TEST_ANSWERS_API_ACTIONS: ApiActions = createApiActions(
  TEST_ANSWERS
);
export const TEST_GOALS_API_ACTIONS: ApiActions = createApiActions(TEST_GOALS);
export const TEST_CONTENT_PROGRESS_API_ACTIONS: ApiActions = createApiActions(
  TEST_CONTENT_PROGRESS
);
export const TEST_MARKER_GROUPS_API_ACTIONS: ApiActions = createApiActions(
  TEST_MARKER_GROUPS
);

/**
 * Action to fetch answers
 */
export const fetchAnswers = () => ({
  type: TEST_ANSWERS_API_ACTIONS.READ.REQUEST,
});

/**
 * Action to show previous page of the answers table
 */
export const answersPreviousPage = () => ({
  type: TEST_ANSWERS_PREVIOUS_PAGE,
});

/**
 * Action to show next page of the answers table
 */
export const answersNextPage = () => ({
  type: TEST_ANSWERS_NEXT_PAGE,
});

export const fetchGoals = () => ({
  type: TEST_GOALS_API_ACTIONS.READ.REQUEST,
});

export const fetchGoalProgress = () => ({
  type: TEST_CONTENT_PROGRESS_API_ACTIONS.READ.REQUEST,
});

export const setSelectedGoal = (goalRef: string) => ({
  type: TEST_GOALS_SET_SELECTED,
  payload: {
    goalRef,
  },
});

export const setAbility = (ability: number) => ({
  type: TEST_SET_ABILITY,
  payload: {
    ability,
  },
});

export const setGoalScore = (goalScore: number) => ({
  type: TEST_SET_GOAL_SCORE,
  payload: {
    goalScore,
  },
});

export const updateGoalProgress = () => ({
  type: TEST_UPDATE_GOAL_PROGRESS_REQUEST,
});

export const fetchProblemIndependentHeadMarkerGroups = () => ({
  type: TEST_MARKER_GROUPS_API_ACTIONS.READ.REQUEST,
});

export const setMarkerHeadGroupSelected = (id: number) => ({
  type: TEST_SET_MARKER_GROUP_SELECTED,
  payload: {
    id,
  },
});

export const generateMarkersForSelectedGroup = (
  id: number,
  amountOfMarkers: number
) => ({
  type: TEST_MARKER_GROUPS_API_ACTIONS.UPDATE.REQUEST,
  payload: {
    id,
    amountOfMarkers,
  },
});

export const deleteMarkers = () => ({
  type: TEST_MARKER_GROUPS_API_ACTIONS.DELETE.REQUEST,
});
