import { createApiActions, ApiActions } from '../../../shared/store/actions';
import {
  ADD_SLIDE_INDEX_TYPES,
  CHANGE_EXERCISE_VIEW_SLIDE,
  CREATE_DECK,
  CREATE_NEW_DECK,
  DECK_FINISHED,
  EXERCISE,
  EXERCISE_FINISHED,
  FIRST,
  HANDLE_NEW_EXERCISE_DATA,
  NEXT,
  NEXT_CARD,
  NEXT_DECK_STATUS,
  PREVIEW,
  READY,
  START_EXERCISE,
  UPDATE_CARD,
  UPDATE_SLIDE_STATE,
  WATCH_CARD_UPDATE,
  SET_ANSWER_ID,
  EXERCISE_INITIATE_ANSWER_FAILURE,
  EXERCISE_SUBMIT_ANSWER_FAILURE,
  EXERCISE_SUBMIT_ANSWER_SUCCESS,
  EXERCISE_SUBMIT_ANSWER_CALL,
  CORRECT_ANSWER,
  SET_OBTAINED_ITEM_ID,
  TOGGLE_HELP
} from '../../../shared/constants';
import {
  Act,
  ActApiFetchFailure,
  ActCardSecondaryUpdate,
  ActCreateNewDeck,
  ActDeckId,
  ActNewExerciseData,
  ActReadExerciseRequest,
  ActUpdateCard,
  ActWatchCardUpdate,
  CreateDeck,
  Deck,
  Exercises,
  FetchExerciseSuccess,
  Intermediate,
  NextDeckStatus,
  RawExercise,
  UpdateExerciseState,
  RecommendationsResponseData,
  Goal,
  ActAddSlideIndexTypes,
  ViewSlideState,
  ActUpdateViewSlideState,
  SlideIndexData,
  SlideIndexData1,
  ActSetAnswerId,
  ActSubmitExerciseAnswerSuccess,
  ActSetObtainedItemId,
  UpdateHelpIsShowing,
} from "./types";
import { 
  ExerciseState, 
  Meta, 
  Answer
} from '../../../external/EddaXcomp/src/components/ExerciseRenderer/ExerciseRendererTypes';

export const EXERCISE_API_ACTIONS: ApiActions = createApiActions(EXERCISE);

export const fetchExerciseSuccess = ( action: ActReadExerciseRequest, data: RecommendationsResponseData ): FetchExerciseSuccess => ({ ...action, type: EXERCISE_API_ACTIONS.READ.SUCCESS, data });

export const fetchExercisesFailure = (error: Error):ActApiFetchFailure => ({ type: EXERCISE_API_ACTIONS.READ.FAILURE, error });

export const fetchInitialData = ():ActReadExerciseRequest => ({ type: EXERCISE_API_ACTIONS.READ.REQUEST, callType: FIRST });

export const fetchNextExerciseSet = (goalId: string):ActReadExerciseRequest => ({ type: EXERCISE_API_ACTIONS.READ.REQUEST, callType: NEXT, callParams: goalId });

export const fetchExercisePreview = (exerciseIds: string):ActReadExerciseRequest => ({ type: EXERCISE_API_ACTIONS.READ.REQUEST, callType: PREVIEW, callParams: exerciseIds });

export const changeExerciseViewSlide = ():Act => ({ type: CHANGE_EXERCISE_VIEW_SLIDE });

export const createDeck = (newDeck: Deck):CreateDeck => ({ type: CREATE_DECK, newDeck});

export const nextDeckReady = ():NextDeckStatus => ({ type: NEXT_DECK_STATUS, status: READY });

export const deckFinished = (deckID: string, cardData: ExerciseState): ActDeckId =>  ({ type: DECK_FINISHED, deckID, cardData });

export const nextDeckCard = (deckID: string, cardData: ExerciseState): ActDeckId => ({ type: NEXT_CARD, deckID, cardData });

export const updateLastDeckCard = (data: ActWatchCardUpdate): ActCardSecondaryUpdate => ({ ...data, secondaryUpdate: true });

export const updateNonTerminalDeckCard = (data: ActWatchCardUpdate): ActCardSecondaryUpdate => ({ ...data, secondaryUpdate: true });

export const updateCard = (deckID: string, cardData: ExerciseState): ActUpdateCard => ({ type: UPDATE_CARD, deckID, cardData});

export const exerciseFinished = (deckID: string, cardData: ExerciseState): ActDeckId => ({ type: EXERCISE_FINISHED, deckID, cardData });

export const newExerciseData = (parts: Array<Exercises|Intermediate>, goal: Goal, isRepetition: boolean): ActNewExerciseData => ({ type: HANDLE_NEW_EXERCISE_DATA, parts, goal, isRepetition });

export const createNewDeck = (id: string, exercises: RawExercise[]): ActCreateNewDeck => ({ type: CREATE_NEW_DECK, id, exercises});

export const cardUpdate = (data: UpdateExerciseState ): ActWatchCardUpdate => ({ type: WATCH_CARD_UPDATE, ...data });

export const addSlideIndexTypes = (slideIndexTypes: Array<SlideIndexData|SlideIndexData1>): ActAddSlideIndexTypes => ({ type: ADD_SLIDE_INDEX_TYPES, slideIndexTypes});

export const updateViewSlideState = (index: number, state: ViewSlideState): ActUpdateViewSlideState => ({ type: UPDATE_SLIDE_STATE, index, state });

export const startExercise = (): Act => ({ type: START_EXERCISE });

export const setAnswerId = (answerId: number): ActSetAnswerId => ({ type: SET_ANSWER_ID, answerId });

export const initiateExerciseAnswerFailure = (error: Error):ActApiFetchFailure => ({ type: EXERCISE_INITIATE_ANSWER_FAILURE, error });

export const submitExerciseAnswerFailure = (error: Error):ActApiFetchFailure => ({ type: EXERCISE_SUBMIT_ANSWER_FAILURE, error });

export const submitExerciseAnswerSuccess = (exerciseId: number): ActSubmitExerciseAnswerSuccess => ({ type: EXERCISE_SUBMIT_ANSWER_SUCCESS, exerciseId });

export const submitExerciseAnswerCall = (exerciseId: number): ActSubmitExerciseAnswerSuccess => ({ type: EXERCISE_SUBMIT_ANSWER_CALL, exerciseId });

export const correctAnswer = (answer: Answer, cardData: ExerciseState, meta: Meta) => ({ type: CORRECT_ANSWER, answer, cardData, meta });

export const setObtainedItemId = (itemId: number, challengeId: number): ActSetObtainedItemId => ({ type: SET_OBTAINED_ITEM_ID, itemId, challengeId });

export const setIsHelpShowing = (isShowing: boolean) : UpdateHelpIsShowing => ({type: TOGGLE_HELP, isShowing});
