import styled, { css } from "styled-components";
import { Navigator } from "../../../shared/components/Navigator";

const clipPath = css`
  clip-path: polygon(
    -0.89% 83.77%,
    1.06% 88.78%,
    4.05% 92.68%,
    6.84% 95.21%,
    10.05% 96.79%,
    14.71% 98.13%,
    20.42% 98.69%,
    25.87% 99.05%,
    72.23% 99.3%,
    77.8% 98.82%,
    84.56% 98.05%,
    88.41% 97.03%,
    91.45% 95.44%,
    94.47% 93.36%,
    97.1% 90.78%,
    99.33% 85.45%,
    100.88% 78.62%,
    102.16% 56.74%,
    101.57% 20.19%,
    99.37% 10.33%,
    95.6% 3.41%,
    90.39% 0.44%,
    83.48% -1.18%,
    66.97% -2.28%,
    62.97% -0.62%,
    55.35% -0.15%,
    36.27% -0.63%,
    31.53% -2.36%,
    27.23% -2.47%,
    21.74% -2.06%,
    14.56% -1.48%,
    7.18% 1.83%,
    4.3% 4.18%,
    2.09% 7.2%,
    0.39% 10.29%,
    -2.47% 20.41%,
    -2.83% 66.6%,
    -1.89% 77.12%
  );
`;

export const StyledWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 545px;
  max-width: 696px;
  width: 100%;
  ${clipPath}
`;

export const BgImage = styled.div<{ backgroundImage: string }>`
  z-index: -1;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2400px;
  height: 2065px;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px;
`;

export const StyledBadgeGrid = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1;

  > :nth-child(even) {
    background: linear-gradient(
      90deg,
      transparent 5%,
      rgba(67, 81, 63, 1) 50%,
      transparent 95%
    );
  }
  > :nth-child(odd) {
    background: linear-gradient(
      90deg,
      transparent 5%,
      rgba(87, 102, 78, 1) 50%,
      transparent 95%
    );
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaba8a;
    border-radius: 10px;
  }
`;

export const StyledBadgeRow = styled.div<{ margin?: string }>`
  display: flex;
  min-height: 85px;
  padding: 15px 28px 15px 10px;

  &:last-child {
    min-height: 120px;
  }
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 160px;
  margin-right: 10px;
`;

export const StyledTitleLabel = styled.span`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 14px;
`;

export const StyledGroupLabel = styled.span`
  color: #ffffff;
  font-size: 11px;
  font-weight: 100;
  margin-top: 14px;
`;

export const StyledMarkersContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, 60px);
  grid-template-rows: repeat(auto-fit, 60px);
  grid-gap: 15px;
  align-items: center;
  justify-items: center;
`;

export const StyledMarker = styled.div<{
  width: string;
  height: string;
  bgColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 50%;
  border: 3px solid #9b9b9b;
  background: ${props =>
    props.bgColor ||
    "radial-gradient(circle, #C9C9C9 0%, #E3E3E3 49.79%, #E3F1DC 90.57%, #909C92 100%)"};
  box-shadow: 0 0 0 3px #43513f, 0 0 0 4px #a9afa7;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const StyledObtainedMarker = styled(StyledMarker)<{ seen: boolean }>`
  ${props =>
    props.seen
      ? `box-shadow:
      0 0 0 3px #43513f,
      0 0 0 4px ${props.bgColor}`
      : `box-shadow:
      0 0 0 3px #43513f,
      0 0 0 4px #FFFFFF`};
`;

export const StyledHeadMarker = styled(StyledMarker)`
  border: 1px solid #9b9b9b;
  background-color: #43513f;

  box-shadow: 0 0 0 3px #43513f, 0 0 0 5px #a9afa7;
`;

export const StyledObtainedHeadMarker = styled(StyledMarker)<{ seen: boolean }>`
  background-color: transparent;
  border: none;

  ${props =>
    props.seen
      ? `box-shadow:
      0 0 0 5px #43513f,
      0 0 0 6px #9b9b9b`
      : `box-shadow:
      0 0 0 5px #43513f,
      0 0 0 6px white`};
`;

export const StyledItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 14px;
`;

export const StyledFilterButton = styled.button<{ selected?: boolean }>`
  background-color: ${props => (props.selected ? "#FFFFFF" : "transparent")};
  border: 2px solid #ffffff;
  border-radius: 25px;
  min-width: 140px;
  height: 32px;
  margin: 6px;
  cursor: pointer;
`;

export const StyledButtonLabel = styled.span<{ selected?: boolean }>`
  color: #ffffff;
  color: ${props => (props.selected ? "#57664e" : "#FFFFFF")};
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  pointer-events: none;
`;

export const StyledBottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
`;

export const StyledBackNavigator = styled(Navigator)`
  margin-left: 16px;
  display: flex;
  height: 60px;
  width: 60px;
  padding: 12px;
  background-color: ${props => props.theme.themePrimary};
  color: ${props => props.theme.white};

  svg {
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #1f5d5d;
    color: ${props => props.theme.white};
  }
`;
