import styled from 'styled-components';

interface HelpProps {
  columns?: string;
  seperator?: boolean;
  topAligned?: boolean;
  marginTop?: string;
  isVideo?: boolean;
  height?: string;
}

interface TextProps {
  margin?: string;
}

interface StyledMathWordImageProps {
  finishedLoading: boolean;
}

export const StyledBackContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const StyledHelpWrapper = styled.div`
  font-family: "Muli", sans-serif;
  color: #277575;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  max-height: 90%;
  flex: 1 1 auto;
`;

export const StyledHelpContent = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: ${({ columns }: HelpProps): string => columns ? columns : 'repeat(auto-fit, minmax(100px, 1fr))'};
  grid-template-rows: minmax(100px, 100%);
  grid-gap: 20px;
  max-height: 100%;
  min-height: 0;
`;

export const StyledHelpColumn = styled.div`
  display: flex;
  position: relative;
  margin-top: ${({ marginTop }: HelpProps): string => marginTop || '0'};

  ${({ seperator }: HelpProps): string => seperator
    ?
    `&:not(:last-child):after {
    content: "";
      background-color: #277575;
      position: absolute;
      width: 1px;
      height: 75%;
      top: 0;
      right: -10px;
      display: block;
    }`
    : ``};
`;

export const StyledColumnContent = styled.div`
  max-height: 100%;
  width: 100%;
  overflow: auto;
  margin: ${({ topAligned }: HelpProps): string => topAligned ? '0' : 'auto'};
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${({ height }: HelpProps): string => height ? height : 'auto'};
`;

export const StyledTopText = styled.div`
  width: 100%;
`;

export const StyledTopHeading = styled.h2`
  font-size: 26px;
  line-height: 34px;
  margin: 0;
`;

export const StyledHelpHeading = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
`;

export const StyledHelpText = styled.span<TextProps>`
  font-size: 20px;
  margin: ${({ margin }: TextProps) => margin ? margin : '0'};
  max-height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
`;

export const StyledMathWordText = styled.span`
  font-size: 18px;
  overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
`;

export const StyledSideImg = styled.img`
  max-width: 100%;
  max-height: 500px;
  min-height: 0;
`;

export const StyledVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;
  
  video {
    min-height: 0;
    width: 100%;
    max-width: 800px;
  }
`;

export const StyledImageContainer = styled.div`
  margin-top: 10px;
`;

export const StyledHelpImageContainer = styled.div`
  flex: 1;
  min-height: 0;
  position: relative;
  max-height: 100%;
  margin: 12px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledTipsImageContainer = styled.div`
  min-height: 0;
  position: relative;
  max-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin: auto 0;
  flex: 1;
`;

export const StyledMathWordImageContainer = styled.div`
  min-height: 0;
  position: relative;
  max-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin: auto 0;
  flex: 1;
`;

export const StyledHelpImage = styled.img`
  height: auto;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 0;
  max-height: 500px;
  object-fit: contain;
  object-position: 0 0;
`;

export const StyledMathWordImage = styled.img`
  height: auto;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 0;
  max-height: 500px;
  object-fit: contain;
  object-position: 0 0;
  display: ${({ finishedLoading }: StyledMathWordImageProps) => finishedLoading ? 'block' : 'none'} !important;
`;

export const FlexContainer = styled.div`
  flex:1;
  max-height: 90%;
  ${({ isVideo }: HelpProps): string => isVideo ? '' : 'overflow: auto;'};
  -webkit-overflow-scrolling: touch;
  display: flex;
`;

export const StyledAudioContainer = styled.div`
  display: flex;
  min-width: 100px;
  max-width: 500px;
  margin: 5px;

  audio {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px rgba(55, 122, 211, .5);
      border-radius: 30px;
    }
  }
`;