import { selectTheme } from "../../../views/profile/api/selectors";
import { useSelector } from "react-redux";
import { PRIMARY_SCHOOL } from "../../constants";
import PrimarySchoolResultSlide from "./PrimarySchoolResultSlide";
import MiddleSchoolResultSlide from "./MiddleSchoolResultSlide";

export interface ResultSlideProps {
  data: ResultSlideData;
  callback(): void;
}

export interface ResultSlideData {
  img_url: string;
  title: string;
  text: string;
  points: number;
  progress_value: number;
  audio_url: string;
}

const ResultSlide: React.FC<ResultSlideProps> = ({
  data: { img_url, title, text, points, progress_value, audio_url },
  callback,
}) => {
  const theme = useSelector(selectTheme);

  return theme === PRIMARY_SCHOOL ? (
    <PrimarySchoolResultSlide
      img_url={img_url}
      title={title}
      text={text}
      points={points}
      progress_value={progress_value}
      audio_url={audio_url}
      callback={callback}
    />
  ) : (
    <MiddleSchoolResultSlide
      title={title}
      text={text}
      points={points}
      audio_url={audio_url}
      callback={callback}
    />
  );
};

export default ResultSlide;
