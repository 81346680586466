import { RootState } from "../../store";
import { SagaService } from "../../services/saga";
import { ReducerService } from "../../services/reducer";
import { reducer } from "./reducer";
import { reducer as audioReducer } from "../../shared/containers/SoundWrapper/store/reducer";
import { watchApplicationMount, watchApplicationProductInit } from "./sagas";
import {AUDIO} from "../../shared/constants";

export const init = () => {
  SagaService.set(watchApplicationMount);
  SagaService.set(watchApplicationProductInit);
  ReducerService.set("application", reducer);
  ReducerService.set(AUDIO, audioReducer);
};

export const getProductId = (state: RootState) => state.application.product.id;

export const getActivationCode = (state: RootState) => state.access.access.code;

export const getHasAccess = (state: RootState) => state.access.access.has_access;
