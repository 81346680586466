import styled from 'styled-components';

export interface StyledFlashMessageProps {
  type?: string;
}

export const StyledMessageContainer = styled.div`
  position: relative;
`;

export const StyledFlashMessage = styled.div<StyledFlashMessageProps>`
  position: relative;
  width: 100%;
  background-color: ${ ( {theme:{themePrimary}} ) => themePrimary };
  color: ${ props => props.theme.white};
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px 6px 6px;
  margin-bottom: 20px;
  min-height: 100px;
`;

export const StyledMessageBody = styled.div`
  flex: 1;
`;

export const StyledMessageTitle = styled.h3`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledMessageText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;

export const StyledButtonContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 6px;
  color: #FFFFFF;
  &:hover {
    color: ${ props => props.theme.neutralLight};
  }
`;

export const StyledClearFlashButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  height: 100%;
  width: 100%;
  
  svg {
    height: 14px;
    width: 14px;
  }
  
  &:focus {
    color: ${ props => props.theme.neutralLight};
    outline: none;
  }
  
`;

export const StyledReloadButton = styled.button`
  height: auto;
  padding: 6px 10px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  background-color: transparent;
  color: #FFFFFF;
  font-family: 'Muli';
  font-weight: 800;
  font-size: 14px;
  margin-top: 8px;
  margin-left: auto;
  cursor: pointer;
  transition: background-color .2s ease, color .2s ease;
  
  &:hover {
    background-color: #FFFFFF;
    color: ${ props => props.theme.themePrimary }
  }
`;
