import * as React from "react";
import {
  StyledToggleWrapper,
  StyledToggle,
  StyledToggleInput,
  StyledToggleHandle,
} from "./StyledToggle";

export interface ToggleProps {
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const [checked, setCheckedStatus] = React.useState(props.checked);
  const { disabled, name } = props;

  const onClick = () => {
    if (!props.disabled) {
      setCheckedStatus(!checked);
    }
  };

  return (
    <StyledToggleWrapper role="presentation" onClick={onClick}>
      <StyledToggle checked={checked}>
        <StyledToggleInput
          aria-label="toggle"
          name={name}
          disabled={disabled}
          type="checkbox"
          checked={checked}
          value={name}
          onChange={props.onChange}
        />
      </StyledToggle>
      <StyledToggleHandle checked={checked} />
    </StyledToggleWrapper>
  );
};
