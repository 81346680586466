import * as React from "react";
import { LineupInput } from "./components/LineupInput";
import { LineUpCell } from "./types";
import { LineupCell } from "./components/LineupCell";
import { ReactComponent as HiddenZero } from "../../../../assets/icons/hidden_zero.svg";
import { StyledLineupGrid, StyledCellValue } from "./StyledLineup";
import {
  focusNextInputFromMap,
  isInteger,
  getCorrectedState,
  renderEmptyRow,
  extractCellIndex,
  renderStaticComma,
  updateCommaInGrid,
} from "./helpers";
import { hasAttemptsLeft } from "../../../../../Helpers";
import {
  isMobileDevice,
  isTouchDevice,
} from "../../../../../../../shared/platform/Platform";

interface Props {
  grid: LineUpCell[][];
  items: any;
  settings: any;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  focusInput: boolean;
  correctAnswers: any;
  keyboardData?: string;
  state: string;
  callback(answers: string): void;
}

interface CellPosition {
  row: number;
  cell: number;
}

const CELL_PADDING = 1;

export const XcLineupDivision: React.FC<Props> = (props) => {
  const [gridData, setGridData] = React.useState<LineUpCell[][]>(props.grid);
  const inputTargetMap: Record<string, string> = {};
  const parsedKeyboardData = props.keyboardData
    ? JSON.parse(props.keyboardData)
    : undefined;
  const isTouch = isTouchDevice();
  const isMobile = isMobileDevice();

  const [commaSelection, setCommaSelection] = React.useState("");

  // Find the position of the equality sign
  const [equalSignPos] = React.useState(function getInitialState() {
    let esp: CellPosition | null = null;

    gridData.forEach((row, rowIndex) =>
      row.forEach((cell, cellIndex) => {
        if (cell.value === "=" || cell.value === "≈") {
          esp = { row: rowIndex, cell: cellIndex };
        }
      })
    );

    return esp ? esp : { row: 0, cell: 0 };
  });

  // Used to focus newly rendered interactive cells, target is initially the first cell after the equalsign
  const [focusTarget, setFocusTarget] = React.useState<string>(
    "[" + equalSignPos.row + ", " + (equalSignPos.cell + 1) + "]"
  );

  // Handles focus offset depending on the length of the numerator
  const [focusCellOffset] = React.useState(function getInitialState() {
    return props.items[0].value.length === 1 ? 1 : 0;
  });

  // Iterates over the grid and creates a reference to each interactive cell
  const [inputRefsMap] = React.useState(function getInitialState() {
    const irm: Record<string, React.RefObject<HTMLInputElement>> = {};

    gridData.forEach((row) =>
      row.forEach((cell) => {
        if (cell.interactive) {
          const ref = React.createRef<HTMLInputElement>();
          irm[cell.id] = ref;
        }
      })
    );

    return irm;
  });

  // Find the ids of the zeroes that are not visible, the decimal zeroes
  const [hiddenZeroes] = React.useState(function getInitialState() {
    const hz: string[] = [];

    gridData[1].forEach((element) => {
      if (element.ghost) {
        hz.push(element.id);
      }
    });

    return hz;
  });

  // Find the cells that does contain a comma
  const [commaCells] = React.useState(function getInitialState() {
    const cc: string[] = [];

    gridData.forEach((row, rowIndex) =>
      row.forEach((cell, cellIndex) => {
        if (cell.comma) {
          cc.push(cell.id);
          cell.comma =
            gridData[rowIndex][cellIndex + 1].ghost ||
            gridData[rowIndex][cellIndex + 1].interactive
              ? false
              : true;
        }
      })
    );

    return cc;
  });

  // Find the cells that are interactive in the grid
  const [interactiveCells] = React.useState(function getInitialState() {
    const ic: Record<string, boolean> = {};

    gridData.forEach((row) =>
      row.forEach((cell) => {
        if (cell.interactive) {
          ic[cell.id] = true;
        }
      })
    );

    return ic;
  });

  /**
   * Caluclates the gridlength minus the hidden cells.
   */
  const calculateGridLength = (grid: LineUpCell[][]) => {
    let gridLength: number = 0;
    grid[0].forEach((cell) => {
      gridLength = !cell.hidden ? gridLength + 1 : gridLength;
    });

    return gridLength;
  };

  const findNextInteractiveCell = (oldCellId: string) => {
    const interactiveCellsIds = Object.keys(interactiveCells);

    const index = interactiveCellsIds.indexOf(oldCellId);
    if (index === interactiveCellsIds.length - 1) {
      return "";
    }

    const nextInteractiveCell = interactiveCellsIds[index + 1];

    return nextInteractiveCell;
  };

  /**
   * Calculates what cell should get focus depending on the parameters
   * @param row
   * @param cell
   */
  const setTargetCell = (row: number, cell: number, memoryCell: boolean) => {
    const currentEqualPos = equalSignPos;
    const oldCellId: string = "[" + row + ", " + cell + "]";
    const targetRow = memoryCell ? currentEqualPos.row : 0;
    const targetCell = memoryCell
      ? currentEqualPos.cell + cell + 1 - focusCellOffset
      : cell - currentEqualPos.cell + focusCellOffset;

    let targetCellId: string = "[" + targetRow + ", " + targetCell + "]";
    let interactive = interactiveCells[targetCellId];
    targetCellId = interactive
      ? targetCellId
      : "[" + currentEqualPos.row + ", " + (cell + 1) + "]";

    interactive = interactiveCells[targetCellId];
    targetCellId = interactive
      ? targetCellId
      : findNextInteractiveCell(oldCellId);

    inputTargetMap[oldCellId] = targetCellId;
  };

  /**
   * Checks if the rendered cell is a "filler" cell, if so the styling will be different.
   * @param row
   * @param cell
   */
  const isFillerCell = (row: number, cell: number) => {
    return row === 1 &&
      cell > equalSignPos.cell &&
      gridData[row + 1][cell].interactive
      ? true
      : false;
  };

  /**
   * Determines wether to render borderbottom or not
   */
  const renderBorderBottom = (cell: LineUpCell) =>
    cell.borderBottom ? true : false;

  const publishSelectionState = (answer: LineUpCell[][]): void => {
    props.callback(JSON.stringify({ answer }));
  };

  const renderValue = (
    id: string,
    value: string,
    isHorizontal: number | undefined,
    rowIndex: number,
    cellIndex: number
  ) =>
    hiddenZeroes.includes(id) ? (
      <HiddenZero />
    ) : (
      <StyledCellValue
        isHorizontal={isHorizontal}
        isRestCell={value.toLowerCase() === "r"}
        isEqualCell={
          rowIndex === equalSignPos.row && cellIndex === equalSignPos.cell
        }
      >
        {value === "r" ? "rest" : value}
      </StyledCellValue>
    );

  const updateGridDataForComma = (id: string) => {
    const newList = updateCommaInGrid(id, gridData, commaSelection);
    setCommaSelection(id === commaSelection ? "" : id);
    setGridData(newList);
    publishSelectionState(newList);
  };

  const updateGridData = (id: string, value: string) => {
    const target = inputTargetMap[id];
    const targetCell = extractCellIndex(target);
    let revealTargetColumn = false;

    const newList = gridData.map((row) =>
      row.map((cell: LineUpCell, cellIndex) => {
        if (cell.id === id) {
          return { ...cell, value };
        }

        if (target === cell.id && cell.hidden) {
          revealTargetColumn = true;
          setFocusTarget(target);
          return { ...cell, hidden: false };
        }

        if (revealTargetColumn && +targetCell === cellIndex) {
          return { ...cell, hidden: false };
        }

        // Render comma
        if (
          revealTargetColumn &&
          commaCells.includes(cell.id) &&
          cellIndex < equalSignPos.cell
        ) {
          return { ...cell, comma: true };
        }

        return cell;
      })
    );

    setGridData(newList);
    publishSelectionState(newList);
  };

  const inputCallback = (value: string, refIndex: number, id: string) => {
    updateGridData(id, value);
    isInteger(value) && focusNextInputFromMap(id, inputTargetMap, inputRefsMap);
  };

  const createInputEl = (
    cell: LineUpCell,
    rowIndex: number,
    cellIndex: number
  ) => {
    const correctedState = getCorrectedState(
      cell.value,
      cell.comma,
      rowIndex,
      cellIndex,
      props.correctAnswers,
      props.currentAttempt
    );
    setTargetCell(rowIndex, cellIndex, cell.inMemory);

    return !cell.hidden ? (
      <LineupInput
        id={cell.id || ""}
        refObj={inputRefsMap[cell.id]}
        callback={inputCallback}
        inMemory={cell.inMemory}
        value={cell.value}
        key={cellIndex}
        type={props.settings.calculateType}
        inContainer
        rowIndex={rowIndex}
        disabled={
          !hasAttemptsLeft(props.currentAttempt, 2) || props.state === "CORRECT"
        }
        updateCommaCallback={updateGridDataForComma}
        correctedStateComma={correctedState.comma}
        correctedStateValue={correctedState.value}
        keyboardData={parsedKeyboardData}
        keyboardType={props.settings.keyboardCharacterSet}
        comma={cell.comma}
        isCommaSelected={commaSelection !== ""}
        cross={correctedState.value === "INCORRECT"}
        cellIndex={cellIndex}
        isVertical={cell.isVertical}
        isHorizontal={cell.isHorizontal}
        isTouchDevice={isTouch && isMobile}
      />
    ) : null;
  };

  const renderRow = (row: LineUpCell[], rowIndex: number) => {
    return row.map((cell: LineUpCell, cellIndex: number) => {
      return cell.interactive ? (
        createInputEl(cell, rowIndex, cellIndex)
      ) : (
        <LineupCell
          id={cell.id}
          borderBottom={renderBorderBottom(cell)}
          key={"key_" + cellIndex}
          hidden={cell.hidden}
          crossed={cell.crossed}
          isFillerCell={isFillerCell(rowIndex, cellIndex)}
          offsetCell={cell.isVertical && !cell.ghost ? true : false}
          displayCorrectAnswer={props.displayCorrectAnswer}
          isMemoryDigit={cell.inMemory}
          calculateType={props.settings.calculateType}
        >
          {renderValue(
            cell.id,
            cell.value,
            cell.isHorizontal,
            rowIndex,
            cellIndex
          )}
          {renderStaticComma(cell.comma)}
        </LineupCell>
      );
    });
  };

  const renderData = (grid: LineUpCell[][]) => {
    return grid.map((row, index) => {
      return (
        <React.Fragment key={"key_" + index}>
          <LineupCell borderBottom={false} />
          {renderRow(row, index)}
          <LineupCell borderBottom={false} />
        </React.Fragment>
      );
    });
  };

  React.useEffect(() => {
    const ref = inputRefsMap[focusTarget];
    if (props.focusInput && ref && ref.current) {
      ref.current.focus();
    }
  }, [focusTarget, inputRefsMap, props.focusInput]);

  return (
    <StyledLineupGrid
      rowLength={
        calculateGridLength(
          props.displayCorrectAnswer
            ? props.correctAnswers.correctAnswers
            : gridData
        ) +
        CELL_PADDING * 2
      }
    >
      {renderEmptyRow(
        calculateGridLength(
          props.displayCorrectAnswer
            ? props.correctAnswers.correctAnswers
            : gridData
        ),
        CELL_PADDING
      )}
      {props.displayCorrectAnswer
        ? renderData(props.correctAnswers.correctAnswers)
        : renderData(gridData)}
      {renderEmptyRow(
        calculateGridLength(
          props.displayCorrectAnswer
            ? props.correctAnswers.correctAnswers
            : gridData
        ),
        CELL_PADDING
      )}
    </StyledLineupGrid>
  );
};
