import styled from "styled-components";

interface RowProps {
  size?: string;
  minimizeTextSpace?: boolean;
  position?: string;
}

export const StyledExerciseRow = styled.div<RowProps>`
  margin-top: 10px;
  flex: ${(props) => props.minimizeTextSpace ? 'initial' : props.size ? `${props.size}` : '1 1 0%'};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  max-height: ${(props) => props.minimizeTextSpace ? `min-content` : 'none'};
  ${(props) => props.position && `position: ${props.position};`}

  &:first-child { margin-top: 0 };
`;

export const StyledExerciseWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 38px 20px 56px;
  display: flex;
  flex: 1;
  flex-direction: column;

  .katex {
      font-family: 'Libre Franklin', sans-serif; // Change font for all Latex in exercise.
  }
`;

export const StyledBlockArray = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-height: 0;
  position: relative;
`;

export const StyledFeedbackWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 50px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  pointer-events: none;
`;

export const StyledFeedbackDisplayContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  
  @media (min-width: 800px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledExerciseRenderer = styled.span`
  width: 100%;
  height: 100%;
`;

export const StyledPreviewLabel = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  border: 1px solid #277575;
  padding: 10px;
`;
