import { put, takeLatest, select } from "typed-redux-saga";
import { RouteService } from "../../../services/route";
import { APPLICATION_READY } from "../../../shared/constants";
import { initRouter } from "./actions";
import { ONBOARDING_ROUTE, EXERCISE_ROUTE } from "../../../views/routes";
import * as qs from "query-string";
import { selectOnboardingCompleted } from "../../../views/profile/api/selectors";

function* initiateRouter() {
  const onboardingCompleted = yield* select(selectOnboardingCompleted); //changed selector for the onboarding_completed from progress to the profile
  const initialRoute = getInitialRoute(onboardingCompleted);
  yield* put(initRouter(initialRoute));
}

export function* routerSaga() {
  yield* takeLatest(APPLICATION_READY, initiateRouter);
}

/**
 * Fetches the querystring part of the URL.
 * If the part is equal to onboarding=true the ONBOARDING_ROUTE will be initial.
 * If preview is in the querystring the EXERCISE_ROUTE will be initial.
 */
function getInitialRoute(onboardingCompleted?: boolean) {
  const queryParams = qs.parse(window.location.search);

  const preview = queryParams && queryParams.preview;
  if (preview) {
    return EXERCISE_ROUTE;
  }

  const onboarding = queryParams && queryParams.onboarding;
  const showOnboarding = onboarding === "true";
  if (!onboardingCompleted || showOnboarding) {
    return ONBOARDING_ROUTE;
  }

  return RouteService.getDefaultRouteKey();
}
