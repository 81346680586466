import { AnyAction } from "redux";
import { NetworkState } from "./types";
import {
  NETWORK_CONNECTED,
  NETWORK_DISCONNECTED,
} from "../../constants";

const initialState = {
  connected: true,
  error: null
};

/**
 * Redux reducer for network status
 * @param {NetworkState} state current state
 * @param {AnyAction} action incoming action
 * @returns {NetworkState} updated state
 */
export function reducer(state: NetworkState = initialState, action: AnyAction) {
  switch (action.type) {
    case NETWORK_CONNECTED:
      return {
        ...state,
        connected: true,
        error: null
      };
    case NETWORK_DISCONNECTED:
      return {
        ...state,
        connected: false,
        error: action.error
      };
    default:
      return state;
  }
}
