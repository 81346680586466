import styled from "styled-components";
import { Navigator } from "../../shared/components/Navigator";
import {
  shineAnimation,
  pulseAnimation,
} from "../../shared/components/Navigator/StyledNavigator";

interface PlaceableProps {
  offsetX: string;
  offsetY: string;
  scale?: string;
  color?: string;
  position?: string;
}
interface AdventureProps {
  notActiveAdventure: boolean;
}

export const StyledTopContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  width: 100vw;
  max-width: 100%;
  padding: 20px;
  position: absolute;

  > div {
    display: inline-block;
    z-index: 1;
  }

  svg {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #333333;
  position: relative;
  overflow: hidden;
`;

export const BackgroundImage = styled.img`
  height: 100%;
  width: auto;
`;

export const StyledLinksContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100vh;
  overflow: hidden;
`;

export const Placeable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform-origin: center;
  z-index: 10;
  width: calc(${(props: PlaceableProps) => props.scale}*420*100vh / 1080);
  transform: translateX(
      calc(${(props: PlaceableProps) => props.offsetX}*100vh / 1080)
    )
    translateY(calc(${(props: PlaceableProps) => props.offsetY}*100vh / 1080));

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const StyledNavigatorLink = styled(Navigator)<AdventureProps>`
  position: relative;
  overflow: hidden;
  background-color: ${(props) =>
    props.notActiveAdventure ? "#277575" : "#ffffff"};
  border-radius: 50px;
  font-weight: 900;
  color: ${(props) => (props.notActiveAdventure ? "#ffffff" : "#277575")};
  padding: 7px;
  box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.3);
  min-width: 185px;
  display: flex;
  justify-content: center;
  outline: none;
  &:hover {
    background-color: #f0f0f0;
  }
  &:focus {
    box-shadow: 0 0 0 4px #277575, 0px 6px 4px 0px rgba(0, 0, 0, 0.3);
  }
  &:active {
    box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.3);
  }

  ${(props) => props.notActiveAdventure && shineAnimation}
  ${(props) => props.notActiveAdventure && pulseAnimation}
`;
