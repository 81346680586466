import { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentRoute,
  selectNextRoute,
} from "../../shared/store/router/selectors";
import { selectHasActiveAdventure } from "../adventurePicker/api/selectors";
import { selectIsStoryRead } from "../markers/api/selectors";
import { ADVENTURE_STORY_ROUTE, EXERCISE_ROUTE } from "../routes";
import { selectAssignmentArray } from "./api/selectors";
import { ParsedAssignment } from "./api/types";

type ReturnProps = {
  currentAssignment: number;
  selectableAssignments: ParsedAssignment[];
  canGoBack: boolean;
  canGoForward: boolean;
  exerciseRoute: string;
  currentRoute: string;
  nextRoute: { route: string; text: string } | undefined;
  hasActiveAdventure: boolean;
  setCurrentAssignment: (index: number) => void;
};

export function useAssignment(): ReturnProps {
  const [currentAssignment, setCurrentAssignment] = useState(0);

  const selectableAssignments = useSelector(selectAssignmentArray);
  const isStoryRead = useSelector(selectIsStoryRead);
  const nextRoute = useSelector(selectNextRoute);
  const currentRoute = useSelector(selectCurrentRoute);
  const hasActiveAdventure = useSelector(selectHasActiveAdventure);

  const getExeRoute = (currentStoryRead: Boolean) =>
    !currentStoryRead ? ADVENTURE_STORY_ROUTE : EXERCISE_ROUTE;

  const exerciseRoute = getExeRoute(isStoryRead || false);

  return {
    selectableAssignments,
    currentAssignment,
    canGoForward: currentAssignment !== 0,
    canGoBack: currentAssignment === 0 && selectableAssignments.length > 1,
    exerciseRoute: nextRoute ? nextRoute.route : exerciseRoute,
    currentRoute: currentRoute as string,
    nextRoute: nextRoute,
    hasActiveAdventure,
    setCurrentAssignment,
  };
}
