import styled from "styled-components";
import { getAlignment } from "./helpers";

export const StyledChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledChart = styled.div<{
  rows: number;
  columns: number;
  color?: string;
  showBorder: boolean;
}>`
  display: grid;
  grid-template-rows: ${(props) => `repeat(${props.rows}, auto)`};
  grid-template-columns: ${(props) => `repeat(${props.columns}, auto)`};
  border: ${(props) =>
    props.showBorder ? "0.0625rem solid #1d717080" : "none"};
`;

export const StyledChartCell = styled.div<{
  correctedState: string;
  minHeight: number;
  minWidth: number;
  showBorder: boolean;
  borderBottom: boolean;
  borderRight: boolean;
  index: number;
  chartType: string;
}>`
  border: ${(props) =>
    props.showBorder ? "0.0625rem solid #1d717080" : "none"};
  border-bottom: ${(props) => props.borderBottom && "0.125rem solid #333333"};
  border-right: ${(props) => props.borderRight && "0.125rem solid #333333"};
  font-family: "Muli", sans-serif;
  position: relative;
  display: flex;
  justify-content: ${({ chartType, index }) => getAlignment(chartType, index)};
  align-items: center;
  background-color: ${(props) => props.color || "#ffffff"};
  width: 100%;
  min-width: ${(props) => `${props.minHeight}rem`};
  min-height: ${(props) => `${props.minWidth}rem`};
  ${(props) =>
    props.correctedState === "INCORRECT" &&
    "box-shadow: inset 0 0 0 0.25rem rgba(188, 36, 36, 0.67)"};
`;

export const StyledTextValue = styled.span<{ isTitle: boolean }>`
  max-width: fit-content;
  font-size: ${(props) => (props.isTitle ? "0.875rem" : "1.25rem")};
  font-weight: ${(props) => (props.isTitle ? "900" : "600")};
`;
