import * as React from "react";
import PrimaryButton from "./PrimaryButton";

interface Props {
  callback?: (id: any) => void;
  icon: any;
  skin?: string;
  overrideStyles?: object;
  id?: number;
  disabled?: boolean;
}

export const RoundButton: React.FC<Props> = ({
  icon,
  callback,
  id,
  children,
  overrideStyles,
  disabled,
  ...rest
}) => {
  const handleClick = () => (callback ? callback(id) : null);

  return (
    <PrimaryButton
      icon={icon}
      overrideStyles={overrideStyles}
      circular={true}
      onClick={handleClick}
      disabled={disabled}
      {...rest}
    />
  );
};
