import {
  StyledFormWrapper,
  OnboardingHeadingContainer,
  OnboardingConfirmWrapper,
  OnboardingParagraph,
  StyledAudioContainer,
  StyledOnboardingContent,
  StyledCheckMark,
  OnboardingSelection,
  OnboardingSelectionWrapper,
  StyledIconButton,
} from "./StyledOnboarding";
import { AudioComponent } from "../../shared/components/Audio";
import { SelectedState } from "./helpers";

interface SelectGradeProps {
  selectedState: SelectedState;
  selectedGrade: string;
  setIsConfirmGradeSelection: (state: SelectedState) => void;
}

const OnboardingConfirm = ({
  selectedState,
  selectedGrade,
  setIsConfirmGradeSelection,
}: SelectGradeProps) => {
  return (
    <StyledOnboardingContent>
      <OnboardingHeadingContainer>
        <StyledAudioContainer>
          <AudioComponent
            audioUrl={`https://media.studentlitteratur.se/audio/mr/story/audio/st01_onge_16_${selectedGrade}.mp3`}
          />
        </StyledAudioContainer>
        <OnboardingParagraph>
          {`Aha, du går i åk ${selectedGrade}, stämmer det`}
        </OnboardingParagraph>
      </OnboardingHeadingContainer>
      <StyledFormWrapper>
        <OnboardingSelectionWrapper>
          <OnboardingConfirmWrapper>
            <OnboardingSelection>Ja</OnboardingSelection>
            <StyledIconButton
              onClick={() => setIsConfirmGradeSelection(SelectedState.YES)}
            >
              {selectedState === SelectedState.YES && <StyledCheckMark />}
            </StyledIconButton>
          </OnboardingConfirmWrapper>
          <OnboardingConfirmWrapper>
            <OnboardingSelection>Nej</OnboardingSelection>
            <StyledIconButton
              onClick={() => setIsConfirmGradeSelection(SelectedState.NO)}
            >
              {selectedState === SelectedState.NO && <StyledCheckMark />}
            </StyledIconButton>
          </OnboardingConfirmWrapper>
        </OnboardingSelectionWrapper>
      </StyledFormWrapper>
    </StyledOnboardingContent>
  );
};
export default OnboardingConfirm;
