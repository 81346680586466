import { takeLatest, put, delay } from "typed-redux-saga";
import { FLASHER_ADD_FLASH, ERROR_TYPES } from "../../constants";
import { AnyAction } from "redux";
import { clearFlash } from "./actions";

export function* watchAddFlashSaga() {
  yield* takeLatest(FLASHER_ADD_FLASH, flashAddedSaga);
}

export function* flashAddedSaga(action: AnyAction) {
  if (action.flashType === ERROR_TYPES.INFO || action.closeTimeout) {
    yield* delay(action.closeTimeout || 3000);
    yield* put(clearFlash(action.id));
  }
}
