import { RootState } from "../../../store";
import { EXERCISE } from "../../../shared/constants";
import { RawExercise } from "./types";

export const getCurrentExerciseId = (state: RootState) => {
  const slideIndex = state.exercise.slideIndex;

  if (state.exercise.slideIndexTypes[slideIndex].type === EXERCISE) {
    const { slideIndexTypes, decks } = state.exercise;

    const deckID = slideIndexTypes[slideIndex].deckID;
    const activeCard = decks[deckID].activeCard;

    return decks[deckID].cardsStates[activeCard].exerciseId;
  }

  throw new Error("Not an exercise");
};

export const getExerciseData = (state: RootState, exerciseId: number) => {
  const slideIndex = state.exercise.slideIndex;

  if (state.exercise.slideIndexTypes[slideIndex].type === EXERCISE) {
    const { exercises } = state.exercise;

    const exerciseDatas = exercises[slideIndex].data;
    return exerciseDatas.find((exe: RawExercise) => exe.id === exerciseId);
  }

  throw new Error("Not an exercise");
};

export const getIsRepetition = ({
  exercise: { isRepetition }
}: RootState): boolean => isRepetition;

export const getObtainedItemId = (state: RootState): RootState => {
  return state.exercise.obtainedItemId;
};

export const getObtainedItemChallengeId = (state: RootState): RootState => {
  return state.exercise.obtainedItemChallengeId;
};
