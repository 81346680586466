import styled from "styled-components";
import { ReactComponent as Triangel } from "../../assets/icons/icon_triangel.svg";
import { ReactComponent as GoBackArrow } from "../../assets/icons/icon_goback_arrow.svg";

export const StyledAdventurePickerContainer = styled.div`
  height: 100%;
  width: 100%;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  @media (max-height: 700px) {
    height: 700px;
  }
`;

export const StyledGoBackArrow = styled(GoBackArrow)<{ desktop: number }>`
  ${props =>
    props.desktop
      ? ` 
      height: 30px;
      width: 35px;
      top: 44px;
      `
      : `
      height: 45px;
      width: 45px;
      top: 37px;
      `}
  position: absolute;
  left: 37px;
  color: #1b2646;
  cursor: pointer;
  pointer-events: none;
`;

export const StyledBackground = styled.div<{ primarySchool: boolean }>`
  background-image: url(${props =>
    props.primarySchool
      ? "https://media.studentlitteratur.se/image/mr/story/images/background_adventuregroups_1-3.jpg"
      : "https://media.studentlitteratur.se/image/mr/story/images/background_adventuregroups_4-6.jpg"});
  height: 100%;
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-height: 700px) {
    background-size: 100vw 700px;
  }
`;

export const StyledButton = styled.button`
  height: 45px;
  background-color: #92c0c7;
  border-radius: 40px;
  cursor: pointer;
  border: 0px;
  margin-left: 1em;
  box-shadow: 0 0 10px 2px rgba(146, 192, 199, 0.6);
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  :focus {
    outline: none;
    box-shadow: 0 0 10px 2px white;
    background-color: white;
  }
`;

export const StyledButtonText = styled.span`
  color: #1d2640;
  font-size: 20px;
  margin: 8px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StyledButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 45px;
  width: 100%;
  bottom: 61px;
  right: 45px;
`;

export const StyledMonitor = styled.div<{
  primarySchool: boolean;
  desktop: number;
}>`
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center center;
  background-image: url(${props =>
    getMonitorImage(props.desktop, props.primarySchool)});
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledMonitorContainer = styled.div<{ desktop: number }>`
  height: ${props => (props.desktop ? "700px" : "95vh")};
  margin: 0 auto;
  width: ${props => (props.desktop ? "900px" : "700px")};
  padding-top: 5vh;
`;

export const StyledGoBackButton = styled(Triangel)<{ desktop: number }>`
  ${props =>
    props.desktop
      ? `
    width: 75px;
    height: 75px;
    top: 39px;
    left: 39px;
  `
      : `
    width: 100px;
    height: 100px;
    top: 26px;
    left: 43px;
  `}
  position:absolute;
  color: #92c0c7;
  & polygon {
    :hover {
      color: #b9f5f2;
      box-shadow: 0 0 10px 2px rgba(146, 192, 199, 0.6);
    }
    cursor: pointer;
  }
`;

export const StyledHomeButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

export const StyledAdventureAudioContainer = styled.div`
  position: absolute;
  top: 75px;
  left: 100px;
`;

export const StyledAdventureLabel = styled.span`
  font-size: 22px;
  color: #a9ebff;
  position: absolute;
  font-weight: 900;
  top: 75px;
  left: 145px;
  letter-spacing: 0px;
`;

const getMonitorImage = (desktop: number, primarySchool: boolean) => {
  if (primarySchool) {
    return desktop
      ? "https://media.studentlitteratur.se/image/mr/story/images/monitor_1-3.jpg"
      : "https://media.studentlitteratur.se/image/mr/story/images/monitor_1-3-tablet.jpg";
  }

  return desktop
    ? "https://media.studentlitteratur.se/image/mr/story/images/monitor_4-6.jpg"
    : "https://media.studentlitteratur.se/image/mr/story/images/monitor_4-6-tablet.jpg";
};
