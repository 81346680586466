import { darken } from "polished";

import * as colors from "./colors";

export const height = "32px";
export const padding = "12px";
export const paddingNumber = 12;

export const fill = {
  defautls: colors.neutralLight,
  disable: colors.neutralLight,
  primary: colors.themePrimary,
  standard: colors.neutralLighter,
};

export const ink = {
  defaults: colors.neutralDark,
  disable: colors.neutralTertiary,
  primary: colors.white,
  standard: colors.themePrimary,
};

export const skins = {
  defaults: () => skin(fill.defautls, ink.defaults),
  standard: () => skin(fill.standard, ink.standard),
  primary: () => skin(fill.primary, ink.primary),
};

export const defaultType = ["14px", 1, 500, "0.02em", "none"];

export const skin = (fillColor: string, inkColor: string) => `
  background-color: ${fillColor};
  color: ${inkColor};
  
  &:hover,
  &:focus {
    background-color: ${darken(0.05, fillColor)};
    color: ${inkColor};
  }
  
  &:active {
    background-color: ${darken(0.08, fillColor)};
    color: ${inkColor};
  }
`;
