import { RootState } from "../../../store";
import { createSelector } from "reselect";

export const selectMarkerHeadGroups = (state: RootState) =>
  state.test.markerGroups;

export const selectSelectedMarkerGroupId = (state: RootState) =>
  state.test.selectedMarkerGroupId;

export const selectSelectedMarkerHeadGroup = createSelector(
  selectMarkerHeadGroups,
  selectSelectedMarkerGroupId,
  (groups, id) => groups.find((group) => group.id === id)
);
