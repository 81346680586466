import { 
  ACCESS_API_ACTIONS
} from "./actions";
import { AnyAction } from "redux";
import { AccessState } from "./types";

const initialState = {
  fetching: false,
  access: {},
  error: null
};

/**
 * Redux reducer for progress
 * @param {AccessState} state current state
 * @param {AnyAction} action incoming action
 * @returns {AccessState} updated state
 */
export function reducer(state: AccessState = initialState, action: AnyAction) {
  switch (action.type) {
    case ACCESS_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case ACCESS_API_ACTIONS.READ.SUCCESS:
      return { ...state, fetching: false, access: action.access };
    case ACCESS_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, access: null, error: action.error };

    default:
      return state;
  }
}
