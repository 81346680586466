import { AnyAction } from 'redux';
import { 
  SPLASH_SET_VISIBILITY,
  SPLASH_MODAL_VISIBILITY,
  DIALOG_SET_VISIBILITY
} from "../../constants";

const initialState = {
  visible: false,
  modalOpen: false,
  dialogOpen: true
};

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SPLASH_SET_VISIBILITY:
      return { ...state, visible: action.visible };
    case SPLASH_MODAL_VISIBILITY:
      return { ...state, modalOpen: action.modalOpen };
    case DIALOG_SET_VISIBILITY:
      return { ...state, dialogOpen: action.dialogOpen };
    default:
      return state;
  }
}
