import * as React from "react";
import { StyledLineupGrid, StyledCellValue } from "./StyledLineup";
import { LineupInput } from "./components/LineupInput";
import { LineUpCell } from "./types";
import { LineupCell } from "./components/LineupCell";
import { LineupMemoryCell } from "./components/LineupMemoryCell";
import {
  focusNextInput,
  isInteger,
  getCorrectedState,
  renderEmptyRow,
  renderMemoryCell,
  renderStaticComma,
  prepareGridData,
  updateCommaInGrid,
} from "./helpers";
import { hasAttemptsLeft } from "../../../../../Helpers";
import {
  isMobileDevice,
  isTouchDevice,
} from "../../../../../../../shared/platform/Platform";

interface Props {
  grid: LineUpCell[][];
  settings: any;
  currentAttempt: number;
  displayCorrectAnswer: boolean;
  focusInput: boolean;
  correctAnswers: any;
  keyboardData?: string;
  state: string;
  callback(answers: string): void;
}

interface CellPosition {
  row: number;
  cell: number;
}

const CELL_PADDING = 1;

export const XcLineupMultiplication: React.FC<Props> = (props) => {
  const inputRefs: Array<React.RefObject<HTMLInputElement>> = [];
  const cellFocusNextMap: Record<string, boolean> = {};
  const inputRefsMap: Record<string, React.RefObject<HTMLInputElement>> = {};
  const parsedKeyboardData = props.keyboardData
    ? JSON.parse(props.keyboardData)
    : undefined;
  const isTouch = isTouchDevice();
  const isMobile = isMobileDevice();

  const [commaSelection, setCommaSelection] = React.useState("");

  let currentRow = 0;

  /**
   * Finds the next empty input cell in the exercise.
   * Excluding memory cells
   */
  const findNextEmptyInputCell = () => {
    const newGrid = gridData
      .map((row) => [...row].reverse())
      .reduce(
        (acc, val) => acc.concat(val),
        []
      ); /* Array.flat() does not work in old iOS*/

    const nextCell = newGrid.find(
      (cell) => cell.interactive && !cell.inMemory && cell.value === ""
    );

    return nextCell ? nextCell.id : "";
  };

  const findFirstInputCell = () => {
    let fic: LineUpCell = {
      id: "",
      inMemory: false,
      value: "",
      interactive: false,
    };

    // Checks first answer row
    for (const cell in gridData[2]) {
      if (
        gridData[2][cell].interactive &&
        !gridData[2][parseInt(cell, 10) + 1].interactive
      ) {
        fic = gridData[2][cell];
        break;
      }
    }

    return fic;
  };
  /**
   * Prepare grid data
   */
  const [gridData, setGridData] = React.useState(function getInitialState() {
    return prepareGridData(props.grid);
  });

  const publishSelectionState = (answer: LineUpCell[][]): void => {
    props.callback(JSON.stringify({ answer }));
  };

  const updateGridDataForComma = (id: string) => {
    const newList = updateCommaInGrid(id, gridData, commaSelection);
    setCommaSelection(id === commaSelection ? "" : id);
    setGridData(newList);
    publishSelectionState(newList);
  };

  const updateGridData = (id: string, value: string) => {
    let memoryPos: CellPosition | null = null;
    const summationRow: number = gridData.length - 1;
    const gridDataLength: number = gridData.length;

    const newList = gridData.map((row, rowIndex) =>
      row.map((cell: LineUpCell, cellIndex) => {
        if (cell.id === id && rowIndex === summationRow && gridDataLength > 3) {
          memoryPos = { row: rowIndex - 3, cell: cellIndex - 1 };
          return { ...cell, value };
        }

        if (cell.id === id) {
          return { ...cell, value };
        }

        return cell;
      })
    );

    if (memoryPos !== null) {
      const rowString = "row";
      const cellString = "cell";
      const memoryCell = newList[memoryPos[rowString]][memoryPos[cellString]];
      memoryCell.hidden = value === "";
    }

    setGridData(newList);
    publishSelectionState(newList);
  };

  const memoryInputCallback = (value: string, refIndex: number, id: string) => {
    updateGridData(id, value);
    isInteger(value) && focusInputFromMemoryCell();
  };

  const inputCallback = (value: string, refIndex: number, id: string) => {
    updateGridData(id, value);
    cellFocusNextMap[id]
      ? isInteger(value) && focusNextInput(refIndex, inputRefs)
      : blurCurrentFocus(refIndex);
  };

  const createInputEl = (
    cell: LineUpCell,
    rowIndex: number,
    cellIndex: number
  ) => {
    const ref = React.createRef<HTMLInputElement>();
    const correctedState = getCorrectedState(
      cell.value,
      cell.comma,
      rowIndex,
      cellIndex,
      props.correctAnswers,
      props.currentAttempt
    );

    const focusNext = currentRow >= rowIndex ? true : false;
    shouldFocusNext(cell.id, focusNext, rowIndex);
    !cell.inMemory && addInputRef(cell.id, ref);

    const tabOrderPos = parseInt(`${rowIndex}${gridData[0].length - cellIndex}`);

    return cell.value && cell.inMemory ? (
      <LineupMemoryCell
        id={cell.id}
        borderBottom={rowIndex === 1}
        key={"key_" + cellIndex}
        crossed={cell.crossed}
        displayCorrectAnswer={props.displayCorrectAnswer}
        isMemoryDigit={cell.inMemory}
        callback={toggleMemoryCrossed}
        callback2={convertToInput}
        correctedState={correctedState.value}
        inContainer={true}
        cross={correctedState.value === "INCORRECT"}
        cellIndex={cellIndex}
      >
        {renderMemoryCell(cell.hidden, cell.value, props.displayCorrectAnswer)}
      </LineupMemoryCell>
    ) : (
      <LineupInput
        tabOrderPos={tabOrderPos}
        id={cell.id || ""}
        refObj={ref}
        borderBottom={rowIndex === gridData.length - 2 || rowIndex === 1}
        callback={
          rowIndex === 0 || rowIndex === 1 ? memoryInputCallback : inputCallback
        }
        refIndex={inputRefs.length - 1}
        rowIndex={rowIndex}
        value={cell.value}
        key={cellIndex}
        disabled={
          !hasAttemptsLeft(props.currentAttempt, 2) || props.state === "CORRECT"
        }
        updateCommaCallback={updateGridDataForComma}
        correctedStateComma={correctedState.comma}
        correctedStateValue={correctedState.value}
        keyboardData={parsedKeyboardData}
        keyboardType={props.settings.keyboardCharacterSet}
        inMemory={cell.inMemory}
        inContainer
        comma={cell.comma}
        isCommaSelected={commaSelection !== ""}
        cross={correctedState.value === "INCORRECT"}
        cellIndex={cellIndex}
        isTouchDevice={isTouch && isMobile}
        gridLength={props.grid.length}
        type={props.settings.calculateType}
      />
    );
  };

  const toggleMemoryCrossed = (id: string) => {
    const newList = gridData.map((row, rowIndex) =>
      row.map((cell: LineUpCell, cellIndex: number) => {
        if (cell.id === id) {
          return { ...cell, crossed: !cell.crossed };
        }
        return cell;
      })
    );

    setGridData(newList);
  };

  const convertToInput = (id: string) => {
    const newList = gridData.map((row, rowIndex) =>
      row.map((cell: LineUpCell, cellIndex: number) => {
        if (cell.id === id) {
          return { ...cell, value: "", crossed: !cell.crossed };
        }

        return cell;
      })
    );

    setGridData(newList);
  };

  const renderRow = (row: LineUpCell[], rowIndex: number) => {
    return row.map((cell: LineUpCell, cellIndex: number) => {
      return cell.interactive ? (
        createInputEl(cell, rowIndex, cellIndex)
      ) : (
        <LineupCell
          id={cell.id}
          borderBottom={rowIndex === gridData.length - 2 || rowIndex === 1}
          key={"key_" + cellIndex}
          hidden={cell.hidden}
          crossed={cell.crossed}
          displayCorrectAnswer={props.displayCorrectAnswer}
          isMemoryDigit={cell.inMemory}
        >
          {cell.inMemory ? (
            renderMemoryCell(
              cell.hidden,
              cell.value,
              props.displayCorrectAnswer
            )
          ) : (
            <StyledCellValue>{cell.value}</StyledCellValue>
          )}
          {renderStaticComma(cell.comma)}
        </LineupCell>
      );
    });
  };

  const addInputRef = (
    cellId: string,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    inputRefs.push(ref);
    inputRefsMap[cellId] = ref;
  };

  const focusInputFromMemoryCell = () => {
    const targetId = findNextEmptyInputCell();
    const targetRef = inputRefsMap[targetId];

    if (targetRef && targetRef.current) {
      targetRef.current.focus();
    }
  };

  const shouldFocusNext = (
    id: string,
    focusNext: boolean,
    rowIndex: number
  ) => {
    currentRow = focusNext ? currentRow : rowIndex;
    cellFocusNextMap[id] = focusNext;
  };

  const blurCurrentFocus = (index: number) => {
    const currentFocus = inputRefs[index].current;

    if (currentFocus) {
      currentFocus.blur();
    }
  };

  React.useEffect(() => {
    const firstInputCell = findFirstInputCell();
    const ref = inputRefsMap[firstInputCell.id];

    if (props.focusInput && ref && ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line
  }, [props.focusInput]);

  const renderData = (grid: LineUpCell[][]) => {
    return grid.map((row, index) => {
      return (
        <React.Fragment key={"key_" + index}>
          <LineupCell borderBottom={index === grid.length - 2 || index === 1} />
          {renderRow(row, index)}
          <LineupCell borderBottom={index === grid.length - 2 || index === 1} />
        </React.Fragment>
      );
    });
  };

  return (
    <StyledLineupGrid rowLength={gridData[0].length + CELL_PADDING * 2}>
      {renderEmptyRow(gridData[0].length, CELL_PADDING)}
      {props.displayCorrectAnswer
        ? renderData(props.correctAnswers.correctAnswers)
        : renderData(gridData)}
      {renderEmptyRow(gridData[0].length, CELL_PADDING)}
    </StyledLineupGrid>
  );
};
