import styled from 'styled-components';

export const StyledFlasherContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 400px;
  max-width: 400px;
  max-height: 100%;
`;
