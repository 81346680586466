import styled, { css } from "styled-components";
import { deviceSize } from "../../../styles/theme";
import { Themes } from "../../../views/profile/api/types";
import { MIDDLE_SCHOOL, PRIMARY_SCHOOL } from "../../constants";

interface ImageOptionsProps {
  scale?: number;
  offsetX?: number;
  offsetY?: number;
  zIndex?: number;
  isMirrored?: boolean;
}

export interface LayoutOptionsProps {
  showMarkers?: boolean;
  theme?: Themes;
}

export const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: #333333;
  position: relative;
  overflow: hidden;
`;

const middleSchoolLayout = css`
  flex-direction: column;
  justify-content: flex-end;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledIntroductionViewLayout = styled.div<LayoutOptionsProps>`
  display: flex;
  flex: 1;
  max-height: 100%;
  position: absolute;
  width: auto;
  height: 100vh;
  overflow: hidden;
  ${(props) => props.theme === MIDDLE_SCHOOL && middleSchoolLayout}
`;

export const StyledStoryMarkers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 1;
`;

export const StyledPlaceablesArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledIntroductionViewRightColumn = styled.div<LayoutOptionsProps>`
  ${(props) => props.theme === PRIMARY_SCHOOL && "flex-grow: 1;"}
  ${(props) => props.theme === MIDDLE_SCHOOL && "width: 100%;"}
  ${(props) =>
    props.theme === MIDDLE_SCHOOL && "justify-content: space-around;"}
  ${(props) => props.theme === PRIMARY_SCHOOL && "max-width: 50%;"}
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: relative;
  z-index: 21;

  @media (min-width: ${deviceSize.desktopL}px) {
    ${(props) => props.theme === MIDDLE_SCHOOL && "width: 100%;"}
    ${(props) =>
      props.theme === MIDDLE_SCHOOL && "justify-content: space-around;"}
    ${(props) => props.theme === PRIMARY_SCHOOL && "max-width: 50%;"}
  }
`;

export const StyledSliderBoxWrapper = styled.div<LayoutOptionsProps>`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  ${(props) => props.theme === PRIMARY_SCHOOL && "max-width: 600px;"}
  ${(props) => !props.showMarkers && "padding-right: 20px;"}
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${deviceSize.desktopL}px) {
    ${(props) => props.theme === PRIMARY_SCHOOL && "max-width: 900px;"}
  }
`;

export const StyledProgressWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 28px;
  z-index: 20;
`;

export const StyledProgressBarContainer = styled.div`
  flex: 1;
  max-width: 512px;
`;

export const PlaceableImageWrapper = styled.div<ImageOptionsProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform-origin: top left;
  z-index: ${(props) => props.zIndex};
  width: calc(${(props) => props.scale}*420*100vh / 1080);
  transform: translateX(calc(${(props) => props.offsetX}*100vh / 1080))
    translateY(calc(${(props) => props.offsetY}*100vh / 1080));
`;

export const PlaceableImage = styled.img`
  width: 100%;
  height: auto;
  user-select: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  ${(props: ImageOptionsProps) =>
    props.isMirrored ? "transform:  scaleX(-1)" : ""};
`;
