import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../store";
import { Profile } from "./api/types";
import { StyledProfileWrapper, StyledProfileCard } from "./StyledProfile";
import { NavBar } from "../../shared/components/NavBar";
import { PrimaryButton } from "../../shared/components/Button";
import { resetProgress } from "../../store/progress/actions";
interface ProfileViewProps {
  profile: Profile;
  onResetProgress(): void;
}

const ProfileViewComponent: React.FC<ProfileViewProps> = (props) => (
  <StyledProfileWrapper backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg">
    <NavBar />
    <StyledProfileCard>
      <h1>Profil</h1>
      <dl>
        <dt>Namn:</dt>
        <dd>{`${props.profile.first_name} ${props.profile.last_name}`}</dd>
        <dt>E-post:</dt>
        <dd>{props.profile.email}</dd>
      </dl>
      {process.env.REACT_APP_ENV !== "production" && (
        <PrimaryButton label="Reset" onClick={props.onResetProgress} />
      )}
      <PrimaryButton label="Logga ut" onClick={onLogout} />
    </StyledProfileCard>
  </StyledProfileWrapper>
);

const onLogout = () => {
  const logoutUrl =
    process.env.REACT_APP_LOGOUT_URL ||
    "https://loggain.test.studentlitteratur.se/logout.php";
  const redirectUrl = window.location.href;
  window.location.href = `${logoutUrl}?redirect_url=${redirectUrl}`;
};

const mapStateToProps = (state: RootState) => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onResetProgress: () => dispatch(resetProgress()),
});

export const ProfileView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileViewComponent);
