import { createApiActions, ApiActions } from "../../../shared/store/actions";
import {
  ADVENTURE_GROUPS,
  GET_ADVENTURES_REQUEST,
  GET_ADVENTURE_CARD_REQUEST,
  START_ADVENTURE_PROBLEM_REQUEST,
  GET_ALL_ADVENTURES_REQUEST,
  SET_ACTIVE_ADVENTURE,
  COMPLETE_PROBLEM,
  RESET_PROBLEM,
} from "../../../shared/constants";
import { AdventuresIDs } from "./types";

export const ADVENTURE_GROUPS_API_ACTIONS: ApiActions = createApiActions(
  ADVENTURE_GROUPS
);

export const getAdventures = (adventureGroupId: number) => ({
  type: GET_ADVENTURES_REQUEST,
  payload: { adventureGroupId },
});

export const getAllAdventures = () => ({
  type: GET_ALL_ADVENTURES_REQUEST,
});

export const getAdventureCard = (
  adventureGroupId: number,
  adventureId: number
) => ({
  type: GET_ADVENTURE_CARD_REQUEST,
  payload: { adventureGroupId, adventureId },
});

export const startAdventureCard = (ids: AdventuresIDs, nextRoute: string) => ({
  type: START_ADVENTURE_PROBLEM_REQUEST,
  payload: {
    adventureGroupId: ids.adventureGroupId,
    adventureId: ids.adventureId,
    adventureCardId: ids.adventureCardId,
    nextRoute,
  },
});

//for test dashboard
export const setActiveAdventure = (
  adventureId: number,
  adventureGroupId: number
) => ({
  type: SET_ACTIVE_ADVENTURE,
  payload: {
    adventureId,
    adventureGroupId,
  },
});

export const completeProblem = (
  adventureGroupId: number,
  adventureId: number,
  activeProblemId: number
) => ({
  type: COMPLETE_PROBLEM,
  payload: {
    adventureGroupId,
    adventureId,
    activeProblemId,
  },
});

export const resetProblem = (
  adventureGroupId: number,
  adventureId: number,
  activeProblemId: number
) => ({
  type: RESET_PROBLEM,
  payload: {
    adventureGroupId,
    adventureId,
    activeProblemId,
  },
});
