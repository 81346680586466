import { format } from "date-fns";
import SvLocale from "date-fns/locale/sv";

export const getTimeAndDateString = (date: string) => {
  return `${format(new Date(date), "iiii", {
    locale: SvLocale
  })}en den ${format(new Date(date), "d MMMM", {
    locale: SvLocale
  })}`;
};
