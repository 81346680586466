import { EXERCISE_API_ACTIONS } from "./actions";

import {
  ADD_SLIDE_INDEX_TYPES,
  CHANGE_EXERCISE_VIEW_SLIDE,
  CREATE_DECK,
  DECK_FINISHED,
  HANDLE_NEW_EXERCISE_DATA,
  NEXT_CARD,
  NEXT_DECK_STATUS,
  NOT_READY,
  UPDATE_CARD,
  UPDATE_SLIDE_STATE,
  SET_ANSWER_ID,
  FIRST,
  EXERCISE_FINISHED,
  EXERCISE_SUBMIT_ANSWER_SUCCESS,
  EXERCISE_SUBMIT_ANSWER_FAILURE,
  EXERCISE_SUBMIT_ANSWER_CALL,
  SET_OBTAINED_ITEM_ID,
  TOGGLE_HELP,
  EMPTY,
} from "../../../shared/constants";
import {
  getNextSetOfCards
} from "./helperFunctions";
import {viewSlideStates} from "../Exercise";

interface AnimationState {
  animationStates: boolean;
}

interface DeckObjFace1 {
  fetching: any;
  exercises: any;
  error: any;
  decks: any;
  slideIndex: any;
  slideIndexTypes: any[];
  animationStates: AnimationState[];
  answerId: number;
  goal: any;
  isRepetition: boolean;
  submittingAnswer: boolean;
  obtainedItemId: number;
  obtainedItemChallengeId: number;
  isHelpShowing: boolean;
}

const initialState: DeckObjFace1 = {
  fetching: false,
  exercises: [],
  error: null,
  decks: [],
  slideIndex: 0,
  slideIndexTypes: [],
  animationStates: [],
  answerId: 0,
  goal: null,
  isRepetition: false,
  submittingAnswer: false,
  obtainedItemId: 0,
  obtainedItemChallengeId: 0,
  isHelpShowing: false
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {

    // READ ACTIONS
    case EXERCISE_API_ACTIONS.READ.REQUEST:
      if (action.callType === FIRST) {
        // If initial call, reset state
        return { ...initialState, fetching: true };
      }
      return { ...state, fetching: true, error: null };

    case ADD_SLIDE_INDEX_TYPES:
      const newViewSlideStates = [...action.slideIndexTypes];

      if (state.slideIndexTypes.length < 1) {
        newViewSlideStates[0].slideState = viewSlideStates.IN_VIEW;
      }

      return {...state, slideIndexTypes: [...state.slideIndexTypes, ...newViewSlideStates] };

    case HANDLE_NEW_EXERCISE_DATA:

      const exercises = [
        ...state.exercises,
        ...action.parts
      ]
      const numExercisesToDiscard = exercises.length > 5 ? exercises.length - 5 : 0;
      const empty = EMPTY.toLowerCase();

      return {
        ...state,
        fetching: false,
        error: null,
        slideIndexTypes: state.slideIndexTypes.map( (t, i) => i < numExercisesToDiscard ? { type: EMPTY, slideState: t.slideState } : t),
        exercises: exercises.map( (e, i) => i < numExercisesToDiscard ? { type: empty } : e),
        goal: action.goal,
        isRepetition: action.isRepetition,
      };


    case EXERCISE_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    // UPDATE ACTIONS
    case EXERCISE_API_ACTIONS.UPDATE.REQUEST:
      return { ...state, fetching: true, error: null };


    case EXERCISE_API_ACTIONS.UPDATE.SUCCESS:
      return { ...state, fetching: false, error: null, exercises: action.exercises};


    case EXERCISE_API_ACTIONS.UPDATE.FAILURE:
      return { ...state, fetching: false, error: action.error };


    case CHANGE_EXERCISE_VIEW_SLIDE:
      const newSlideIndexes = [...state.slideIndexTypes];

      // If there is no new slide
      if (newSlideIndexes.length <= state.slideIndex + 1) {
        return state;
      }

      newSlideIndexes[state.slideIndex + 1].slideState = viewSlideStates.ENTERING;
      newSlideIndexes[state.slideIndex].slideState = viewSlideStates.LEAVING;

      return {
        ...state,
        slideIndex: state.slideIndex+1,
        nextDeckStatus: NOT_READY,
        slideIndexTypes: newSlideIndexes
      };


    case NEXT_DECK_STATUS:
      return { ...state, nextDeckStatus: action.status };


    case UPDATE_SLIDE_STATE:
      const slideIndexTypes = [ ...state.slideIndexTypes ];
      slideIndexTypes[action.index].slideState = action.state;
      if (action.index > 0 && action.index < slideIndexTypes.length) {
        slideIndexTypes[action.index-1].slideState = viewSlideStates.OUT_OF_VIEW;
      }

      return {
        ...state,
        slideIndexTypes
      };


    case CREATE_DECK:
      return {
        ...state,
        decks: {
          ...state.decks,
          [action.newDeck.id]: action.newDeck
        }
      };


    case NEXT_CARD:
      if (state.decks[action.deckID].deckFinished) {
        return state;
      } else {
        const {activeCard, amountOfCards, cards: currentCards} = state.decks[action.deckID];
        const cards = getNextSetOfCards(amountOfCards, currentCards, activeCard);

        return {
          ...state,
          exerciseFinished: false,
          decks: { // CARD DECK
            ...state.decks, // SPREAD OF ALL DECKS
            [action.deckID]: { // TARGET ACTIVE DECK
              ...state.decks[action.deckID], // SPREAD ACTIVE DECKS VALUES THAT ARE NOT TO BE UPDATED
              activeCard: state.decks[action.deckID].activeCard - 1, // SET NEXT CARD AS ACTIVE ONE
              cards
            }
          }
        }
      }

    case DECK_FINISHED:
      return {
        ...state,
        decks: {
          ...state.decks,
          [action.deckID]: {
            ...state.decks[action.deckID],
            deckFinished: true,
          }
        }
      };

    case UPDATE_CARD:
      return {
        ...state,
        decks: { // CARD DECKS
          ...state.decks, // SPRED OF ALL DECKS
          [action.deckID]: { // TARGET ACTIVE DECK
            ...state.decks[action.deckID], // SPREAD ACTIVE DECKS VALUES THAT ARE NOT TO BE UPDATED
            cardsStates: {
              ...state.decks[action.deckID].cardsStates,
              [action.cardData.id]: action.cardData
            },
          }
        }
      };

    case SET_ANSWER_ID:
      return {
        ...state,
        answerId: action.answerId,
        exerciseFinished: false
      };

    case EXERCISE_FINISHED:
      return {
        ...state,
        exerciseFinished: true
      };   
 
    case EXERCISE_SUBMIT_ANSWER_CALL: 
      return {
        ...state,
        submitFailure: false,
        submittingAnswer: true
      };

    case EXERCISE_SUBMIT_ANSWER_SUCCESS: 
      return {
        ...state,
        submitFailure: false,
        submittingAnswer: false
      };
    case EXERCISE_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        submitFailure: true,
        submittingAnswer: false
      };

    case SET_OBTAINED_ITEM_ID:
      return {
        ...state,
        obtainedItemId: action.itemId,
        obtainedItemChallengeId: action.challengeId
      }

    case TOGGLE_HELP:
      return {
        ...state,
        isHelpShowing: action.isShowing,
      }

    default:
      return state;
  }
}
