import * as React from "react";
import { StyledMenuItem } from "./StyledMenuItem";

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
}

const MenuItem: React.FC<Props> = (props) => {
  return (
    <li>
      <StyledMenuItem onClick={props.onClick}>{props.children}</StyledMenuItem>
    </li>
  );
};

export { MenuItem };
