import styled from "styled-components";
import { DropDownStyles } from "../DropDown";

interface Props {
  overrideStyles?: DropDownStyles;
}

export const StyledItem = styled.li<Props>`
  width: 100%;
  height: 56px;
  justify-content: flex-start;
  flex: 1;
  color: inherit;
  padding: 0 16px;
  font-weight: normal;
  background-color: #F9F6F6;
  color: ${props => props.theme.themePrimary};
  font-size: ${ ({ overrideStyles }) => overrideStyles ? overrideStyles.fontSize : '26px'};
  font-family: inherit;
  letter-spacing: 0.31px;
  line-height: 34px;
  font-weight: 300;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 100ms ease;
  z-index: 5;
  
  &:hover, &:focus {
    outline: none;
    background-color: ${({ overrideStyles, theme }) => overrideStyles ? overrideStyles.backgroundColor : theme.themeLight};
  }
  
  &:last-child {
    border-bottom: ${ ({ overrideStyles, theme }) => overrideStyles ? overrideStyles.borderBottom : `solid 6px ${theme.themePrimary}` };
  }
`;