import styled, { css } from 'styled-components';
import { handleShake } from './HandleKeyFrames';

interface HandleProps {
  handleHasMoved: boolean,
  percent: number,
}

interface HandleGraphicProps {
  exeState?: string,
  displayCorrectAnswer?: boolean
}

const getBackgroundColor = (state='DEFAULT'): string => {
  switch(state) {
    case 'CORRECT':
      return '#1FC84C';
    
    case 'TRY_AGAIN':
      return '#FDAF2D';

    case 'INCORRECT':
      return '#EC4C3F';
  }
  return '#287574';
}

const AnimateHandle = css`
    animation: ${handleShake} 7.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    animation-delay: 6s;
`;

export const StyledHandle = styled.div`
    position: absolute;
    margin-left: -25px;
    margin-top: 20px;
    z-index: 2;
    cursor: pointer;
    ${(props: HandleProps) => ((!props.handleHasMoved) 
      ? AnimateHandle 
      : null)};
    left: ${({ percent }: HandleProps) => percent}%;
    &:hover,:active,focus {
      animation: none;
    }
`;

export const StyledHandleGraphic = styled.div`
  height: 50px;	
  width: 50px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  pointer-events: none;
  background-color: ${({ exeState, displayCorrectAnswer }: HandleGraphicProps) => displayCorrectAnswer ? getBackgroundColor('CORRECT') : getBackgroundColor(exeState)};
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  svg {
    width: 100%;
    height: auto;
  }
`;