import * as React from "react";
import { StyledIconContainer } from "./StyledFraction";
import { ReactComponent as Cross } from "../../../../assets/icons/icon_x.svg";
import { FractionCell } from "./components/FractionCell";
import { IFractionCell } from "./types";

export interface Fraction {
  whole: FractionPart;
  numerator: FractionPart;
  denominator: FractionPart;
  percent: FractionPart;
  decimal: FractionPart;
  position: number;
  type: string;
}

interface FractionPart {
  value: string;
  cellId: string;
}

const FRACTION = "FRACTION";
const WHOLE = "WHOLE";
const NUMERATOR = "NUMERATOR";
const DENOMINATOR = "DENOMINATOR";
const PERCENT = "PERCENT";
const DECIMAL = "DECIMAL";

const FILLER_CELL: IFractionCell = {
  id: "",
  hidden: false,
  value: "",
  valueLength: 0,
  interactive: false,
  borderBottom: false,
  leftmostCell: false,
  isFillerColumn: true,
};

/**
 * Decides what color the border should have depending on current state
 * @param state
 */
export const getBorderColor = (state: string) => {
  switch (state) {
    case "CORRECT":
      return "2px solid #1FC84C";

    case "INCORRECT":
      return "2px solid #EC4C3F";

    case "DEFAULT":
      return "2px solid #b8cece";
  }
};

export const getCorrectedState = (
  cell: IFractionCell,
  correctCellsList: string[],
  currentAttempt: number,
  exerciseState: string
) => {
  if (exerciseState === "CORRECT") {
    return { value: "CORRECT" };
  } else if (currentAttempt === 2) {
    return correctCellsList.includes(cell.id)
      ? { value: "CORRECT" }
      : { value: "INCORRECT" };
  }
  return { value: "DEFAULT" };
};

export const getCorrectedStateList = (
  answerMap: Record<number, Fraction>,
  correctMap: Record<number, Fraction>,
  correctQuota: string,
  setting: string,
  calculateType: string,
  numberOfDecimals: number
) => {
  const correctCells: string[] = [];
  const keys = Object.keys(answerMap);

  switch (setting) {
    case "single":
      // Compare the individual elements of the maps and append their cell id to the list of correct cells if matching.
      keys.forEach((key) => {
        const answerFraction = answerMap[+key];
        const correctFraction = correctMap[+key];

        if (correctFraction) {
          if (correctFraction.whole.value === answerFraction.whole.value) {
            correctCells.push(answerFraction.whole.cellId);
          }

          if (
            correctFraction.numerator.value === answerFraction.numerator.value
          ) {
            correctCells.push(answerFraction.numerator.cellId);
          }

          if (
            correctFraction.denominator.value ===
            answerFraction.denominator.value
          ) {
            correctCells.push(answerFraction.denominator.cellId);
          }

          if (correctFraction.percent.value === answerFraction.percent.value) {
            correctCells.push(answerFraction.percent.cellId);
          }

          if (correctFraction.decimal.value === answerFraction.decimal.value) {
            correctCells.push(answerFraction.decimal.cellId);
          }
        }
      });
      return correctCells;

    case "multiple":
      let total: number = 0;
      let position: number = 0;
      let tempList: string[] = [];
      let type: string = "";

      keys.forEach((key) => {
        switch (answerMap[+key].type) {
          case FRACTION:
            const fractionPos = answerMap[+key].position;
            const whole = answerMap[+key].whole;
            const numerator = answerMap[+key].numerator;
            const denominator = answerMap[+key].denominator;
            type = FRACTION;

            if (fractionPos !== position) {
              position++;

              if (+total.toFixed(6) === +correctQuota) {
                correctCells.push.apply(correctCells, tempList);
              }

              total = 0;
              tempList = [];
            }

            if (fractionPos === position) {
              const f =
                numerator.value !== "" && denominator.value !== ""
                  ? +numerator.value / +denominator.value
                  : 0;
              const w = whole.value !== "" ? +whole.value : 0;

              tempList.push(whole.cellId);
              tempList.push(numerator.cellId);
              tempList.push(denominator.cellId);

              if (
                calculateType === "plus" ||
                (calculateType === "minus" && total === 0)
              ) {
                total += w + f;
              } else {
                total -= w + f;
              }
            }

            break;
          case PERCENT:
            const percentPos = answerMap[+key].position;
            const percent = answerMap[+key].percent;
            type = PERCENT;

            if (percentPos !== position) {
              position++;

              if (+total.toFixed(6) === +correctQuota) {
                correctCells.push.apply(correctCells, tempList);
              }

              total = 0;
              tempList = [];
            }

            if (percentPos === position) {
              const p = +percent.value / 100;

              tempList.push(percent.cellId);

              if (
                calculateType === "plus" ||
                (calculateType === "minus" && total === 0)
              ) {
                total += p;
              } else {
                total -= p;
              }
            }
            break;
          case DECIMAL:
            const decimalPos = answerMap[+key].position;
            const decimal = answerMap[+key].decimal;
            type = DECIMAL;

            if (decimalPos !== position) {
              position++;

              if (+total.toFixed(numberOfDecimals) === +correctQuota) {
                correctCells.push.apply(correctCells, tempList);
              }

              total = 0;
              tempList = [];
            }

            if (decimalPos === position) {
              const d = +decimal.value;

              tempList.push(decimal.cellId);

              if (
                calculateType === "plus" ||
                (calculateType === "minus" && total === 0)
              ) {
                total += d;
              } else {
                total -= d;
              }
            }

            break;
          default:
        }
      });

      numberOfDecimals =
        type === FRACTION || type === PERCENT ? 6 : numberOfDecimals;

      // Add finalpart
      if (+total.toFixed(numberOfDecimals) === +correctQuota) {
        correctCells.push.apply(correctCells, tempList);
      }
      return correctCells;

    default:
      return correctCells;
  }
};

export const extractRowIndex = (cellId: string) => {
  return cellId
    ? cellId.substring(cellId.lastIndexOf("[") + 1, cellId.lastIndexOf(","))
    : "";
};

export const extractCellIndex = (cellId: string) => {
  return cellId
    ? cellId.substring(cellId.lastIndexOf(",") + 1, cellId.lastIndexOf("]"))
    : "";
};

/**
 * Renders an empty row
 * @param gridLength
 * @param cellPadding
 */
export const renderEmptyRow = (gridLength: number, cellPadding: number) => {
  const cells = [];

  for (let i = 0; i < gridLength + cellPadding * 2; i++) {
    cells.push(<FractionCell borderBottom={false} key={"paddingRow" + i} />);
  }
  return <>{cells}</>;
};

const findFractionPart = (cell: IFractionCell, rowIndex: number) => {
  if (rowIndex === 1 && cell.borderBottom) {
    return NUMERATOR;
  }

  if (rowIndex === 2 && cell.isInteger) {
    return WHOLE;
  }

  if (rowIndex === 2 && cell.isPercent) {
    return PERCENT;
  }

  if (rowIndex === 2 && cell.isDecimal) {
    return DECIMAL;
  }

  if (rowIndex === 2 && !isNaN(+cell.value)) {
    return DENOMINATOR;
  }
};

const findCellValueLength = (
  cell: IFractionCell,
  grid: IFractionCell[][],
  rowIndex: number
) => {
  if (
    (!cell.interactive && isNaN(+cell.value)) ||
    (!cell.interactive && cell.value === "")
  ) {
    return 0;
  }

  return cell.interactive ? 1 : findValueLength(cell, grid[rowIndex]);
};

export const findHorizontalOffset = (
  cell: IFractionCell,
  grid: IFractionCell[][]
) => {
  const length = cell.valueLength;
  const cellIndex = +extractCellIndex(cell.id);
  const rowIndex = +extractRowIndex(cell.id);

  if (!cell.interactive && cell.value === "") {
    return undefined;
  }

  switch (rowIndex) {
    case 1:
      return findNumeratorOffset(grid, cellIndex, length);
    case 2:
      return findDenominatorOffset(grid, cell, cellIndex, length);
    default:
      return undefined;
  }
};

const findDenominatorOffset = (
  grid: IFractionCell[][],
  cell: IFractionCell,
  cellIndex: number,
  length: number
) => {
  if (cell.isInteger || isNaN(+cell.value)) {
    return undefined;
  }

  const row = grid[1];
  for (let i = cellIndex; i < row.length; i++) {
    if (
      (!isNaN(+row[i].value) && row[i].value !== "" && row[i].borderBottom) ||
      row[i].interactive
    ) {
      const vl = row[i].valueLength;
      const diff = length > vl ? 0 : vl - length;

      return diff;
    }
    if (isNaN(+row[i].value) || !row[i].borderBottom) {
      break;
    }
  }

  for (let j = cellIndex; j >= 0; j--) {
    if (
      (!isNaN(+row[j].value) && row[j].value !== "" && row[j].borderBottom) ||
      row[j].interactive
    ) {
      const vl = row[j].valueLength;
      const diff = length > vl ? 0 : vl - length;

      return diff;
    }
  }
};

const findNumeratorOffset = (
  grid: IFractionCell[][],
  cellIndex: number,
  length: number
) => {
  const row = grid[2];

  for (let i = cellIndex; i < row.length; i++) {
    if (isNaN(+row[i].value)) {
      break;
    }
    if ((!isNaN(+row[i].value) && row[i].value !== "") || row[i].interactive) {
      const vl = row[i].valueLength;
      const diff = length > vl ? 0 : vl - length;

      return diff;
    }
  }

  for (let j = cellIndex; j >= 0; j--) {
    if ((!isNaN(+row[j].value) && row[j].value !== "") || row[j].interactive) {
      const vl = row[j].valueLength;
      const diff = length > vl ? 0 : vl - length;

      return diff;
    }
  }
};

const findValueLength = (cell: IFractionCell, row: IFractionCell[]) => {
  const i = +extractCellIndex(cell.id);
  let valCounter: number = 1;

  // Find values to the right
  for (let j = i + 1; j < row.length; j++) {
    if (
      isNaN(+row[j].value) ||
      row[j].value === "" ||
      (row[j].isInteger && !cell.isInteger) ||
      (!row[j].isInteger && cell.isInteger)
    ) {
      break;
    }
    valCounter++;
  }

  // Find values to the left
  for (let k = i - 1; k >= 0; k--) {
    if (
      isNaN(+row[k].value) ||
      row[k].value === "" ||
      (row[k].isInteger && !cell.isInteger) ||
      (!row[k].isInteger && cell.isInteger)
    ) {
      break;
    }
    valCounter++;
  }

  return valCounter;
};

const mapLeftmostInteractiveCellsInRow = (row: IFractionCell[]) => {
  const cells: IFractionCell[] = [];
  row.forEach((c, i) => {
    if (
      (c.interactive && i === 0) ||
      (c.interactive && c.fractionPart !== row[i - 1].fractionPart)
    ) {
      cells.push(c);
    }
  });
  return cells;
};

export const mapFractionsInGrid = (grid: IFractionCell[][]) => {
  const fractionMap: Record<number, Fraction> = {};
  const signCells = mapSignCellsIndexes(grid[2]);

  mapWholes(grid[2], signCells, fractionMap);
  mapNumerators(grid[1], signCells, fractionMap);
  mapDenominators(grid[2], signCells, fractionMap);
  mapDecimals(grid[2], signCells, fractionMap);
  mapPercentages(grid[2], signCells, fractionMap);

  return fractionMap;
};

const mapWholes = (
  row: IFractionCell[],
  signCells: number[],
  map: Record<number, Fraction>
) => {
  let value: string = "";
  let index: number = 0;
  let cellId: string = "";
  let position: number = 0;
  row.forEach((cell, cellIndex) => {
    // Breakpoint
    if (signCells.includes(cellIndex)) {
      const fractionPart: FractionPart = {
        value,
        cellId,
      };

      const fraction: Fraction = {
        whole: fractionPart,
        numerator: { value: "", cellId: "" },
        denominator: { value: "", cellId: "" },
        percent: { value: "", cellId: "" },
        decimal: { value: "", cellId: "" },
        type: FRACTION,
        position,
      };

      map[index] = fraction;

      value = "";
      cellId = "";
      index++;

      if (row[cellIndex].value === "=") {
        position++;
      }
    }

    if (cell.isInteger && !cell.isDecimal && !cell.isPercent) {
      value = value.concat(cell.value);
      cellId = cellId === "" || cellIndex === row.length - 1 ? cell.id : cellId;
    }
  });

  // Add final part
  const fp: FractionPart = {
    value,
    cellId,
  };

  const f: Fraction = {
    whole: fp,
    numerator: { value: "", cellId: "" },
    denominator: { value: "", cellId: "" },
    percent: { value: "", cellId: "" },
    decimal: { value: "", cellId: "" },
    type: FRACTION,
    position,
  };

  map[index] = f;
};

const mapNumerators = (
  row: IFractionCell[],
  signCells: number[],
  map: Record<number, Fraction>
) => {
  let value: string = "";
  let index: number = 0;
  let cellId: string = "";
  row.forEach((cell, cellIndex) => {
    // Breakpoint
    if (signCells.includes(cellIndex)) {
      const fractionPart: FractionPart = {
        value,
        cellId,
      };

      const fraction = map[index];
      fraction.numerator = fractionPart;
      map[index] = fraction;

      value = "";
      cellId = "";
      index++;
    }

    if (cell.borderBottom && !cell.isFillerColumn) {
      value = value.concat(cell.value);
      cellId = cellId === "" || cellIndex === row.length - 1 ? cell.id : cellId;
    }
  });

  // Add final part
  const fp: FractionPart = {
    value,
    cellId,
  };

  const f = map[index];
  f.numerator = fp;
  map[index] = f;
};

const mapDenominators = (
  row: IFractionCell[],
  signCells: number[],
  map: Record<number, Fraction>
) => {
  let value: string = "";
  let index: number = 0;
  let cellId: string = "";
  row.forEach((cell, cellIndex) => {
    // Breakpoint
    if (signCells.includes(cellIndex)) {
      const fractionPart: FractionPart = {
        value,
        cellId,
      };

      const fraction = map[index];
      fraction.denominator = fractionPart;
      map[index] = fraction;

      value = "";
      cellId = "";
      index++;
    }

    if (!cell.isInteger && !isNaN(+cell.value) && !cell.isFillerColumn) {
      value = value.concat(cell.value);
      cellId = cellId === "" || cellIndex === row.length - 1 ? cell.id : cellId;
    }
  });

  // Add final part
  const fp: FractionPart = {
    value,
    cellId,
  };

  const f = map[index];
  f.denominator = fp;
  map[index] = f;
};

const mapDecimals = (
  row: IFractionCell[],
  signCells: number[],
  map: Record<number, Fraction>
) => {
  let value: string = "";
  let index: number = 0;
  let cellId: string = "";
  let setType: boolean = false;

  row.forEach((cell, cellIndex) => {
    // Breakpoint
    if (signCells.includes(cellIndex)) {
      const fractionPart: FractionPart = {
        value,
        cellId,
      };

      const fraction = map[index];
      fraction.decimal = fractionPart;
      map[index] = fraction;

      fraction.type = setType ? DECIMAL : fraction.type;

      value = "";
      cellId = "";
      setType = false;
      index++;
    }

    if (cell.isDecimal) {
      value = value.concat(cell.value);
      cellId = cellId === "" || cellIndex === row.length - 1 ? cell.id : cellId;
      setType = true;
    }
  });

  // Add final part
  const fp: FractionPart = {
    value,
    cellId,
  };

  const f = map[index];
  f.decimal = fp;
  f.type = setType ? DECIMAL : f.type;
  map[index] = f;
};

const mapPercentages = (
  row: IFractionCell[],
  signCells: number[],
  map: Record<number, Fraction>
) => {
  let value: string = "";
  let index: number = 0;
  let cellId: string = "";
  let setType: boolean = false;
  row.forEach((cell, cellIndex) => {
    // Breakpoint
    if (signCells.includes(cellIndex)) {
      const fractionPart: FractionPart = {
        value,
        cellId,
      };

      const fraction = map[index];
      fraction.percent = fractionPart;
      map[index] = fraction;

      fraction.type = setType ? PERCENT : fraction.type;

      value = "";
      cellId = "";
      setType = false;
      index++;
    }

    if (cell.isPercent) {
      value = value.concat(cell.value);
      cellId = cellId === "" || cellIndex === row.length - 1 ? cell.id : cellId;
      setType = true;
    }
  });

  // Add final part
  const fp: FractionPart = {
    value,
    cellId,
  };

  const f = map[index];
  f.percent = fp;
  f.type = setType ? PERCENT : f.type;
  map[index] = f;
};

const moveInteractiveCellsToLeftmostPosition = (
  row: IFractionCell[],
  leftMostCells: IFractionCell[],
  signCellIndexes: number[]
) => {
  leftMostCells.forEach((c) => {
    const cIndex = +extractCellIndex(c.id);
    const rIndex = +extractRowIndex(c.id);

    for (let i = cIndex - 1; i >= 0; i--) {
      if (
        row[i].interactive ||
        row[i].value !== "" ||
        row[i].isInteger ||
        signCellIndexes.includes(i)
      ) {
        break;
      }
      if (rIndex === 1 && !row[i].borderBottom) {
        break;
      }

      const cell = row[i];
      if (!cell.interactive || cell.value === "") {
        row[i] = c;
        row[i + 1] = Object.assign({}, cell);
      }
    }
  });
};

const moveCellsToLeftMostPosition = (
  row: IFractionCell[],
  checkRow: IFractionCell[],
  signCellIndexes: number[]
) => {
  row.forEach((cell, cellIndex) => {
    const rIndex = +extractRowIndex(cell.id);

    if (
      cell.interactive ||
      signCellIndexes.includes(cellIndex) ||
      !checkRow[cellIndex].borderBottom ||
      cell.value === ""
    ) {
      return;
    }

    for (let i = cellIndex - 1; i >= 0; i--) {
      const c = row[i];

      if (
        (row[i].borderBottom && row[i].value === "") ||
        (checkRow[i].borderBottom && row[i].value === "")
      ) {
        row[i] = cell;
        row[i + 1] = Object.assign({}, c);
      }

      if (rIndex === 1 ? !row[i].borderBottom : !checkRow[i].borderBottom) {
        break;
      }
    }
  });
};

const mapSignCellsIndexes = (row: IFractionCell[]) => {
  const signCellsIndexes: number[] = [];
  row.forEach((c, i) => {
    if (
      c.value !== "" &&
      isNaN(+c.value) &&
      c.value !== "." &&
      c.value !== "," &&
      c.value !== "%"
    ) {
      signCellsIndexes.push(i);
    }
  });

  return signCellsIndexes;
};

const removeRedundantColumns = (
  grid: IFractionCell[][],
  signCellsIndexes: number[]
) => {
  const numRow = grid[1];
  const denRow = grid[2];

  const numCol: number[] = [];
  numRow.forEach((c, i) => {
    if (
      !c.interactive &&
      c.value === "" &&
      !signCellsIndexes.includes(i) &&
      !c.isFillerColumn
    ) {
      numCol.push(i);
    }
  });

  const denCol: number[] = [];
  denRow.forEach((c, i) => {
    if (
      !c.interactive &&
      c.value === "" &&
      !signCellsIndexes.includes(i) &&
      !c.isFillerColumn
    ) {
      denCol.push(i);
    }
  });

  const removeCols = mergeArrayCommonElements(numCol, denCol);
  removeCols.reverse();

  removeCols.forEach((col) => {
    grid.forEach((row) => {
      row.splice(col, 1);
    });
  });
};

const mergeArrayCommonElements = (array1: number[], array2: number[]) => {
  const array3: number[] = [];
  array1.forEach((item) => {
    if (array2.indexOf(item) !== -1) {
      array3.push(item);
    }
  });

  return array3;
};

export const addGridColumn = (
  grid: IFractionCell[][],
  newColumnIndex: number
) => {
  grid.forEach((row) => {
    row.push(Object.assign({}, FILLER_CELL));
  });

  grid.forEach((row, rowIndex) => {
    shiftGridToRight(row, newColumnIndex, rowIndex);
  });

  grid.forEach((row, rowIndex) =>
    row.forEach((cell, cellIndex) => {
      cell.id = updateCellId(rowIndex, cellIndex);
    })
  );
};

export const removeGridColumn = (
  grid: IFractionCell[][],
  removeIndexes: number[]
) => {
  removeIndexes.forEach((i) => {
    grid.forEach((row) => {
      row.splice(i, 1);
    });
  });

  grid.forEach((row, rowIndex) =>
    row.forEach((cell, cellIndex) => {
      cell.id = updateCellId(rowIndex, cellIndex);
    })
  );
};

const shiftGridToRight = (
  row: IFractionCell[],
  newColumnIndex: number,
  rowIndex: number
) => {
  for (let i = row.length - 1; i >= newColumnIndex; i--) {
    const newCell = Object.assign({}, FILLER_CELL);
    newCell.borderBottom = rowIndex === 1 ? true : false;

    row[i] = i !== newColumnIndex ? row[i - 1] : newCell;
  }
};

const finalizeGridData = (
  grid: IFractionCell[][],
  numerators: IFractionCell[],
  denominators: IFractionCell[],
  signCells: number[],
  prepareInitialData: boolean
) => {
  // Move all leftmost interactive cells to the leftmost position of their fraction
  moveInteractiveCellsToLeftmostPosition(grid[2], denominators, signCells);
  moveInteractiveCellsToLeftmostPosition(grid[1], numerators, signCells);

  // Move all non interactive cells to their leftmost position
  moveCellsToLeftMostPosition(grid[1], grid[1], signCells);
  moveCellsToLeftMostPosition(grid[2], grid[1], signCells);

  // Remove redundant columns
  removeRedundantColumns(grid, signCells);

  // Update the ids of the entire grid
  grid.forEach((row, rowIndex) =>
    row.forEach((cell, cellIndex) => {
      cell.id = updateCellId(rowIndex, cellIndex);
    })
  );
};

export const updateCellId = (row: number, cellIndex: number) => {
  return "[" + row + ", " + cellIndex + "]";
};

// Preparing griddata used to show the correct answer!
export const prepareCorrectGridData = (grid: IFractionCell[][]) => {
  const data: IFractionCell[][] = grid;

  // First step of interactive cell preparation, find out what fraction part it belongs to.
  data.forEach((row, rowIndex) =>
    row.forEach((cell) => {
      if (cell.interactive) {
        cell.fractionPart = findFractionPart(cell, rowIndex);
      }
      cell.isHorizontal = 0;
    })
  );

  // Move entire value to the first interactive cell in a sequence!
  let value = "";
  let startIndex = 0;
  const keepCells: number[] = [];
  data.forEach((row) =>
    row.forEach((cell, cellIndex) => {
      const previousFractionPart =
        cellIndex === 0 ? undefined : row[cellIndex - 1].fractionPart;
      const nextFractionPart =
        cellIndex === row.length - 1
          ? undefined
          : row[cellIndex + 1].fractionPart;

      if (cell.interactive && cell.fractionPart !== previousFractionPart) {
        keepCells.push(cellIndex);
        startIndex = cellIndex;
        value = cell.value;
        cell.value = "";
      } else if (
        cell.interactive &&
        cell.fractionPart === previousFractionPart &&
        cell.fractionPart === nextFractionPart
      ) {
        keepCells.push(cellIndex);
        value = value.concat(cell.value);
        cell.value = "";
      }

      if (cell.interactive && cell.fractionPart !== nextFractionPart) {
        keepCells.push(cellIndex);
        value = value.concat(cell.value);
        cell.value = "";
        row[startIndex].value = value;
        row[startIndex].width = value.length;
        row[startIndex].valueLength = value.length === 0 ? 1 : value.length;
        value = "";
      }
    })
  );

  // Remap grid so that only one interactive cell exists for each sequence.
  const numeratorRowLeftmostCells = mapLeftmostInteractiveCellsInRow(data[1]);
  const denominatorRowLeftmostCells = mapLeftmostInteractiveCellsInRow(data[2]);
  const signCellsIndexes = mapSignCellsIndexes(data[2]);
  const leftmostCells = numeratorRowLeftmostCells.concat(
    ...denominatorRowLeftmostCells
  );

  // Remove redundant interactive cells and update leftmostCell.
  data.forEach((row) =>
    row.forEach((cell, cellIndex) => {
      if (cell.interactive && leftmostCells.includes(cell)) {
        cell.leftmostCell = true;
      } else {
        cell.interactive = false;
        cell.isFillerColumn = keepCells.includes(cellIndex) ? true : false;
      }
    })
  );

  finalizeGridData(
    data,
    numeratorRowLeftmostCells,
    denominatorRowLeftmostCells,
    signCellsIndexes,
    false
  );

  // Find initial length of the value represented by the cells, skip interactive cells when preparing correct griddata.
  data.forEach((row, rowIndex) =>
    row.forEach((cell) => {
      if (rowIndex === 1 || rowIndex === 2) {
        if (!cell.interactive) {
          cell.valueLength = findCellValueLength(cell, data, rowIndex);
        }
      }
    })
  );

  return data;
};

export const findFirstInteractiveCellId = (grid: IFractionCell[][]) => {
  for (let i = 0; i < grid[0].length; i++) {
    if (grid[1][i].interactive) {
      return grid[1][i].id;
    }

    if (grid[2][i].interactive) {
      return grid[2][i].id;
    }
  }

  return "";
};

/**
 * Prepare the griddata, both interactive and non-interactive cells are prepared.
 */
export const prepareGridData = (grid: IFractionCell[][]) => {
  const data: IFractionCell[][] = grid;

  // First step of interactive cell preparation, find out what fraction part it belongs to.
  data.forEach((row, rowIndex) =>
    row.forEach((cell) => {
      if (cell.interactive) {
        cell.fractionPart = findFractionPart(cell, rowIndex);
      }
      cell.isHorizontal = 0;
    })
  );

  // Remap grid so that only one interactive cell exists for each sequence.
  const numeratorRowLeftmostCells = mapLeftmostInteractiveCellsInRow(data[1]);
  const denominatorRowLeftmostCells = mapLeftmostInteractiveCellsInRow(data[2]);
  const signCellsIndexes = mapSignCellsIndexes(data[2]);
  const leftmostCells = numeratorRowLeftmostCells.concat(
    ...denominatorRowLeftmostCells
  );

  // Remove redundant interactive cells and update leftmostCell.
  data.forEach((row) =>
    row.forEach((cell) => {
      if (cell.interactive && leftmostCells.includes(cell)) {
        cell.leftmostCell = true;
      } else {
        cell.interactive = false;
      }
    })
  );

  finalizeGridData(
    data,
    numeratorRowLeftmostCells,
    denominatorRowLeftmostCells,
    signCellsIndexes,
    true
  );

  // Find initial length of the value represented by the cells, interactive cells will start with 1.
  data.forEach((row, rowIndex) =>
    row.forEach((cell) => {
      if (rowIndex === 1 || rowIndex === 2) {
        cell.valueLength = findCellValueLength(cell, data, rowIndex);
      }
    })
  );

  return data;
};

export const renderCross = (
  correctedState: string | undefined,
  render: boolean,
  isInteger: boolean | undefined,
  width: number | undefined,
  isHorizontal: number | undefined
) =>
  correctedState === "INCORRECT" && render ? (
    <StyledIconContainer
      topPosition={true}
      isInteger={isInteger}
      correctedState={correctedState}
      isHorizontal={isHorizontal}
    >
      <Cross color="#EC4C3F" height="8px" width="8px" />
    </StyledIconContainer>
  ) : null;
