import { reducer } from "./api/reducer";
import { SagaService } from "../../services/saga";
import { ReducerService } from "../../services/reducer";
import {
  watchGetAnswersSaga,
  watchGetGoalsSaga,
  watchGetGoalProgressSaga,
  watchUpdateGoalProgressSaga,
  watchGetProblemIndependentMarkerHeadGroupSaga,
  watchGenerateMarkersSaga,
  watchDeleteMarkersSaga,
} from "./api/sagas";

export * from "./Test";

export const init = () => {
  SagaService.set(watchGetAnswersSaga);
  SagaService.set(watchGetGoalsSaga);
  SagaService.set(watchGetGoalProgressSaga);
  SagaService.set(watchUpdateGoalProgressSaga);
  SagaService.set(watchGetProblemIndependentMarkerHeadGroupSaga);
  SagaService.set(watchGenerateMarkersSaga);
  SagaService.set(watchDeleteMarkersSaga);
  ReducerService.set("test", reducer);
};
