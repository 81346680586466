import * as React from "react";
import {
  textSizes,
  StyledInput,
  MirrorSpan,
  StyledInputContainer,
  StyleldIconContainer,
} from "./StyledBlanks";
import { ReactComponent as WrongIcon } from "../../../../assets/icons/icon_x.svg";
import { ReactComponent as CorrectIcon } from "../../../../assets/icons/icon_correct.svg";
import {
  isIOSDevice,
  isMobileDevice,
  isTouchDevice,
} from "../../../../../../../shared/platform/Platform";
import { VirtualKeyboard } from "../../../VirtualKeyboard/VirtualKeyboard";
import {
  getKeyboardLayout,
  getKeyboardPosition,
  selectText,
  updateNumpadValue,
} from "../../../VirtualKeyboard/helpers";
import { KeyboardData } from "../../../VirtualKeyboard/types";
import { StyledCaret } from "../XcLineup/components/LineupInput/StyledLineupInput";

export interface BlankProps {
  children?: JSX.Element;
  id: string;
  correctAnswer?: string;
  displayCorrectAnswer: boolean;
  state: string;
  inputType: string;
  textSize: string;
  currentAttempt: number;
  keyboardData?: KeyboardData;
  focusInput: boolean;
  punctuationMarksSensitive: boolean;
  callback(id: string, answer: string): void;
}

export interface BlankState {
  answer: string;
  inputWidth: string;
  showNumpad: boolean;
  focused: boolean;
}

export class Blank extends React.PureComponent<BlankProps, BlankState> {
  public state: Readonly<BlankState> = {
    answer: "",
    inputWidth: textSizes[this.props.textSize].baseWidth,
    showNumpad: false,
    focused: false,
  };

  /**
   * Use hidden span tag to get correct width for dynamic text input element because spans resize automatically
   */
  private mirrorRef: React.RefObject<HTMLSpanElement>;
  private inputRef: React.RefObject<HTMLInputElement>;
  private touchDevice = isTouchDevice();
  private isMobile = isMobileDevice();

  constructor(props: BlankProps) {
    super(props);
    this.mirrorRef = React.createRef();
    this.inputRef = React.createRef();
  }

  public componentDidMount() {
    this.focusCurrentInput();
    window.addEventListener("resize", this.handleWindowResize);
  }

  public componentDidUpdate() {
    this.focusCurrentInput();
    this.resizeInput(12);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  public handleWindowResize = () => {
    if (this.inputRef && this.inputRef.current) {
      this.touchDevice && this.isMobile && this.inputRef.current.blur();
    }
    this.setState({ showNumpad: false });
  };

  /**
   * Get the margin used for positioning the input cursor
   * for when the virtual keyboard is used. Its based on the
   * width of the input field.
   */
  public getCursorMargin() {
    return this.mirrorRef.current
      ? Math.min(this.mirrorRef.current.clientWidth) / 2
      : null;
  }

  public render() {
    return (
      <>
        <StyledInputContainer textSize={this.props.textSize}>
          {this.state.showNumpad && (
            <StyledCaret margin={this.getCursorMargin()} />
          )}
          <StyledInput
            displayCorrectAnswer={this.props.displayCorrectAnswer}
            isCorrect={this.getIsAnswerCorrect()}
            type="text"
            value={this.getInputValue()}
            inputWidth={this.state.inputWidth}
            onChange={this.onAnswerChange}
            state={this.props.state}
            textSize={this.props.textSize}
            onMouseDown={selectText}
            onBlur={this.deselectText}
            onFocus={this.handleOnFocus}
            ref={this.inputRef}
            readOnly={this.touchDevice && this.isMobile} // Set to readonly to disable OS software keyboard
            aria-label="Skriv-i-lucka"
          />

          {this.renderIcon()}

          <MirrorSpan ref={this.mirrorRef} textSize={this.props.textSize}>
            {this.getInputValue()}
          </MirrorSpan>
        </StyledInputContainer>
        {this.touchDevice && this.state.showNumpad ? (
          <VirtualKeyboard
            buttonLayout={
              this.props.keyboardData
                ? getKeyboardLayout(this.props.keyboardData)
                : undefined
            }
            callback={this.numpadCallback}
            position={getKeyboardPosition()}
          />
        ) : null}
      </>
    );
  }

  private focusCurrentInput = () => {
    if (
      this.props.focusInput &&
      this.inputRef &&
      this.inputRef.current &&
      !this.state.focused
    ) {
      this.setState({ focused: true });
      this.inputRef.current.focus();
    }
  };

  private handleOnFocus = () => {
    this.touchDevice && this.isMobile && this.showNumpad();
  };

  private showNumpad = () => this.setState({ showNumpad: true });

  private numpadCallback = (value: string) => {
    const updatedValue = updateNumpadValue(
      this.inputRef,
      value,
      this.state.answer
    );
    if (updatedValue !== undefined) {
      this.updateAnswer(updatedValue);
    }
  };

  /**
   * Clears selected text when focus is lost
   * @param {FocusEvent} event
   */
  private deselectText = (event: React.FocusEvent) => {
    this.setState({ showNumpad: false });
    if (window) {
      const selectedElement = window.getSelection();
      selectedElement && selectedElement.empty();
    }
  };

  /**
   * Gets input type
   * @param {string} inputType
   */
  private getInputType = (inputType: string) => {
    // setSelectionRange no longer supports the type number so utilize the type text instead.
    if (isIOSDevice() && inputType === "number") {
      return "text";
    }

    if (inputType === "number" || inputType === "time") {
      return inputType;
    } else if (inputType === "alphaNumeric") {
      return "text";
    } else {
      return "number";
    }
  };

  /**
   * Renders icon
   */
  private renderIcon = () => {
    if (this.props.state === "CORRECT") {
      return this.getIcon(true);
    }
    if (this.props.state === "INCORRECT") {
      return this.getIcon(this.getIsAnswerCorrect());
    } else {
      return null;
    }
  };

  /**
   * Renders correct or incorrect icon
   * @param {boolean} correctAnswer
   * @returns {any}
   */
  private renderCorrectOrIncorrectIcon = (correctAnswer: boolean) =>
    correctAnswer || this.props.displayCorrectAnswer ? (
      <StyleldIconContainer color="#1FC84C">
        <CorrectIcon color="#1FC84C" />
      </StyleldIconContainer>
    ) : (
      <StyleldIconContainer color="#EC4C3F">
        <WrongIcon color="#EC4C3F" />
      </StyleldIconContainer>
    );

  /**
   * Returns the correct icon
   *
   * @param {boolean} correctAnswer
   * @returns {boolean}
   */
  private getIcon = (correctAnswer: boolean): JSX.Element => (
    <React.Fragment>
      {this.renderCorrectOrIncorrectIcon(correctAnswer)}
    </React.Fragment>
  );

  /**
   * Gets value for input field depending on answer toggle
   */
  private getInputValue = (): string | undefined =>
    this.props.displayCorrectAnswer && !this.getIsAnswerCorrect()
      ? this.props.correctAnswer
      : this.state.answer;

  /**
   * Check if answer is correct
   */
  private getIsAnswerCorrect = (): boolean => {
    if (this.props.punctuationMarksSensitive) {
      return this.state.answer === this.props.correctAnswer;
    }

    // Exchange all commas (,) for dots (.)
    const answer = this.state.answer.replace(",", ".");
    const correctAnswer =
      this.props.correctAnswer && this.props.correctAnswer.replace(",", ".");

    return answer === correctAnswer;
  };

  /**
   * Get width from hidden span tag
   *
   * @param {number} paddingOffset
   */
  private resizeInput = (paddingOffset: number) => {
    const spanWidth = this.mirrorRef.current
      ? this.mirrorRef.current.clientWidth + paddingOffset
      : 60 + paddingOffset;
    const width = spanWidth + "px";
    this.setState({ inputWidth: width });
  };

  /**
   * Updates the answer when user types in an input
   * If the currentAttempt is 2 then we block new changes to the blank.
   *
   * @param {any} answer
   */
  private onAnswerChange = ({
    currentTarget: { value: answer },
  }: React.FormEvent<HTMLInputElement>): void => {
    this.updateAnswer(answer);
  };

  private updateAnswer = (answer: string) => {
    if (this.props.currentAttempt !== 2 && this.props.state !== "CORRECT") {
      this.setState({ answer }, this.updateParent);
    }
  };

  /**
   * Call upon parent to set a new state for this Blank
   */
  private updateParent = (): void =>
    this.props.callback(this.props.id, this.state.answer);
}
