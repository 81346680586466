import styled from 'styled-components';
import {CardState} from "./Card";
interface HelpProps {
  isTablet?: boolean;
}

interface CardSideProps {
  showHelp?: boolean;
  isIOS?: boolean;
}

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  -webkit-perspective: 1000px;
	position: relative;
`;

export const StyledCardOptionsWrapper = styled.div`
  width: 40px;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30px;
  right: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCardOptionWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.3);
`;

export const StyledCardBody = styled.section`
  background-color: #FFFFFF;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.3);
  position: relative;
  width: 100%;
  height: 100%; 
  transition: 500ms;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
  ${({showHelp}: CardState) => showHelp ? 'transform: rotateY(179.999deg);' : '' }
`;

export const StyledCardFront = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  top: 0;
  top: 0;
  left: 0;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
  background-color: white;
  /*Transition on z-index to prevent bug in Edge*/
  ${({ showHelp }: CardSideProps) => showHelp ? 'z-index: 0;' : 'z-index: 2;' }
  transition-delay: 250ms;
  transition-property: z-index;
  ${({ isIOS, showHelp }: CardSideProps) => isIOS && showHelp ? 'display: none' : ''}
`;

export const StyledCardBack = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: white;
  ${({ showHelp }: CardSideProps) => showHelp ? 'z-index: 2;' : 'z-index: 0;' }
  transition-delay: 250ms;
  transition-property: z-index;
  ${({ isIOS, showHelp }: CardSideProps) => isIOS && !showHelp ? 'display: none' : ''}
`;

export const StyledCardBackBody = styled.div`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(0deg);
  display: flex;
  ${({ isTablet }: HelpProps): string => isTablet ? 'padding: 20px 80px;' : 'padding: 40px 70px;'};
  flex-direction: column;
`;

export const StyledCardCloseHelp = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 100%);
  height: 24px;
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.themePrimary};
  
  &:hover {
    color: ${props => props.theme.themeSecondary}
  };
`;

export const StyledCardBackBodyContent = styled.div`
  max-height: 100%;
  flex: 1;
`;

export const StyledTipContainer = styled.div`
  position: relative;
`;