import { PROFILE_API_ACTIONS, PROFILE_SET_THEME } from "./actions";
import { ProfileState } from "./types";

const initialState: ProfileState = {
  fetching: false,
  profile: {},
  error: null,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    // READ ACTIONS
    case PROFILE_API_ACTIONS.READ.REQUEST:
      return { ...state, fetching: true, error: null };
    case PROFILE_API_ACTIONS.READ.SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        profile: action.profile,
      };
    case PROFILE_API_ACTIONS.READ.FAILURE:
      return { ...state, fetching: false, error: action.error };

    case PROFILE_API_ACTIONS.UPDATE.REQUEST:
      return {
        ...state,
        fetching: true,
        profile: { ...state.profile, ...action.payload },
        error: null,
      };
    case PROFILE_API_ACTIONS.UPDATE.SUCCESS:
      return {
        ...state,
        fetching: false,
        profile: { ...state.profile, ...action.profile },
        error: null,
      };
    case PROFILE_API_ACTIONS.UPDATE.FAILURE:
      return { ...state, fetching: false, error: action.error };
    case PROFILE_SET_THEME:
      return {
        ...state,
        profile: { ...state.profile, ...{ theme: action.theme }}
      }
    default:
      return state;
  }
}
