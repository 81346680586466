// action types
import { createApiActions, ApiActions} from "../../../shared/store/actions";
import { AUTH_TOKEN } from '../../../shared/constants';

export const AUTH_TOKEN_API_ACTIONS: ApiActions = createApiActions(AUTH_TOKEN);

export const AUTH_READY = "AUTH_READY";
export const AUTH_TOKEN_UPDATE = "AUTH_TOKEN_UPDATE";
export const AUTH_TOKEN_UPDATE_SUCCESS = "AUTH_TOKEN_UPDATE_SUCCESS";
export const AUTH_TOKEN_UPDATE_FAILURE = "AUTH_TOKEN_UPDATE_FAILURE";

export const CAMPAIGN_REQUEST = "CAMPAIGN_REQUEST";
export const CAMPAIGN_REQUEST_SUCCESS = "CAMPAIGN_REQUEST_SUCCESS";
export const CAMPAIGN_REQUEST_FAILURE = "CAMPAIGN_REQUEST_FAILURE";