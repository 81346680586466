import * as React from 'react';
import {
  StyledHandleGraphic,
} from "./StyledHandle";
import { ReactComponent as HandleIcon } from "../../../../../assets/icons/icon_numberline_handle.svg";

interface HandleGraphicProps {
  exeState?: string
  displayCorrectAnswer?: boolean; 
}

const Handle: React.FC<HandleGraphicProps> = ({ exeState, displayCorrectAnswer }): JSX.Element => (
  <StyledHandleGraphic 
    exeState={exeState}
    displayCorrectAnswer={displayCorrectAnswer}
  >
    <HandleIcon />
  </StyledHandleGraphic>
);

export default Handle;