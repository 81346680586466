import { ReducerService } from "../services/reducer";
import { RouteService } from "../services/route";
import { SagaService } from "../services/saga";
import { ConstraintService } from "../services/constraint";
import RouterStore from "./store/router";
import AuthStore from "./store/auth";
import FlasherStore from "./store/flasher";
import SocketStore from "./store/socket";
import NetworkStore from "./store/network";

export const RouterActions = RouterStore.actions;
export { Router } from "./containers/Router";
export { Auth } from "./containers/Auth";
export { ErrorBoundary } from "./containers/ErrorBoundary";

export const init = (): void => {
  ReducerService.set(
    "location",
    RouterStore.createReducer(RouteService.keys())
  );
  ReducerService.set("auth", AuthStore.reducer);
  ReducerService.set("flasher", FlasherStore.reducer);
  ReducerService.set("network", NetworkStore.reducer);

  SagaService.set(RouterStore.saga);
  SagaService.set(AuthStore.saga);
  SagaService.set(AuthStore.updateTokenSaga);
  SagaService.set(AuthStore.requestCampaignSaga);
  ConstraintService.set(SocketStore.saga);
  SagaService.set(SocketStore.watchSocketErrorSaga);
  SagaService.set(SocketStore.watchSocketClosedSaga);
  SagaService.set(SocketStore.watchSocketChannelSaga);
  SagaService.set(FlasherStore.watchAddFlashSaga);
  SagaService.set(NetworkStore.watchNetworCheckConnectionSaga);
  SagaService.set(NetworkStore.watchDelayedNetworkDisconnectedSaga);
};
