import React from "react";
import { CSSTransition } from "react-transition-group";

type Props = {
  children: React.ReactNode;
  isIn: boolean;
  fadeKey: string;
}
const FadeIn = ({ children, isIn, fadeKey }: Props) => {
  return (
    <CSSTransition
      timeout={300}
      key={fadeKey}
      in={isIn}
      unmountOnExit
      addEndListener={(node, done) => {
        node.addEventListener("transitionend", done, false);
      }}
      classNames="fade"
    >
      {children}
    </CSSTransition>
  );
};

export default FadeIn;
