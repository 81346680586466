import * as React from "react";
import {
  TransitionMotion,
  spring,
  TransitionPlainStyle,
} from "@serprex/react-motion";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { clearFlash } from "../../../shared/store/flasher/actions";
import { FlashMessage } from "../../../shared/components/FlashMessage/FlashMessage";
import { StyledFlasherContainer } from "./StyledFlasher";
import { StyledMessageContainer } from "../../components/FlashMessage/StyledFlashMessage";
import { AnimatedStyles } from "./types";

export interface FlasherProps {
  messages: any;
  close(id: string): void;
}

class FlasherComponent extends React.PureComponent<FlasherProps> {
  /**
   * Render function for Flasher component
   */
  public render() {
    const { messages } = this.props;
    const flashes = messages
      .map((message: any) => ({
        key: message.get("id"),
        data: {
          msg: message.get("msg"),
          type: message.get("type"),
          id: message.get("id"),
        },
        style: {
          opacity: spring(1, { stiffness: 50, damping: 10 }),
          transformY: spring(0, { stiffness: 110, damping: 20 }),
          transformX: spring(0, { stiffness: 110, damping: 20 }),
        },
      }))
      .toArray();

    return (
      <TransitionMotion
        styles={flashes}
        willEnter={this.willEnter}
        willLeave={this.willLeave}
      >
        {this.renderFlasherContainer}
      </TransitionMotion>
    );
  }

  /**
   * Gets styles to be animated
   * @param style
   */

  private getInterpolatedStyles = ({
    style: { opacity, transformX, transformY },
  }: TransitionPlainStyle): AnimatedStyles => ({
    opacity,
    transform: `translate(${transformX}%, ${transformY}%)`,
  });

  /**
   * Renders flash messages
   * @param interpolated
   */
  private renderInterpolated = (
    interpolated: TransitionPlainStyle[]
  ): JSX.Element[] =>
    interpolated.map((item: TransitionPlainStyle) => {
      return (
        <StyledMessageContainer
          style={this.getInterpolatedStyles(item)}
          key={item.key}
        >
          <FlashMessage data={item.data} onClick={this.props.close} />
        </StyledMessageContainer>
      );
    });

  /**
   * Renders flasher container
   * @param interpolated
   */
  private renderFlasherContainer = (interpolated: TransitionPlainStyle[]) => (
    <StyledFlasherContainer>
      {this.renderInterpolated(interpolated)}
    </StyledFlasherContainer>
  );

  /**
   * Sets starting values of animation
   */
  private willEnter = () => ({
    opacity: 0,
    transformY: 30,
    transformX: 0,
  });

  /**
   * Sets end values of the animated component before its removed.
   */
  private willLeave = () => ({
    opacity: spring(0),
    transformY: spring(0),
    transformX: spring(-30),
  });
}

const mapStateToProps = (state: RootState) => ({
  messages: state.flasher.get("messages").reverse(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close: (id: string) => dispatch(clearFlash(id)),
});

export const Flasher = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlasherComponent);
