import * as actions from "./actions";
import { reducer } from "./reducer";
import { watchGetTokenSaga, watchUpdateTokenSaga, watchRequestCampaignSaga } from "./sagas";
import { RootState } from "../../../store";

const AuthStore = {
  actions,
  reducer,
  saga: watchGetTokenSaga,
  updateTokenSaga: watchUpdateTokenSaga,
  requestCampaignSaga: watchRequestCampaignSaga
};

export default AuthStore;

export const getToken = (state: RootState) => state.auth.jwt;