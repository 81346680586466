import * as React from "react";
import { LARGE } from "../../../../constants";
import {
  StyledLoader,
  StyledLoaderCenterContainer,
} from "../../../CorrectionButton/StyledCorrectionButton";
import { ImgWrapper, StyledImage } from "./StyledXcImage";
export interface SimpleImageProps {
  Path: string;
  alt?: string;
  height?: number | string;
  width?: number | string;
}

export const XcSimpleImage: React.FC<SimpleImageProps> = ({
  Path,
  height,
  width,
  alt,
}) => {
  const altText = alt || "";
  const [imageIsLoaded, setImageIsLoaded] = React.useState<boolean>(false);

  /**
   *  Callback for when image has finished loading
   */
  const onImageLoaded = () => {
    setImageIsLoaded(true);
  };

  const renderLoader = () => (
    <StyledLoaderCenterContainer>
      <StyledLoader size={LARGE} />
    </StyledLoaderCenterContainer>
  );

  return (
    <ImgWrapper className={"img-wrapper"}>
      {!imageIsLoaded && renderLoader()}
      <StyledImage
        finishedLoading={imageIsLoaded}
        onLoad={onImageLoaded}
        src={Path}
        alt={altText}
        width={width}
        height={height}
      />
    </ImgWrapper>
  );
};

export default XcSimpleImage;
