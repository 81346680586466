import {
  ADD_SNACKBAR_MESSAGE,
  REMOVE_SNACKBAR_MESSAGE,
  CLEAR_SNACKBAR_MESSAGES,
  REMOVE_SNACKBAR_MESSAGES,
  REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE
} from "../../../constants";
import { SnackBarMessage } from "./types";

export const addSnackbarMessage = (payload: {
  severity: SnackBarMessage["severity"];
  message: string;
  routes: string[];
}) => ({
  type: ADD_SNACKBAR_MESSAGE,
  message: payload
});

export const removeSnackbarMessage = (index: number) => ({
  type: REMOVE_SNACKBAR_MESSAGE,
  message: index
});

export const removeSnackbarMessages = (indexArr: number[]) => ({
  type: REMOVE_SNACKBAR_MESSAGES,
  message: indexArr
});

export const removeSnackbarMessagesWithRoute = (route: string) => ({
  type: REMOVE_SNACKBAR_MESSAGES_WITH_ROUTE,
  message: route
});

export const clearSnackbarMessages = () => ({
  type: CLEAR_SNACKBAR_MESSAGES
});
