import styled from "styled-components";

export const StyledLogoContainer = styled.div`
  position: absolute;
  left: 18px;
  bottom: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  
  svg {
    height: 100%;
    width: auto;
  }
`;