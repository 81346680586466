import  styled from "styled-components";
import { deviceSize } from "../../styles/theme";

export interface DashboardProps {
  backgroundImage?: string
}

export const StyledDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  max-width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%), 
    url(${(props:DashboardProps) => props.backgroundImage});
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;
  
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) /* FIX IE 10 & 11 */
  {
    *::-ms-backdrop { 
      background: url(${(props:DashboardProps) => props.backgroundImage}) 
      repeat-x repeat-y;  
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(142deg, rgba(39,117,117,0.25) 0%, rgba(39,117,117,0) 37%, rgba(39,117,117,0) 50%, rgba(39,117,117,0.25) 75%, rgba(39,117,117,0.5) 100%);
  }
`;

export const StyledParagraph = styled.div`
  font-size: 26px;
  font-weight: 400;
  color: ${ props => props.theme.themePrimary };
  line-height: 33px;

  @media (max-width: ${deviceSize.tabletS}px ) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const StyledLoadingContainer = styled.div `
  margin: auto;
`

export const StyledMenuRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const StyledBottomRow = styled.div`
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 620px;
  
  @media (max-width: ${deviceSize.tabletS}px ) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0;
  }
`;

export const StyledFooter = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding: 12px;
`;
