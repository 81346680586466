import { keyframes }  from "styled-components";

export const bounce = keyframes `
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(.90);
  }

  20% {
    transform: scale(.90);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(.90);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(.90);
  }

  100% {
    transform: scale(.90);
  }
`;

export const waves = keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;