import * as React from "react";
import {
  StyledSmallScreenBlocker,
  StyledContentContainer,
  StyledLogoContainer,
  StyledMessageContainer,
} from "./StyledSmallScreenBlocker";
import { ReactComponent as TomoyoLogo } from "../../../assets/logos/tomoyo_logo_white_line.svg";
import {
  MINIMUM_DEVICE_WIDTH,
  MINIMUM_DEVICE_HEIGHT,
  SCREEN_BLOCKER_MESSAGE,
} from "../../constants";
import { isSafari } from "../../platform/Platform";

export const SmallScreenBlocker: React.FC = (props) => {
  /**
   * Get pixel ratio, different approach for safari vs other browsers.
   */
  const getPixelRatio = () =>
    isSafari()
      ? document.documentElement.clientWidth / window.innerWidth
      : window.devicePixelRatio ||
        window.screen.availWidth / document.documentElement.clientWidth;

  /**
   * Set inital dimensions & pixelratio
   */
  const [deviceWidth, setDeviceWidth] = React.useState(
    document.documentElement.clientWidth
  );
  const [deviceHeight, setDeviceHeight] = React.useState(
    document.documentElement.clientHeight
  );
  const [pixelRatio, setPixelRatio] = React.useState(getPixelRatio());

  /**
   * Effect to listen on the resize event.
   */
  React.useEffect(() => {
    const onResize = () => {
      isZooming() ? setPixelRatio(getPixelRatio()) : setDimensions();
    };

    /**
     * Check if the screen is zooming in or resizing
     */
    const isZooming = () => (getPixelRatio() > pixelRatio ? true : false);

    /**
     * Set new dimensions on resize
     */
    const setDimensions = () => {
      setDeviceWidth(document.documentElement.clientWidth);
      setDeviceHeight(document.documentElement.clientHeight);
    };

    if (!isZooming()) {
      window.addEventListener("resize", onResize);
      setDimensions();
    }
    return () => window.removeEventListener("resize", onResize);
  }, [pixelRatio]);

  /**
   * Render Screen blocker with message
   * @param message
   */
  const renderScreenBlocker = (message: string) => (
    <StyledSmallScreenBlocker>
      <StyledContentContainer>
        <StyledLogoContainer>
          <TomoyoLogo />
        </StyledLogoContainer>
        <StyledMessageContainer>{message}</StyledMessageContainer>
      </StyledContentContainer>
    </StyledSmallScreenBlocker>
  );

  return deviceWidth < MINIMUM_DEVICE_WIDTH ||
    deviceHeight < MINIMUM_DEVICE_HEIGHT
    ? renderScreenBlocker(SCREEN_BLOCKER_MESSAGE)
    : null;
};
