import styled from "styled-components";
import { blink } from "../../XcLineup/components/LineupInput/StyledLineupInput";
import { getAlignment, getPadding } from "../helpers";

export const StyledInputContainer = styled.div<{ chartType: string }>`
  position: relative;
  width: 100%;
  padding: ${({ chartType }) => chartType !== "equation" && "0 1rem 0 1rem"};
`;

export const StyledChartInput = styled.input<{
  chartType: string;
  correctedState: string;
  index: number;
}>`
  border-width: 0 0 0.15rem 0;
  border-style: solid;
  border-color: #277575;
  text-align: ${({ chartType, index }) => getAlignment(chartType, index)};
  font-size: 1.125rem;
  font-family: "Muli", sans-serif;
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-top: 3px;
  ${({ chartType, correctedState, index }) =>
    chartType === "equation" &&
    correctedState === "INCORRECT" &&
    getPadding(index)};

  &:focus {
    outline: 0;
    border-width: 0 0 0.3rem 0;
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const StyledMirrorSpan = styled.span`
  font-family: "Muli", sans-serif;
  white-space: pre;
  position: absolute;
  visibility: hidden;
  left: -50px;
  padding: 0;
  font-size: 1.125rem;
  line-height: normal;
  letter-spacing: normal;
`;

export const StyledChartCaret = styled.div<{ margin: number | null }>`
  opacity: 0;
  height: 40%;
  width: 1px;
  background-color: ${(props) => props.theme.themePrimary};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.margin
      ? `translate(${props.margin}px, -50%)`
      : `translate(-50%, -50%)`};
  animation: ${blink} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) forwards
    infinite;
`;

export const StyledStatusDot = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  padding: 0.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.125rem solid ${(props) => props.color};
  border-radius: 50%;
  background-color: #ffffff;
`;
