import { Post } from "../adventurePicker/api/types";
import IntroductionView from "../../shared/components/IntroductionView";
import { useState } from "react";
import { PrimaryButton } from "../../shared/components/Button";
import { isPreviewed } from "./helpers";
import {
  StyledLoaderCenterContainer,
  StyledLoader,
} from "../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../external/EddaXcomp/src/constants";
import {
  StyledParagraph,
  StyledLoadingContainer,
} from "../dashboard/StyledDashboard";
import { Profile } from "../profile/api/types";

interface OnboardingIntroductionProps {
  posts: Post[];
  onSetOnboardingCompleted(user: Profile): void;
  onRouteToDashboard(): void;
}

const OnboardingIntroduction = ({
  posts,
  onSetOnboardingCompleted,
  onRouteToDashboard,
}: OnboardingIntroductionProps) => {
  const [lastSlideVisited, setIsLastSlideVisited] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const onboardingButtonClick = () => {
    if (!isPreviewed(true)) {
      onSetOnboardingCompleted({ onboarding_completed: true });
    }
    onRouteToDashboard();
  };

  const onSlideChange = (slideIndex: number) => {
    setIsLastSlide(slideIndex === posts.length - 1);
    setIsLastSlideVisited(
      slideIndex === posts.length - 1 && !lastSlideVisited
        ? true
        : lastSlideVisited
    );
  };

  return posts ? (
    <IntroductionView slides={posts} onSlideChange={onSlideChange}>
      {(isLastSlide || lastSlideVisited) && (
        <PrimaryButton
          animation="pulse"
          label="Fortsätt"
          onClick={onboardingButtonClick}
          style={{
            width: "237px",
            height: "50px",
            boxShadow: "none",
            animationDelay: "4s",
          }}
        />
      )}
    </IntroductionView>
  ) : (
    <StyledLoadingContainer>
      <StyledParagraph>Laddar innehåll!</StyledParagraph>
      <StyledLoaderCenterContainer>
        <StyledLoader size={LARGE} />
      </StyledLoaderCenterContainer>
    </StyledLoadingContainer>
  );
};

export default OnboardingIntroduction;
