import { ConstraintService } from "../../services/constraint";
import { ReducerService } from "../../services/reducer";
import { reducer } from "./reducer";
import * as actions from "./actions";
import { 
  hasAccessSaga, 
  watchExpiryNoticeRead,
  watchFetchAccessSaga,
} from "./sagas";
import { SagaService } from "../../services/saga";

/**
 * Init progress store
 */
export const init = () => {
  ConstraintService.set(hasAccessSaga);
  SagaService.set(watchExpiryNoticeRead);
  SagaService.set(watchFetchAccessSaga)
  ReducerService.set("access", reducer);
};

export const AccessActions = actions;
