import React from "react";
import { RoundButton } from "../../shared/components/Button/RoundButton";
import DropDown from "../../shared/components/DropDown";
import { Toggle } from "../../shared/components/Toggle";
import {
  StyledSectionHeading,
  StyledTestContentSection,
  StyledPrimarybutton,
  StyledButtonWrapper,
  StyledInputLabel,
  StyledIconContainer,
  StyledTestContentRow,
  StyledTextInput,
  StyledErrorMessage,
} from "./StyledTest";
import useNewTestDashboard from "./useNewTestDashboard";
import { ReactComponent as ResetIcon } from "../../assets/icons/icon_reset.svg";
import theme from "../../styles/theme";
import { DropDownItem } from "../../shared/components/DropDown/DropDown";

const NOOP = () => {};

const dropDownStyles = {
  fontSize: "11px",
  height: "37px",
  borderBottom: "solid 3px",
  width: "100%",
  maxWidth: "100%",
  backgroundColor: theme.themeLightActive,
};

const NewTestDashboard = () => {
  const {
    resetGameProgress,
    activeAdventure,
    allAdventures,
    activeProblem,
    markers,
    selectedGroup,
    amountOfMarkers,
    errorMessage,
    onToggleActiveAdventure,
    completeActiveProblem,
    resetActiveProblem,
    onSelectMarkerGroup,
    onAmountOfMarkersChange,
    generateMarkers,
    resetErrorMessage,
    deleteAllMarkers,
  } = useNewTestDashboard();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAmountOfMarkersChange(e.target.value);
    resetErrorMessage();
  };

  const onChangeMarkerGroup = (selectedItem: DropDownItem) => {
    onSelectMarkerGroup(selectedItem.value);
    resetErrorMessage();
  };

  return (
    <StyledTestContentSection>
      <StyledSectionHeading>
        Aktivt äventyr:{" "}
        {activeAdventure ? activeAdventure.title : "Inget aktivt äventyr"}
      </StyledSectionHeading>
      <StyledSectionHeading>
        Aktivt problem:{" "}
        {activeProblem ? activeProblem.title : "Inget aktivt problem"}
      </StyledSectionHeading>
      {activeAdventure && activeProblem && (
        <StyledButtonWrapper>
          <StyledPrimarybutton
            onClick={() =>
              completeActiveProblem(activeAdventure, activeProblem.id)
            }
          >
            AVSLUTA
          </StyledPrimarybutton>
          <StyledPrimarybutton
            onClick={() =>
              resetActiveProblem(activeAdventure, activeProblem.id)
            }
          >
            NOLLSTÄLL
          </StyledPrimarybutton>
        </StyledButtonWrapper>
      )}
      <StyledSectionHeading>Lås/Öppna äventyr: </StyledSectionHeading>
      <DropDown
        dropDownData={allAdventures.map((adventure) => ({
          value: adventure.id.toString(),
          label: adventure.title,
          secondary: (
            <Toggle
              key={adventure.id + adventure.locked.toString()}
              checked={!adventure.locked}
              onChange={() => onToggleActiveAdventure(adventure)}
            />
          ),
        }))}
        overrideStyles={{ ...dropDownStyles, zIndex: 2 }}
        onChange={NOOP}
      />
      <StyledSectionHeading>Välj huvudmärkesgrupp: </StyledSectionHeading>
      <DropDown
        dropDownData={markers.map((group) => ({
          value: group.id.toString(),
          label: group.label,
        }))}
        onChange={onChangeMarkerGroup}
        overrideStyles={{ ...dropDownStyles, zIndex: 1 }}
        defaultItem={
          selectedGroup && {
            value: selectedGroup?.id.toString(),
            label: selectedGroup?.label,
          }
        }
      />
      {selectedGroup && (
        <StyledInputLabel>
          Tillgängligt antal märken för "{selectedGroup.label}" -{" "}
          {selectedGroup.available_markers}
        </StyledInputLabel>
      )}
      <StyledButtonWrapper>
        <StyledTextInput
          type="number"
          min="1"
          max={selectedGroup && selectedGroup.available_markers}
          value={amountOfMarkers}
          onChange={onChange}
        ></StyledTextInput>
        <StyledPrimarybutton
          onClick={generateMarkers}
          overrideStyles={{ boxShadow: "none", marginLeft: "1.2rem" }}
          disabled={!selectedGroup && errorMessage}
        >
          Generera
        </StyledPrimarybutton>
      </StyledButtonWrapper>
      {errorMessage && 
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
         }
      <StyledTestContentRow>
        <StyledButtonWrapper>
          <StyledInputLabel>Nollställ Alla Märken</StyledInputLabel>
          <RoundButton
            skin="primary"
            callback={deleteAllMarkers}
            icon={
              <StyledIconContainer>
                <ResetIcon />
              </StyledIconContainer>
            }
            overrideStyles={{ boxShadow: "none" }}
          />
        </StyledButtonWrapper>
      </StyledTestContentRow>
      <StyledTestContentRow>
        <StyledButtonWrapper>
          <StyledInputLabel>Nollställ progress</StyledInputLabel>
          <RoundButton
            skin="primary"
            callback={resetGameProgress}
            icon={
              <StyledIconContainer>
                <ResetIcon />
              </StyledIconContainer>
            }
            overrideStyles={{ boxShadow: "none" }}
          />
        </StyledButtonWrapper>
      </StyledTestContentRow>
    </StyledTestContentSection>
  );
};

export default NewTestDashboard;
