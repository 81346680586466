import styled from 'styled-components';

interface ImgProps {
  obtained: boolean;
}

export const StyledGameItemContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledItemImg = styled.img<ImgProps>`
  max-width: 100%;
  max-height: 100%;
  opacity: ${ ( {obtained} ) => obtained ? '1' : '0.2' };
`;