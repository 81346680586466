import styled from "styled-components";

export const StyledIntermediateSliderImage = styled.img`
  width: 200px;
`;

export const StyledIntermediateSliderContent = styled.div`
  margin-left: 20px;
  max-width: 700px;
`;

export const StyledIntermediateSliderDescription = styled.h2`
  color: ${props => props.theme.themePrimary};
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.31px;
  line-height: 33px;
`;

export const StyledIntermediateSliderTitle = styled.h2`
  color: ${props => props.theme.themePrimary};
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0.31px;
  line-height: 33px;
`;

export const StyledIntermediateSliderText = styled.p`
  color: ${props => props.theme.themePrimary};
  font-size: 26px;
  letter-spacing: 0.31px;
  line-height: 33px;
`;

export const StyledContinueButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const StyledIntermediateSliderList = styled.ul`
  margin: 0;
  margin-left: 1rem;
  padding: 0;
`;

export const StyledIntermediateSliderListItem = styled.li`
  list-style: disc;
  color: ${props => props.theme.themePrimary};
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0.31px;
  line-height: 33px;
  margin-top: 0.5em;
`;
