import { Map } from "immutable";
import { Reducer } from "redux";

let reducerMap = Map<string, Reducer>();

/**
 * Helper for seamlessly add a reducer to the application
 *
 * @export
 * @class ReducerService
 */
export class ReducerService {
  public static set(key: string, reducer: Reducer): void {
    if (reducerMap.has(key)) {
      throw new Error(`Reducer '${key}' already exists`);
    }

    reducerMap = reducerMap.set(key, reducer);
  }

  public static fold(): { [key: string]: Reducer } {
    return reducerMap.reduce((reducers, reducer?: Reducer, key?: string) => ({ ...reducers, [!key ? "no-reducer-key" : key]: reducer }), {});
  }
}
