import { PROGRESS_RESET_FAILURE } from "../../shared/constants";
import { AnyAction } from "redux";
import { ProgressState } from "./types";

const initialState = {
  fetching: false,
  progress: {},
  error: null,
};

/**
 * Redux reducer for progress
 * @param {ProgressState} state current state
 * @param {AnyAction} action incoming action
 * @returns {ProgressState} updated state
 */
export function reducer(
  state: ProgressState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case PROGRESS_RESET_FAILURE: {
      return { ...state, fetching: false, error: action.error };
    }
    default:
      return state;
  }
}
