import styled, {css} from 'styled-components';

interface TooltipProps {
  visible: boolean;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  arrowPosition: string;
}

const getArrowPosition = (props: any) => {
  switch(props.arrowPosition) {
    case 'top': return css`
      transform-origin: 90% top;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        z-index: 10;
        top: -22px;
        right: 0;
        transform: translateX(-50%);
        border-bottom-color: ${props.theme.themeLight};
      }
    `;
      
    case 'left': return css`
      transform-origin: left 60%;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        z-index: 10;
        top: 0;
        left: -24px;
        transform: translateY(50%);
        border-right-color: ${props.theme.themeLight};
      }
    `;
    
    case 'bottom': return css`
      transform-origin: 90% bottom;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        z-index: 10;
        bottom: -22px;
        right: 0;
        transform: translateX(-50%);
        border-top-color: ${props.theme.themeLight};
      }
    `;
    
    case 'right': return css`
      transform-origin: right 60%;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        z-index: 10;
        top: 0;
        right: -24px;
        transform: translateY(50%);
        border-left-color: ${props.theme.themeLight};
      }
    `;
      
    default: return css`
      transform-origin: 90% top;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        z-index: 10;
        top: 0;
        left: -24px;
        transform: translateY(50%);
        border-right-color: ${props.theme.themeLight};
      }
    `;
  }
}

export const StyledTooltip = styled.div`
  position: absolute;
  top: ${props => props.top || 'auto'};
  left: ${props => props.left || 'auto'};
  bottom: ${props => props.bottom || 'auto'};
  right: ${props => props.right || 'auto'};
  z-index: 10;
  width: 200px;
  transform: ${(props:TooltipProps) => props.visible ? 'scale(1)' : 'scale(0)'};
  transition: transform 300ms cubic-bezier(0.615, 0.005, 0.000, 0.995);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
    0 2px 2px rgba(0,0,0,0.1), 
    0 4px 4px rgba(0,0,0,0.1), 
    0 8px 8px rgba(0,0,0,0.1),
    0 16px 16px rgba(0,0,0,0.1);
  ${props => getArrowPosition(props)};
`;

export const StyledTooltipContent = styled.div`
  background-color: ${props => props.theme.themeLight}; 
  color: ${props => props.theme.themePrimary};
  padding: 6px;
  font-family: 'Muli';
  text-align: center;
`;

export const StyledText = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
`;