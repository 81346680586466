import * as React from 'react';
import { ViewSlideState } from '../../../views/exercise/api/types';
import {
  StyledMarkerContainer
} from './StyledMarkers';

interface MarkerAnimatorProps {
  viewSlideState: ViewSlideState,
  id: any,
  content: JSX.Element,
  index?: number
}

const MarkerImageAnimator: React.FC<MarkerAnimatorProps> = ({ viewSlideState, content, id, index }) => (
  <StyledMarkerContainer
    viewSlideState={viewSlideState}
    markerIndex={index}
    key={`ItemSlide ${id}`}>
    {content}
  </StyledMarkerContainer>
)

export default MarkerImageAnimator
