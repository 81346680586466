import { call, put, select, takeLatest } from "typed-redux-saga";
import { getToken } from "../../shared/store/auth";
import { getProductId } from "../Application";
import {
  PROGRESS_RESET_FAILURE,
  RESET_GAME_PROGRESS_REQUEST,
} from "../../shared/constants";
import { resetGameProgressRequest } from "./requests";
import { addSnackbarMessage } from "../../shared/components/Snackbar/api/actions";

export function* watchResetGameProgressSaga() {
  yield* takeLatest(RESET_GAME_PROGRESS_REQUEST, resetGameProgressSaga);
}

function* resetGameProgressSaga() {
  try {
    // Post updated progress to api
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(resetGameProgressRequest, token, productId);

    if (response.status === 200) {
      yield* put(
        addSnackbarMessage({
          severity: "success",
          message: "All progression nollställd, ladda om sidan",
          routes: [],
        })
      );
    }
  } catch (error) {
    yield* put({ type: PROGRESS_RESET_FAILURE, error });
  }
}
