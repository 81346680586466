import React from "react";
import { Item, Items } from "../AdventureCarousel/types";
import {
  StyledPickerListContainer,
  StyledButton,
  StyledButtonImageWrapper,
  StyledButtonText,
  StyledButtonContainer,
  StyledLockedAdventureIcon,
  StyledAdventureTitle,
  StyledAdventureDescriptionWrapper,
  StyledAdventureTextWrapper,
  StyledAdventureText,
  StyledDescriptionImageWrapper,
  StyledAdventureRewardsWrapper,
  StyledAdventureRewards,
  StyledAdventureDataWrapper,
} from "./StyledAdventurePickerList";
import { ReactComponent as Planet1 } from "../../../assets/icons/icon_planet_orange.svg";
import FadeIn from "../../../views/adventurePicker/FadeIn";
import { AudioComponent } from "../Audio";
import { NumberTranslation } from "../../constants";

type Props = {
  items: Items;
  setSelected: (item: Item) => void;
  selected: Item | null;
  viewSize: "desktop" | "tablet";
  selectedAdventureGroup: Item | null;
};

const audioButtonStyles = {
  border: "2px solid white",
  background: "black",
  height: "35px",
  width: "35px",
  boxShadow: "none",
};

const audioColor = {
  active: "white",
  notActive: "white",
};

const AdventurePickerList = ({
  items,
  setSelected,
  selected,
  viewSize,
  selectedAdventureGroup,
}: Props) => {
  const customMargin =
    viewSize === "desktop"
      ? { margin: selected ? "40px 0px 0px 5px" : "50px 0px 0px 5px" }
      : { margin: selected ? "20px 0px 0px 5px" : "30px 0px 0px 5px" };

  const renderItems = () => {
    return items.map((i) => (
      <StyledButton
        selected={!!selected && i.id === selected.id}
        onClick={() => !i.locked && setSelected(i)}
        selectable={!i.locked}
        key={i.id}
        aria-label={i.title}
      >
        {!i.locked ? (
          <StyledButtonImageWrapper>
            {i.image_url ? (
              <img src={i.image_url} alt={i.title} />
            ) : (
              <Planet1 />
            )}
          </StyledButtonImageWrapper>
        ) : (
          <StyledLockedAdventureIcon />
        )}
        <StyledButtonText
          selected={!!selected && i.id === selected.id}
          selectable={!i.locked}
        >
          {!i.locked ? i.title : "låst"}
        </StyledButtonText>
      </StyledButton>
    ));
  };
  return (
    <FadeIn fadeKey="adventurePickerList" isIn={Boolean(items)}>
      <StyledPickerListContainer>
        <StyledButtonContainer>{renderItems()}</StyledButtonContainer>
        <FadeIn fadeKey="adventurePickerDescription" isIn>
          <StyledAdventureDataWrapper>
            <StyledAdventureDescriptionWrapper>
              <StyledDescriptionImageWrapper>
                {selectedAdventureGroup?.image_url || selected?.image_url ? (
                  <img
                    src={
                      selected
                        ? selected.image_url
                        : selectedAdventureGroup?.image_url
                    }
                    alt="valt äventyr"
                  />
                ) : (
                  <Planet1 />
                )}
                <StyledAdventureRewardsWrapper>
                  {selected && (
                    <StyledAdventureRewards>
                      {selected.rewards_to_complete}
                    </StyledAdventureRewards>
                  )}
                </StyledAdventureRewardsWrapper>
              </StyledDescriptionImageWrapper>
              <AudioComponent
                audioColor={audioColor}
                audioUrl={
                  selected
                    ? selected.text_audio_url || ""
                    : selectedAdventureGroup?.text_audio_url || ""
                }
                styles={{ ...audioButtonStyles, ...customMargin }}
              />
              <StyledAdventureTextWrapper>
                <StyledAdventureTitle>
                  {selected ? selected.title : selectedAdventureGroup?.title}
                </StyledAdventureTitle>
                <StyledAdventureText>
                  {selected ? selected.text : selectedAdventureGroup?.text}
                </StyledAdventureText>
                {selected && (
                  <StyledAdventureTitle>Märken</StyledAdventureTitle>
                )}
                {selected && (
                  <StyledAdventureText>
                    {`Du behöver samla ${
                      selected.rewards_to_complete === 1
                        ? `ett märke`
                        : `${
                            NumberTranslation[selected.rewards_to_complete]
                          } märken`
                    } för att klara äventyret.`}
                  </StyledAdventureText>
                )}
              </StyledAdventureTextWrapper>
            </StyledAdventureDescriptionWrapper>
          </StyledAdventureDataWrapper>
        </FadeIn>
      </StyledPickerListContainer>
    </FadeIn>
  );
};

export default AdventurePickerList;
