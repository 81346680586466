import styled, { keyframes } from "styled-components";
import { viewSlideStates } from "../../../views/exercise";
import { ViewSlideState } from "../../../views/exercise/api/types";

interface MarkersProps {
  border?: boolean;
  shadow?: boolean;
  adventure?: boolean;
  obtained?: boolean;
  color?: string;
  seen?: boolean;
  viewSlideState?: ViewSlideState;
  markerIndex?: number;
  withMargin?: boolean;
}

const markerAnimation = keyframes`
  0% {
    transform: scale(0);
    animation-timing: cubic-bezier(0.340, 0.010, 0.000, 1.000);
  }
  97% {
    transform: scale(1.05);
    animation-timing: ease-in-out;
  }
  100% {
    transform: scale(1);
    animation-timing: ease-in;
  }
`;

export const StyledMarkerContainerPrimarySchool = styled.div<MarkersProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: ${(props) => (props.border ? "3px solid #D6E2E1" : "none")};
  box-shadow: ${(props) => (props.shadow ? "0 0 16px 4px #F9F6F6" : "none")};
  ${(props) => "background:" + props.color};
`;

export const StyledMarkerImg = styled.img<{ noPadding?: boolean }>`
  border-radius: 50%;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: ${({ noPadding }) => (!noPadding ? "10px" : "0")};
`;

export const StyledGreyMarker = styled.div`
  border-radius: 50%;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
`;

export const StyledMarkers = styled.div<MarkersProps>`
  max-width: 800px;
  align-self: center;
  display: flex;
  ${(props) => props.withMargin && "margin: 10px;"}
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) { margin: 5px; }
`;

export const StyledEmptyMarkerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  margin: 5px 5px;
  background-color: #ffffff;
  border: 3px solid #d6e2e1;
`;

export const StyledMarkerItemImageWrapper = styled.div<MarkersProps>`
  width: 79px;
  height: 79px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: ${(props) =>
    props.adventure ? "4px solid #6cc5bc" : "1px solid white"};
  border-radius: 50%;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.adventure ? "0 0 5px 2px #808080" : "0 0 3px 1px #b7b7b7"};
`;

export const StyledMarkerContainer = styled.div<MarkersProps>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  animation: ${({ viewSlideState }) =>
    viewSlideState === viewSlideStates.IN_VIEW && markerAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-delay: ${({ markerIndex }) =>
    markerIndex ? markerIndex * 300 : 0}ms;
  animation-fill-mode: forwards;
`;

export const StyledMarkerContainerMiddleSchool = styled.div<MarkersProps>`
  width: 45%;
  border-radius: 50px;
  margin: 5px;
  display: flex;
  align-items: center;
  ${(props) => props.obtained && !props.seen && "border: 4px solid white;"}
  ${(props) =>
    props.obtained && !props.seen && "box-shadow: 0 0 10px 1px white;"}
  color: white;
`;

export const StyledMarkerItemContainer = styled.div`
  display: flex;
  background: white;
  margin: 2px;
  width: 100%;
  border-radius: 50px;
  box-shadow: inset 0 0 1px #848484;
`;

export const StyledMarkerItemText = styled.span`
  font-family: "Muli", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin-left: 10px;
  color: #227574;
  align-self: center;
`;

export const StyledBadgeContainer = styled.div<MarkersProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-self: center;
  justify-content: center;
  ${(props) => !props.adventure && "border-style: double;"}
  ${(props) => !props.adventure && "border: 1px solid #b7b7b7;"}
  ${(props) =>
    !props.adventure && "box-shadow: inset 0 0 0px 3px rgb(189 188 188 / 50%);"}
`;

export const StyledBadgeImgWrapper = styled.div<MarkersProps>`
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  ${(props) =>
    !props.adventure &&
    props.obtained &&
    "box-shadow: inset 0 0 15px 7px #888888"}
  ${(props) => !props.adventure && props.obtained && "border: 2px solid white;"}
  align-self: center;
`;

export const StyledBadgeImg = styled.img<MarkersProps>`
  padding: 1px;
  border-radius: 50%;
  align-self: center;
  width: ${(props) => (props.adventure ? "100%" : "40px")};
  height: ${(props) => (props.adventure ? "100%" : "40px")};
  ${(props) =>
    !props.adventure && props.obtained && "background:" + props.color};
  ${(props) =>
    !props.adventure &&
    props.obtained &&
    "box-shadow: 0 0 7px 10px" + props.color}};
`;
