import {
  StyledLoader,
  StyledLoaderCenterContainer,
} from "../../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../../external/EddaXcomp/src/constants";
import { MarkersGrid } from "../../../shared/components/MarkersGrid";
import { ArrowButton } from "../../../shared/components/Button/ArrowButton";
import { NavBar } from "../../../shared/components/NavBar";
import ExerciseSlider from "../../exercise/components/exersiceSlider";
import { MarkerSubGroup } from "../api/types";
import { useFilterMarkers, useRequestMarkers } from "../hooks";
import {
  StyledMarkersViewWrapper,
  StyledChallengeTitle,
  StyledContentWrapper,
  StyledExcButtonContainer,
  StyledFooter,
  StyledFooterWrapper,
  StyledSliderWrapper,
  StyledTopWrapper,
  StyledExpandButtonContainer,
  StyledMissionType,
  StyledMissionTypeWrapper,
} from "../StyledMarkers";
import { StudliLogo } from "../../../shared/components/StudliLogo";
import { PRIMARY_SCHOOL } from "../../../shared/constants";
import { Themes } from "../../profile/api/types";
import { useState } from "react";
import { Button } from "../../../shared/components/Button";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/icon_dropdown_arrow.svg";
import { ReactComponent as TimerIcon } from "../../../assets/icons/icon_timer.svg";
import NoMarkersView from "../noMarkers/NoMarkersView";
import { Navigator } from "../../../shared/components/Navigator";

const sliderSettings: object = {
  speed: 300,
  centerPadding: 0,
  variableWidth: false,
  accessibility: false,
  waitForAnimate: false,
};

const PrimarySchoolMarkers = () => {
  const [markerHookObject] = useRequestMarkers();

  const {
    adventureMarkers,
    assignmentMarkers,
    fetching,
    exerciseRoute,
    activeIndex,
    slideForward,
    slideBackward,
    hasActiveAdventure,
  } = markerHookObject;

  const [selectedFilter, filter] = useFilterMarkers();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded((old) => !old);

  const onFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if ((e.target as HTMLButtonElement).value === "all" && expanded) {
      setExpanded(false);
    }
    filter(e);
  };

  const expandButtonLabel = expanded ? "Minimera" : "Expandera";

  return (
    <StyledMarkersViewWrapper backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg">
      <NavBar position="relative" />

      {!fetching &&
        adventureMarkers.length === 0 &&
        assignmentMarkers.length === 0 && <NoMarkersView />}

      {fetching && (
        <StyledLoaderCenterContainer>
          <StyledLoader size={LARGE} />
        </StyledLoaderCenterContainer>
      )}

      {!fetching &&
        (adventureMarkers.length > 0 || assignmentMarkers.length > 0) && (
          <>
            <StyledContentWrapper expanded={expanded}>
              <StyledTopWrapper>
                <StyledChallengeTitle>
                  {selectedFilter === "all" &&
                    adventureMarkers.length > 0 &&
                    adventureMarkers[activeIndex].label}
                  {(selectedFilter === "assignment" ||
                    adventureMarkers.length === 0) &&
                    "Dina uppdragsmärken"}
                </StyledChallengeTitle>
                {selectedFilter === "all" && adventureMarkers.length > 0 && (
                  <>
                    <ArrowButton
                      ariaLabel="Föregående sida"
                      square
                      slim
                      onClick={slideBackward}
                      width="28px"
                      direction="left"
                      disabled={activeIndex <= 0}
                    />
                    <ArrowButton
                      ariaLabel="Nästa sida"
                      square
                      slim
                      onClick={slideForward}
                      width="28px"
                      direction="right"
                      disabled={activeIndex >= adventureMarkers.length - 1}
                    />
                  </>
                )}
              </StyledTopWrapper>
              <StyledMissionTypeWrapper>
                <StyledMissionType
                  aria-label="Visa alla märken"
                  value="all"
                  onClick={onFilterClick}
                  faded={selectedFilter !== "all"}
                >
                  Äventyrsmärken
                </StyledMissionType>
                {assignmentMarkers.length > 0 && (
                  <StyledMissionType
                    aria-label="Visa uppdragsmärken"
                    value="assignment"
                    onClick={onFilterClick}
                    faded={selectedFilter === "all"}
                  >
                    Uppdragsmärken
                    <TimerIcon />
                  </StyledMissionType>
                )}
              </StyledMissionTypeWrapper>
              <StyledSliderWrapper expanded={expanded}>
                {selectedFilter === "all" && adventureMarkers.length > 0 && (
                  <ExerciseSlider
                    overrideSettings={sliderSettings}
                    slides={adventureMarkers.map((group: MarkerSubGroup) => (
                      <MarkersGrid
                        key={group.id}
                        items={group.rewards}
                        theme={PRIMARY_SCHOOL as Themes}
                      />
                    ))}
                    slideIndex={activeIndex}
                  />
                )}
                {(selectedFilter === "assignment" ||
                  adventureMarkers.length === 0) && (
                  <MarkersGrid
                    isFilterActive
                    items={assignmentMarkers}
                    theme={PRIMARY_SCHOOL as Themes}
                  />
                )}
              </StyledSliderWrapper>
              {selectedFilter === "assignment" && assignmentMarkers.length > 4 && (
                <StyledExpandButtonContainer expanded={expanded}>
                  <Button
                    aria-label={expandButtonLabel}
                    circular
                    onClick={toggleExpand}
                  >
                    <ArrowIcon color="#277675" />
                  </Button>
                </StyledExpandButtonContainer>
              )}
            </StyledContentWrapper>
            <StyledFooterWrapper>
              <StyledFooter>
                <StudliLogo />
                {hasActiveAdventure && (
                  <StyledExcButtonContainer>
                    <Navigator
                      route={exerciseRoute}
                      label="Räkna"
                      skin="primary"
                      overrideStyles={{
                        width: "120px",
                        height: "40px",
                        fontWeight: 600,
                        fontSize: "20px",
                      }}
                    />
                  </StyledExcButtonContainer>
                )}
              </StyledFooter>
            </StyledFooterWrapper>
          </>
        )}
    </StyledMarkersViewWrapper>
  );
};

export default PrimarySchoolMarkers;
