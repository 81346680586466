import * as React from 'react';


export interface Props {
  steps: number;
  totalSteps: number;
  jumpInterval: number;
  image: string;
  turnImage: boolean;
  showJumps: boolean;
}

const Jump: React.FC<Props> = ({ steps, totalSteps, jumpInterval=1, image, turnImage, showJumps }):JSX.Element | null => {
  const relSteps:number = Math.abs(steps);
  const totalviewBoxX:number = 1000;
  const viewBoxX:number = totalviewBoxX / totalSteps;
  const activeviewBoxX:number = relSteps * viewBoxX;
  const viewBox:string = `0 0 ${activeviewBoxX} 80`;
  const frac:number = jumpInterval * ((relSteps / jumpInterval) - (Math.floor(relSteps / jumpInterval)));


  /**
   * When the bird is mirrored the x pos has to be adjusted
   * @returns {number}
   */
  const turnedBirdX = (): number => turnImage
    ? - (viewBoxX / 2 + 20)
    : (viewBoxX / 2 / jumpInterval);

  /**
   * Get svg images X value in ddifferent ways for neg and pos side
   * @returns {number}
   */
  const getSvgImageX = (): number => steps > 0
    ? (steps * viewBoxX) - 20 - (viewBoxX / 2)
    : turnedBirdX();

  /**
   * Adjusts jump curve position for negative values. Needed for step intervals for curve to start at right end
   * @returns {number}
   */
  const minusAdjust= (): number => steps < 0
    ? (viewBoxX * (jumpInterval - frac) / jumpInterval)
    : 0;

  /**
   * Renders path
   * @param {number} index
   * @returns {JSX.Element}
   */
  const renderPath = (index: number): JSX.Element => (
    <path
      key={index}
      transform={`translate(${jumpInterval * ((viewBoxX * index) - minusAdjust())}, 0)`}
      d={`M0 80 C 0 20, ${viewBoxX * jumpInterval} 20, ${viewBoxX * jumpInterval} 80`}
      stroke="#277575"
      strokeWidth=""
    />
  );

  /**
   * Generates dots
   * @returns {JSX.Element[]}
   */
  const generateDots = (): JSX.Element[] => {
    let rows: JSX.Element[] = [];

    for (let index = 0; index <= (relSteps / jumpInterval); index++) {
      rows = [...rows, renderPath(index)]
    }

    return rows;
  };

  /**
   * Renders SVG image
   * @returns {JSX.Element | null}
   */
  const renderSvgImage = ():JSX.Element | null => image && showJumps
    ? <image
      id="bird"
      xlinkHref={image}
      x={getSvgImageX()}
      y="16"
      width="32px"
      height="32px"
    />
    : null;

  return (
    <svg id="svg-jump" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} style={{ position: 'absolute', bottom: '22px', width: '100%' }}>
      <g
        id="path"
        stroke="none"
        strokeWidth="2"
        fill="transparent"
        fillRule="evenodd"
        strokeDasharray="5"
        strokeLinecap="round"
      >
        { generateDots() }
        { renderSvgImage() }
      </g>
    </svg>
  );
};

export default Jump;
