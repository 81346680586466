import styled, { css } from "styled-components";
import { deviceSize } from "../../styles/theme";
interface MarkersViewProps {
  backgroundImage?: string;
}

const getBackgroundGradient = (img: string = "") => css`
  background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    ),
    url(${img});
`;

export const StyledMissionTypeWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledMissionType = styled.button`
  font-size: 1.4rem;
  display: flex;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.8;
  }
  svg {
    pointer-events: none;
  }
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
  padding: 0.3rem 1.4rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 600;
  color: ${(props) => props.theme.themePrimary};
  opacity: ${(props) => (props.faded ? "0.5" : "1")};
`;

export const StyledMarkersViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  ${(props) => getBackgroundGradient(props.backgroundImage)}
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;

  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active) /* FIX IE 10 & 11 */ {
    *::-ms-backdrop {
      background: url(${(props: MarkersViewProps) => props.backgroundImage})
        repeat-x repeat-y;
    }
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    ${getBackgroundGradient()}
  }
`;

export const StyledContentWrapper = styled.div<{ expanded: boolean }>`
  position: relative;
  margin-bottom: ${(props) => (props.expanded ? "50px" : "0")};
`;

export const StyledExpandButtonContainer = styled.div<{ expanded: boolean }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;

  svg {
    transform: ${(props) => (props.expanded ? "rotate(180deg)" : "initial")};
  }
`;

export const StyledTopWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  button:last-of-type {
    margin-left: 18px;
  }
`;

export const StyledSliderWrapper = styled.div<{ expanded: boolean }>`
  overflow: auto;
  max-height: ${(props) => (props.expanded ? "none" : "512px")};
  max-width: 900px;
  width: 90vw;
  flex: 1;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.3);
  @media (max-width: ${deviceSize.phone}px) {
    width: 100vw;
  }
`;

export const StyledChallengeTitle = styled.h2`
  margin-right: auto;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.21px;
  line-height: 33px;
  color: ${(props) => props.theme.themePrimary};
`;

export const StyledFooterWrapper = styled.div`
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const StyledFooter = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  padding: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledNavItemContainer = styled.div`
  margin: 0 15px;
`;

export const StyledExcButtonContainer = styled.div`
  position: absolute;
  right: 12px;
`;

export const StyledFilterButton = styled.button`
  border-radius: 50%;
  background-color: ${(props) => props.theme.themePrimary};
  height: 57px;
  width: 57px;
  border: 0;
  cursor: pointer;

  svg {
    pointer-events: none;
  }
`;
