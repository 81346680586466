import {
  watchNewExerciseData,
  watchCardUpdates,
  watchExerciseFinish,
  watchGetExercisesSaga,
  watchNewDeck,
  watchDeckFinished,
  watchNextDeckStatus,
  watchExerciseStarted,
  watchCorrectAnswer,
  watchLogMediaPlayed
} from "./api/sagas";
import { SagaService } from "../../services/saga";
import { reducer } from "./api/reducer";
import { ReducerService } from "../../services/reducer";

export * from "./Exercise";

export const init = () => {
  SagaService.set(watchGetExercisesSaga);
  SagaService.set(watchNewDeck);
  SagaService.set(watchNextDeckStatus);
  SagaService.set(watchCardUpdates);
  SagaService.set(watchNewExerciseData);
  SagaService.set(watchExerciseFinish);
  SagaService.set(watchDeckFinished);
  SagaService.set(watchExerciseStarted);
  SagaService.set(watchCorrectAnswer);
  SagaService.set(watchLogMediaPlayed);
  ReducerService.set("exercise", reducer);
};
