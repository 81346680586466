import * as React from "react";
import {
  StyledColumnContent,
  StyledHelpHeading,
  StyledHelpImageContainer,
} from "../StyledHelp";
import { HelpData } from "../../../../views/exercise/api/types";
import { renderSoundWrappedElement } from "../Help";
import {
  XcImage,
  ImageProps,
} from "../../../../external/EddaXcomp/src/components/BlockLoader/ExerciseComponents";

interface Props {
  data: HelpData;
}

const renderHelpImage = (src: string) => (
  <StyledHelpImageContainer>
    <XcImage image={{ src: src }} />
  </StyledHelpImageContainer>
);

const renderHelpSVGImage = ({ icons, format }: ImageProps) => (
  <XcImage icons={icons} format={format} position={"relative"} />
);

export const HelpImage: React.FC<Props> = ({ data }) => {
  const { image, assets } = data;

  return (
    <StyledColumnContent topAligned={true}>
      <StyledHelpHeading>{data.title}</StyledHelpHeading>
      {image && image.src
        ? renderHelpImage(image.src)
        : assets
        ? renderHelpSVGImage(assets)
        : null}
      {renderSoundWrappedElement(data)}
    </StyledColumnContent>
  );
};
