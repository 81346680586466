import * as React from "react";
import {
  StyledSliderBox,
  StyledSliderBoxArrowWrapper,
  StyledSliderBoxContentOptional,
  StyledSliderBoxContentText,
  StyledSliderBoxContent,
  StyledSliderBoxContentTitle,
  ContentWrapper,
  StyledAudioContainer,
  StyledNextButton,
} from "./StyledSliderBox";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/icon_arrow_slim_large.svg";
import HtmlRenderer from "../../HtmlRenderer";
import { ArrowButton } from "../../Button/ArrowButton";
import { Themes } from "../../../../views/profile/api/types";
import { MIDDLE_SCHOOL, PRIMARY_SCHOOL } from "../../../constants";

interface SliderBoxProps {
  audio: JSX.Element | null;
  prevCallback: any;
  nextCallback: any;
  title: string;
  body: string;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  children?: any;
  showMarkers: boolean;
  theme: Themes;
}

const SliderBox: React.FC<any> = (props: SliderBoxProps) => (
  <StyledSliderBox showMarkers={props.showMarkers} theme={props.theme}>
    {props.theme === MIDDLE_SCHOOL && (
      <StyledSliderBoxArrowWrapper theme={props.theme}>
        {!props.isFirstSlide && (
          <ArrowButton
            direction="left"
            inverted
            width="50px"
            slim
            onClick={props.prevCallback}
          />
        )}
      </StyledSliderBoxArrowWrapper>
    )}
    <ContentWrapper theme={props.theme}>
      {props.theme === PRIMARY_SCHOOL && (
        <StyledSliderBoxArrowWrapper theme={props.theme}>
          {!props.isFirstSlide && (
            <StyledNextButton
              style={{ transform: `rotate(180deg)` }}
              onClick={props.prevCallback}
            >
              <ArrowIcon />
            </StyledNextButton>
          )}
        </StyledSliderBoxArrowWrapper>
      )}
      {props.theme === MIDDLE_SCHOOL && props.audio && (
        <StyledAudioContainer theme={props.theme}>
          {props.audio}
        </StyledAudioContainer>
      )}
      <StyledSliderBoxContent theme={props.theme}>
        {props.theme === PRIMARY_SCHOOL && props.audio && (
          <StyledAudioContainer theme={props.theme}>
            {props.audio}
          </StyledAudioContainer>
        )}
        {props.title && (
          <StyledSliderBoxContentTitle>
            {props.title}
          </StyledSliderBoxContentTitle>
        )}

        <HtmlRenderer
          component={StyledSliderBoxContentText}
          html={props.body}
        />
      </StyledSliderBoxContent>
      {props.theme === MIDDLE_SCHOOL && props.children && (
        <StyledSliderBoxContentOptional>
          {props.children}
        </StyledSliderBoxContentOptional>
      )}
      {props.theme === PRIMARY_SCHOOL && (
        <StyledSliderBoxArrowWrapper theme={props.theme}>
          {!props.isLastSlide && (
            <StyledNextButton onClick={props.nextCallback}>
              <ArrowIcon />
            </StyledNextButton>
          )}
        </StyledSliderBoxArrowWrapper>
      )}
    </ContentWrapper>

    {props.theme === MIDDLE_SCHOOL && (
      <StyledSliderBoxArrowWrapper theme={props.theme}>
        {!props.isLastSlide && (
          <ArrowButton
            direction="right"
            inverted
            width="50px"
            slim
            onClick={props.nextCallback}
          />
        )}
      </StyledSliderBoxArrowWrapper>
    )}
    {props.theme === PRIMARY_SCHOOL && props.children && (
      <StyledSliderBoxContentOptional>
        {props.children}
      </StyledSliderBoxContentOptional>
    )}
  </StyledSliderBox>
);

export default SliderBox;
