import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import { App } from "./App";
import * as SharedModule from "./shared";
import * as ViewsModule from "./views";
import * as MarkersModule from "./views/markers";
import * as DashboardModule from "./views/dashboard";
import * as ExerciseModule from "./views/exercise";
import * as OnboardingModule from "./views/onboarding";
import * as ProfileModule from "./views/profile";
import * as TestModule from "./views/test";
import * as ApplicationStore from "./store/Application";
import * as ProgressStore from "./store/progress";
import * as SnackbarStore from "./shared/components/Snackbar";
import * as SplashStore from "./shared/store/splash";
import * as AccessStore from "./store/access";
import * as AdventureGroupsModule from "./views/adventurePicker";
import * as AssignmentsModule from "./views/assignment";
import { createStore } from "./store";
import packageJson from "../package.json";
import { getApplicationVersion } from "./shared/tools/misc";
import "react-alice-carousel/lib/alice-carousel.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://67a49fe498ca4179b59d08c21238af1a@sentry.io/1333151",
    release: `${packageJson.name}@${getApplicationVersion()}`,
    environment: `${process.env.NODE_ENV}`,
  });
} else {
  console.warn(
    `Sentry will NOT be initiated for this env: ${process.env.NODE_ENV}`
  );
}

ReactGA.initialize("UA-36803650-3");

ViewsModule.init();
MarkersModule.init();
DashboardModule.init();
ExerciseModule.init();
OnboardingModule.init();
ProfileModule.init();
SnackbarStore.init();
ApplicationStore.init();
ProgressStore.init();
SplashStore.init();
TestModule.init();
AccessStore.init();
AdventureGroupsModule.init();
SharedModule.init();
AssignmentsModule.init();
const store = createStore();
const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);
root.render(<App store={store} />);
