import * as React from "react";
import {
  StyledSortBox,
  StyledSortBoxLabel,
  StyledSortBoxGraphic,
  StyledSortBoxImage,
  StyledBoxItemContainer,
  StyledSortBoxImageContainer,
} from "./StyledSortBox";
import XcImage from "../../../XcImage";
import AutoLaTeX from "react-autolatex";
import { useDrop } from "react-dnd";

interface Props {
  id: string;
  value: string;
  label: string;
  image?: {
    src: string;
  };
  assets?: {
    icons: any;
    format: any;
  };
  canAcceptItem: boolean;
  chipsFormat: string;
  textSize: string;
  callback: (param: string) => void;
  onDropCallback: (param: string, param2: string) => void;
}

export const SortBox: React.FC<Props> = (props) => {
  const handleClick = () => props.canAcceptItem && props.callback(props.id);
  const onDrop = () => {
    props.canAcceptItem && props.onDropCallback(props.id, tempItem.id);
  };

  const [{ isOver, item }, drop] = useDrop({
    accept: "sort-item",
    drop: () => onDrop(),
    collect: (monitor) => ({
      item: monitor.getItem(),
      isOver: monitor.isOver(),
      canDrop: props.canAcceptItem,
    }),
  });
  const tempItem: any = item;

  const renderLabel = () => {
    if (props.image) {
      return (
        <StyledSortBoxImageContainer>
          <StyledSortBoxImage src={props.image.src} />
        </StyledSortBoxImageContainer>
      );
    } else if (props.assets) {
      return (
        <StyledSortBoxImageContainer>
          <XcImage
            icons={props.assets.icons}
            format={props.assets.format}
            position={"absolute"}
          />
        </StyledSortBoxImageContainer>
      );
    } else {
      return (
        <StyledSortBoxLabel textSize={props.textSize}>
          <AutoLaTeX>{props.label}</AutoLaTeX>
        </StyledSortBoxLabel>
      );
    }
  };

  return (
    <StyledSortBox>
      <StyledSortBoxGraphic
        ref={drop}
        onMouseUp={handleClick}
        isOver={isOver}
        canAcceptItem={props.canAcceptItem}
      >
        <StyledBoxItemContainer chipsFormat={props.chipsFormat}>
          {props.children}
        </StyledBoxItemContainer>
      </StyledSortBoxGraphic>
      {renderLabel()}
    </StyledSortBox>
  );
};
