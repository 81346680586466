import { AnyAction, Reducer } from "redux";
import { MapTypeAllowedData, TypedMap } from "./typed-map";

export type HandlerFn<
  DataType extends MapTypeAllowedData<DataType>,
  Action extends AnyAction
> = (state: TypedMap<DataType>, action: Action) => TypedMap<DataType>;

export interface IHandlers<
  DataType extends MapTypeAllowedData<DataType>,
  Action extends AnyAction
> {
  [key: string]: HandlerFn<DataType, Action>;
}

/**
 * Creates reducers from an object
 * e.g { [SOME_ACTION]: (state, action) {...} }
 *
 * @export
 * @template DataType
 * @template Action
 * @param {TypedMap<DataType>} initialState
 * @param {IHandlers<DataType, Action>} handlers
 * @returns {Reducer}
 */
export function createReducer<
  DataType extends MapTypeAllowedData<DataType>,
  Action extends AnyAction = AnyAction
>(
  initialState: TypedMap<DataType>,
  handlers: IHandlers<DataType, Action>
): Reducer {
  return function reducer(state = initialState, action: AnyAction) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action as Action);
    } else {
      return state;
    }
  };
}
