import { reducer } from "./reducer";
import * as actions from "./actions";

import { 
  watchNetworCheckConnectionSaga,
  watchDelayedNetworkDisconnectedSaga,
} from "./sagas";

const NetworkStore = {
  reducer,
  actions,
  watchNetworCheckConnectionSaga,
  watchDelayedNetworkDisconnectedSaga,
}

export default NetworkStore;