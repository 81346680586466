import { List } from "immutable";

let sagas = List<() => IterableIterator<any>>();

/**
 * All sagas that are registered in ConstraintService
 * will be executed before APPLICATION_READY action
 *
 * @export
 * @class ConstraintService
 */
export class ConstraintService {
  public static set(saga: () => IterableIterator<any>): void {
    sagas = sagas.push(saga);
  }

  public static get array(): Array<(() => IterableIterator<any>)> {
    return sagas.toArray();
  }
}
