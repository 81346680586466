import styled, { css } from "styled-components";
import { Navigator } from "../../../shared/components/Navigator";
interface AdventureModalProps {
  isImage?: boolean;
  showBackground?: boolean;
  expandModal?: boolean;
}

const backgroundImage = css`
  background-image: linear-gradient(
      142deg,
      rgba(39, 117, 117, 0.25) 0%,
      rgba(39, 117, 117, 0) 37%,
      rgba(39, 117, 117, 0) 50%,
      rgba(39, 117, 117, 0.25) 75%,
      rgba(39, 117, 117, 0.5) 100%
    ),
    url(https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg);
`;

export const StyledAdventureInfoModalWrapper = styled.div<AdventureModalProps>`
  display: flex;
  flex-direction: column;
  ${({ showBackground }) => showBackground && backgroundImage}
  ${({ showBackground }) =>
    !showBackground &&
    "background: white;"}
  background-repeat: no-repeat, repeat;
  background-position: top left, top left;
  border: 3px solid white;
  box-shadow: 0 0px 14px 0 rgba(0 0 0 /35%);
  position: absolute;
  z-index: 30;
  max-width: 800px;
  ${({ expandModal }) => !expandModal && "max-height: 400px;"}
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (width: 768px) {
    width: 600px;
    height: 300px;
  }
`;

export const StyledAdventureInfoTextContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 75%;
`;

export const StyledAdventureInfoText = styled.p<AdventureModalProps>`
  display: flex;
  color: rgb(39, 117, 117);
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.31px;
  line-height: 33px;
  width: 100%;
  ${props => (props.isImage ? "padding: 10px 100px" : "padding: 10px 0px")};
`;

export const StyledDetailsContainer = styled.div`
  color: rgb(39, 117, 117);
  font-size: 18px;
  display: block;
  max-height: 100%;
  overflow: auto;
  padding: 1em;
`;

export const StyledCloseButtonContainer = styled.div`
  position: fixed;
  top: -3%;
  left: 96%;
  z-index: 40;
`;

export const StyledCloseButton = styled.button`
  border-radius: 50px;
  border: rgb(34, 117, 116);
  height: 40px;
  width: 40px;
  padding: 5px;
  background-color: rgb(34, 117, 116);
  color: white;
  font-size: 1em;
  cursor: pointer;
  box-shadow: 0 0 14px 0 rgba(0 0 0 /35%);
  svg {
    padding-top: 3px;
  }
`;

export const StyledNavigatorsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: right;
  margin: 10px 0;
`;
export const StyledNavigator = styled(Navigator)`
  background-color: rgb(34, 117, 116);
  font-weight: 600;
  color: white;
  padding: 7px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin: 0 0.5rem;
`;

export const StyledAdventureImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-self: center;
  margin: 20px;
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 14px 0 rgba(0 0 0 /35%);
`;

export const StyledImageAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 85%;
  align-items: center;
  flex-grow: 2;
`;
