import { RootState } from "../../store";
import { connect } from "react-redux";
import { Profile } from "../profile/api/types";
import { PRIMARY_SCHOOL } from "../../shared/constants";
import MiddleSchoolMarkers from "./middleSchool/MiddleSchoolMarkers";
import PrimarySchoolMarkers from "./primarySchool/PrimarySchoolMarkers";
export interface MarkersViewProps {
  profile: Profile;
}

const MarkersViewComponent = ({ profile: { theme } }: MarkersViewProps) =>
  theme === PRIMARY_SCHOOL ? <PrimarySchoolMarkers /> : <MiddleSchoolMarkers />;

const mapStateToProps = (state: RootState) => ({
  profile: state.profile.profile,
});

export const MarkersView = connect(mapStateToProps)(MarkersViewComponent);
