export const getAlignment = (
  chartType: string | undefined,
  index: number | undefined
) => {
  if (chartType === "equation") {
    switch (index) {
      case 0:
        return "end";
      case 1:
        return "center";
      case 2:
        return "start";
      default:
        return "center";
    }
  }
  return "center";
};

export const getPadding = (index: number | undefined) => {
  switch (index) {
    case 0:
      return "padding: 0 0.3rem 0 0";
    case 1:
      return "padding: 0";
    case 2:
      return "padding: 0 0 0 0.3rem";
    default:
      return "padding: 0";
  }
};
