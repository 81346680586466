import {
  StyledLoader,
  StyledLoaderCenterContainer,
} from "../../../external/EddaXcomp/src/components/CorrectionButton/StyledCorrectionButton";
import { LARGE } from "../../../external/EddaXcomp/src/constants";
import { NavBar } from "../../../shared/components/NavBar";
import { Navigator } from "../../../shared/components/Navigator";
import { ATTIC_ROUTE, EXERCISE_ROUTE } from "../../routes";
import { useFilterMarkers, useRequestMarkers } from "../hooks";
import {
  BgImage,
  StyledBadgeGrid,
  StyledBadgeRow,
  StyledBottomContainer,
  StyledContainer,
  StyledTitleLabel,
  StyledGroupLabel,
  StyledItemContainer,
  StyledLabelContainer,
  StyledMarkersContainer,
  StyledMarker,
  StyledWrapper,
  StyledObtainedMarker,
  StyledHeadMarker,
  StyledObtainedHeadMarker,
  StyledFiltersContainer,
  StyledFilterButton,
  StyledButtonLabel,
  StyledBackNavigator,
} from "./StyledMiddleSchoolMarkers";
import { ReactComponent as BackIcon } from "../../../assets/icons/icon_back.svg";
import { getNumberOfItems } from "../helpers";
import { useMemo } from "react";
import NoMarkersView from "../noMarkers/NoMarkersView";

interface MarkerGraphicProps {
  obtained: boolean;
  seen: boolean;
  img: string;
  color?: string;
}

const HeadMarkerGraphic = (props: MarkerGraphicProps) =>
  props.obtained ? (
    <StyledObtainedHeadMarker width="70px" height="70px" seen={props.seen}>
      <img src={props.img} alt="Huvudmärke" />
    </StyledObtainedHeadMarker>
  ) : (
    <StyledHeadMarker bgColor="#43513f" width="70px" height="70px" />
  );

const MarkerGraphic = (props: MarkerGraphicProps) =>
  props.obtained ? (
    <StyledObtainedMarker
      bgColor={props.color}
      width="50px"
      height="50px"
      seen={props.seen}
    >
      <img src={props.img} alt="Märke" />
    </StyledObtainedMarker>
  ) : (
    <StyledMarker width="50px" height="50px" />
  );

const MiddleSchoolMarkers = () => {
  const [markerHookObject] = useRequestMarkers();

  const { allMarkers, fetching } = markerHookObject;

  const markers = allMarkers.map((group) => ({
    ...group,
    rewards: group.rewards.filter((item) =>
      item.sub_rewards?.some((marker) => marker.obtained)
    ),
  }));

  const [selectedFilter, filter] = useFilterMarkers();

  const countMarkers = useMemo(() => getNumberOfItems(markers), [markers]);

  const countMathMarkers = useMemo(
    () => getNumberOfItems(markers.filter((item) => item.type === "math")),
    [markers]
  );

  const countAssignmentMarkers = useMemo(
    () =>
      getNumberOfItems(markers.filter((item) => item.type === "assignment")),
    [markers]
  );

  const countAdventureMarkers = useMemo(
    () => getNumberOfItems(markers.filter((item) => item.type === "adventure")),
    [markers]
  );

  return (
    <StyledWrapper>
      <NavBar position="absolute">
        <StyledBackNavigator route={ATTIC_ROUTE} circular aria-label="Vinden">
          <BackIcon />
        </StyledBackNavigator>
      </NavBar>
      <StyledContainer>
        {!fetching && markers.length === 0 && <NoMarkersView />}

        {fetching && (
          <StyledLoaderCenterContainer>
            <StyledLoader size={LARGE} />
          </StyledLoaderCenterContainer>
        )}
        {!fetching && markers.length > 0 && (
          <>
            <StyledFiltersContainer>
              <StyledFilterButton
                selected={selectedFilter === "all"}
                type="button"
                value="all"
                onClick={filter}
              >
                <StyledButtonLabel selected={selectedFilter === "all"}>
                  Alla: {countMarkers}
                </StyledButtonLabel>
              </StyledFilterButton>
              <StyledFilterButton
                selected={selectedFilter === "math"}
                type="button"
                value="math"
                onClick={filter}
              >
                <StyledButtonLabel selected={selectedFilter === "math"}>
                  Matte: {countMathMarkers}
                </StyledButtonLabel>
              </StyledFilterButton>
              <StyledFilterButton
                type="button"
                value="adventure"
                onClick={filter}
                selected={selectedFilter === "adventure"}
              >
                <StyledButtonLabel selected={selectedFilter === "adventure"}>
                  Äventyr: {countAdventureMarkers}
                </StyledButtonLabel>
              </StyledFilterButton>
              <StyledFilterButton
                type="button"
                value="assignment"
                onClick={filter}
                selected={selectedFilter === "assignment"}
              >
                <StyledButtonLabel selected={selectedFilter === "assignment"}>
                  Uppdrag: {countAssignmentMarkers}
                </StyledButtonLabel>
              </StyledFilterButton>
            </StyledFiltersContainer>
            <StyledBadgeGrid>
              {markers.map(
                (group) =>
                  (group.type === selectedFilter || selectedFilter === "all") &&
                  group.rewards.map((item) => (
                    <StyledBadgeRow key={item.id}>
                      <StyledLabelContainer>
                        <StyledTitleLabel>{group.label}</StyledTitleLabel>
                        <StyledGroupLabel>{item.label}</StyledGroupLabel>
                      </StyledLabelContainer>

                      <StyledMarkersContainer>
                        {item.sub_rewards &&
                          item.sub_rewards.map((marker) => (
                            <MarkerGraphic
                              obtained={marker.obtained}
                              seen={marker.seen}
                              img={marker.thumbnail_url}
                              key={marker.id}
                              color={marker.color}
                            />
                          ))}
                      </StyledMarkersContainer>

                      <StyledItemContainer>
                        <HeadMarkerGraphic
                          seen={item.seen}
                          obtained={item.obtained}
                          img={item.thumbnail_url}
                        />
                      </StyledItemContainer>
                    </StyledBadgeRow>
                  ))
              )}
            </StyledBadgeGrid>
          </>
        )}
      </StyledContainer>
      <StyledBottomContainer>
        <Navigator
          route={EXERCISE_ROUTE}
          label="Räkna"
          skin="primary"
          overrideStyles={{
            width: "120px",
            height: "40px",
            fontWeight: 600,
            fontSize: "20px",
          }}
        />
      </StyledBottomContainer>
      <BgImage backgroundImage="https://media.studentlitteratur.se/image/mr/story/images/vy_bad_2400x2065.jpg" />
    </StyledWrapper>
  );
};

export default MiddleSchoolMarkers;
