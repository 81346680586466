import * as React from "react";
import {
  StyledFlashMessage,
  StyledClearFlashButton,
  StyledMessageTitle,
  StyledMessageBody,
  StyledMessageText,
  StyledButtonContainer,
  StyledReloadButton,
} from "./StyledFlashMessage";
import { ReactComponent as CloseIcon } from "../../../assets/icons/icon_close.svg";
import { reloadPage } from "../../containers/ErrorBoundary";
import { ERROR_TYPES } from "../../../shared/constants";

interface FlashMessageProps {
  data: any;
  onClick: (id: string) => void;
}

export const FlashMessage: React.FC<FlashMessageProps> = (props) => {
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick(props.data.id);
    }
  };

  const renderReloadButton = () =>
    props.data.type === ERROR_TYPES.ERROR ? (
      <StyledReloadButton onClick={reloadPage}>Räkna vidare</StyledReloadButton>
    ) : null;

  return (
    <StyledFlashMessage type={props.data.type}>
      <StyledMessageBody>
        <StyledMessageTitle>Meddelande</StyledMessageTitle>
        <StyledMessageText>{props.data.msg}</StyledMessageText>
      </StyledMessageBody>
      <StyledButtonContainer>
        <StyledClearFlashButton onClick={handleOnClick}>
          <CloseIcon />
        </StyledClearFlashButton>
      </StyledButtonContainer>
      {renderReloadButton()}
    </StyledFlashMessage>
  );
};
