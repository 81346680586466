import * as React from "react";
import BlockLoader from '../BlockLoader/BlockLoader';
import {
  StyledBlockArray,
  StyledExerciseWrapper,
  StyledExerciseRenderer,
  StyledFeedbackWrapper,
  StyledFeedbackDisplayContainer,
  StyledExerciseRow,
  StyledPreviewLabel
} from "./StyledExerciseRenderer";
import CorrectionButton from "../CorrectionButton/CorrectionButton";
import {
  Answer,
  Meta,
  ExerciseData,
  Settings
} from "./ExerciseRendererTypes";
import FeedbackDisplay from "../FeedbackDisplay";
import ToggleButton from "../ToggleButton";
import { ReactComponent as CorrectIcon } from "../../assets/icons/icon_correct.svg";
import * as qs from 'query-string';

export interface ExerciseRendererProps {
  data: ExerciseData;
  meta: Meta;
  isCorrect?: boolean;
  state: string;
  currentAttempt: number;
  isHelpShowing: boolean;
  submitFailure?: boolean;
  focusInput?: boolean;
  callback(answer: Answer, meta: Meta): void;
}

export interface ExerciseRendererState {
  isCorrect: boolean;
  isDirty: boolean;
  selectedAnswers: string;
  toggleAnswers: boolean;
}

class ExerciseRenderer extends React.PureComponent <ExerciseRendererProps, ExerciseRendererState> {
  public state: ExerciseRendererState = {
    isCorrect: false,
    isDirty: false,
    selectedAnswers: "",
    toggleAnswers: false
  };

  public layout: string[] = (this.props.data.settings && this.props.data.settings.layout) || [];

  public componentDidUpdate(prevProps: ExerciseRendererProps) {
    if (!prevProps.submitFailure && this.props.submitFailure) {
      // if we just got a submitFailure, force the state dirty to display
      // the submit button even if the user hasn't made any changes. 
      this.setState({ isDirty: true });
    }
  }

  public render(){
    return (
      <StyledExerciseRenderer>
        <StyledExerciseWrapper>
          {this.renderEx(this.props.meta.cardData.exerciseId, this.props.data)}
        </StyledExerciseWrapper>
        <StyledFeedbackWrapper>
          <StyledFeedbackDisplayContainer>
            <FeedbackDisplay state={this.props.state} />
          </StyledFeedbackDisplayContainer>
          { this.renderToggleButton() }
          { this.CorrBtnRenderer() }
        </StyledFeedbackWrapper>
      </StyledExerciseRenderer>
    )
  }
  
  /**
   * Renders button
   */
  private renderToggleButton = (): (JSX.Element | null ) => {
    return this.props.state === 'INCORRECT' 
    ? <ToggleButton
        callback={this.toggleShowCorrectAnswer}
        label={!this.state.toggleAnswers ? 'Visa rätt svar' : 'Visa ditt svar'}
        icon={!this.state.toggleAnswers ? <CorrectIcon color={'#277575'} /> : undefined} 
      />
    : null
  };
  
  /**
   * Renders the input
   * @returns JSX.Element
   * @constructor
   */
  private CorrBtnRenderer = () => this.state.isDirty || this.props.state === 'INCORRECT' || this.props.state === 'CORRECT' || this.props.meta.disabled
    ? (
        <CorrectionButton
          callback={this.exerciseFinished}
          state={this.props.state}
          disabled={this.props.meta.disabled}
        />
    ) : null;

  /**
   * Register if an answer is right or wrong and dirtify the state.
   * @param selectedAnswers
   */
  private registerSelectedAnswerStatus = (selectedAnswers: string):void => {
    this.setState({ selectedAnswers, isDirty: true });
  };

  /**
   * Render exercises recursively
   * @param {ExerciseData} dataObject
   * @returns {any}
   */
  private renderEx = (exerciseId: number, dataObject: ExerciseData, index: number = 0) => {
    return (dataObject.type || "").toLowerCase() === 'blockarray'
      ? (
        <React.Fragment>
          { this.renderPreviewLabel(exerciseId) }
          <StyledBlockArray key={dataObject.guiId}>
            { dataObject.data.map((item: ExerciseData, i: number) => this.renderEx(exerciseId, item, i)) }
          </StyledBlockArray>
        </React.Fragment>
        
      )
      : this.renderExercises(dataObject.guiId, dataObject.type, dataObject.data, dataObject.operative, dataObject.settings || {}, this.layout[index])
  };

  private renderPreviewLabel = (exerciseId: number) => {
    const queryParams = qs.parse(window.location.search);
    return (
      queryParams && queryParams.preview ? <StyledPreviewLabel>ID: {exerciseId}</StyledPreviewLabel> : null
    )
  }

  /**
   * Renders an exercise
   * @param blockId
   * @param blockType
   * @param blockData
   * @param operative
   * @param settings
   * @returns {any}
   */
  private renderExercises = (blockId: string, blockType: string, blockData: ExerciseData[], operative: boolean, settings: Settings, size: string = '1') => (
    <StyledExerciseRow 
      key={`row-${blockId}`} 
      size={size}  
      minimizeTextSpace={blockType === 'text' && this.props.data.settings && this.props.data.settings.minimizeSpace}
      position={(blockType === 'blanks') || (blockType === 'lineup') ? undefined : 'relative'}
    >
      <BlockLoader
        key={ blockId }
        blockType={ blockType }
        blockData={ blockData }
        operative={ operative }
        settings={settings}
        currentAttempt={this.props.currentAttempt}
        state={this.props.state}
        callback={this.registerSelectedAnswerStatus}
        displayCorrectAnswer={this.getShowCorrectAnswer()}
        correctAnswers={this.props.meta.correctAnswerData && JSON.parse(this.props.meta.correctAnswerData)}
        size={size}
        isHelpShowing={this.props.isHelpShowing}
        focusInput={this.props.focusInput ? this.props.focusInput : false}
      />
    </StyledExerciseRow>
  );

  /**
   * Callback when an exercise is finished
   */
  private exerciseFinished = () => {
    if(this.props.currentAttempt < 1) {
      this.setState({ isDirty: false });
    }
    this.props.callback({ answer: this.state.selectedAnswers }, this.props.meta);
  }
  
  private toggleShowCorrectAnswer= () => {
    this.setState({ toggleAnswers: !this.state.toggleAnswers });
  }
  
 /**
  * Get correct answer boolean
  */
  private getShowCorrectAnswer = (): boolean => {
    return this.props.state === 'INCORRECT' && this.state.toggleAnswers === true;
  }
}

export default ExerciseRenderer;
