import { AnyAction } from "redux";
import { 
  SPLASH_SET_VISIBILITY,
  SPLASH_MODAL_VISIBILITY,
  DIALOG_SET_VISIBILITY
} from '../../constants';

export const removeSplash = (): AnyAction => ({ type: SPLASH_SET_VISIBILITY, visible: false });
export const showSplash = (): AnyAction => ({ type: SPLASH_SET_VISIBILITY, visible: true });

export const hideModal = (): AnyAction => ({ type: SPLASH_MODAL_VISIBILITY, modalOpen: false });
export const showModal = (): AnyAction => ({ type: SPLASH_MODAL_VISIBILITY, modalOpen: true });

export const showDialog = (): AnyAction => ({ type: DIALOG_SET_VISIBILITY, dialogOpen: true });
export const hideDialog = (): AnyAction => ({ type: DIALOG_SET_VISIBILITY, dialogOpen: false });