import { put, call, takeEvery, delay } from "typed-redux-saga";
import axios from "axios";

import { networkConnected, networkDisonnected } from "../network/actions";
import {
  NETWORK_CHECK_CONNECTION,
  NETWORK_DISCONNECTED_DELAYED,
} from "../../constants";

const DISCONNECT_DELAY = 2000;

function checkFetch() {
  return axios({
    method: "get",
    url: `/hc`,
  });
}

export function* checkNetworkConnectionSaga() {
  try {
    yield* call(checkFetch);
    yield* put(networkConnected());
  } catch (e) {
    yield* put(networkDisonnected(e as Error));
  }
}

function* delayedCheckNetworkConnectionSaga() {
  yield* delay(DISCONNECT_DELAY);
  yield* checkNetworkConnectionSaga();
}

/**
 * Watcher saga to handle network error actions
 */
export function* watchNetworCheckConnectionSaga() {
  yield* takeEvery(NETWORK_CHECK_CONNECTION, checkNetworkConnectionSaga);
}

/**
 * Watcher saga to handle network error actions
 */
export function* watchDelayedNetworkDisconnectedSaga() {
  yield* takeEvery(
    NETWORK_DISCONNECTED_DELAYED,
    delayedCheckNetworkConnectionSaga
  );
}
