import * as React from "react";
import { StyledChartCell } from "../StyledChart";
import { ChartCellType } from "../types";
import {
  StyledStatusDot,
  StyledChartInput,
  StyledMirrorSpan,
  StyledChartCaret,
  StyledInputContainer,
} from "./StyledChartInput";
import { Check, X } from "react-feather";
import { VirtualKeyboard } from "../../../../VirtualKeyboard/VirtualKeyboard";
import { KeyboardData } from "../../../../VirtualKeyboard/types";
import {
  getKeyboardLayout,
  getKeyboardPosition,
  selectText,
  updateNumpadValue,
} from "../../../../VirtualKeyboard/helpers";
import { cellColumnSizes, cellRowSizes } from "../cellSizes";

interface Props {
  cell: ChartCellType;
  correctedState: string;
  disabled: boolean;
  keyboardData: KeyboardData | undefined;
  isTouchDevice: boolean;
  showBorder: boolean;
  chartType: string;
  index: number;
  callback: (param1: string, param2: string) => void;
}

export const ChartInput = ({
  cell,
  correctedState,
  callback,
  disabled,
  isTouchDevice,
  keyboardData,
  showBorder,
  chartType,
  index,
}: Props) => {
  const [showNumpad, setShowNumpad] = React.useState<boolean>(false);
  const [caretMargin, setCaretMargin] = React.useState<number | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  /**
   * This is used to get the with of the text in the input-element
   * for positioning virtual keyboard caret
   */
  const mirrorRef = React.useRef<HTMLSpanElement>(null);

  const keyboardPosition = getKeyboardPosition();
  const keyboardLayout = keyboardData
    ? getKeyboardLayout(keyboardData)
    : undefined;

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    callback(cell.id, e.currentTarget.value);
  };

  const numpadCallback = (value: string) => {
    const updatedValue = updateNumpadValue(inputRef, value, cell.value);
    if (updatedValue !== undefined) {
      callback(cell.id, updatedValue);
    }
  };

  const handleOnFocus = () => {
    setShowNumpad(true);
  };

  const handleOnBlur = () => {
    setShowNumpad(false);
    if (window) {
      const selectedElement = window.getSelection();
      selectedElement && selectedElement.empty();
    }
  };

  React.useEffect(() => {
    const getCaretMargin = () =>
      mirrorRef.current ? Math.min(mirrorRef.current.clientWidth) / 2 : null;

    setCaretMargin(getCaretMargin());
  }, [cell.value, mirrorRef, setCaretMargin]);

  return (
    <>
      <StyledChartCell
        chartType={chartType}
        index={index}
        minHeight={cellRowSizes[cell.row]}
        minWidth={cellColumnSizes[cell.column]}
        correctedState={correctedState}
        color={cell.color}
        showBorder={showBorder}
        borderBottom={cell.borderBottom}
        borderRight={cell.borderRight}
      >
        <StyledInputContainer chartType={chartType}>
          {isTouchDevice && showNumpad && (
            <StyledChartCaret margin={caretMargin} />
          )}
          <StyledChartInput
            index={index}
            correctedState={correctedState}
            chartType={chartType}
            ref={inputRef}
            disabled={disabled}
            value={cell.value}
            onChange={handleOnChange}
            size={1}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            type="text"
            readOnly={isTouchDevice}
            aria-label="skrivfält"
            onMouseDown={selectText}
          />
          <StyledMirrorSpan ref={mirrorRef}>{cell.value}</StyledMirrorSpan>
        </StyledInputContainer>
        {correctedState === "INCORRECT" && (
          <StyledStatusDot color="#EC4C3F">
            <X color="#EC4C3F" size={30} strokeWidth={3} />
          </StyledStatusDot>
        )}
        {correctedState === "CORRECT" && (
          <StyledStatusDot color="#1FC84C">
            <Check color="#1FC84C" size={30} strokeWidth={3} />
          </StyledStatusDot>
        )}
      </StyledChartCell>
      {isTouchDevice && showNumpad ? (
        <VirtualKeyboard
          buttonLayout={keyboardLayout}
          callback={numpadCallback}
          position={keyboardPosition}
        />
      ) : null}
    </>
  );
};
