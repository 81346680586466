import * as React from "react";
import {
  StyledToggleButton,
  StyledLabel,
  StyledIconContainer,
} from "./StyledToggleButton";

interface Props {
  callback: () => void;
  label: string;
  icon?: JSX.Element;
}

const ToggleButton: React.FC<Props> = ({ callback, label, icon }) => {
  const renderIcon = () =>
    icon ? <StyledIconContainer>{icon}</StyledIconContainer> : null;

  return (
    <StyledToggleButton onClick={callback}>
      <StyledLabel>{label}</StyledLabel>
      {renderIcon()}
    </StyledToggleButton>
  );
};
export default ToggleButton;
