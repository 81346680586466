import * as React from 'react';
import { StyledFractionCell } from './StyledFractionCell'


interface Props {
  borderBottom: boolean;
  hidden?: boolean;
  id?: string;
  callback?: (param: string) => void;
}


export const FractionCell: React.FC<Props> = (props) => {

  const handleOnClick = () => {
    if (props.callback && props.id) {
      props.callback(props.id);
    }
  }

  return (
    <StyledFractionCell
      onClick={handleOnClick}
      borderBottom={props.borderBottom}
    >
      {props.children}
    </StyledFractionCell>
  )

}
