import * as React from "react";
import { StyledColumnContent, StyledHelpHeading } from "../StyledHelp";
import { HelpData } from "../../../../views/exercise/api/types";
import { renderSoundWrappedElement } from "../Help";

interface Props {
  data: HelpData;
}

export const About: React.FC<Props> = ({ data }) => {
  return (
    <StyledColumnContent>
      <StyledHelpHeading>{data.title}</StyledHelpHeading>
      {renderSoundWrappedElement(data)}
    </StyledColumnContent>
  );
};
