export const BLANKS: string = 'BLANKS';
export const TEXT: string = 'TEXT';
export const MULTIPLE_CHOICE: string = 'MULTIPLE_CHOICE';
export const ORDERED: string = 'ordered';
export const UNORDERED: string = 'unordered';
export const PARKING: string = 'parking';
export const SHUFFLED: string = 'shuffled';

export const EXTRA_SMALL: string = 'EXTRASMALL';
export const SMALL: string = 'SMALL';
export const MEDIUM: string = 'MEDIUM';
export const LARGE: string = 'LARGE';

export const S: string = 'S';
export const M: string = 'M';
export const L: string = 'L';
export const XL: string = 'XL';

export const TIME: string = 'time';
