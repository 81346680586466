import * as React from "react";
import { Check, X, RefreshCcw } from "react-feather";
import {
  StyledStatusDotWrapper,
  StyledStatusDotsWrapper,
  StyledStatusDot,
  StyledActiveDot,
} from "./StyledStatusDots";
import { CardStates } from "../../../views/exercise/api/types";
import { CorrectionBtnStates } from "../CorrectionButton";

interface StatusDotsProps {
  dotStates: CardStates;
  activeDot: number;
}

interface StatusColors {
  [key: string]: string;
}

export const stateColors: StatusColors = {
  [CorrectionBtnStates.DEFAULT]: "#277575",
  [CorrectionBtnStates.INCORRECT]: "red",
  [CorrectionBtnStates.CORRECT]: "green",
  [CorrectionBtnStates.TRY_AGAIN]: "orange",
};

const StatusDots: React.FC<StatusDotsProps> = ({
  dotStates,
  activeDot,
}): JSX.Element => {
  const renderStatusDot = (
    state: string,
    isActive: boolean
  ): JSX.Element | null => {
    const { DEFAULT, CORRECT, INCORRECT, TRY_AGAIN } = CorrectionBtnStates;

    if (isActive && state === DEFAULT) {
      return <StyledActiveDot />;
    } else if (state === CORRECT) {
      return <Check color={stateColors[state]} />;
    } else if (state === INCORRECT) {
      return <X color={stateColors[state]} />;
    } else if (state === TRY_AGAIN) {
      return <RefreshCcw color={stateColors[state]} />;
    }

    return null;
  };

  const renderStatusDots = (): JSX.Element[] => {
    return Object.keys(dotStates)
      .reverse()
      .map((exerciseId: any) => {
        const state = dotStates[exerciseId].state;
        const isActive = parseInt(exerciseId, 10) === activeDot;

        return (
          <StyledStatusDotWrapper
            key={exerciseId}
            dotState={state}
            isActive={isActive}
          >
            <StyledStatusDot>
              {renderStatusDot(state, isActive)}
            </StyledStatusDot>
          </StyledStatusDotWrapper>
        );
      });
  };

  return (
    <StyledStatusDotsWrapper>{renderStatusDots()}</StyledStatusDotsWrapper>
  );
};

export default StatusDots;
