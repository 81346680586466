interface CellRowSize {
  [key: string]: number;
}

export const cellRowSizes: CellRowSize = {
  S: 2.188,
  M: 4.375,
  L: 6.25,
  XL: 8,
};

export const cellColumnSizes: CellRowSize = {
  S: 2.188,
  M: 3.125,
  L: 6.25,
  XL: 8,
};
