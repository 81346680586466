import { PRIMARY_SCHOOL } from "../../shared/constants";
import { Themes } from "../profile/api/types";

const primarySchool = (isVertical: boolean, withEffect: boolean) => {
  if (isVertical) {
    return withEffect
      ? "https://media.studentlitteratur.se/image/mr/story/images/background_attic_1-3_effects_vertical.png"
      : "https://media.studentlitteratur.se/image/mr/story/images/background_attic_1-3_vertical.jpg";
  }
  return withEffect
    ? "https://media.studentlitteratur.se/image/mr/story/images/background_attic_1-3_effects.png"
    : "https://media.studentlitteratur.se/image/mr/story/images/background_attic_1-3_tight.jpg";
};

const middleSchool = (isVertical: boolean, withEffect: boolean) => {
  if (isVertical) {
    return withEffect
      ? "https://media.studentlitteratur.se/image/mr/story/images/background_attic_4-6_vertical_effects.png"
      : "https://media.studentlitteratur.se/image/mr/story/images/background_attic_4-6_vertical.jpg";
  }
  return withEffect
    ? "https://media.studentlitteratur.se/image/mr/story/images/background_attic_4-6_effects.png"
    : "https://media.studentlitteratur.se/image/mr/story/images/background_attic_4-6.jpg";
};

export const getAtticBackgroundImage = (
  theme: Themes | undefined,
  withEffect: boolean,
  isVertical: boolean
) =>
  theme === PRIMARY_SCHOOL
    ? primarySchool(isVertical, withEffect)
    : middleSchool(isVertical, withEffect);
