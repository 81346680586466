import styled from 'styled-components';

interface CellProps {
  borderBottom?: boolean;
  clickable?: boolean;
  offsetCell?: boolean;
  fillerCell?: boolean;
}

export const StyledCell = styled.div<CellProps>`
  position: relative;
  height: ${props => props.fillerCell ? '81px' : 'auto'};
  margin-top: ${props => props.offsetCell ? '-20px' : '0px'};
  width: 100%;
  background-color: #f7fafa;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.clickable ? 'pointer' : 'auto'};
  ${props => props.borderBottom && 'border-bottom: 2px solid #1D7170'};
`;

export const StyledCrossLine = styled.div`
  position: absolute;
  width: 2px;
  height: 90%;
  background-color: #277575;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(135deg);
`;

export const StyledSmallCrossLine = styled(StyledCrossLine)`
  width: 3px;
  height: 50%;
  transform: translate(-50%, 0%) rotate(135deg);
`;