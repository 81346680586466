import * as React from "react";
import nextArrowIcon from "../../../assets/icons/next-arrow.svg";
import {
  StyledBtnLabel,
  StyledBtnState,
  StyledContinueState,
  StyledCorrBtn
} from "./StyledCorrectionButton";
import { CorrBtnState } from "../../../external/EddaXcomp/src/components/CorrectionButton/CorrectionButtonTypes";

interface Props {
  state: CorrBtnState;
  callback(): void
}

interface CorrBtnStatesConstants  {
  DEFAULT: CorrBtnState;
  CORRECT: CorrBtnState;
  INCORRECT: CorrBtnState;
  TRY_AGAIN: CorrBtnState;
  CONTINUE: CorrBtnState;
}

export interface BtnState {
  backgroundColor: string;
  label: string;
}

export interface BtnStates {
  DEFAULT: BtnState;
  CORRECT: BtnState;
  INCORRECT: BtnState;
  TRY_AGAIN: BtnState;
  CONTINUE: BtnState;
  [key: string]: BtnState;
}

export const CorrectionBtnStates: CorrBtnStatesConstants = {
  DEFAULT: 'DEFAULT',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  TRY_AGAIN: 'TRY_AGAIN',
  CONTINUE: 'CONTINUE'
};

export const btnLabels: BtnStates = {
  DEFAULT: {
    backgroundColor: '#277575',
    label: 'Rätta'
  },
  CORRECT: {
    backgroundColor: '#489030',
    label: 'Rätt'
  },
  INCORRECT: {
    backgroundColor: '#CD5C5C',
    label: 'Fel'
  },
  TRY_AGAIN: {
    backgroundColor: 'orange',
    label: 'Fel'
  },
  CONTINUE: {
    backgroundColor: '#277575',
    label: 'Fel'
  }
};

interface State {
  hover: boolean
}

class CorrectionButton extends React.PureComponent <Props, State> {
  constructor(props: Props){
    super(props);

    this.state = {
      hover: false
    }
  }

  public onHover = (): void => this.setState({ hover: true});

  public onLeave = (): void => this.setState({ hover: false});

  public render(): JSX.Element {

    return (
      <StyledCorrBtn
        backgroundColor={btnLabels[this.props.state].backgroundColor}
        hover={this.state.hover}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onLeave}
        onClick={this.props.callback}
        state={this.props.state}
        aria-label="Rätta"
      >
          { this.getLabel() }
        <StyledContinueState>
          <img src={nextArrowIcon} alt="Next arrow"/>
        </StyledContinueState>
      </StyledCorrBtn>
    )
  }

  private renderLabel = ( state: string, label:string ): JSX.Element => (
    <StyledBtnState state={state}>
      <StyledBtnLabel>
        { label }
      </StyledBtnLabel>
    </StyledBtnState>
  );

  private getLabel = (): (JSX.Element | null) => {
    const { CORRECT, INCORRECT, TRY_AGAIN } = CorrectionBtnStates;

    switch ( this.props.state ){
      case CORRECT:
        return this.renderLabel(this.props.state, 'Rätt svar. Fortsätt!');

      case INCORRECT:
        return this.renderLabel(this.props.state, 'Fel svar. Nästa fråga!');

      case TRY_AGAIN:
        return this.renderLabel(this.props.state, 'Fel svar. Försök igen!');

      default:
        break;
    }
    return null;
  };
}

export default CorrectionButton;
