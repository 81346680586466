import * as qs from "query-string";

/**
 * Removes the querystring part from the URL by replacing
 * the path with just the pathname
 */
export const resetPreviewedURL = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

/**
 * Fetches the querystring part of the URL.
 * Checks if the part is equal to onboarding=true.
 *
 * If onboarding is not completed, we consider this not to be a preview.
 *
 * We also clean up the URL if it contains a querystring.
 *
 * @returns {boolean}
 */
export const isPreviewed = (shouldResetPreviewURL: boolean): boolean => {
  const queryParams = qs.parse(window.location.search);
  const onboarding = queryParams && queryParams.onboarding;

  if (shouldResetPreviewURL) {
    resetPreviewedURL();
  }

  return onboarding === "true";
};

export enum SelectedState {
  NOT_SELECTED = "not_selected",
  YES = "yes",
  NO = "no",
}
