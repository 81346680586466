import * as React from "react";
import {
  StyledResSlideContainer,
  StyledResSlidePoints,
  StyledResSlideText,
  StyledResSlideTextWrapper,
  StyledResSlideTitle,
  StyledProgressBarContainer,
  StyledImgContainer,
} from "./StyledResultSlide";
import { ArrowButton } from "../Button/ArrowButton";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { AudioComponent } from "../Audio";
import { StyledExeContentContainer } from "../../../views/exercise/StyledExercise";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../views/profile/api/selectors";

interface PrimarySchoolResultSlideProps {
  img_url: string;
  title: string;
  text: string;
  points: number;
  progress_value: number;
  audio_url: string;
  callback: () => void;
}

const PrimarySchoolResultSlide = ({
  img_url,
  title,
  text,
  points,
  progress_value,
  audio_url,
  callback,
}: PrimarySchoolResultSlideProps) => {
  const theme = useSelector(selectTheme);

  const nextButtonCallback = () => callback();

  return (
    <StyledExeContentContainer>
      <StyledResSlideContainer theme={theme}>
        <StyledImgContainer imgUrl={img_url} />
        <AudioComponent audioUrl={audio_url} />
        <StyledResSlideTextWrapper>
          <StyledResSlideTitle>{title}</StyledResSlideTitle>
        </StyledResSlideTextWrapper>

        <StyledResSlideTextWrapper marginTop="6px" marginBottom="25px">
          <StyledResSlideText>{text}</StyledResSlideText>
        </StyledResSlideTextWrapper>

        <StyledProgressBarContainer>
          <ProgressBar
            overrideStyles={{ padding: "4px", height: "32px" }}
            progress={progress_value}
          />
        </StyledProgressBarContainer>

        <StyledResSlideTextWrapper marginBottom="32px">
          <StyledResSlidePoints>{points} Poäng</StyledResSlidePoints>
        </StyledResSlideTextWrapper>

        <div>
          <ArrowButton onClick={nextButtonCallback} width="64px" />
        </div>
      </StyledResSlideContainer>
    </StyledExeContentContainer>
  );
};

export default PrimarySchoolResultSlide;
