import * as React from "react";
import {
  StyledResSlideTitle,
  StyledResSlideText,
} from "../ResultSlide/StyledResultSlide";
import { ArrowButton } from "../Button/ArrowButton";
import {
  StyledItemImg,
  StyledItemName,
  StyledItemWrapper,
  StyledItemSlideContainer,
  StyledItemText,
  StyledItemImgLarge,
  StyledLargeImgCard,
  StyledAdventureDetailsWrapper,
  StyledTitleWrapper,
  StyledTrophyWrapper,
  StyledNavigatorCount,
  StyledFeedbackWrapper,
} from "./StyledMarkerItemSlide";
import { ViewSlideState } from "../../../views/exercise/api/types";
import Markers from "../Markers/Markers";
import { StyledExeContentContainer } from "../../../views/exercise/StyledExercise";
import { viewSlideStates } from "../../../views/exercise";
import { Modal } from "../Modal";
import { Themes } from "../../../views/profile/api/types";
import { PRIMARY_SCHOOL, MIDDLE_SCHOOL } from "../../constants";
import { AudioComponent } from "../Audio";
import { ReactComponent as Trophy } from "../../../assets/icons/trophy_icon.svg";
import { Marker, MarkerHeadGroup } from "../../../views/markers/api/types";
import {
  getAllMarkerHeadgroupLabels,
  getAllProblemSpecificMarkers,
  getFirstNonNotifiedProblemSpecificMarkerItem,
} from "../Markers/helpers";
import MarkerImageMiddleSchool from "../Markers/MarkerImageMiddleSchool";
export interface MarkerItemSlideProps {
  data: MarkerItemSlideData;
  viewSlideState: ViewSlideState;
  theme: Themes;
  callback(): void;
}

export interface MarkerItemSlideData {
  type: string;
  points: number;
  progress_value: number;
  title: string;
  text: string;
  audio_url: string;
  adventure_image_url: string;
  new_challenge?: boolean;
  adventure_title: string;
  adventure_problem_title: string;
  marker_head_groups: MarkerHeadGroup[];
}

const MarkerItemSlide = (props: MarkerItemSlideProps) => {
  const [isShowItemModal, setShowItemModal] = React.useState(false);

  /**
   * Show item modal
   */
  const showItemModal = () => setShowItemModal(true);

  /**
   * Hide item modal
   */
  const hideItemModal = () => setShowItemModal(false);

  const renderItemModal = (item: Marker | undefined) =>
    props.viewSlideState === viewSlideStates.IN_VIEW &&
    item &&
    item.obtained && (
      <Modal show={isShowItemModal} backdropClick={hideItemModal}>
        <StyledLargeImgCard>
          <StyledItemImgLarge imgUrl={item.thumbnail_url} />
        </StyledLargeImgCard>
      </Modal>
    );

  const renderItem = () => {
    const item = getFirstNonNotifiedProblemSpecificMarkerItem(
      props.data.marker_head_groups
    );
    return (
      item && (
        <StyledItemWrapper>
          {!item.obtained && <StyledItemText>Du får snart</StyledItemText>}
          <StyledItemImg
            viewSlideState={props.viewSlideState}
            obtained={item.obtained}
            imgUrl={item.thumbnail_url}
            onClick={showItemModal}
          />
          <StyledItemName>{item.label}</StyledItemName>
        </StyledItemWrapper>
      )
    );
  };

  const renderTrophy = () => (
    <StyledTrophyWrapper>
      <Trophy />
      <StyledTitleWrapper>
        <StyledResSlideTitle>
          {`Poäng: ${props.data.points}`}
        </StyledResSlideTitle>
      </StyledTitleWrapper>
    </StyledTrophyWrapper>
  );

  const renderMarkersGroup = () => {
    const item = getFirstNonNotifiedProblemSpecificMarkerItem(
      props.data.marker_head_groups
    );
    const markers = getAllProblemSpecificMarkers(props.data.marker_head_groups);
    const data =
      item && props.theme === PRIMARY_SCHOOL ? item.sub_rewards : markers;
    const headGroupLabels = getAllMarkerHeadgroupLabels(
      props.data.marker_head_groups
    );

    return (
      <React.Fragment>
        <Markers
          withMargin
          theme={props.theme}
          viewSlideState={props.viewSlideState}
          data={data as Marker[]}
          headGroupLabels={headGroupLabels}
          shadow
        />

        {props.theme === PRIMARY_SCHOOL && renderItemModal(item)}
      </React.Fragment>
    );
  };

  return (
    <StyledExeContentContainer>
      <StyledItemSlideContainer theme={props.theme}>
        {props.theme === PRIMARY_SCHOOL && (
          <StyledFeedbackWrapper>
            <AudioComponent audioUrl={props.data.audio_url} />
            <StyledResSlideTitle>{props.data.title}</StyledResSlideTitle>
            <StyledResSlideText>{props.data.text}</StyledResSlideText>
          </StyledFeedbackWrapper>
        )}
        {props.theme === MIDDLE_SCHOOL && renderTrophy()}
        {props.theme === MIDDLE_SCHOOL && (
          <StyledAdventureDetailsWrapper>
            <MarkerImageMiddleSchool
              image={props.data.adventure_image_url}
              adventure
              viewSlideState={props.viewSlideState}
            />
            <StyledTitleWrapper>
              <StyledResSlideTitle>
                {props.data.adventure_problem_title}
              </StyledResSlideTitle>
            </StyledTitleWrapper>
          </StyledAdventureDetailsWrapper>
        )}
        {renderMarkersGroup()}
        {props.theme === PRIMARY_SCHOOL && renderItem()}
        {props.theme === MIDDLE_SCHOOL && (
          <StyledFeedbackWrapper>
            <AudioComponent audioUrl={props.data.audio_url} />
            <StyledResSlideTitle>{props.data.title}</StyledResSlideTitle>
            <StyledResSlideText>{props.data.text}</StyledResSlideText>
          </StyledFeedbackWrapper>
        )}
        {props.theme === PRIMARY_SCHOOL && (
          <ArrowButton onClick={props.callback} width="64px" />
        )}
        {props.theme === MIDDLE_SCHOOL && (
          <StyledNavigatorCount onClick={props.callback}>
            Räkna
          </StyledNavigatorCount>
        )}
      </StyledItemSlideContainer>
    </StyledExeContentContainer>
  );
};

export default MarkerItemSlide;
