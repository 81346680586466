import * as React from "react";
import {
  StyledTextContainer,
  StyledTextLine,
  StyledHeading,
  StyledNewLine,
} from "./StyledXcText";
import { StyledInputContainer, StyledCard } from "../XcBlanks/StyledBlanks";
import { M } from "../../../../constants";
import AutoLaTeX from "react-autolatex";
import HtmlRenderer from "../../../../../../../shared/components/HtmlRenderer";

export interface TextProps {
  text: string;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  color?: string;
}
interface CompProps {
  textSize: string;
}

export const XcText: React.FC<TextProps> = ({
  text,
  fontSize,
  fontWeight,
  fontFamily,
  color,
}) => {
  /**
   * Renders a card with a single value
   * @param {string} value
   * @param {number} index
   * @returns {JSX.Element}
   */
  const renderValueCard = (value: string, index: number): JSX.Element => {
    const valueText = value.substring(1, value.indexOf("]"));
    const textElem = valueText.replace(/ /g, "\u00a0");
    return (
      <StyledInputContainer textSize={M} key={`text_card_container_${index}`}>
        <HtmlRenderer<CompProps>
          key={`text_card_${index}`}
          component={StyledCard}
          html={textElem}
          textSize={M}
        />
      </StyledInputContainer>
    );
  };

  /**
   * Modifies text
   * @returns {JSX.Element}
   */
  const modifyText = (): JSX.Element[] => {
    const modifiedString = text.replace(/[[]/g, "#[").replace(/[\]]/g, "]#");
    const modifiedStringArray = modifiedString
      .split("#")
      .map((stringValue: string) => {
        if (stringValue.includes("<br>")) {
          return stringValue.split(/(<br>+)/);
        } else {
          return stringValue;
        }
      });
    // Remove empty and flatten back to one dimensional array
    const strings = modifiedStringArray
      .reduce((acc: string[], val) => acc.concat(val), [])
      .filter((v) => v !== ""); /* Array.flat() does not work in Edge*/

    return strings.map((value: string, index: number) => {
      return value.includes("]")
        ? renderValueCard(value, index)
        : renderParagraph(value, index);
    });
  };

  /**
   * Renders text values on cards or text
   * @returns {string | JSX.Element}
   */
  const renderText = (): JSX.Element =>
    text.includes("[") || text.includes("<br>") ? (
      <StyledTextLine>{modifyText()}</StyledTextLine>
    ) : (
      renderParagraph(text)
    );

  const renderParagraph = (paragraph: string, index: number = 0): JSX.Element =>
    paragraph.includes("<br>") ? (
      <StyledNewLine key={`line${index}`} />
    ) : (
      <StyledHeading
        className="soundContainer"
        key={`paragraph${index}`}
        style={{
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeight: fontWeight,
          color,
        }}
      >
        <AutoLaTeX>{paragraph}</AutoLaTeX>
      </StyledHeading>
    );

  return <StyledTextContainer>{renderText()}</StyledTextContainer>;
};

export default XcText;
