/**
 * Ugly hack to check if version is pre ios 13.
 */
export const preIOS13 = () => {
  return !(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
};

export const isSafari = () => {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return /Safari/i.test(uA) && /Apple Computer/.test(vendor);
};

export const isTouchDevice = () => {
  if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
    return true;
  }
  return false;
};

export const isIOSDevice = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 and 14 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const isMobileDevice = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
    isIOSDevice()
  ) {
    return true;
  } else {
    return false;
  }
};
