import { PRIMARY_SCHOOL } from "../../shared/constants";
import { Themes } from "../profile/api/types";
import { LinksDataType } from "./types";

const primarySchoolLinks: LinksDataType[] = [
  {
    offsetX: "414",
    offsetY: "825",
    label: "ÄVENTYR",
    route: "ADVENTURE_PICKER_ROUTE",
  },
  {
    offsetX: "930",
    offsetY: "569",
    label: "FIGURER",
    route: "CHARACTERS_ROUTE",
  },
  {
    offsetX: "1330",
    offsetY: "415",
    label: "MÄRKEN",
    route: "MARKERS_ROUTE",
  },
];

const primarySchoolLinksVertical: LinksDataType[] = [
  {
    offsetX: "619",
    offsetY: "842",
    label: "ÄVENTYR",
    route: "ADVENTURE_PICKER_ROUTE",
  },
  {
    offsetX: "997",
    offsetY: "565",
    label: "FIGURER",
    route: "CHARACTERS_ROUTE",
  },
  {
    offsetX: "759",
    offsetY: "272",
    label: "MÄRKEN",
    route: "MARKERS_ROUTE",
  },
];

const middleSchoolLinks: LinksDataType[] = [
  {
    offsetX: "485",
    offsetY: "853",
    label: "ÄVENTYR",
    route: "ADVENTURE_PICKER_ROUTE",
  },
  {
    offsetX: "1667",
    offsetY: "557",
    label: "MÄRKEN",
    route: "MARKERS_ROUTE",
  },
];

const middleSchoolLinksVertical: LinksDataType[] = [
  {
    offsetX: "543",
    offsetY: "853",
    label: "ÄVENTYR",
    route: "ADVENTURE_PICKER_ROUTE",
  },
  {
    offsetX: "1100",
    offsetY: "694",
    label: "MÄRKEN",
    route: "MARKERS_ROUTE",
  },
];

export const getAtticLink = (
  theme: Themes | undefined,
  isVertical: boolean
) => {
  if (theme === PRIMARY_SCHOOL) {
    return isVertical ? primarySchoolLinksVertical : primarySchoolLinks;
  }
  return isVertical ? middleSchoolLinksVertical : middleSchoolLinks;
};
