import * as React from "react";
import {
  StyledHelpColumn,
  StyledColumnContent,
  StyledHelpHeading,
  StyledSideImg,
  StyledTipsImageContainer,
} from "../StyledHelp";
import { HelpData } from "../../../../views/exercise/api/types";
import { renderSoundWrappedElement } from "../Help";
import { isSafari } from "../../../platform/Platform";
import { mentorImgSrc } from "../HelpRenderer";
import {
  XcImage,
  ImageProps,
} from "../../../../external/EddaXcomp/src/components/BlockLoader/ExerciseComponents";

interface Props {
  data: HelpData;
}

const renderMentor = () => (
  <StyledColumnContent topAligned={isSafari()}>
    <StyledSideImg src={mentorImgSrc} alt="Mentorn" />
  </StyledColumnContent>
);

const renderHelpImage = (src: string) => (
  <StyledColumnContent height={"100%"} topAligned={isSafari()}>
    <StyledTipsImageContainer>
      <XcImage image={{ src: src }} />
    </StyledTipsImageContainer>
  </StyledColumnContent>
);

const renderHelpSVGImage = ({ icons, format }: ImageProps) => (
  <StyledColumnContent height={"100%"} topAligned={isSafari()}>
    <XcImage icons={icons} format={format} position={"relative"} />
  </StyledColumnContent>
);

export const Tips: React.FC<Props> = ({ data }) => {
  const { image, assets } = data;

  return (
    <React.Fragment>
      <StyledHelpColumn>
        <StyledColumnContent>
          <StyledHelpHeading>{data.title}</StyledHelpHeading>
          {renderSoundWrappedElement(data)}
        </StyledColumnContent>
      </StyledHelpColumn>
      <StyledHelpColumn>
        {image && image.src
          ? renderHelpImage(image.src)
          : assets
          ? renderHelpSVGImage(assets)
          : renderMentor()}
      </StyledHelpColumn>
    </React.Fragment>
  );
};
