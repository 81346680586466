import * as React from "react";
import { connect } from "react-redux";
import { TopBar, TopBarColumn } from "../Topbar";
import { TEST_ROUTE } from "../../../views/routes";
import { RootState } from "../../../store";
import { Profile } from "../../../views/profile/api/types";
import { HomeButton } from "../Button/HomeButton";
import { ProfileItem } from "../ProfileItem";
import { StyledNavigatorTest } from "./StyledNavBar";
import testButtonSvg from "../../../assets/icons/test_button.svg";

export interface NavBarProps {
  centerPiece?: JSX.Element | null;
  position?: string;
  progressBarValue?: number;
  hideHomeBtn?: boolean;
  children?: React.ReactNode;
}

export interface ReduxProps {
  profile: Profile;
}

export type OwnProps = NavBarProps & ReduxProps;

const NavBarComponent: React.FC<OwnProps> = (props) => {
  const icon = <img src={testButtonSvg} alt="" />;

  return (
    <TopBar position={props.position}>
      <TopBarColumn alignment="left">
        {props.hideHomeBtn || <HomeButton />}
        {props.children}
      </TopBarColumn>

      <TopBarColumn alignment="center">{props.centerPiece}</TopBarColumn>

      <TopBarColumn alignment="right">
        {props.profile.is_test && (
          <StyledNavigatorTest route={TEST_ROUTE} icon={icon} />
        )}
        <ProfileItem
          name={props.profile.first_name}
          picture={props.profile.picture}
          backPlate={true}
        />
      </TopBarColumn>
    </TopBar>
  );
};

const mapStateToProps = (state: RootState): ReduxProps => ({
  profile: state.profile.profile,
});

export const NavBar = connect(mapStateToProps, {})(NavBarComponent);
