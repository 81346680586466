import { call, put, select } from "typed-redux-saga";
import { ACCESS_API_ACTIONS } from "./actions";
import { getToken } from "../../shared/store/auth";
import { getProductId, getActivationCode, getHasAccess } from "../Application";
import { fetchAccess, expiryNoticeReadRequest } from "./requests";
import { delay, takeLatest } from "typed-redux-saga";
import {
  DIALOG_SET_VISIBILITY,
  EXPIRY_NOTICE_READ_SUCCESS,
  EXPIRY_NOTICE_READ_FAILURE,
} from "../../shared/constants";
import { showSplash } from "../../shared/store/splash/actions";

/**
 * Worker saga to check if user has access to the product
 */
export function* hasAccessSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchAccess, token, productId);
    const access = response.data;

    // dispatch a success action to the store with the access
    yield* put({ type: ACCESS_API_ACTIONS.READ.SUCCESS, access });
  } catch (error) {
    yield* put({ type: ACCESS_API_ACTIONS.READ.FAILURE, error });
  }
}

/**
 * Worker saga to inform that the user has read the expiry notice
 */
function* expiryNoticeReadSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const activationCode = yield* select(getActivationCode);

    yield* call(expiryNoticeReadRequest, token, productId, activationCode);
    yield* put({ type: EXPIRY_NOTICE_READ_SUCCESS });
  } catch (error) {
    yield* put({ type: EXPIRY_NOTICE_READ_FAILURE, error });
  }
}

export function* watchExpiryNoticeRead() {
  yield* takeLatest(DIALOG_SET_VISIBILITY, expiryNoticeReadSaga);
}

export function* fetchAccessSaga() {
  const hasAccess = yield* select(getHasAccess);

  if (!hasAccess) {
    yield* put(showSplash());
    return;
  }

  yield* delay(300000);
  yield* call(hasAccessSaga);
}

export function* watchFetchAccessSaga() {
  yield* takeLatest(ACCESS_API_ACTIONS.READ.SUCCESS, fetchAccessSaga);
}
