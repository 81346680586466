import styled from 'styled-components';

export const StyledSmallScreenBlocker = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #1d1d1b;
`;

export const StyledContentContainer = styled.div`
  padding: 12px;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  svg {
    max-width: 430px;
    width: 100%;
    height: auto;
  }
`;

export const StyledMessageContainer = styled.h3`
  text-align: center;
  max-width: 400px
  font-size: 24px;
  font-weight: 300;
  color: #FFFFFF;
`;