import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { TouchTransition } from "react-dnd-multi-backend";

/**
 * Polyfill for missing elementsFromPoint function
 */
function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
  return dropTargets.filter((t: any) => {
    const rect = t.getBoundingClientRect();

    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

// Check if browser has elementsFromPoint function.
const hasNative = document && document.elementsFromPoint;

const backendOptions = {
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
  enableMouseEvents: true,
};

export const HTML5toTouch = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
    },
    {
      id: "touch",
      backend: TouchBackend, // Note that you can call your backends with options
      options: backendOptions,
      preview: true,
      transition: TouchTransition,
    },
  ],
};
