import * as React from 'react';
import { ReactComponent as IconDivision } from "../../assets/icons/icon_division.svg";
import { ReactComponent as IconMultiplikation } from "../../assets/icons/icon_multiplikation.svg";
import { ReactComponent as IconBackspace } from "../../assets/icons/icon_undo.svg";
import { ReactComponent as IconComma } from "../../assets/icons/icon_comma_key.svg";

export const keyboardIcons: Record<string, JSX.Element> = {
  'IconDivision': <IconDivision width={'20px'} />,
  'IconMultiplikation': <IconMultiplikation fill={'#FFFFFF'} />,
  'IconUndo': <IconBackspace width={'22px'} fill={'#277575'} />,
  'IconComma': <IconComma width={'12px'} fill={'#277575'} />
}