import styled from "styled-components";
import { DropDownButtonProps } from './DropDownButton';

interface IconProps {
  flipped?: boolean
}

const getBorderStyle = (borderStyle?: string): string | null => borderStyle || '6px solid';

export const StyledDropDownButton = styled.button<DropDownButtonProps>`
  border: none;
  border-bottom: ${ ({ open, overrideStyles }) => open ? 'border-bottom: solid 6px #F9F6F6' : getBorderStyle(overrideStyles && overrideStyles.borderBottom)};
  border-color: ${props => props.theme.themePrimary};
  border-radius: 0;
  box-sizing: border-box;
  width: ${ ({ overrideStyles }) => overrideStyles ? overrideStyles.width : '335px'};
  min-width: 140px;
  height: ${ ({overrideStyles}) => overrideStyles ? overrideStyles.height : '56px'};
  background-color: #F9F6F6;
  color: ${props => props.theme.themePrimary};
  font-weight: 700;
  font-family: inherit;
  padding: 0 12px 0 15px;
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  transition: border 200ms ease;
  overflow: hidden;
  z-index: 0;

  &:focus {
    outline: none;
  }
`;

export const StyledDropDownButtonLabel = styled.span<DropDownButtonProps>`
  font-size: 26px;
  font-size: ${props => props.overrideStyles ? props.overrideStyles.fontSize : '26px'};
  letter-spacing: 0.32px;
  line-height: 34px;
  font-weight: 400;
  color: inherit;
  margin-right: auto;
`;

export const StyledDropDownButtonIcon = styled.img<IconProps>`
  transition: transform 200ms ease;
  transform: ${ props => props.flipped ? 'rotate(180deg)' : null};
`;