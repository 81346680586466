import { ASSIGNMENT_API_ACTIONS } from "./actions";
import { AssignmentState } from "./types";

const initialState: AssignmentState = {
  assignments: { current: null, previous: null }
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ASSIGNMENT_API_ACTIONS.READ.SUCCESS:
      return { assignments: action.assignments };
    default:
      return state;
  }
}
