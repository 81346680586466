import * as React from "react";
import {
  StyledResSlideContainer,
  StyledResSlideText,
  StyledResSlideTextWrapper,
  StyledResSlideTitle,
  StyledFeedback,
  StyledAtticRoute,
  StyledTitleWrapper,
  StyledTrophyWrapper,
  StyledNavigatorWrapper,
  StyledNavigatorsContainer,
  StyledNavigatorCount,
  StyledNavigatorTimeMachine,
} from "./StyledResultSlide";
import { AudioComponent } from "../Audio";
import { StyledExeContentContainer } from "../../../views/exercise/StyledExercise";
import { ReactComponent as Trophy } from "../../../assets/icons/trophy_icon.svg";
import { ReactComponent as TimeMachine } from "../../../assets/icons/timemachine_stars.svg";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../views/profile/api/selectors";
import { ATTIC_ROUTE } from "../../../views/routes";

interface MiddleSchoolResultSlideProps {
  title: string;
  text: string;
  points: number;
  audio_url: string;
  callback(): void;
}

const MiddleSchoolResultSlide = ({
  title,
  text,
  points,
  audio_url,
  callback,
}: MiddleSchoolResultSlideProps) => {
  const theme = useSelector(selectTheme);

  return (
    <StyledExeContentContainer>
      <StyledResSlideContainer theme={theme}>
        <StyledTrophyWrapper>
          <Trophy />
          <StyledTitleWrapper>
            <StyledResSlideTitle>{`Poäng: ${points}`}</StyledResSlideTitle>
          </StyledTitleWrapper>
        </StyledTrophyWrapper>
        <StyledFeedback>
          <AudioComponent audioUrl={audio_url} />
          <StyledResSlideTextWrapper marginTop="10px">
            <StyledResSlideTitle>{title}</StyledResSlideTitle>
          </StyledResSlideTextWrapper>

          <StyledResSlideTextWrapper marginTop="6px" marginBottom="25px">
            <StyledResSlideText>{text}</StyledResSlideText>
          </StyledResSlideTextWrapper>
        </StyledFeedback>

        <StyledAtticRoute>
          <StyledResSlideTextWrapper marginTop="6px" marginBottom="25px">
            <StyledResSlideTitle>
              För att samla äventyrsmärken behöver du välja ett äventyr:
            </StyledResSlideTitle>
          </StyledResSlideTextWrapper>
          <StyledNavigatorsContainer>
            <StyledNavigatorWrapper>
              <StyledNavigatorTimeMachine
                aria-label="Åk till vinden"
                route={ATTIC_ROUTE}
                circular
                icon={<TimeMachine />}
              />
              <StyledNavigatorCount onClick={callback}>
                Räkna
              </StyledNavigatorCount>
            </StyledNavigatorWrapper>
          </StyledNavigatorsContainer>
        </StyledAtticRoute>
      </StyledResSlideContainer>
    </StyledExeContentContainer>
  );
};

export default MiddleSchoolResultSlide;
