import { takeLatest, call, put, select } from "typed-redux-saga";
import { getToken } from "../../../shared/store/auth";
import { getProductId } from "../../../store/Application";
import { fetchMarkers } from "./requests";
import {
  GET_MARKERS_REQUEST,
  GET_MARKERS_REQUEST_SUCCESS,
  GET_MARKERS_REQUEST_FAILURE,
  ERROR_TYPES,
} from "../../../shared/constants";
import { addFlash } from "../../../shared/store/flasher/actions";

export function* watchGetMarkersSaga() {
  yield* takeLatest(GET_MARKERS_REQUEST, getMarkersSaga);
}

function* getMarkersSaga() {
  try {
    const token = yield* select(getToken);
    const productId = yield* select(getProductId);
    const response = yield* call(fetchMarkers, token, productId);
    const markers = response.data;

    yield* put({
      type: GET_MARKERS_REQUEST_SUCCESS,
      markers,
    });
  } catch (error) {
    yield* put(
      addFlash("Det uppstod ett fel vid hämtning av märken.", ERROR_TYPES.ERROR)
    );
    yield* put({ type: GET_MARKERS_REQUEST_FAILURE, error });
  }
}
