import {
  GET_MARKERS_REQUEST,
  GET_MARKERS_REQUEST_SUCCESS,
  GET_MARKERS_REQUEST_FAILURE,
} from "../../../shared/constants";
import { AnyAction } from "redux";

const initialState = {
  fetching: false,
  error: null,
  markers: [],
};

/**
 * Redux reducer for markers view
 * @param state current state
 * @param action incoming action
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_MARKERS_REQUEST:
      return { ...state, fetching: true, error: null };
    case GET_MARKERS_REQUEST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        markers: action.markers,
      };
    case GET_MARKERS_REQUEST_FAILURE:
      return { ...state, fetching: false, error: action.error };

    default:
      return state;
  }
}
