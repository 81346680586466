import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NavBar } from "../../shared/components/NavBar";
import { Sliders, BookOpen } from "react-feather";
import { RootState } from "../../store";
import {
  StyledTestWrapper,
  StyledTestCard,
  StyledTestContainer,
  StyledTestAnswersPagination,
  StyledTestColumn,
  StyledSectionHeading,
  StyledTestTableColumn,
  StyledIconContainer,
  StyledTestContentSection,
  StyledSettingsLabel,
  StyledButtonContainer,
  StyledTestContentRow,
  StyledInputLabel,
  StyledTextInput,
  StyledTestLi,
} from "./StyledTest";
import { Answer } from "./api/types";
import {
  fetchAnswers,
  answersPreviousPage,
  answersNextPage,
  updateGoalProgress,
  setSelectedGoal,
  setAbility,
  setGoalScore,
  fetchGoals,
  fetchGoalProgress,
} from "./api/actions";
import DropDown from "../../shared/components/DropDown";
import { DropDownItem } from "../../shared/components/DropDown/DropDown";
import { TestTable } from "./TestTable/TestTable";
import { ReactComponent as NextArrow } from "../../assets/icons/icon_arrow_slim.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/icon_arrow_slim.svg";
import { RoundButton } from "../../shared/components/Button/RoundButton";
import {
  StyledButtonText,
  StyledHelpButtonRack as StyledTabButtonRack,
  StyledButtonContainer as StyledTabButtonContainer,
} from "../../shared/components/Help/components/ButtonRack/StyledHelpButtonRack";
import NewTestDashboard from "./NewTestDashboard";

interface TestViewProps {
  pageIndex: number;
  answers: Answer[];
  goals: DropDownItem[];
  selectedGoal?: string;
  ability?: number;
  goalScore?: number;
  requestAnswers(): void;
  nextPage(): void;
  previousPage(): void;
  onUpdateGoalProgress(): void;
  requestGoals(): void;
  requestGoalProgress(): void;
  setSelectedGoal(goalRef: string): void;
  setAbility(ability: number): void;
  setGoalScore(goalScore: number): void;
}

interface TestViewState {
  activeTab: number;
}

class TestViewComponent extends React.PureComponent<TestViewProps> {
  public state: TestViewState = {
    activeTab: 0,
  };

  public componentDidMount() {
    this.props.requestAnswers();
    this.props.requestGoals();
    this.props.requestGoalProgress();
  }

  public setactiveTab = (id: number) => {
    this.setState({ activeTab: id });
  };

  public render() {
    const pageSize = 50;

    const paginationButtonStyles = {
      padding: "0",
      width: "40px",
      height: "40px",
      boxShadow: "none",
    };

    const dropDownStyles = {
      fontSize: "16px",
      height: "37px",
      borderBottom: "solid 3px",
      width: "100%",
      maxWidth: "100%",
    };

    return (
      <StyledTestWrapper
        backgroundImage={
          "https://media.studentlitteratur.se/image/mr/story/images/mr_symboler_bg_noise_tile.jpg"
        }
      >
        <NavBar />
        <StyledTestContainer>
          <StyledTestCard>
            {this.state.activeTab === 0
              ? this.renderSettings(dropDownStyles)
              : this.renderTable(pageSize, paginationButtonStyles)}
            {this.renderTabButtons()}
          </StyledTestCard>
        </StyledTestContainer>
      </StyledTestWrapper>
    );
  }

  private renderSettings = (dropDownStyles: {}) => {
    const {
      goals,
      onUpdateGoalProgress,
      ability,
      goalScore,
      selectedGoal,
    } = this.props;

    const currentGoalItem = goals.find((item) => item.value === selectedGoal);
    return (
      <StyledTestColumn>
        <NewTestDashboard />
        <StyledTestContentSection>
          <StyledSectionHeading>Innehåll</StyledSectionHeading>
          <StyledSettingsLabel>Mål</StyledSettingsLabel>
          <DropDown
            overrideStyles={{ ...dropDownStyles }}
            dropDownData={goals}
            defaultItem={currentGoalItem}
            onChange={this.onChangeGoal}
          />
          <StyledSettingsLabel>Ability</StyledSettingsLabel>
          <ul>
            <StyledTestLi>0 = Okänt</StyledTestLi>
            <StyledTestLi>1-30 = Lägst (endast lätt)</StyledTestLi>
            <StyledTestLi>31-50 = Låg (blandat lätt och medel)</StyledTestLi>
            <StyledTestLi>51-70 = Medel (endast medel)</StyledTestLi>
            <StyledTestLi>71-90 = Hög (blandat medel och svår)</StyledTestLi>
            <StyledTestLi>91+ = Högst (endast svår)</StyledTestLi>
          </ul>
          {/* No need for any aria-label here because this is a test component for testers/developers */}
          <StyledSettingsLabel>Ability</StyledSettingsLabel>
          <StyledTextInput
            type="number"
            value={(ability || 0).toString()}
            onChange={this.onAbilityChange}
          />
          <StyledSettingsLabel>Målpoäng</StyledSettingsLabel>
          <p>700 krävs för att klara ett mål</p>
          <StyledTextInput
            type="number"
            value={(goalScore || 0).toString()}
            onChange={this.onGoalScoreChange}
            aria-label="Målpoäng"
          />
          <StyledTestContentRow>
            <StyledButtonContainer>
              <StyledInputLabel>Spara</StyledInputLabel>
              <RoundButton
                skin={"primary"}
                callback={onUpdateGoalProgress}
                icon={
                  <StyledIconContainer>
                    <SaveIcon />
                  </StyledIconContainer>
                }
                overrideStyles={{ boxShadow: "none" }}
              />
            </StyledButtonContainer>
          </StyledTestContentRow>
        </StyledTestContentSection>
      </StyledTestColumn>
    );
  };

  private renderTabButtons = () => (
    <StyledTabButtonRack
      style={{ justifyContent: "center", alignSelf: "center", margin: "8px" }}
    >
      <StyledTabButtonContainer>
        <RoundButton
          id={0}
          skin={this.state.activeTab === 0 ? "primary" : "standard"}
          callback={this.setactiveTab}
          icon={<Sliders size={"28px"} />}
          overrideStyles={{ padding: "28px" }}
        />
        <StyledButtonText>Inställningar</StyledButtonText>
      </StyledTabButtonContainer>
      <StyledTabButtonContainer>
        <RoundButton
          id={1}
          skin={this.state.activeTab === 1 ? "primary" : "standard"}
          callback={this.setactiveTab}
          icon={<BookOpen size={"28px"} />}
          overrideStyles={{ padding: "28px" }}
        />
        <StyledButtonText>Historik</StyledButtonText>
      </StyledTabButtonContainer>
    </StyledTabButtonRack>
  );

  private renderTable = (pageSize: number, paginationButtonStyles: {}) => (
    <StyledTestTableColumn>
      <TestTable
        answers={this.props.answers}
        pageIndex={this.props.pageIndex}
        pageSize={pageSize}
      />
      <StyledTestAnswersPagination>
        <RoundButton
          skin={"primary"}
          callback={this.props.previousPage}
          icon={
            <StyledIconContainer>
              <NextArrow style={{ transform: `rotate(180deg)` }} />
            </StyledIconContainer>
          }
          disabled={this.props.pageIndex === 0}
          overrideStyles={{
            ...paginationButtonStyles,
            opacity: this.props.pageIndex === 0 ? ".3" : "1",
          }}
        />
        <RoundButton
          skin={"primary"}
          callback={this.props.nextPage}
          icon={
            <StyledIconContainer>
              <NextArrow />
            </StyledIconContainer>
          }
          disabled={
            (this.props.pageIndex + 1) * pageSize >= this.props.answers.length
          }
          overrideStyles={{
            ...paginationButtonStyles,
            opacity:
              (this.props.pageIndex + 1) * pageSize >= this.props.answers.length
                ? ".3"
                : "1",
          }}
        />
      </StyledTestAnswersPagination>
    </StyledTestTableColumn>
  );

  private onChangeGoal = (selectedItem: DropDownItem) => {
    this.props.setSelectedGoal(selectedItem.value);
  };

  private onAbilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setAbility(parseInt(event.target.value, 10));
  };

  private onGoalScoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setGoalScore(parseInt(event.target.value, 10));
  };
}

const mapStateToProps = (state: RootState) => ({
  answers: state.test.answers,
  pageIndex: state.test.pageIndex,
  goals: state.test.goals,
  selectedGoal: state.test.selectedGoal,
  ability: state.test.ability,
  goalScore: state.test.goalScore,
  goalProgress: state.progress.progress.goal_progress,
  goalStage: state.progress.progress.goal_stage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestAnswers: () => dispatch(fetchAnswers()),
  previousPage: () => dispatch(answersPreviousPage()),
  nextPage: () => dispatch(answersNextPage()),
  onUpdateGoalProgress: () => dispatch(updateGoalProgress()),
  requestGoals: () => dispatch(fetchGoals()),
  requestGoalProgress: () => dispatch(fetchGoalProgress()),
  setSelectedGoal: (goalRef: string) => dispatch(setSelectedGoal(goalRef)),
  setAbility: (ability: number) => dispatch(setAbility(ability)),
  setGoalScore: (goalScore: number) => dispatch(setGoalScore(goalScore)),
});

export const TestView = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestViewComponent);
