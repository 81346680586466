import { createGlobalStyle } from "styled-components";

import { base } from "./base";
import { global } from "./global";

const GlobalStyle = createGlobalStyle`
  ${base}
  ${global}
`;

export default GlobalStyle;
