import * as React from "react";

import XcSimpleImage from "./XcSimpleImage";
import { XcMultiSvg, SVGData } from "./XcMultiSvg/XcMultiSvg";

interface OldSVGData {
  X: number;
  Y: number;
  Size: string;
  AssetId: string;
  AssetPath: string;
}

export interface OldImageProps {
  Icons?: SVGData[];
  Format?: string;
  Path?: string;
  alt?: string;
  height?: number | string;
  width?: number | string;
  position?: string;
}

export interface ImageProps {
  image?: any;
  icons?: SVGData[];
  format?: string;
  alt?: string;
  height?: number | string;
  width?: number | string;
  position?: string;
}

const OldXcImage: React.FC<OldImageProps> = ({
  Icons,
  Format,
  Path,
  height,
  width,
  alt,
  position,
}) => {
  const altText = alt || "";

  if (Icons) {
    return (
      <XcMultiSvg
        icons={Icons}
        format={Format}
        width={width || "100%"}
        height={height || "100%"}
        position={position}
      />
    );
  } else {
    return (
      <XcSimpleImage
        Path={Path || ""}
        alt={altText}
        height={height}
        width={width}
      />
    );
  }
};

const NewXcImage: React.FC<ImageProps> = (props) => {
  const { image, icons, format, height, width, alt, position } = props;
  const altText = alt || "";

  if (icons) {
    return (
      <XcMultiSvg
        icons={icons}
        format={format}
        width={width || "100%"}
        height={height || "100%"}
        position={position}
      />
    );
  } else {
    const { src = "", attributes = {} } = image || {};
    return <XcSimpleImage Path={src} alt={altText} {...attributes} />;
  }
};

export const XcImage: React.FC<any> = (props) => {
  if (props.Icons) {
    const newProps = {
      ...props,
      Icons: props.Icons.map((icon: OldSVGData) => ({
        x: icon.X,
        y: icon.Y,
        size: icon.Size,
        image: { src: icon.AssetPath },
      })),
    };
    return <OldXcImage {...newProps} />;
  } else if (props.Path) {
    return <OldXcImage {...props} />;
  } else {
    return <NewXcImage {...props} />;
  }
};

export default XcImage;
